import React, { useCallback, useEffect } from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Button, Box, Breadcrumb, Carousel, Form, Flex, Select, Pagination, Tag } from '@cowsquare/design';
import { themeGet } from '@styled-system/theme-get';
import { Form as FinalForm, Field } from 'react-final-form';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { useQueryParams, StringParam, NumberParam } from 'use-query-params';
import { Section } from '../../components/Section';
import LocationCard from '../../components/LocationCard';
import { COUNTRY_MAP, COUNTRY_BANNER_MAP } from '../../../constants/country';
import { useUser } from '../../../utils/hooks';
import AddToPlanButton from '../../components/AddToPlanButton';
import FreePlannerIntroSection from '../../components/FreePlannerIntroSection';
import { LocationCardLoading } from 'views/components/Loading';
import { useMachine } from '@xstate/react';
import { attractionMachine, ATTRACTION_STATES, ATTRACTION_EVENTS } from 'machines/attractionMachines';
import { assign } from 'xstate';

const TravelFilterFlex = styled(Flex).attrs({
  width: 1,
  mt: '-56px',
  flexDirection: ['column', 'row'],
  alignItems: ['initial', 'center'],
})`
  z-index: 1;
  background-color: ${themeGet('colors.whites.0')};
  border-radius: 8px;

  & > ${Form} {
    flex: 1;
  }
`;

const TourCardBox = styled(Box).attrs({
  width: [1, 1 / 3],
  pb: ['24px', '48px'],
  px: [0, 4],
})``;

const StyledFormItem = styled(Form.FormItem)`
  margin-bottom: 0;
`;
const defaultOption = { name: '全部', value: '' };
const getOptions = (name, data) =>
  R.pipe(
    R.propOr([], name),
    R.map(x => ({ ...x, value: x.name })),
    R.prepend(defaultOption)
  )(data);
const getOptionByName = (value, data, defaultValue = defaultOption) =>
  R.pipe(R.find(R.propEq('name', value)), R.defaultTo(defaultValue))(data);

const GET_ALL_OPTIONS = gql`
  query GET_ALL_OPTIONS($country: String!) {
    features: vontravel_features {
      name
    }

    areas: vontravel_areas(where: { country: { name: { _eq: $country } } }) {
      name
    }

    audience: vontravel_audience {
      name
    }
  }
`;

const AttractionList = () => {
  const { country } = useParams();
  const history = useHistory();
  const { user } = useUser();
  const userId = R.propOr(null, 'id', user);
  const countryName = COUNTRY_MAP[country];

  if (!countryName) {
    // TODO: When 404 page is done, change redirect url to 404 page.
    history.push('/');
  }
  const { data } = useQuery(GET_ALL_OPTIONS, { variables: { country } });
  const [query, setQuery] = useQueryParams({
    feature: StringParam,
    area: StringParam,
    audience: StringParam,
    page: NumberParam,
  });
  const { feature, area, audience, page = 1 } = query;
  const [current, send] = useMachine(attractionMachine, {
    context: {
      country,
      feature,
      area,
      audience,
      userId,
      pageSize: 12,
      page,
    },
    actions: {
      refetch: assign({
        country,
        feature,
        area,
        audience,
        userId,
        page,
      }),
    },
  });

  useEffect(() => {
    send(ATTRACTION_EVENTS.REFETCH);
  }, [country, feature, area, audience, userId, page, send]);

  const { data: attractionsData, pageSize } = current.context;
  const areaOptions = getOptions('areas', data);
  const featureOptions = getOptions('features', data);
  const audienceOptions = getOptions('audience', data);
  const totalAttractions = R.pathOr(0, ['meta', 'aggregate', 'count'], attractionsData);
  const onQuery = useCallback(
    values => {
      setQuery({
        ...query,
        page: 1,
        feature: R.path(['feature', 'value'], values),
        area: R.path(['area', 'value'], values),
        audience: R.path(['audience', 'value'], values),
      });
    },
    [query, setQuery]
  );
  const onPageChange = useCallback(
    nextPage => {
      setQuery({ ...query, page: nextPage });
    },
    [query, setQuery]
  );

  return (
    <>
      <Carousel>
        <Carousel.Slide
          title={`${countryName}優遊地點`}
          backgroundImage={`url(${COUNTRY_BANNER_MAP[country]})`}
          height="304px"
        />
      </Carousel>
      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        <Section px={[5, 0]}>
          <TravelFilterFlex px={5} pt={5} pb={[5, 0]}>
            <FinalForm
              onSubmit={onQuery}
              initialValues={{
                area: getOptionByName(area, areaOptions, defaultOption),
                audience: getOptionByName(audience, audienceOptions, defaultOption),
                feature: getOptionByName(feature, featureOptions, defaultOption),
              }}
              render={({ handleSubmit, submitting }) => (
                <>
                  <Form>
                    <Flex flexDirection={['column', 'row']} justifyContent="space-between">
                      <Box flex={1} pr={[0, 5]}>
                        <Field name="area">
                          {({ input, meta }) => (
                            <StyledFormItem label="城市/地區" input={input} meta={meta}>
                              <Select {...input} placeholder="全部" options={areaOptions} size="large" />
                            </StyledFormItem>
                          )}
                        </Field>
                      </Box>
                      <Box flex={1} pr={[0, 5]}>
                        <Field name="audience">
                          {({ input, meta }) => (
                            <StyledFormItem label="對象" input={input} meta={meta}>
                              <Select {...input} placeholder="全部" options={audienceOptions} size="large" />
                            </StyledFormItem>
                          )}
                        </Field>
                      </Box>
                      <Box flex={1} pr={[0, 5]}>
                        <Field name="feature">
                          {({ input, meta }) => (
                            <StyledFormItem label="特色" input={input} meta={meta}>
                              <Select {...input} placeholder="全部" options={featureOptions} size="large" />
                            </StyledFormItem>
                          )}
                        </Field>
                      </Box>
                    </Flex>
                  </Form>
                  <Box>
                    <Flex justifyContent="flex-end">
                      <Button onClick={handleSubmit} disabled={submitting} variant="primary" size="large">
                        搜尋
                      </Button>
                    </Flex>
                  </Box>
                </>
              )}
            ></FinalForm>
          </TravelFilterFlex>
          <Box mt={6}>
            <Breadcrumb>
              <Breadcrumb.Item linkProps={{ as: Link, to: '/' }}>首頁</Breadcrumb.Item>
              <Breadcrumb.Item>{`優遊地點-${countryName}`}</Breadcrumb.Item>
            </Breadcrumb>
          </Box>
        </Section>
      </Flex>
      <Section flexDirection="column" margin="48px auto 80px" px={[5, 0]}>
        <Flex
          flexDirection={['column', 'row']}
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          mx={-4}
          mb={['-24px', '-48px']}
        >
          {current.matches(ATTRACTION_STATES.LOADING) &&
            R.times(
              i => (
                <TourCardBox key={i}>
                  <LocationCardLoading />
                </TourCardBox>
              ),
              3
            )}
          {current.matches(ATTRACTION_STATES.LOADED) &&
            R.pipe(
              R.propOr([], 'list'),
              R.map(item => (
                <TourCardBox key={item.id}>
                  <LocationCard
                    id={item.id}
                    collectible={!R.pipe(R.propOr([], 'users'), R.isEmpty)(item)}
                    as={Link}
                    to={`/優遊地點/${country}/${item.name}`}
                    // TODO: 加上預設圖
                    coverUrl={R.path(['cover', 'link'], item)}
                    title={item.name}
                    height="410px"
                    tags={R.map(
                      x => (
                        <Tag key={x.feature.name}>{x.feature.name}</Tag>
                      ),
                      item.features
                    )}
                    location={`${countryName} ${R.pathOr('', ['area', 'name'], item)}`}
                    links={
                      <AddToPlanButton
                        onClick={e => {
                          e.preventDefault();
                          history.push(
                            `/自助設計優遊/新計劃?cardType=attraction&cardName=${item.name}&country=${country}`
                          );
                        }}
                      />
                    }
                  />
                </TourCardBox>
              ))
            )(attractionsData)}
          {/* --- TODO: handle EMPTY & ERROR --- */}
          {current.matches(ATTRACTION_STATES.EMPTY) && null}
          {current.matches(ATTRACTION_STATES.ERROR) && null}
        </Flex>
        {totalAttractions > pageSize && (
          <Box mt={6} width={1}>
            <Flex justifyContent="center">
              <Pagination
                total={totalAttractions}
                current={page}
                pageSize={pageSize}
                onChange={onPageChange}
              />
            </Flex>
          </Box>
        )}
      </Section>
      <FreePlannerIntroSection />
    </>
  );
};

export default AttractionList;
