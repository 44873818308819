import React from 'react';
import RemoveFromPlanButton from '../RemoveFromPlanButton';
import { themeGet } from '@styled-system/theme-get';
import styled, { css } from 'styled-components';
import { background } from 'styled-system';
import { Box } from '@cowsquare/design';

const StyledLink = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  box-shadow: ${themeGet('card.boxShadow')};
  background-color: ${themeGet('card.backgroundColor')};
  border: ${themeGet('card.border')};
  border-radius: ${themeGet('card.borderRadius')};
  border-color: ${themeGet('card.borderColor')};
  text-decoration: none;
  height: 323px;
`;

const ellipsisByLinesCss = lines => css`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${lines};
  -webkit-box-orient: vertical;
`;

const Cover = styled.div`
  ${background}
  height: 160px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
`;

const Title = styled.h3`
  ${ellipsisByLinesCss(1)}
  margin: 8px 16px;
  line-height: 1.5;
  color: ${themeGet('card.title.color')};
  font-size: ${themeGet('card.title.fontSize')};
`;

const Description = styled.p`
  ${ellipsisByLinesCss(2)}
  margin: 0 16px;
  line-height: 1.5;
  color: ${themeGet('card.description.color')};
  font-size: ${themeGet('card.description.fontSize')};
`;

const PlanCard = ({ coverUrl, title, description, onRemove, onClick }) => {
  return (
    <StyledLink rel="noopener" onClick={onClick}>
      <Box>
        <Cover backgroundImage={`url(${coverUrl})`} />

        <Title>{title}</Title>
        <Description>{description}</Description>
      </Box>
      <Box p="16px">
        <RemoveFromPlanButton
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            onRemove();
          }}
        />
      </Box>
    </StyledLink>
  );
};

export default React.memo(PlanCard);
