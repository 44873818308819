import React from 'react';
import * as R from 'ramda';
import { Redirect, Route } from 'react-router-dom';
import { ANONYMOUS } from '../../constants/role';
import { useUser } from '../../utils/hooks';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { user, loading } = useUser();

  const role = R.propOr(ANONYMOUS, ['role'], user);

  // TODO: return 取得 user 資訊時的載入畫面
  if (loading) return null;

  return (
    <Route
      {...rest}
      render={props => (role !== ANONYMOUS ? <Component {...props} /> : <Redirect to="/" />)}
    />
  );
};

export default PrivateRoute;
