import React from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { color, layout } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import { Button, Box, Flex } from '@cowsquare/design';
import { getUri } from '../../utils/getUri';
import { useBannersQuery } from '../../utils/hooks';
import PickTourIcon from '../../images/icon_pick_tour.svg';
import AddToPlanIcon from '../../images/icon_add_to_plan.svg';
import TourPriceIcon from '../../images/icon_tour_price.svg';
import { Banners } from '../components/Banners';

const StepFlex = styled(Flex).attrs({
  width: 1,
  flexDirection: 'column',
  alignItems: 'center',
})`
  ${color}
`;

const StepIcon = styled(Box).attrs({
  as: 'img',
  mb: 6,
})``;

const Title = styled(Box).attrs({ as: 'p', m: 0 })`
  font-size: ${themeGet('fontSizes.h1')};
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
`;

const StepDescription = styled(Box).attrs({ as: 'p', m: 0, mt: 4 })`
  font-size: ${themeGet('fontSizes.h4')};
  line-height: 1.5;
  text-align: center;
`;

const Step = ({ icon, title, description }) => (
  <Flex maxWidth="680px" py={7} flexDirection="column" alignItems="center">
    <StepIcon src={icon} />
    <Title>{title}</Title>
    <StepDescription>{description}</StepDescription>
  </Flex>
);

const ActionBar = styled(Box)`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 64px;
  background-color: ${themeGet('colors.whites.0')};
  box-shadow: 0 -2px 10px 0 ${themeGet('colors.blacks.10')};
  z-index: ${themeGet('zIndex.fixed')};
`;

const PlanLink = styled(Box)`
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  color: ${themeGet('link.color')};
  ${layout}
`;

const FreePlanner = () => {
  const location = useLocation();
  const uri = getUri(location);
  const { banners } = useBannersQuery(uri);

  return (
    <>
      <Banners banners={banners} itemProps={{ height: '424px' }} />
      <Flex width={1} px={[5, 0]} flexDirection="column" alignItems="center" justifyContent="center">
        <Box mt={7}>
          <Title>3個簡單步驟</Title>
          <Title>輕鬆設計您的專屬旅程</Title>
        </Box>
        <StepFlex>
          <Step
            icon={PickTourIcon}
            title="瀏覽想去的景點或旅程"
            description="Von Travel 提供多個景點與不同主題的行程供您挑選，無論是親子遊、家庭出遊、好友旅行，都可以完整規劃。"
          />
        </StepFlex>
        <StepFlex backgroundColor="white">
          <Step
            icon={AddToPlanIcon}
            title="加入自助設計優遊"
            description="在整個網站中的任何一張行程或景點卡，點擊【加入旅遊計劃】，即可快速將您想去的景點或行程加入自助行程設計，日期、順序一目瞭然！"
          />
        </StepFlex>
        <StepFlex>
          <Step
            icon={TourPriceIcon}
            title="自動估算旅費"
            description="系統會根據您的行程設計而提供即時的網上初步旅費估算，讓您運籌帷幄。"
          />
        </StepFlex>
      </Flex>
      <ActionBar>
        <Flex justifyContent="center" alignItems="center" height="100%">
          <PlanLink display={['none', 'block']}>馬上開始規劃行程吧！</PlanLink>
          <Button variant="primary" size="large" as={Link} to="/自助設計優遊/新計劃">
            開始設計行程
          </Button>
        </Flex>
      </ActionBar>
    </>
  );
};

export default FreePlanner;
