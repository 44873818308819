import React, { useMemo } from 'react';
import * as R from 'ramda';
import { PlanContextProvider } from '../components/PlanContext';
import { DateParam, useQueryParams, StringParam } from 'use-query-params';
import PlanTour from './PlanTour';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { CARD_TYPE } from '../components/planTourDrawer/constants/CARD_TYPE';

const GET_TOUR_RECORD = gql`
  query getTourRecord($country: String!, $name: String!) {
    record: vontravel_tours(where: { name: { _eq: $name }, country: { name: { _eq: $country } } }) {
      id
      name
      price
      cover {
        link
      }
      description {
        content
      }
      days
    }
  }
`;

const GET_ATTRACTIONS_CONTENT = gql`
  query getAttractionsContent($name: String!, $country: String!) {
    record: vontravel_attractions(
      where: { name: { _eq: $name }, area: { country: { name: { _eq: $country } } } }
    ) {
      id
      name
      price
      baby_evaluted_price
      children_evaluted_price
      teen_evaluted_price
      adult_evaluted_price
      elder_evaluted_price
      cover {
        link
      }
      description {
        content
      }
    }
  }
`;

const PlanTourPage = () => {
  const [{ endDate, startDate, country, cardName, cardType: urlCardType = '' }] = useQueryParams({
    endDate: DateParam,
    startDate: DateParam,
    country: StringParam,
    cardType: StringParam,
    cardName: StringParam,
  });

  const cardType =
    urlCardType === ''
      ? null
      : urlCardType.toLocaleLowerCase() === 'tour'
      ? CARD_TYPE.TOUR
      : CARD_TYPE.ATTRACTION;

  const { data } = useQuery(cardType === CARD_TYPE.TOUR ? GET_TOUR_RECORD : GET_ATTRACTIONS_CONTENT, {
    skip: !(country && cardType && cardName),
    variables: {
      country,
      name: cardName,
    },
  });
  const firstCard = useMemo(() => {
    const card = R.path(['record', 0], data);
    return card
      ? {
          ...card,
          cardType,
        }
      : undefined;
  }, [cardType, data]);

  return (
    <PlanContextProvider endDate={endDate} startDate={startDate} firstCard={firstCard}>
      <PlanTour />
    </PlanContextProvider>
  );
};

export default PlanTourPage;
