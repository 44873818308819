import React from 'react';
import styled from 'styled-components';
import { Box, Button, Flex, Form, Input, Notification } from '@cowsquare/design';
import { themeGet } from '@styled-system/theme-get';
import { Form as FinalForm, Field } from 'react-final-form';
import * as R from 'ramda';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import * as rules from '../../../utils/validate';
import { useUser } from '../../../utils/hooks';

const FormItem = Form.FormItem;

const UPDATE_USER_INFO_MUTATION = gql`
  mutation UpdateUserInfo($user: UpdateUserInput!) {
    update_user_info(user: $user) {
      name
      phone
    }
  }
`;

const Title = styled(Box).attrs({ as: 'h1', m: 0, mb: 5 })`
  font-size: ${themeGet('fontSizes.h2')};
  line-height: 1.5;
  font-weight: 500;
`;

const InfoForm = () => {
  const { user } = useUser();
  const [updateUser] = useMutation(UPDATE_USER_INFO_MUTATION);

  return (
    <FinalForm
      initialValues={{
        name: R.propOr('', 'name', user),
        email: R.propOr('', 'email', user),
        phone: R.propOr('', 'phone', user),
      }}
      onSubmit={formValues =>
        updateUser({
          variables: {
            user: {
              id: user.id,
              name: formValues.name,
              phone: formValues.phone,
            },
          },
        }).then(() => {
          Notification.success({ message: '已成功更新個人資料', description: '請確認個人資料是否正確' });
        })
      }
    >
      {({ handleSubmit, submitting }) => (
        <Form onSubmit={handleSubmit}>
          <Title>基本資料</Title>
          <Field name="name" validate={rules.required('請輸入姓名')}>
            {({ input, meta }) => (
              <FormItem label="姓名" input={input} meta={meta}>
                <Input {...input} size="large" placeholder="請輸入姓名" />
              </FormItem>
            )}
          </Field>
          <Field name="email">
            {({ input, meta }) => (
              <FormItem label="電子郵件" input={input} meta={meta}>
                <Input {...input} size="large" disabled />
              </FormItem>
            )}
          </Field>
          <Field name="phone">
            {({ input, meta }) => (
              <FormItem label="電話號碼" input={input} meta={meta}>
                <Input {...input} size="large" placeholder="請輸入電話號碼" />
              </FormItem>
            )}
          </Field>
          <Flex justifyContent="flex-end">
            <Button w="84px" size="large" variant="primary" disabled={submitting}>
              儲存
            </Button>
          </Flex>
        </Form>
      )}
    </FinalForm>
  );
};

export default InfoForm;
