import React from 'react';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Box } from '@cowsquare/design';
import Home from '../pages/Home';
import CreateTour from '../pages/CreateTour';
import FreeTourList from '../pages/FreeTourList';
import FreeTour from '../pages/FreeTour';
import ThemeTour from '../pages/ThemeTour';
import ThemeTourList from '../pages/ThemeTourList';
import FreeTourDetail from '../pages/FreeTourDetail';
import AttractionList from '../pages/attractions/AttractionList';
import AttractionDetail from '../pages/attractions/AttractionDetail';
import WishList from '../pages/WishList';
import AboutUs from '../pages/AboutUs';
import StudyTour from '../pages/StudyTour';
import FreePlanner from '../pages/FreePlanner';
import CharterService from '../pages/CharterService';
import PrivateRoute from '../components/PrivateRoute';
import Epaper from './epaper/Epaper';
import Footer from './footer/Footer';
import Header from './header/Header';
import MemberForm from '../components/MemberForm';
import PlanTourPage from '../pages/PlanTourPage';
import UserInfo from '../pages/userInfo';
import ContactUs from 'views/pages/ContactUs';
import Checkout from 'views/pages/Checkout';
import Thankyou from 'views/pages/Thankyou';
import Errors from 'views/pages/ErrorCheckout';
import ResetPassword from '../pages/ResetPassword';
import Conditions from 'views/pages/Conditions';
import ErrorPage from 'views/pages/Error';
import { useIosSafari } from '../../utils/hooks';
import Homestay from 'views/pages/Homestay';
import HomestayList from 'views/pages/HomestayList';
import HomestayDetail from 'views/pages/HomestayDetail';

const Container = styled(Box)`
  background-color: ${themeGet('colors.background')};
`;

const Content = styled.div`
  width: 100%;
  margin: 0 auto;
`;

export const Layout = ({ match }) => {
  const isIosSafari = useIosSafari();
  // use calc for fix Safari 100vh bug
  // ref: https://stackoverflow.com/questions/37112218/css3-100vh-not-constant-in-mobile-browser
  // 56px = header height
  return (
    <Container as="main" minHeight={isIosSafari ? 'calc(100vh - 56px)' : '100vh'}>
      <Header />
      <Content>
        <Switch>
          <Route exact path={`${match.url}`} component={Home} />
          <Route exact path={`${match.url}自由旅程/:country`} component={FreeTour} />
          <Route exact path={`${match.url}自由旅程/:country/所有旅程`} component={FreeTourList} />
          <Route exact path={`${match.url}自由旅程/:country/所有旅程/:name`} component={FreeTourDetail} />
          <Route exact path={`${match.url}優遊地點/:country`} component={AttractionList} />
          <Route exact path={`${match.url}優遊地點/:country/:name`} component={AttractionDetail} />
          <Route exact path={`${match.url}主題優遊`} component={ThemeTour} />
          <Route exact path={`${match.url}主題優遊/:feature`} component={ThemeTourList} />
          <Route exact path={`${match.url}民宿優選`} component={Homestay} />
          <Route exact path={`${match.url}民宿優選/:country`} component={HomestayList} />
          <Route exact path={`${match.url}民宿優選/:country/:name`} component={HomestayDetail} />
          <Route exact path={`${match.url}更多/關於我們`} component={AboutUs} />
          <Route exact path={`${match.url}更多/聯絡我們`} component={ContactUs} />
          <Route exact path={`${match.url}checkout`} component={Checkout} />
          <Route exact path={`${match.url}thankyou`} component={Thankyou} />
          <Route exact path={`${match.url}error-checkout`} component={Errors} />
          <Route exact path={`${match.url}更多/遊學團`} component={StudyTour} />
          <Route exact path={`${match.url}更多/(隱私政策|使用條款)`} component={Conditions} />
          <Route exact path={`${match.url}自助設計優遊`} component={FreePlanner} />
          <Route exact path={`${match.url}自助設計優遊/新計劃`} component={CreateTour} />
          <Route exact path={`${match.url}包車旅遊`} component={CharterService} />
          <PrivateRoute exact path={`${match.url}願望清單`} component={WishList} />
          <PrivateRoute exact path={`${match.url}個人資料`} component={UserInfo} />
          <Route exact path={`${match.url}重設密碼`} component={ResetPassword} />
          <Redirect to="/error/404" />
        </Switch>
      </Content>
      <Epaper />
      <Footer />
      <MemberForm />
    </Container>
  );
};

export const SimpleLayout = ({ match }) => {
  const isIosSafari = useIosSafari();
  // use calc for fix Safari 100vh bug
  // ref: https://stackoverflow.com/questions/37112218/css3-100vh-not-constant-in-mobile-browser
  // 112px = 56px + 56px = header height
  return (
    <Container as="main" minHeight={isIosSafari ? 'calc(100vh - 112px)' : '100vh'}>
      <Header />
      <Switch>
        <Route exact path={`${match.url}/`} component={PlanTourPage} />
        <Redirect to="/error/404" />
      </Switch>
      <MemberForm />
    </Container>
  );
};

export const ErrorLayout = ({ match }) => {
  return (
    <Container>
      <Header />
      <Switch>
        <Route exact path={`${match.url}/:status`} component={ErrorPage} />
        <Route path="*" component={ErrorPage} />
      </Switch>
    </Container>
  );
};
