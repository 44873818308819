import React from 'react';

export const ContactIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20.629 4C26.357 4 31 8.643 31 14.371v.033c0 3.227-1.474 6.11-3.785 8.012L17.394 31v-6.225h-6.023C5.643 24.776 1 20.133 1 14.406v-.034C1 8.643 5.643 4 11.371 4zm-1.57 13.75H10v1.5h9.058v-1.5zm3.2-4H10v1.5h12.258v-1.5zm-1-4H10v1.5h11.258v-1.5z"
    />
  </svg>
);
