import React, { useState, useCallback } from 'react';
import * as R from 'ramda';
import { Tag, Box, Button, Flex, Icon, Modal, ModalBody, ModalHeader } from '@cowsquare/design';
import * as D from 'date-fns/fp';
import styled from 'styled-components';
import CreateTourForm from './CreateTourForm';
import { themeGet } from '@styled-system/theme-get';
import { position, color, layout, border } from 'styled-system';
import { sum } from 'utils/sum';

const format = date => (date ? D.format('yyyy-MM-dd', date) : '');

const InfoBarWrap = styled(Flex).attrs({
  justifyContent: 'space-between',
  backgroundColor: ['initial', 'whites.0'],
  borderTop: ['none', '1px solid'],
  borderColor: ['unset', 'blacks.10'],
})`
  ${color}
  ${border}
  font-size: ${themeGet('fontSizes.text')};
`;

const StyledTag = styled(Tag)`
  max-width: initial;
  padding: 6px 8px;
`;

const MobileInfoBar = ({ country, startDate, endDate, totalPeople, onClick }) => {
  return (
    <Box px="16px" py="12px" display={['initial', 'none']} onClick={onClick}>
      <Tag.Group>
        <StyledTag>{country}</StyledTag>
        <StyledTag>
          {format(startDate)} ~ {format(endDate)}
        </StyledTag>
        <StyledTag>{totalPeople}位</StyledTag>
      </Tag.Group>
    </Box>
  );
};

const LabelWithIcon = ({ icon, label }) => (
  <Flex mr="32px" alignItems="center">
    <Box mr="8px">{icon}</Box>
    {label}
  </Flex>
);

const DesktopInfoBarWrap = styled(Box).attrs({
  px: '16px',
  py: '24px',
  display: ['none', 'flex'],
})`
  font-weight: 500;
  cursor: pointer;
`;

const DesktopInfoBar = ({
  country,
  startDate,
  endDate,
  infants,
  children,
  teenagers,
  adults,
  elders,
  onClick,
}) => {
  const travelersLabel = R.compose(
    R.join(' '),
    R.map(([number, label]) => `${number}位${label}`),
    R.filter(([number]) => Boolean(number))
  )([
    [infants, '嬰兒 / 幼童'],
    [children, '小童'],
    [teenagers, '青少年'],
    [adults, '成人'],
    [elders, '長者'],
  ]);

  return (
    <DesktopInfoBarWrap onClick={onClick}>
      <LabelWithIcon icon={<Icon name="location_fill" color="primary" />} label={country} />
      <LabelWithIcon
        icon={<Icon name="calendar" color="success" />}
        label={`${format(startDate)} ~ ${format(endDate)}`}
      />
      <LabelWithIcon icon={<Icon name="user" color="orange" />} label={travelersLabel} />
    </DesktopInfoBarWrap>
  );
};

const ActionButtonWrap = styled.div.attrs({
  position: ['fixed', 'initial'],
  bg: ['whites.0', 'initial'],
  width: ['100%', '400px'],
})`
  ${position}
  ${color}
  ${layout}
  bottom: 0;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: ${themeGet('zIndex.fixed')};
`;

const ErrorMessage = styled.div`
  color: ${themeGet('colors.danger')};
  font-size: ${themeGet('fontSizes.text')};
`;

const TourInfoBar = ({
  country,
  startDate,
  endDate,
  plans,
  infants = 0,
  children = 0,
  teenagers = 0,
  adults = 0,
  elders = 0,
  onChange,
  onSend,
}) => {
  const [open, setOpen] = useState(false);
  const handleClose = useCallback(e => {
    e.stopPropagation();
    setOpen(false);
  }, []);
  const handleOpen = useCallback(e => {
    e.stopPropagation();
    setOpen(true);
  }, []);

  const handleSubmit = useCallback(
    values => {
      if (sum([values.infants, values.children, values.teenagers, values.adults, values.elders]) === 0) {
        return {
          infants: '請輸入旅行人數；至少要有一名成年人同行',
          children: true,
          teenagers: true,
          adults: true,
          elders: true,
        };
      }
      if (sum([values.adults, values.elders]) === 0) {
        return {
          adults: '至少要有一名成年人同行',
          elders: true,
        };
      }
      setOpen(false);
      onChange(values);
    },
    [onChange]
  );

  const showError = R.isEmpty(plans);

  const [minPlanDate, maxPlanDate] = R.compose(R.juxt([D.min, D.max]), R.map(R.prop('date')))(plans);

  return (
    <>
      <InfoBarWrap>
        <MobileInfoBar
          country={country}
          startDate={startDate}
          endDate={endDate}
          totalPeople={R.sum([infants, children, teenagers, adults, elders])}
          onClick={handleOpen}
        />

        <DesktopInfoBar
          country={country}
          startDate={startDate}
          endDate={endDate}
          infants={infants}
          children={children}
          teenagers={teenagers}
          adults={adults}
          elders={elders}
          onClick={handleOpen}
        />

        <ActionButtonWrap>
          <ErrorMessage>{showError ? '請輸入最少一個優遊旅程或景點以進行報價' : ''}</ErrorMessage>
          <Button
            onClick={onSend}
            variant={['default', 'primary']}
            size={['large', 'default']}
            disabled={showError}
          >
            送出估價
          </Button>
        </ActionButtonWrap>
      </InfoBarWrap>

      <Modal borderRadius="8px" width={['auto', '856px']} show={open} onClose={handleClose} closeButton>
        <ModalHeader divider={false}></ModalHeader>
        <ModalBody p={5} pt={0}>
          <CreateTourForm
            initialValues={{
              country,
              startDate,
              endDate,
              infants,
              children,
              teenagers,
              adults,
              elders,
            }}
            onSubmit={handleSubmit}
            minPlanDate={R.isEmpty(plans) ? null : minPlanDate}
            maxPlanDate={R.isEmpty(plans) ? null : maxPlanDate}
            submitButtonLabel="儲存"
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default React.memo(TourInfoBar);
