import React from 'react';
import { Button } from '@cowsquare/design';
import styled from 'styled-components';

const AddButton = styled(Button)`
  height: auto;
  padding: 0;
`;

const AddToPlanButton = props => (
  <AddButton variant="link" prefixIcon="ADD_SQUARE" {...props}>
    加入旅遊計劃
  </AddButton>
);

export default AddToPlanButton;
