import { Box, Button, Icon } from '@cowsquare/design';
import { themeGet } from '@styled-system/theme-get';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import * as R from 'ramda';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import posed, { PoseGroup } from 'react-pose';
import styled from 'styled-components';
import { headerMenu, MENU_STATUS } from '../../../constants/menu';
import { useUser, useMemberForm, useMobileMedia } from '../../../utils/hooks';
import { Logo, LogoLight } from '../Logo';
import { DesktopMenu, DesktopUser } from './DesktopMenu';
import { MenuIcon } from './Icons';
import { MobileMenu, MobileUser } from './MobileMenu';

const Content = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  padding: 0 24px;
  height: 56px;
  background-color: white;
  z-index: ${themeGet('zIndex.sticky')};
`;

const SideMenuAnimation = posed.div({
  enter: {
    x: 0,
    transition: { duration: 300, ease: 'circOut' },
  },
  exit: {
    x: '100%',
    transition: { duration: 200, ease: 'circIn' },
    delay: 0,
  },
});

const SideMenu = styled(SideMenuAnimation)`
  display: flex;
  flex-direction: column;
  background-color: ${themeGet('colors.background')};
  height: 100%;
  overflow: auto;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: ${themeGet('zIndex.fixed')};
  -webkit-overflow-scrolling: touch;
`;

const SideMenuHeader = styled.div`
  background-color: #0080c8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  padding: 0 24px;
`;

const SideMenuButton = styled(Button).attrs({
  variant: 'link',
})`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  padding: 0;
`;

const Header = () => {
  const targetRef = React.createRef();
  const { user } = useUser();
  const [menuStatus, setMenuStatus] = useState(MENU_STATUS.OFF);
  const { openLoginForm, openSignupForm } = useMemberForm();
  const isMobileMedia = useMobileMedia();

  const openMenu = useCallback(() => {
    setMenuStatus(MENU_STATUS.ON);
  }, []);
  const closeMenu = useCallback(() => {
    clearAllBodyScrollLocks();
    setMenuStatus(MENU_STATUS.OFF);
  }, []);

  useEffect(() => {
    if (isMobileMedia && menuStatus !== MENU_STATUS.OFF) {
      disableBodyScroll(targetRef.current);
    }
    return () => clearAllBodyScrollLocks();
  }, [isMobileMedia, menuStatus, targetRef]);

  const menu = R.propOr([], 'default', headerMenu);

  return (
    <Content>
      {/* --- Desktop Menu --- */}
      <Box width={1} display={['none', 'flex']} justifyContent="space-between" alignItems="center">
        <Box>
          <Link to="/">
            <Logo />
          </Link>
        </Box>
        <Box>
          <DesktopMenu menu={menu} />
        </Box>
        <Box>
          <DesktopUser user={user} onLoginClick={openLoginForm} onSignupClick={openSignupForm} />
        </Box>
      </Box>
      {/* --- Mobile Menu --- */}
      <Box width={1} display={['flex', 'none']} justifyContent="space-between" alignItems="center">
        <Box>
          <Link to="/">
            <Logo />
          </Link>
        </Box>
        <SideMenuButton onClick={openMenu}>
          <MenuIcon />
        </SideMenuButton>
        <PoseGroup>
          {menuStatus !== MENU_STATUS.OFF && (
            <SideMenu key="mobile-menu" ref={targetRef}>
              {/* this div is for safari's bug */}
              <div>
                <SideMenuHeader>
                  <Box>
                    <Link to="/" onClick={closeMenu}>
                      <LogoLight />
                    </Link>
                  </Box>
                  <SideMenuButton onClick={closeMenu}>
                    <Icon name="close" color="#fff" />
                  </SideMenuButton>
                </SideMenuHeader>
              </div>
              {menuStatus === MENU_STATUS.ON && (
                <BasicMobileMenu
                  menu={menu}
                  user={user}
                  closeMenu={closeMenu}
                  onLoginClick={openLoginForm}
                  onSignupClick={openSignupForm}
                />
              )}
            </SideMenu>
          )}
        </PoseGroup>
      </Box>
    </Content>
  );
};

const BasicMobileMenu = ({ menu, user, ...restProps }) => (
  <>
    <MobileMenu menu={menu} {...restProps} />
    <MobileUser user={user} {...restProps} />
  </>
);

export default Header;
