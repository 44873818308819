export const FOOTER_SERVICE = [
  {
    name: '自由旅程',
    link: '/自由旅程/中國',
  },
  {
    name: '優遊地點',
    link: '/優遊地點/台灣',
  },
  {
    name: '主題優遊',
    link: '/主題優遊',
  },
  {
    name: '自助設計優遊',
    link: '/自助設計優遊',
  },
  {
    name: '民宿優選',
    link: '/民宿優選',
  },
  {
    name: '包車優遊',
    link: '/包車旅遊',
  },
];

export const FOOTER_MORE = [
  {
    name: '關於我們',
    link: '/更多/關於我們',
  },
  {
    name: '聯絡我們',
    link: '/更多/聯絡我們',
  },
  {
    name: '遊學團',
    link: '/更多/遊學團',
  },
];

export const FOOTER_OTHER = [
  {
    name: '條款及聲明',
    link: '/更多/使用條款',
  },
];
