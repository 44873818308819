import React, { useCallback, useEffect } from 'react';
import { Flex, Breadcrumb, Box, Select, Button, Input, Heading, Pagination } from '@cowsquare/design';
import { useParams, Link, useLocation } from 'react-router-dom';
import { Section } from 'views/components/Section';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { Form } from '@cowsquare/design/src/form/Form';
import { Form as FinalForm, Field } from 'react-final-form';
import { useMachine } from '@xstate/react';
import * as R from 'ramda';
import { TourCardLoading } from 'views/components/Loading';
import HomestayCard from 'views/components/HomestayCard';
import { getBedAmount, getRate } from 'utils/homestay';
import { NumberParam, ArrayParam, StringParam, useQueryParams } from 'use-query-params';
import { homestayListMachine, HOMESTAY_LIST_STATE, HOMESTAY_LIST_EVENT } from 'machines/homestayListMachine';
import { getUri } from 'utils/getUri';
import { useBannersQuery } from 'utils/hooks';
import { Banners } from 'views/components/Banners';

const FilterFormWrapper = styled(Flex).attrs({
  width: 1,
  mt: '-56px',
  flexDirection: ['column', 'row'],
  alignItems: ['initial', 'center'],
})`
  z-index: 1;
  background-color: ${themeGet('colors.whites.0')};
  border-radius: 8px;

  & > ${Form} {
    flex: 1;
  }
`;

const StyledFormItem = styled(Form.FormItem)`
  margin-bottom: 0;
`;

const CardBox = styled(Box).attrs({
  width: [1, 1 / 3],
  px: [5, 4],
  pb: ['24px', '48px'],
})``;

const defaultOption = { name: '全部', value: '' };
const getOptions = (data, defaultOption) =>
  R.pipe(
    R.map(x => ({ name: x.name, value: x.id })),
    R.when(() => R.complement(R.isNil)(defaultOption), R.prepend(defaultOption))
  )(data);

const EQUIPMENT_OPTIONS = [
  { name: '廚房', value: 'kitchen' },
  { name: '空調', value: 'air_conditioner' },
  { name: '免費WiFi', value: 'wifi' },
  { name: '嬰兒設施', value: 'baby_equipment' },
  { name: '停車場', value: 'parking' },
  { name: '溫泉', value: 'hot_spring' },
];

const getOptionByValue = (value, data, defaultValue = defaultOption) =>
  R.pipe(R.find(R.propEq('value', value)), R.defaultTo(defaultValue))(data);
const getMultiOptionsByValue = (values, data) =>
  R.filter(x => R.includes(R.prop('value', x), values || []))(data);

const getOptionValue = R.prop('value');
const getMultiOptionValues = R.pluck('value');

const HomestayList = () => {
  const location = useLocation();
  const uri = getUri(location);
  const { banners } = useBannersQuery(uri);
  const { country } = useParams();
  const [query, setQuery] = useQueryParams({
    area: StringParam,
    city: StringParam,
    people: NumberParam,
    bedroom: NumberParam,
    views: ArrayParam,
    equipments: ArrayParam,
    page: NumberParam,
  });
  const { area, city, people = 0, bedroom = 0, views, equipments, page = 1 } = query;
  const [state, send] = useMachine(homestayListMachine, {
    context: { country, page, area, city, people, bedroom, views, equipments },
  });
  const { areaList, cityListByArea, viewList, homestays, pageSize, total } = state.context;
  const areaOptions = getOptions(areaList, defaultOption);
  const getCitiesOptions = areaId => getOptions(R.propOr([], areaId, cityListByArea), defaultOption);
  const viewOptions = getOptions(viewList);

  useEffect(() => {
    send({ type: HOMESTAY_LIST_EVENT.REFETCH, area, city, people, bedroom, views, equipments, page });
  }, [send, area, page, city, people, bedroom, views, equipments]);

  const onSubmit = useCallback(
    ({ area, city, people, bedroom, views, equipments }) => {
      setQuery(
        {
          page: 1,
          area: getOptionValue(area),
          city: getOptionValue(city),
          people,
          bedroom,
          views: getMultiOptionValues(views),
          equipments: getMultiOptionValues(equipments),
        },
        'pushIn'
      );
    },
    [setQuery]
  );

  const onPageChange = useCallback(
    nextPage => {
      setQuery({ page: nextPage }, 'pushIn');
    },
    [setQuery]
  );

  return (
    <>
      <Banners banners={banners} itemProps={{ height: '304px' }} />
      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        <Section px={[5, 0]}>
          <FilterFormWrapper px={5} pt={5} pb={[5, 0]}>
            <FinalForm
              onSubmit={onSubmit}
              initialValues={{
                area: getOptionByValue(area, areaOptions, defaultOption),
                city: getOptionByValue(city, getCitiesOptions(area), defaultOption),
                people: people,
                bedroom: bedroom,
                views: getMultiOptionsByValue(views, viewOptions),
                equipments: getMultiOptionsByValue(equipments, EQUIPMENT_OPTIONS),
              }}
              mutators={{
                setCities: (args, state, utils) => {
                  utils.changeValue(state, 'city', () => defaultOption);
                },
              }}
            >
              {({ handleSubmit, submitting, values, form }) => (
                <Form>
                  <Flex flexDirection={['column', 'row']}>
                    <Flex flex={1} flexDirection="column">
                      <Flex flexDirection={['column', 'row']}>
                        <Box flex={1} pr={[0, 5]}>
                          <Field name="area">
                            {({ input, meta }) => (
                              <StyledFormItem label="地區" input={input} meta={meta}>
                                <Select
                                  {...input}
                                  placeholder="全部"
                                  options={areaOptions}
                                  size="large"
                                  onChange={option => {
                                    form.mutators.setCities();
                                    input.onChange(option);
                                  }}
                                />
                              </StyledFormItem>
                            )}
                          </Field>
                        </Box>
                        <Box flex={1} pr={[0, 5]}>
                          <Field name="city">
                            {({ input, meta }) => (
                              <StyledFormItem label="城市" input={input} meta={meta}>
                                <Select
                                  {...input}
                                  placeholder="全部"
                                  options={getCitiesOptions(values.area.value)}
                                  size="large"
                                  disabled={values.area === defaultOption}
                                />
                              </StyledFormItem>
                            )}
                          </Field>
                        </Box>
                        <Box flex={1} pr={[0, 5]}>
                          <Field name="people">
                            {({ input, meta }) => (
                              <StyledFormItem label="住宿人數" input={input} meta={meta}>
                                <Input type="number" min="0" size="large" {...input} />
                              </StyledFormItem>
                            )}
                          </Field>
                        </Box>
                      </Flex>
                      <Flex flexDirection={['column', 'row']}>
                        <Box flex={1} pr={[0, 5]}>
                          <Field name="bedroom">
                            {({ input, meta }) => (
                              <StyledFormItem label="睡房數目" input={input} meta={meta}>
                                <Input type="number" min="0" size="large" {...input} />
                              </StyledFormItem>
                            )}
                          </Field>
                        </Box>
                        <Box flex={1} pr={[0, 5]}>
                          <Field name="views" multiple>
                            {({ input, meta }) => (
                              <StyledFormItem label="周邊景觀" input={input} meta={meta}>
                                <Select
                                  placeholder="選擇"
                                  options={viewOptions}
                                  size="large"
                                  inputValue={`已選擇：${values.views.length}`}
                                  multiple
                                  {...input}
                                />
                              </StyledFormItem>
                            )}
                          </Field>
                        </Box>
                        <Box flex={1} pr={[0, 5]}>
                          <Field name="equipments" multiple={true}>
                            {({ input, meta }) => (
                              <StyledFormItem label="主要設施" input={input} meta={meta}>
                                <Select
                                  placeholder="選擇"
                                  options={EQUIPMENT_OPTIONS}
                                  size="large"
                                  inputValue={`已選擇：${values.equipments.length}`}
                                  multiple
                                  {...input}
                                />
                              </StyledFormItem>
                            )}
                          </Field>
                        </Box>
                      </Flex>
                    </Flex>
                    <Flex pb={[0, '20px']} justifyContent="flex-end" alignItems="flex-end">
                      <Button onClick={handleSubmit} disabled={submitting} variant="primary" size="large">
                        搜尋
                      </Button>
                    </Flex>
                  </Flex>
                </Form>
              )}
            </FinalForm>
          </FilterFormWrapper>
          <Box mt={6}>
            <Breadcrumb>
              <Breadcrumb.Item linkProps={{ as: Link, to: '/' }}>首頁</Breadcrumb.Item>
              <Breadcrumb.Item linkProps={{ as: Link, to: `/民宿優選` }}>民宿優選</Breadcrumb.Item>
              <Breadcrumb.Item>{country}民宿</Breadcrumb.Item>
            </Breadcrumb>
          </Box>
        </Section>
      </Flex>
      <Section flexDirection="column" margin="48px auto 80px">
        <Heading textAlign="center" mb="48px">
          {country}民宿
        </Heading>
        <Flex
          flexDirection={['column', 'row']}
          alignItems="center"
          flexWrap="wrap"
          mx={[0, -4]}
          mb={['-24px', '-48px']}
        >
          {state.matches(HOMESTAY_LIST_STATE.LOADING) &&
            R.times(
              i => (
                <CardBox key={i}>
                  <TourCardLoading />
                </CardBox>
              ),
              3
            )}
          {state.matches(HOMESTAY_LIST_STATE.LOADED) &&
            R.map(
              homestay => (
                <CardBox key={homestay.id}>
                  <HomestayCard
                    id={homestay.id}
                    as={Link}
                    to={`/民宿優選/${country}/${encodeURIComponent(homestay.name)}`}
                    coverUrl={R.pipe(R.propOr([], 'pictures'), R.head, R.path(['picture', 'link']))(homestay)}
                    title={homestay.name}
                    description={homestay.description}
                    people={homestay.people}
                    bedroom={homestay.bedroom}
                    bed={getBedAmount(homestay)}
                    bathroom={homestay.bathroom}
                    city={R.pathOr('', ['city', 'name'], homestay)}
                    area={R.pathOr('', ['city', 'area', 'name'], homestay)}
                    featured={homestay.featured}
                    rate={`${getRate(homestay)} (${homestay.reviews.length})`}
                  />
                </CardBox>
              ),
              homestays
            )}
        </Flex>
        {total > pageSize && (
          <Box mt={7}>
            <Flex justifyContent="center">
              <Pagination current={page} total={total} pageSize={pageSize} onChange={onPageChange} />
            </Flex>
          </Box>
        )}
      </Section>
    </>
  );
};
export default HomestayList;
