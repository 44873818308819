import React, { useState, useCallback } from 'react';
import { useService } from '@xstate/react';
import * as R from 'ramda';
import styled from 'styled-components';
import { Flex, Box, Select, DrawerHeader, DrawerBody, Heading, Text } from '@cowsquare/design';
import { COUNTRY_MAP } from '../../../constants/country';
import { themeGet } from '@styled-system/theme-get';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import TabAttraction from './TabAttraction';
import TabTour from './TabTour';
import TabFavorite from './TabFavorite';
import { FP_DRAWER_EVENTS, FP_DRAWER_STATES } from './machines/drawerMachine';
import { CARD_TYPE } from './constants/CARD_TYPE';

const TAB = {
  TOUR: `${FP_DRAWER_STATES.LIST}.${FP_DRAWER_STATES.LIST_TOUR_TAB}`,
  ATTRACTION: `${FP_DRAWER_STATES.LIST}.${FP_DRAWER_STATES.LIST_ATTRACTION_TAB}`,
  FAVORITE: `${FP_DRAWER_STATES.LIST}.${FP_DRAWER_STATES.LIST_FAVORITE_TAB}`,
  FAVORITE_TOUR: `${FP_DRAWER_STATES.LIST}.${FP_DRAWER_STATES.LIST_FAVORITE_TAB}.${FP_DRAWER_STATES.FAVORITE_TAB_TOUR}`,
};

const TAB_OPTIONS = [
  {
    label: '我的收藏',
    event: FP_DRAWER_EVENTS.CLICK_FAVORITE_TAB,
    value: TAB.FAVORITE,
  },
  { label: '自由旅程', event: FP_DRAWER_EVENTS.CLICK_TOUR_TAB, value: TAB.TOUR },
  {
    label: '優遊地點',
    event: FP_DRAWER_EVENTS.CLICK_ATTRACTION_TAB,
    value: TAB.ATTRACTION,
  },
];

const GET_OPTIONS = gql`
  query GET_OPTIONS($country: String!) {
    features: vontravel_features {
      name
    }

    areas: vontravel_areas(where: { country: { name: { _eq: $country } } }) {
      name
    }
  }
`;

const TabGroup = Flex;
const Tab = styled.button`
  flex: 1;
  border: none;
  padding-bottom: 8px;
  border-bottom: 4px solid ${({ active }) => (active ? themeGet('colors.primary') : 'rgba(0, 0, 0, 0)')};
  text-align: center;
  color: ${({ active }) => (active ? themeGet('colors.primary') : 'rgba(0, 0, 0, 0.6)')};
  outline: none;
  background: none;
  font-size: 14px;
  cursor: pointer;
`;

const defaultOption = { name: '全部', value: undefined };
const getOptions = key =>
  R.pipe(
    R.propOr([], key),
    R.map(({ name }) => ({ name, value: name })),
    R.prepend(defaultOption)
  );

const CardListDrawerPage = ({ service, country, onClose }) => {
  const [current, send] = useService(service);
  const countryName = COUNTRY_MAP[country];
  const [area, setArea] = useState({});
  const [feature, setFeature] = useState({});
  const onOpenContentDrawerPage = useCallback(
    (cardName, cardType) => {
      send({
        type:
          cardType === CARD_TYPE.TOUR
            ? FP_DRAWER_EVENTS.OPEN_TOUR_CONTENT
            : FP_DRAWER_EVENTS.OPEN_ATTRACTION_CONTENT,
        data: cardName,
      });
    },
    [send]
  );

  const { data: options } = useQuery(GET_OPTIONS, {
    variables: { country },
  });

  return (
    <>
      <DrawerHeader height="auto" divider={false} onClose={onClose}>
        <Heading my={5} as="h2" fontSize="18px" color="primary">
          新增行程/景點
        </Heading>
        <Box mb={6}>
          <Text mb={3} color="blacks.3" fontSize="13px">
            {countryName}優遊推薦
          </Text>
          <Flex flexDirection={['column', 'row']} justifyContent="space-between" height={['96px', 'initial']}>
            <Box flex={1} mr={[0, 5]}>
              <Select
                size="large"
                placeholder="選擇城市"
                options={getOptions('areas')(options)}
                value={area}
                onChange={setArea}
                disabled={current.matches(TAB.TOUR) || current.matches(TAB.FAVORITE_TOUR)}
              />
            </Box>
            <Box flex={1}>
              <Select
                size="large"
                placeholder="選擇分類"
                options={getOptions('features')(options)}
                value={feature}
                onChange={setFeature}
              />
            </Box>
          </Flex>
        </Box>
        <TabGroup>
          {TAB_OPTIONS.map(({ value, label, event }) => (
            <Tab key={label} onClick={() => send(event)} active={current.matches(value)}>
              {label}
            </Tab>
          ))}
        </TabGroup>
      </DrawerHeader>
      <DrawerBody>
        {current.matches(TAB.FAVORITE) && (
          <TabFavorite
            service={service}
            country={country}
            area={area.value}
            feature={feature.value}
            onOpenContentDrawerPage={onOpenContentDrawerPage}
            closeDrawer={onClose}
          />
        )}
        {current.matches(TAB.ATTRACTION) && (
          <TabAttraction
            country={country}
            area={area.value}
            feature={feature.value}
            onOpenContentDrawerPage={onOpenContentDrawerPage}
            closeDrawer={onClose}
          />
        )}
        {current.matches(TAB.TOUR) && (
          <TabTour
            country={country}
            feature={feature.value}
            onOpenContentDrawerPage={onOpenContentDrawerPage}
            closeDrawer={onClose}
          />
        )}
      </DrawerBody>
    </>
  );
};

export default CardListDrawerPage;
