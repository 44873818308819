import React from 'react';

export const CloseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
    <path
      fill="#9B9B9B"
      d="M10.752 11.936L4.825 6.009a.909.909 0 0 1-.013-1.286.909.909 0 0 1 1.286.013l5.927 5.927 5.927-5.927a.909.909 0 0 1 1.285-.013.909.909 0 0 1-.012 1.286l-5.927 5.927 5.927 5.927a.909.909 0 0 1 .012 1.285.909.909 0 0 1-1.285-.012l-5.927-5.927-5.927 5.927a.909.909 0 0 1-1.286.012.909.909 0 0 1 .013-1.285l5.927-5.927z"
    />
  </svg>
);

export const MenuIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
    <path
      fill="#9B9B9B"
      d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
    />
  </svg>
);
