import React from 'react';
import styled from 'styled-components';
import { Box, Flex } from '@cowsquare/design';
import { themeGet } from '@styled-system/theme-get';
import { typography } from 'styled-system';

const Content = styled(Flex).attrs({
  justifyContent: 'flex-start',
  alignItems: 'center',
  pl: [6, 5],
})`
  height: 120px;
  background-color: ${themeGet('colors.whites.0')};
`;

const Icon = styled.img`
  width: 60px;
  height: 60px;
`;

const Title = styled.h3`
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  ${typography}
`;

const Description = styled.p`
  font-size: 13px;
  line-height: 1.62;
  color: ${themeGet('colors.blacks.4')};
  margin: 0;
  margin-top: 8px;
`;

export const InfoCard = ({ img, title, description }) => (
  <Content>
    <Icon src={img}></Icon>
    <Box ml={4}>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Box>
  </Content>
);
