import React from 'react';
import styled from 'styled-components';
import { layout, border, space } from 'styled-system';
import { Box, Flex, Image } from '@cowsquare/design';
import LoadingBanner from '../../images/group-2.svg';

/**
 * TODO: Move to cowsquare design
 */

export const Loading = styled.span`
  display: inline-block;
  ${layout}
  ${border}
  ${space}
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.1) 61%, rgba(0, 0, 0, 0.05));

  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: placeholderAnimate;

  @keyframes placeholderAnimate {
    0%{ background-position: ${({ width }) => `-${width}`} 0; }
    100%{ background-position: ${({ width }) => `${width}`} 0; }
  }
`;

const CardWrapper = styled(Flex)`
  ${layout}
  ${space}
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
  overflow: hidden;
`;

const CardContent = styled(Box).attrs({
  display: 'flex',
})`
  flex-direction: column;
  flex: 1;
`;

export const TourCardLoading = () => (
  <CardWrapper flexDirection="column">
    <Image width={1} src={LoadingBanner} />
    <CardContent p={5} minHeight={252}>
      <Loading height="20px" width="60%" />
      <Loading height="14px" width="100%" mt={5} />
      <Loading height="14px" width="80%" mt={4} />
    </CardContent>
  </CardWrapper>
);

export const LocationCardLoading = () => (
  <CardWrapper flexDirection="column">
    <Image width={1} src={LoadingBanner} />
    <CardContent p={5} minHeight={202}>
      <Loading height="20px" width="60%" />
    </CardContent>
  </CardWrapper>
);

export const HomestayDetailLoading = () => (
  <Flex width={1} flexDirection="column">
    <Loading width={['80%', '328px']} height="14px" mt={6} mb="40px" />
    <Loading width={[1, '539px']} height="40px" mb="24px" />
    <Flex flexDirection={['column', 'row']} mb="80px">
      <Loading width={['50%', '131px']} height="14px" mr={[0, '24px']} mb={[5, 0]} />
      <Loading width={['50%', '131px']} height="14px" mr={[0, '24px']} mb={[5, 0]} />
      <Loading width={['50%', '131px']} height="14px" mr={[0, '24px']} mb={[5, 0]} />
    </Flex>
    <Loading width={[1, '1016px']} height={['300px', '240px']} mb={[6, '200px']} />
  </Flex>
);
