import gql from 'graphql-tag';

export const GET_STATIC_CONTENTS = gql`
  query getStaticContents($name: String!) {
    contents: vontravel_static_pages(where: { page: { name: { _eq: $name } } }) {
      content {
        content
      }
    }
  }
`;
