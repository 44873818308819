import { useQuery } from '@apollo/react-hooks';
import { Box, Breadcrumb, Button, Flex, Form, Pagination, Select, Tag } from '@cowsquare/design';
import { themeGet } from '@styled-system/theme-get';
import gql from 'graphql-tag';
import * as R from 'ramda';
import React, { useCallback } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import { Link, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { getHTMLString } from '../../utils/getHTMLString';
import { Section } from '../components/Section';
import TourCard from '../components/TourCard';
import { useFeatureBannerQuery } from '../../utils/hooks';
import { Banners } from '../components/Banners';
import AddToPlanButton from '../components/AddToPlanButton';
import { TourCardLoading } from 'views/components/Loading';

const PAGE_SIZE = 12;

const TravelFilterFlex = styled(Flex).attrs({
  width: 1,
  mt: '-56px',
  flexDirection: ['column', 'row'],
  alignItems: ['initial', 'center'],
})`
  z-index: 1;
  background-color: ${themeGet('colors.whites.0')};
  border-radius: 8px;

  & > ${Form} {
    flex: 1;
  }
`;

const StyledFormItem = styled(Form.FormItem)`
  margin-bottom: 0;
`;

const TourCardBox = styled(Box).attrs({
  width: [1, 1 / 3],
  pb: ['24px', '48px'],
  px: [0, 4],
})``;

const GET_TOUR_LIST_BY_FEATURE = gql`
  query getTourListByFeature($feature: String!, $limit: Int, $offset: Int, $where: vontravel_tours_bool_exp) {
    list: vontravel_tours(
      limit: $limit
      order_by: { created_at: desc, name: asc }
      offset: $offset
      where: $where
    ) {
      id
      name
      price
      days
      cover {
        link
      }
      content {
        content
      }
      description {
        content
      }
      country {
        name
      }
      features {
        feature {
          id
          name
        }
      }
      isCharter
    }

    audience: vontravel_audience(
      where: { tours: { tour: { features: { feature: { name: { _eq: $feature } } } } } }
    ) {
      id
      name
    }

    features: vontravel_features(
      where: { tours: { tour: { features: { feature: { name: { _eq: $feature } } } } } }
    ) {
      id
      name
    }

    total: vontravel_tours_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

const defualtOption = { name: '全部', value: '' };
const getOptions = (name, data, nameToReject = '') =>
  R.pipe(
    R.propOr([], name),
    R.when(R.always(nameToReject), R.reject(R.propEq('name', nameToReject))),
    R.map(x => ({ ...x, value: x.name })),
    R.prepend(defualtOption)
  )(data);
const getOptionByName = (value, data, defaultValue = defualtOption) =>
  R.pipe(R.find(R.propEq('name', value)), R.defaultTo(defaultValue))(data);

const ThemeTourList = () => {
  const { feature } = useParams();
  const history = useHistory();

  if (!feature) {
    // TODO: When 404 page is done, change redirect url to 404 page.
    history.push('/');
  }

  const [query, setQuery] = useQueryParams({
    feature: StringParam,
    audience: StringParam,
    page: NumberParam,
  });
  const { feature: additionalFeature, audience, page = 1 } = query;
  const onQuery = useCallback(
    values => {
      setQuery({
        ...query,
        page: 1,
        feature: R.path(['feature', 'value'], values),
        audience: R.path(['audience', 'value'], values),
      });
    },
    [query, setQuery]
  );
  const onPageChange = useCallback(
    nextPage => {
      setQuery({ ...query, page: nextPage });
    },
    [query, setQuery]
  );

  const { data, loading } = useQuery(GET_TOUR_LIST_BY_FEATURE, {
    skip: !feature,
    variables: {
      limit: PAGE_SIZE,
      offset: (page - 1) * PAGE_SIZE,
      where: {
        audience: { audience: { name: { _eq: audience } } },
        _and: [
          { features: { feature: { name: { _eq: feature } } } },
          {
            features: {
              feature: {
                name: { _eq: additionalFeature },
              },
            },
          },
        ],
      },
      feature,
    },
  });

  const list = R.pathOr([], ['list'], data);
  const audienceOptions = getOptions('audience', data);
  const featureOptions = getOptions('features', data, feature);
  const { banner } = useFeatureBannerQuery(feature);

  const total = R.pathOr(0, ['total', 'aggregate', 'count'], data);

  return (
    <>
      <Banners banners={[banner]} itemProps={{ height: '304px' }} />
      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        <Section px={[5, 0]}>
          <TravelFilterFlex px={5} pt={5} pb={[5, 0]}>
            <FinalForm
              onSubmit={onQuery}
              initialValues={{
                audience: getOptionByName(audience, audienceOptions, defualtOption),
                feature: getOptionByName(additionalFeature, featureOptions, defualtOption),
              }}
              render={({ handleSubmit, submitting }) => (
                <>
                  <Form>
                    <Flex flexDirection={['column', 'row']} justifyContent="space-between">
                      <Box flex={1} pr={[0, 5]}>
                        <Field name="audience">
                          {({ input, meta }) => (
                            <StyledFormItem label="對象" input={input} meta={meta}>
                              <Select {...input} placeholder="全部" options={audienceOptions} size="large" />
                            </StyledFormItem>
                          )}
                        </Field>
                      </Box>
                      <Box flex={1} pr={[0, 5]}>
                        <Field name="feature">
                          {({ input, meta }) => (
                            <StyledFormItem label="特色" input={input} meta={meta}>
                              <Select {...input} placeholder="全部" options={featureOptions} size="large" />
                            </StyledFormItem>
                          )}
                        </Field>
                      </Box>
                    </Flex>
                  </Form>
                  <Box>
                    <Flex justifyContent="flex-end">
                      <Button onClick={handleSubmit} disabled={submitting} variant="primary" size="large">
                        搜尋
                      </Button>
                    </Flex>
                  </Box>
                </>
              )}
            ></FinalForm>
          </TravelFilterFlex>
          <Box mt={6}>
            <Breadcrumb>
              <Breadcrumb.Item linkProps={{ as: Link, to: '/' }}>首頁</Breadcrumb.Item>
              <Breadcrumb.Item linkProps={{ as: Link, to: `/主題優遊` }}>主題優遊</Breadcrumb.Item>
              <Breadcrumb.Item>{`所有${feature}主題優遊`}</Breadcrumb.Item>
            </Breadcrumb>
          </Box>
        </Section>
      </Flex>
      <Section flexDirection="column" margin="48px auto 80px" px={[5, 0]}>
        <Flex
          flexDirection={['column', 'row']}
          alignItems="center"
          flexWrap="wrap"
          mx={[0, -4]}
          mb={['-24px', '-48px']}
        >
          {loading
            ? R.times(TourCardLoading)
            : R.map(
                item => (
                  <TourCardBox key={item.id}>
                    <TourCard
                      as={Link}
                      to={`/自由旅程/${R.pathOr('', ['country', 'name'], item)}/所有旅程/${item.name}`}
                      coverUrl={R.pathOr('', ['cover', 'link'], item)}
                      height="460px"
                      title={item.name}
                      description={R.pipe(R.pathOr('', ['description', 'content']), getHTMLString)(item)}
                      tags={R.pipe(
                        R.propOr([], 'features'),
                        R.pluck('feature'),
                        R.map(x => <Tag key={x.id}>{x.name}</Tag>)
                      )(item)}
                      links={
                        <AddToPlanButton
                          onClick={e => {
                            e.preventDefault();
                            history.push(
                              `/自助設計優遊/新計劃?cardType=tour&cardName=${item.name}&country=${R.pathOr(
                                '',
                                ['country', 'name'],
                                item
                              )}`
                            );
                          }}
                        />
                      }
                      country={R.pathOr('', ['country', 'name'], item)}
                      isCharter={item.isCharter}
                    />
                  </TourCardBox>
                ),
                list
              )}
        </Flex>
        {total > PAGE_SIZE && (
          <Box mt={7}>
            <Flex justifyContent="center">
              <Pagination current={page} total={total} pageSize={PAGE_SIZE} onChange={onPageChange} />
            </Flex>
          </Box>
        )}
      </Section>
    </>
  );
};

export default ThemeTourList;
