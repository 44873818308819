import React, { useCallback, useEffect } from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { Link, useParams, useHistory, useLocation } from 'react-router-dom';
import { themeGet } from '@styled-system/theme-get';
import { Button, Box, Breadcrumb, Flex, Tag } from '@cowsquare/design';
import { Section } from '../components/Section';
import TourCard from '../components/TourCard';
import { Banners } from '../components/Banners';
import { COUNTRY_MAP } from '../../constants/country';
import { getHTMLString } from '../../utils/getHTMLString';
import { getUri } from '../../utils/getUri';
import { useBannersQuery, useUser } from '../../utils/hooks';
import AddToPlanButton from '../components/AddToPlanButton';
import { InfoCardSection } from '../components/InfoCardSection';
import FreePlannerIntroSection from '../components/FreePlannerIntroSection';
import { TourCardLoading } from '../components/Loading';
import { useMachine } from '@xstate/react';
import { tourMachine, TOUR_EVENTS, TOUR_STATES } from 'machines/tourMachine';
import { assign } from 'xstate';

const InfoCardFlex = styled(Flex).attrs({
  width: 1,
  mx: [0, -4],
  flexDirection: ['column', 'row'],
  justifyContent: 'space-between',
})`
  z-index: 1;
  background-color: ${themeGet('colors.whites.0')};
`;

const TourCardBox = styled(Box).attrs({
  width: [1, 1 / 3],
  pb: ['24px', '48px'],
  px: [0, 4],
})``;

const FreeTour = () => {
  const { country } = useParams();
  const history = useHistory();
  const location = useLocation();
  const countryName = COUNTRY_MAP[country];
  const { user } = useUser();
  const userId = R.propOr(null, 'id', user);

  if (!countryName) {
    // TODO: When 404 page is done, change redirect url to 404 page.
    history.push('/');
  }

  const onClickMore = useCallback(() => history.push(`/自由旅程/${country}/所有旅程/`), [country, history]);
  const uri = getUri(location);
  const { banners } = useBannersQuery(uri);
  const [current, send] = useMachine(tourMachine, {
    context: {
      pageSize: 9,
      country: countryName,
      userId,
    },
    actions: {
      refetch: assign({
        country: countryName,
        userId,
      }),
    },
  });

  useEffect(() => {
    send(TOUR_EVENTS.REFETCH);
  }, [countryName, userId, send]);

  const { data } = current.context;
  const list = R.propOr([], 'list', data);

  return (
    <>
      <Banners banners={banners} height="304px" />
      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        <InfoCardFlex>
          <InfoCardSection />
        </InfoCardFlex>
        <Section flexDirection="column" margin="48px auto 80px" px={[5, 0]}>
          <Breadcrumb>
            <Breadcrumb.Item linkProps={{ as: Link, to: '/' }}>首頁</Breadcrumb.Item>
            <Breadcrumb.Item>自由旅程-{countryName}</Breadcrumb.Item>
          </Breadcrumb>
          <Flex
            flexDirection={['column', 'row']}
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
            mx={-4}
            mt={'48px'}
            mb={['-24px', '-48px']}
          >
            {current.matches(TOUR_STATES.LOADING) &&
              R.times(
                i => (
                  <TourCardBox key={i}>
                    <TourCardLoading />
                  </TourCardBox>
                ),
                3
              )}
            {current.matches(TOUR_STATES.LOADED) &&
              R.map(
                item => (
                  <TourCardBox key={item.id}>
                    <TourCard
                      id={item.id}
                      collectible={!R.pipe(R.propOr([], 'users'), R.isEmpty)(item)}
                      as={Link}
                      to={`/自由旅程/${countryName}/所有旅程/${item.name}`}
                      coverUrl={R.pathOr('', ['cover', 'link'], item)}
                      height="460px"
                      title={item.name}
                      description={R.pipe(R.pathOr('', ['description', 'content']), getHTMLString)(item)}
                      tags={R.pipe(
                        R.propOr([], 'features'),
                        R.pluck('feature'),
                        R.map(x => <Tag key={x.id}>{x.name}</Tag>)
                      )(item)}
                      links={
                        <AddToPlanButton
                          onClick={e => {
                            e.preventDefault();
                            history.push(
                              `/自助設計優遊/新計劃?cardType=tour&cardName=${item.name}&country=${country}`
                            );
                          }}
                        />
                      }
                      country={countryName}
                      isCharter={item.isCharter}
                    />
                  </TourCardBox>
                ),
                list
              )}
          </Flex>
          <Box mt={6} width={1}>
            <Flex justifyContent="center">
              <Button size="large" variant="primary" onClick={onClickMore}>
                更多自由旅程
              </Button>
            </Flex>
          </Box>
        </Section>
        <FreePlannerIntroSection />
      </Flex>
    </>
  );
};

export default FreeTour;
