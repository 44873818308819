import { Machine, assign } from 'xstate';

const STATES = {
  CLOSED: 'CLOSED',
  OPENED: 'OPENED',
  LIST: 'LIST',
  LIST_TOUR_TAB: 'LIST_TOUR_TAB',
  LIST_ATTRACTION_TAB: 'LIST_ATTRACTION_TAB',
  LIST_FAVORITE_TAB: 'LIST_FAVORITE_TAB',
  FAVORITE_TAB_TOUR: 'FAVORITE_TAB_TOUR',
  FAVORITE_TAB_ATTRACTION: 'FAVORITE_TAB_ATTRACTION',
  TOUR_CONTENT: 'TOUR_CONTENT',
  ATTRACTION_CONTENT: 'ATTRACTION_CONTENT',
  VALUATION: 'VALUATION',
};

const EVENTS = {
  OPEN_LIST: 'OPEN_LIST',
  CLICK_TOUR_TAB: 'CLICK_TOUR_TAB',
  CLICK_ATTRACTION_TAB: 'CLICK_ATTRACTION_TAB',
  CLICK_FAVORITE_TAB: 'CLICK_FAVORITE_TAB',
  CLICK_FAVORITE_TOUR: 'CLICK_FAVORITE_TOUR',
  CLICK_FAVORITE_ATTRACTION: 'CLICK_FAVORITE_ATTRACTION',
  OPEN_TOUR_CONTENT: 'OPEN_TOUR_CONTENT',
  OPEN_ATTRACTION_CONTENT: 'OPEN_ATTRACTION_CONTENT',
  OPEN_VALUATION: 'OPEN_VALUATION',
  CLOSE: 'CLOSE',
  BACK_TO_LIST: 'BACK_TO_LIST',
};

const drawerMachine = Machine(
  {
    id: 'fpd',
    initial: STATES.CLOSED,
    context: {
      fromList: false,
      currentCardName: '',
    },
    states: {
      [STATES.CLOSED]: {
        entry: ['assignNotFromList'],
        on: {
          [EVENTS.OPEN_LIST]: STATES.LIST,
          [EVENTS.OPEN_TOUR_CONTENT]: STATES.TOUR_CONTENT,
          [EVENTS.OPEN_ATTRACTION_CONTENT]: STATES.ATTRACTION_CONTENT,
          [EVENTS.OPEN_VALUATION]: STATES.VALUATION,
        },
      },
      [STATES.LIST]: {
        entry: ['assignFromList'],
        id: STATES.LIST,
        initial: STATES.LIST_TOUR_TAB,
        states: {
          [STATES.LIST_TOUR_TAB]: {
            on: {
              [EVENTS.OPEN_TOUR_CONTENT]: `#fpd.${STATES.TOUR_CONTENT}`,
            },
          },
          [STATES.LIST_ATTRACTION_TAB]: {
            on: {
              [EVENTS.OPEN_ATTRACTION_CONTENT]: `#fpd.${STATES.ATTRACTION_CONTENT}`,
            },
          },
          [STATES.LIST_FAVORITE_TAB]: {
            initial: STATES.FAVORITE_TAB_TOUR,
            states: {
              [STATES.FAVORITE_TAB_ATTRACTION]: {
                on: {
                  [EVENTS.OPEN_ATTRACTION_CONTENT]: `#fpd.${STATES.ATTRACTION_CONTENT}`,
                },
              },
              [STATES.FAVORITE_TAB_TOUR]: {
                on: {
                  [EVENTS.OPEN_TOUR_CONTENT]: `#fpd.${STATES.TOUR_CONTENT}`,
                },
              },
            },
            on: {
              [EVENTS.CLICK_FAVORITE_ATTRACTION]: `${STATES.LIST_FAVORITE_TAB}.${STATES.FAVORITE_TAB_ATTRACTION}`,
              [EVENTS.CLICK_FAVORITE_TOUR]: `${STATES.LIST_FAVORITE_TAB}.${STATES.FAVORITE_TAB_TOUR}`,
            },
          },
          hist: {
            type: 'history',
            history: 'deep',
          },
        },
        on: {
          [EVENTS.CLICK_ATTRACTION_TAB]: `#${STATES.LIST}.${STATES.LIST_ATTRACTION_TAB}`,
          [EVENTS.CLICK_FAVORITE_TAB]: `#${STATES.LIST}.${STATES.LIST_FAVORITE_TAB}`,
          [EVENTS.CLICK_TOUR_TAB]: `#${STATES.LIST}.${STATES.LIST_TOUR_TAB}`,
          [EVENTS.CLOSE]: STATES.CLOSED,
        },
      },
      [STATES.ATTRACTION_CONTENT]: {
        entry: 'assignCurrentCardName',
        exit: 'resetCurrentCardName',
        on: {
          [EVENTS.BACK_TO_LIST]: [
            { target: `${STATES.LIST}.hist`, cond: 'isFromList' },
            { target: STATES.LIST },
          ],
          [EVENTS.CLOSE]: STATES.CLOSED,
        },
      },
      [STATES.TOUR_CONTENT]: {
        entry: 'assignCurrentCardName',
        exit: 'resetCurrentCardName',
        on: {
          [EVENTS.BACK_TO_LIST]: [
            { target: `${STATES.LIST}.hist`, cond: 'isFromList' },
            { target: STATES.LIST },
          ],
          [EVENTS.CLOSE]: STATES.CLOSED,
        },
      },
      [STATES.VALUATION]: {
        on: {
          [EVENTS.CLOSE]: STATES.CLOSED,
        },
      },
    },
  },
  {
    actions: {
      assignFromList: assign({ fromList: true }),
      assignNotFromList: assign({ fromList: false }),
      assignCurrentCardName: assign({
        currentCardName: (_, event) => event.data,
      }),
      resetCurrentCardName: assign({
        currentCardName: '',
      }),
    },
    guards: {
      isFromList: context => context.fromList,
    },
  }
);

export { STATES as FP_DRAWER_STATES, EVENTS as FP_DRAWER_EVENTS, drawerMachine };
