import _taggedTemplateLiteral from "/opt/build/repo/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  display: inline-block;\n  padding: 4px 8px;\n  max-width: ", ";\n  font-size: ", ";\n  line-height: ", ";\n  color: ", ";\n  background-color: ", ";\n  border: ", ";\n  border-radius: ", ";\n  box-shadow: ", ";\n  cursor: pointer;\n\n  &:hover {\n    color: ", ";\n    border: ", ";\n    background-color: ", ";\n  }\n\n  &:active {\n    color: ", ";\n    border: ", ";\n    background-color: ", ";\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import styled from 'styled-components';
import { space } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import { ellipsis } from 'polished';
export var Tag = styled.div(_templateObject(), ellipsis(), space, themeGet('tag.maxWidth'), themeGet('tag.fontSize'), themeGet('tag.lineHeight'), themeGet('tag.color'), themeGet('tag.backgroundColor'), themeGet('tag.border'), themeGet('tag.borderRadius'), themeGet('tag.boxShadow'), themeGet('tag.hover.color'), themeGet('tag.hover.border'), themeGet('tag.hover.backgroundColor'), themeGet('tag.active.color'), themeGet('tag.active.border'), themeGet('tag.active.backgroundColor'));