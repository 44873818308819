import React from 'react';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const HTMLContentWrapper = styled.div`
  line-height: 1.5;

  & > :first-child {
    margin-top: 0;
  }

  a {
    color: ${themeGet('colors.link')};
    text-decoration: none;
  }
`;

export const HTMLContent = ({ html }) => <HTMLContentWrapper dangerouslySetInnerHTML={{ __html: html }} />;
