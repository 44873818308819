import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useHistory, Prompt } from 'react-router-dom';

const RouteLeavingPrompt = ({ when = true, children }) => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const nextLocationRef = useRef(null);
  const shouldBlockedRef = useRef(true);

  const handleBlockedNavigation = useCallback(
    nextLocation => {
      if (nextLocation.pathname !== history.location.pathname && shouldBlockedRef.current) {
        nextLocationRef.current = nextLocation;
        setShow(true);
        return false;
      }
      return true;
    },
    [history]
  );

  const onCancel = useCallback(() => {
    shouldBlockedRef.current = true;
    nextLocationRef.current = null;
    setShow(false);
  }, []);

  const onConfirm = useCallback(() => {
    const { pathname, search } = nextLocationRef.current;

    shouldBlockedRef.current = false;
    setShow(false);
    history.push(`${pathname}${search}`);
  }, [history]);

  useEffect(() => {
    const onBeforeUnload = event => {
      if (!when) return;
      event.returnValue = true;
    };

    window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [when]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      {children({ onConfirm, onCancel, show })}
    </>
  );
};

export default RouteLeavingPrompt;
