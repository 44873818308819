import React, { useCallback, useRef } from 'react';
import { useService } from '@xstate/react';
import { Drawer } from '@cowsquare/design';
import CardListDrawerPage from './CardListDrawerPage';
import TourCardDrawerPage from './TourCardDrawerPage';
import AttractionCardDrawerPage from './AttractionCardDrawerPage';
import ValuationDrawerPage from './ValuationDrawerPage';
import { FP_DRAWER_STATES, FP_DRAWER_EVENTS } from './machines/drawerMachine';

const PlanTourDrawer = ({ drawerService, country, formValue }) => {
  const [current, send, service] = useService(drawerService);
  const drawerRef = useRef();

  const onClose = useCallback(() => {
    send(FP_DRAWER_EVENTS.CLOSE);
  }, [send]);

  const onBackList = useCallback(() => {
    send(FP_DRAWER_EVENTS.BACK_TO_LIST);
  }, [send]);

  return (
    <Drawer
      width={[1, '552px']}
      show={!current.matches(FP_DRAWER_STATES.CLOSED)}
      onClose={onClose}
      closeButton
      ref={drawerRef}
    >
      {current.matches(FP_DRAWER_STATES.LIST) && (
        <CardListDrawerPage service={service} country={country} onClose={onClose} />
      )}
      {current.matches(FP_DRAWER_STATES.TOUR_CONTENT) && (
        <TourCardDrawerPage
          country={country}
          onBackList={onBackList}
          onClose={onClose}
          cardName={current.context.currentCardName}
        />
      )}
      {current.matches(FP_DRAWER_STATES.ATTRACTION_CONTENT) && (
        <AttractionCardDrawerPage
          country={country}
          onBackList={onBackList}
          onClose={onClose}
          cardName={current.context.currentCardName}
        />
      )}
      {current.matches(FP_DRAWER_STATES.VALUATION) && (
        <ValuationDrawerPage formValue={formValue} onClose={onClose}></ValuationDrawerPage>
      )}
    </Drawer>
  );
};

export default PlanTourDrawer;
