import { useRef, useCallback } from 'react';
import { setRef } from './setRef';
export var useDefaultRef = function useDefaultRef(refFromProps) {
  var initialValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var tempRef = useRef(initialValue);
  var setRefValue = useCallback(function (value) {
    setRef(tempRef, value);
    setRef(refFromProps, value);
  }, [tempRef, refFromProps]);
  return [tempRef, setRefValue];
};