import React from 'react';
import { Heading, Text, DrawerHeader } from '@cowsquare/design';
import ValuationDrawerContent from './ValuationDrawerContent';

const ValuationDrawerPage = ({ onClose, formValue }) => {
  return (
    <>
      <DrawerHeader height="auto" divider={false} onClose={onClose}>
        <Heading my={5} as="h2" fontSize="18px" color="primary">
          即時估價
        </Heading>
        <Text mb={5} color="blacks.3">
          確認以下資訊後，將會聯同您的行程規劃一同送出作即時系統估價！
        </Text>
      </DrawerHeader>
      <ValuationDrawerContent initialValues={formValue}></ValuationDrawerContent>
    </>
  );
};

export default ValuationDrawerPage;
