import { Machine, assign } from 'xstate';
import { client } from 'apollo/client';
import gql from 'graphql-tag';
import * as R from 'ramda';

const GET_OPTIONS = gql`
  query getOptions($country: String) {
    cities: vontravel_homestays(where: { city: { area: { country: { name: { _eq: $country } } } } }) {
      city {
        id
        name
        area {
          id
          name
        }
      }
    }
    views: vontravel_view_types {
      id
      name
    }
  }
`;

const GET_HOMESTAYS_BY_COUNTRY = gql`
  query getHomestays($where: vontravel_homestays_bool_exp, $limit: Int!, $offset: Int!) {
    meta: vontravel_homestays_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    homestays: vontravel_homestays(
      limit: $limit
      offset: $offset
      order_by: { featured: desc, approved_reviews_aggregate: { count: desc }, created_at: desc }
      where: $where
    ) {
      id
      name
      featured
      city {
        id
        name
        area {
          id
          name
          country {
            id
            name
          }
        }
      }
      people
      description
      bedroom
      double_bed
      single_bed
      bunk_bed
      sofa_bed
      bathroom
      view_type {
        name
      }
      pictures(order_by: { created_at: asc }) {
        picture {
          link
        }
      }
      reviews(where: { approved: { _eq: true } }) {
        rate
      }
    }
  }
`;

const S = {
  LOADING: 'LOADING',
  LOADING_OPTIONS: 'LOADING_OPTIONS',
  LOADING_HOMESTAYS: 'LOADING_HOMESTAYS',
  LOADED: 'LOADED',
  ERROR: 'ERROR',
  EMPTY: 'EMPTY',
};

const E = {
  REFETCH: 'REFETCH',
};

const convertToCityCondition = (country, area, city) =>
  city
    ? { id: { _eq: city } }
    : area
    ? { area: { id: { _eq: area } } }
    : { area: { country: { name: { _eq: country } } } };
const convertToBoolCondition = arr =>
  R.pipe(
    R.defaultTo([]),
    R.map(x => ({ [x]: { _eq: true } })),
    R.mergeAll
  )(arr);

const homestayListMachine = Machine(
  {
    context: {
      country: '',
      areaList: [],
      cityList: [],
      cityListByArea: {},
      viewList: [],
      homestays: [],
      total: 0,
      page: 1,
      pageSize: 12,
      area: null,
      city: null,
      people: 1,
      bedroom: 1,
      views: [],
      equipments: [],
    },
    initial: S.LOADING,
    states: {
      [S.LOADING]: {
        id: S.LOADING,
        initial: S.LOADING_OPTIONS,
        states: {
          [S.LOADING_OPTIONS]: {
            invoke: {
              src: 'getOptions',
              onDone: {
                target: S.LOADING_HOMESTAYS,
                actions: ['assignCityList', 'assignAreaList', 'assignCityListByArea', 'assignViewList'],
              },
              onError: { target: `#${S.ERROR}` },
            },
          },
          [S.LOADING_HOMESTAYS]: {
            invoke: {
              src: 'getHomestays',
              onDone: { target: `#${S.LOADED}`, actions: ['assignHomestays', 'assignTotal'] },
              onError: { target: `#${S.ERROR}` },
            },
          },
        },
      },
      [S.LOADED]: {
        id: S.LOADED,
        on: {
          [E.REFETCH]: {
            target: `#${S.LOADING}.${S.LOADING_HOMESTAYS}`,
            actions: [
              'assignArea',
              'assignCity',
              'assignPeople',
              'assignBedroom',
              'assignViews',
              'assignEquipments',
              'assignPage',
            ],
          },
        },
      },
      [S.ERROR]: {
        id: S.ERROR,
      },
    },
  },
  {
    actions: {
      assignCityList: assign({
        cityList: (_, event) => R.pipe(R.propOr([], 'cities'), R.pluck('city'), R.uniq)(event.data),
      }),
      assignAreaList: assign({ areaList: ({ cityList }) => R.pipe(R.pluck('area'), R.uniq)(cityList) }),
      assignCityListByArea: assign({
        cityListByArea: ({ cityList }) => R.groupBy(R.path(['area', 'id']), cityList),
      }),
      assignViewList: assign({ viewList: (_, event) => R.propOr([], 'views', event.data) }),
      assignHomestays: assign({ homestays: (_, event) => R.propOr([], 'homestays', event.data) }),
      assignTotal: assign({ total: (_, event) => R.pathOr(0, ['meta', 'aggregate', 'count'], event.data) }),
      assignArea: assign({ area: (_, event) => event.area }),
      assignCity: assign({ city: (_, event) => event.city }),
      assignPeople: assign({ people: (_, event) => event.people }),
      assignBedroom: assign({ bedroom: (_, event) => event.bedroom }),
      assignViews: assign({ views: (_, event) => event.views }),
      assignEquipments: assign({ equipments: (_, event) => event.equipments }),
      assignPage: assign({ page: (_, event) => event.page }),
    },
    services: {
      getOptions: ({ country }) =>
        client
          .query({
            query: GET_OPTIONS,
            variables: {
              country,
            },
          })
          .then(res => res.data),
      getHomestays: ({ country, pageSize, page, city, area, people, bedroom, views, equipments }) =>
        client
          .query({
            query: GET_HOMESTAYS_BY_COUNTRY,
            variables: {
              where: {
                city: convertToCityCondition(country, area, city),
                people: { _gte: people },
                bedroom: { _gte: bedroom },
                view_type: { id: { _in: views } },
                ...convertToBoolCondition(equipments),
              },
              limit: pageSize,
              offset: pageSize * (page - 1),
            },
          })
          .then(res => res.data),
    },
  }
);
export { homestayListMachine, S as HOMESTAY_LIST_STATE, E as HOMESTAY_LIST_EVENT };
