import React from 'react';
import styled from 'styled-components';
import trash from '../../images/icon-trash.svg';

const StyledButton = styled.button`
  padding: 0;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  background: none;
  font-size: 13px;
  color: #ff4c4c;
  cursor: pointer;
`;

const Img = styled.img`
  margin-right: 4px;
`;

const RemoveFromPlanButton = props => (
  <StyledButton variant="link" {...props}>
    <Img src={trash} alt="trash" />
    從計劃中移除
  </StyledButton>
);

export default RemoveFromPlanButton;
