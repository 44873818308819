import { Box, Flex, Form, Input, Notification, Text } from '@cowsquare/design';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Field, Form as FinalForm } from 'react-final-form';
import DatePicker from 'react-modern-calendar-datepicker';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
// import RangePicker from "react-range-picker";
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const FormItem = Form.FormItem;



const CustomBtn = styled(Box).attrs({as:'button'})`
    background: #f79646;
    color: #fff;
    padding: 10px 20px;
    border-radius: 7px;
    margin-left: 15px;
    margin-bottom: 20px;
    border:0;
    font-size:16px;
    cursor: pointer;
`;
const DateBox = styled(Box).attrs({})`
  .DatePicker {
     display: block;
  }
  &#planDate{
      width:100%
  }
  .range-custom-input{
    border-radius: 8px;
    color: #000;
    border-color: #c0c0c0;
    max-width: 230px;
    min-width: unset;
    width: 100%;
    font-size: 14px;
    height:40px;
    @media (max-width:1024px){
       max-width: unset;
    }
  }
 
`;
const NumberOfPeopleField = ({ name, label, onChange, value }) => (
  <Box width={['100%', '14%']}>
    <Field name={name}>
      {({ input, meta }) => (
        <FormItem label={label} input={input} meta={{ ...meta, touched: true }}>
          <Input {...input} onChange={onChange} size="large" placeholder="請輸入人數" type="number" min={0} step={1} value={value} />
        </FormItem>
      )}
    </Field>
  </Box>
);

const CreateTourFormPick = ({
  onSubmit,
  initialValues,
  price,
  nameT,
  date_range,
  payButtonLabel = '立即預訂',
  type_date_range,
  maxPlanDate,
  minDuration = 0,
}) => {
  const history = useHistory();
  const [valInfants, setValInfants] = useState(0)
  const [valChildren, setValChildren] = useState(0)
  const [valTeenagers, setValTeenagers] = useState(0)
  const [valAdults, setValAdults] = useState(0)
  const [valElders, setValElders] = useState(0)
  const [range, setRange] = useState(true)
  const [listDisable, setListDisable] = useState([])

  const [selectedDayRange, setSelectedDayRange] = useState({
    from: null,
    to: null
  });
  const [selectedDays, setSelectedDays] = useState([]);
  const range_dates = date_range ? date_range.replace(/\s/g, '') : ''
  const dateArray = range_dates.split(',');
  const miD = dateArray[0].split('/');
  const minimumDate = {
    year:  miD[0],
    month:  miD[1],
    day: miD[2]
  };
  const maD = dateArray[dateArray.length - 1].split('/');
  const maximumDate = 
  {
    year:  maD[0],
    month:  maD[1],
    day:  maD[2]
  };
//logic get all disabledays
let datesDisable = []
const getRangeDates = (startDate, endDate) => {
    let listItem = []
    let currentDate = startDate
    const addDays = function (days) {
      const date = new Date(this.valueOf())
      date.setDate(date.getDate() + days)
      return date
    }
    while (currentDate < endDate) {
      listItem.push(currentDate)
      currentDate = addDays.call(currentDate, 1)
    }
    listItem.shift();
    datesDisable = datesDisable.concat(listItem)
    return datesDisable
}
const getDisableDays = () =>{
  dateArray.forEach(function (date, index) {
       getRangeDates(new Date(date), new Date(dateArray[index + 1]))
  })
  let finalDisableDays = []
  datesDisable.forEach(function (item, index) {
    let dayDis = {
       year: new Date(item).getFullYear(),
       month:  new Date(item).getMonth() + 1,
       day:  new Date(item).getDate()
    }
    finalDisableDays.push(dayDis)
  })
  setListDisable(finalDisableDays)
}

  //eslint-disable-next-line
  const [cookies, setCookie] = useCookies(['tourFinal']);
  //redirect to checkout page
  const onClick = () =>{
    if(type_date_range){
      if( !selectedDayRange.from || !selectedDayRange.to || priceFinal === 0 ) Notification.error({ message: 'error', description: 'Check required field!' });
      else {
        let planTour = selectedDayRange.from.year + '/' + selectedDayRange.from.month + '/' + selectedDayRange.from.day + ' 至 ' + selectedDayRange.to.year + '/' +selectedDayRange.to.month + '/' + selectedDayRange.to.day;
        setCookieTour(planTour)
        history.push(`/checkout`);
      }
    }else{
      if( selectedDays.length === 0 || priceFinal === 0 ) Notification.error({ message: 'error', description: 'Check required field!' });
      else {
        let planTour = ''
        selectedDays.forEach(function (item) {
          planTour += item.year + '/' + item.month + '/' + item.day + ', '
         })
        setCookieTour(planTour.slice(0, -2))
        history.push(`/checkout`);
      }
    }
  }
  //change number people
  const change = (e, name) => {
    if(! e.target.value || e.target.value < 0){
      e.target.value = 0;
    }
    else if(name === 'infants'){
      setValInfants(e.target.value)
    }else if(name === 'children'){
      setValChildren(e.target.value)
    }else if(name === 'teenagers'){
      setValTeenagers(e.target.value)
    }else if(name === 'adults'){
      setValAdults(e.target.value)
    }else if(name === 'elders'){
      setValElders(e.target.value)
    }
    console.log(e.target.value, name)
  }
  let allPeople = parseInt(valInfants) + parseInt(valChildren) + parseInt(valTeenagers) + parseInt(valAdults) + parseInt(valElders);
  let priceFinal = price * allPeople;
  const setCookieTour = (planTour) => {
    let tour = {'price': priceFinal, 'name': nameT, 'planDate': planTour, 'itemPrice': price, "package":[{'value':valAdults,'key':'成人'},{'value':valElders, 'key': '長者'},{'key': '嬰兒','value':valInfants},{'key': '小童', 'value': valChildren}, {'key': '青少年', 'value': valTeenagers}]}
    setCookie('tourFinal', tour);
  }
  //handle error selectday
  const handleDisabledSelect = disabledDay => {
    console.log('Tried selecting a disabled day', disabledDay);
  };

  const formatInputValue = () => {
    if(selectedDayRange.from && selectedDayRange.to){
      return `從 ${selectedDayRange.from.year}/${selectedDayRange.from.month}/${selectedDayRange.from.day} 至 ${selectedDayRange.to.year}/${selectedDayRange.to.month}/${selectedDayRange.to.day}`
    }else if(selectedDays.length !== 0){
        let dateMultiple = ''
        selectedDays.forEach(function (item) {
          dateMultiple += item.day + '/' + item.month + ', '
         })
        return dateMultiple.slice(0, -2)
    }
  };

  useEffect(() => {
   if(!type_date_range) getDisableDays();
   setRange(type_date_range)
   //eslint-disable-next-line
  }, [type_date_range])

  return (
    <FinalForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={({ planDate }) => {
        if ((!selectedDayRange.from && type_date_range) || (selectedDays.length === 0 && !type_date_range)) {
          return {
            planDate: '請再次檢查您的旅行天數',
          };
        }
        return undefined;
      }}
    >
      {({ handleSubmit, form }) => (
        <Form onSubmit={handleSubmit}>
         
          <Flex flexDirection={['column', 'row']} justifyContent="space-between">
            <Box width={['100%', '230px']}>
              <Field name="planDate">
                  {({ input, meta }) => (
                    <FormItem label="旅行開始日期" input={input} meta={meta}>
                      <DateBox className="date-box">
                        {/* <RangePicker onDateSelected={onDateChanges} placeholderText={'請選擇旅行日期'}/> */}
                        {
                          range ?
                        
                         <DatePicker
                          value={selectedDayRange}
                          onChange={setSelectedDayRange}
                          inputPlaceholder="請選擇旅行日期"
                          shouldHighlightWeekends
                          minimumDate={minimumDate}
                          maximumDate={maximumDate}
                          inputClassName="range-custom-input" 
                          formatInputText={formatInputValue}
                          colorPrimary="#0080c8" 
                          colorPrimaryLight="rgba(75, 207, 250, 0.4)" 

                        /> :
                         <DatePicker
                          value={selectedDays}
                          onChange={setSelectedDays}
                           inputPlaceholder="請選擇旅行日期"
                          shouldHighlightWeekends
                          disabledDays={listDisable} // here we pass them
                          onDisabledDayError={handleDisabledSelect} // handle error
                          minimumDate={minimumDate}
                          maximumDate={maximumDate}
                          formatInputText={formatInputValue}
                          inputClassName="range-custom-input" 
                          colorPrimary="#0080c8"
                          colorPrimaryLight="rgba(75, 207, 250, 0.4)" 
                        />
                      }
                      </DateBox>
                    </FormItem>
                  )}
                </Field>
            </Box>
            <NumberOfPeopleField name="infants" label="嬰兒 / 幼童（0-5歲）" onChange={(e)=>change(e,'infants')} value={valInfants} />
            <NumberOfPeopleField name="children" label="小童（6-12歲）" onChange={(e)=>change(e,'children')} value={valChildren}/>
            <NumberOfPeopleField name="teenagers" label="青少年（13-17歲）" onChange={(e)=>change(e,'teenagers')} value={valTeenagers}/>
            <NumberOfPeopleField name="adults" label="成人（18-59歲）" onChange={(e)=>change(e,'adults')} value={valAdults}/>
            <NumberOfPeopleField name="elders" label="長者（60歲以上）" onChange={(e)=>change(e,'elders')} value={valElders}/>
          </Flex>
          <Flex justifyContent="flex-end">
              <Text className="tempPrice">總額: HKD <strong>{priceFinal}</strong></Text>
              <CustomBtn  onClick={onClick} className="checkout">
                {payButtonLabel}
              </CustomBtn>
          </Flex>
        </Form>
      )}
    </FinalForm>
  );
};

export default CreateTourFormPick;
