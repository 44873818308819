import styled from 'styled-components';
import { border } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import { Box } from '@cowsquare/design';
import { Section } from './Section';

export const FeatureCardSection = styled(Section).attrs({
  flexWrap: ['wrap', 'nowrap'],
})`
  box-shadow: 0 4px 16px 0 ${themeGet('colors.blacks.9')};
  border-radius: 8px;
  background-color: ${themeGet('colors.whites.0')};
`;

export const FeatureBox = styled(Box).attrs({
  width: 1,
  borderRightWidth: [0, 1],
  borderBottomWidth: [1, 0],
})`
  border: 0;
  border-style: solid;
  border-color: ${themeGet('colors.blacks.9')};
  ${border}

  &:last-child {
    border-right: 0;
    border-bottom: 0;
  }
`;
