import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { themeGet } from '@styled-system/theme-get';
import {
  Box,
  Carousel,
  Flex,
  Icon,
  Tag,
  Button,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
} from '@cowsquare/design';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Section } from '../Section';
import { HTMLContent } from '../HTMLContent';
import { COUNTRY_MAP } from '../../../constants/country';
import IconButton from './IconButton';
import { usePlanContext } from '../PlanContext';
import { CARD_TYPE } from './constants/CARD_TYPE';

const StyledIcon = styled(Icon).attrs({
  fontSize: 24,
})`
  color: ${props => props.color || themeGet('colors.primary')(props)};
  margin-right: 8px;
`;

const Description = styled(Box).attrs({
  mt: 6,
})`
  font-size: ${themeGet('fontSizes.h5')};
  line-height: 1.5;
  color: ${themeGet('colors.blacks.3')};
`;

const InfoBox = styled(Box).attrs({
  pl: ['16px', '24px'],
  display: 'flex',
  alignItems: 'center',
  height: ['24px', '56px'],
})``;

const InfoFlex = styled(Flex).attrs({
  width: 1,
  flexDirection: ['column', 'row'],
  justifyContent: 'flex-start',
  alignItems: ['flex-start', 'center'],
  py: ['16px', 0],
})`
  font-size: 14px;
  font-weight: 500;
  background-color: ${themeGet('colors.whites.0')};
`;

const GET_TOUR_RECORD = gql`
  query getTourRecord($country: String!, $name: String!) {
    record: vontravel_tours(where: { name: { _eq: $name }, country: { name: { _eq: $country } } }) {
      id
      name
      price
      days
      isCharter
      hasGuide
      cover {
        link
      }
      content {
        content
      }
      description {
        content
      }
      features {
        feature {
          id
          name
        }
      }
      country_id
    }
  }
`;

const numberWithCommas = x => x.toLocaleString();

const TourCardDrawerPage = ({ country, cardName: name, onClose, onBackList }) => {
  const { addCard, selectedDate, canAddCardToDate } = usePlanContext();
  const history = useHistory();
  const countryName = COUNTRY_MAP[country];

  if (!countryName) {
    // TODO: When 404 page is done, change redirect url to 404 page.
    history.push('/');
  }

  const { data: tourContent } = useQuery(GET_TOUR_RECORD, {
    variables: { country: countryName, name },
  });

  const record = R.pathOr({}, ['record', '0'], tourContent);
  const features = R.pipe(R.propOr([], 'features'), R.map(R.path(['feature', 'name'])))(record);

  return (
    <>
      <DrawerHeader divider={false} onClose={onClose}>
        <Flex alignItems="center">
          <IconButton onClick={onBackList} name="ARROW_LEFT" />
          <Box ml={5}>{name}</Box>
        </Flex>
      </DrawerHeader>
      <DrawerBody>
        <Carousel>
          <Carousel.Slide
            backgroundImage={`url(${R.pathOr('', ['cover', 'link'], record)})`}
            height="218px"
          />
        </Carousel>
        <InfoFlex>
          <InfoBox mb={[4, 0]}>
            <StyledIcon name="LOCATION_FILL" color="primary" />
            {countryName}
          </InfoBox>
          <InfoBox mb={[4, 0]}>
            <StyledIcon name="DATE" color="#38c296" />
            {`${R.propOr('', 'days', record)}日遊`}
          </InfoBox>
          <InfoBox>
            <StyledIcon name="MONEY_CIRCLE_FILL" color="#ec951a" />
            {`${R.pipe(R.propOr(0, 'price'), numberWithCommas)(record)}起 (每人)`}
          </InfoBox>
        </InfoFlex>
        <Box p={5}>
          <Box>
            <Tag.Group itemStyle={{ mr: 3 }}>
              {R.map(
                feature => (
                  <Tag key={feature}>{feature}</Tag>
                ),
                features
              )}
            </Tag.Group>
          </Box>
          <Description>
            <HTMLContent html={R.pathOr('', ['description', 'content'], record)} />
          </Description>
          <Section margin="48px auto 0" py="48px" px={[5, 0]}>
            <HTMLContent html={R.pathOr('', ['content', 'content'], record)} />
          </Section>
        </Box>
      </DrawerBody>
      <DrawerFooter>
        <Button
          size="large"
          variant="primary"
          disabled={R.complement(canAddCardToDate)(selectedDate, CARD_TYPE.TOUR, record.days)}
          onClick={() => {
            addCard(selectedDate, { ...record, cardType: CARD_TYPE.TOUR });
            onClose();
          }}
        >
          加入
        </Button>
      </DrawerFooter>
    </>
  );
};

export default TourCardDrawerPage;
