import React, { useMemo } from 'react';
import { Carousel, Heading, Text, Box, Flex, Button } from '@cowsquare/design';
import { imageSwitch } from 'views/components/ResponsiveImage';
import { Section } from 'views/components/Section';
import { HOMESTAY_COUNTRIES } from 'constants/country';
import { EasyCard } from 'views/components/EasyCard';
import * as R from 'ramda';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { TourCardLoading } from 'views/components/Loading';
import { Machine, assign } from 'xstate';
import { client } from 'apollo/client';
import { useMachine } from '@xstate/react';
import HomestayCard from 'views/components/HomestayCard';
import gql from 'graphql-tag';
import { getBedAmount, getRate } from 'utils/homestay';

const GET_HOMESTAYS = gql`
  query getHomestays {
    vontravel_homestays(
      order_by: { featured: desc, approved_reviews_aggregate: { count: desc }, created_at: desc }
    ) {
      id
      name
      featured
      city {
        id
        name
        area {
          id
          name
          country {
            id
            name
          }
        }
      }
      people
      description
      bedroom
      double_bed
      single_bed
      bunk_bed
      sofa_bed
      bathroom
      view_type {
        name
      }
      pictures(order_by: { created_at: asc }) {
        picture {
          link
        }
      }
      reviews(where: { approved: { _eq: true } }) {
        rate
      }
    }
  }
`;

const S = {
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  ERROR: 'ERROR',
};

const homestayMachine = Machine(
  {
    context: {
      homestays: [],
    },
    initial: S.LOADING,
    states: {
      [S.LOADING]: {
        invoke: {
          src: 'getHomestays',
          onDone: { target: S.LOADED, actions: ['assignHomestays'] },
          onError: { target: S.ERROR },
        },
      },
      [S.LOADED]: {},
      [S.ERROR]: {},
    },
  },
  {
    actions: {
      assignHomestays: assign({
        homestays: (_, event) => event.data,
      }),
    },
    services: {
      getHomestays: () => client.query({ query: GET_HOMESTAYS }).then(res => res.data.vontravel_homestays),
    },
  }
);

const EasyCardBox = styled(Box).attrs({
  width: [1, 1 / 3],
  px: [5, 4],
  pb: [5, '48px'],
})``;

const CardBox = styled(Box).attrs({
  width: [1, 1 / 3],
  px: [5, 4],
  pb: ['24px', '48px'],
})``;

const getTopSixHomestayByCountry = countryName => R.pipe(R.propOr([], countryName), R.take(6));

const Homestay = () => {
  const [state] = useMachine(homestayMachine);
  const { homestays } = state.context;
  const homestaysByCountry = useMemo(
    () => R.groupBy(R.path(['city', 'area', 'country', 'name']), homestays),
    [homestays]
  );

  return (
    <>
      <Carousel>
        <Carousel.Slide
          title="融入當地 盡情體驗"
          description="其他煩惱的一切，交給 Von Travel"
          backgroundImage={`url(${imageSwitch('filios-sazeides-lg3TETZvqv4-unsplash.jpg')})`}
          height="424px"
        />
      </Carousel>
      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        <Heading mt={7} textAlign="center">
          民宿優選
        </Heading>
        <Text mt={4} px={[5, 0]} textAlign="center" fontSize="16px">
          旅遊中透過特色民宿感受不一樣的住宿設計，或貼近自然景觀和生態環境，讓自己融入當地的生活和美景之中
        </Text>
        <Section mt={6} mb={[7, 6]}>
          {R.map(area => (
            <EasyCardBox key={area.name}>
              <EasyCard
                as={Link}
                to={area.available ? `/民宿優選/${area.name}` : '/民宿優選'}
                img={area.cover}
                title={area.name}
                showMask={!area.available}
                maskText="即將推出"
              />
            </EasyCardBox>
          ))(HOMESTAY_COUNTRIES)}
        </Section>
        {R.map(
          country =>
            country.available && (
              <Section flexDirection="column" mb="80px" mx="auto" px={[5, 0]}>
                <Heading textAlign="center">{country.name}</Heading>
                <Flex
                  flexDirection={['column', 'row']}
                  justifyContent="center"
                  alignItems="center"
                  flexWrap="wrap"
                  mx={-4}
                  mt={6}
                  mb={['-24px', '-48px']}
                >
                  {state.matches(S.LOADING)
                    ? R.times(
                        i => (
                          <CardBox key={i}>
                            <TourCardLoading />
                          </CardBox>
                        ),
                        3
                      )
                    : R.map(homestay => (
                        <CardBox key={homestay.id}>
                          <HomestayCard
                            id={homestay.id}
                            as={Link}
                            to={`/民宿優選/${country.name}/${encodeURIComponent(homestay.name)}`}
                            coverUrl={R.pipe(
                              R.propOr([], 'pictures'),
                              R.head,
                              R.path(['picture', 'link'])
                            )(homestay)}
                            title={homestay.name}
                            description={homestay.description}
                            people={homestay.people}
                            bedroom={homestay.bedroom}
                            bed={getBedAmount(homestay)}
                            bathroom={homestay.bathroom}
                            city={R.pathOr('', ['city', 'name'], homestay)}
                            area={R.pathOr('', ['city', 'area', 'name'], homestay)}
                            featured={homestay.featured}
                            rate={`${getRate(homestay)} (${homestay.reviews.length})`}
                          ></HomestayCard>
                        </CardBox>
                      ))(getTopSixHomestayByCountry(country.name)(homestaysByCountry))}
                </Flex>
                <Box mt={6} width={1}>
                  <Flex justifyContent="center">
                    <Button size="large" variant="primary" as={Link} to={`/民宿優選/${country.name}`}>
                      更多{country.name}民宿
                    </Button>
                  </Flex>
                </Box>
              </Section>
            )
        )(HOMESTAY_COUNTRIES)}
      </Flex>
    </>
  );
};
export default Homestay;
