import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  Box, Breadcrumb, Button, Carousel, Flex, Form, Icon, Input, Modal,
  ModalBody, ModalFooter, ModalHeader, Notification, Text
} from '@cowsquare/design';
import { themeGet } from '@styled-system/theme-get';
import { useMachine } from '@xstate/react';
import axios from "axios";
import gql from 'graphql-tag';
import { recommendHomestayMachine, RECOMMEND_HOMESTAY_EVENT } from 'machines/recommendHomestayMachine';
import queryString from 'query-string';
import * as R from 'ramda';
import React, { useCallback, useEffect, useState } from 'react';
import {useCookies} from 'react-cookie';
import { Field, Form as FinalForm } from 'react-final-form';
import ReactStars from 'react-rating-stars-component';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { flexbox, layout } from 'styled-system';
import { composeGoogleMapUrl } from 'utils/composeGoogleMapUrl';
import { formatDate, formatTime } from 'utils/dateFormat';
import getSecurityName from 'utils/getSecurityName';
import { getBedAmount, getCleanlinessRate, getRate, getStaffRate } from 'utils/homestay';
import { useMemberForm, useUser } from 'utils/hooks';
import ContactButton from 'views/components/ContactButton';
import { FeatureCard } from 'views/components/FeatureCard';
import { FeatureBox, FeatureCardSection } from 'views/components/FeatureCardSection';
import HomestayCard from 'views/components/HomestayCard';
import { HomestayDetailLoading } from 'views/components/Loading';
import Map from 'views/components/Map';
import Progress from 'views/components/Progress';
import { Section } from 'views/components/Section';
import { Title } from 'views/components/Title';
import AirconditionerIcon from '../../images/icon_airconditioner.svg';
import AppliancesIcon from '../../images/icon_appliances.svg';
import BabyIcon from '../../images/icon_baby.svg';
import BathIcon from '../../images/icon_bath.svg';
import BedIcon from '../../images/icon_bed.svg';
import DiningIcon from '../../images/icon_dining.svg';
import DriverIcon from '../../images/icon_driver.svg';
import DryerIcon from '../../images/icon_dryer.svg';
import ElevatorIcon from '../../images/icon_elevator.svg';
import EquipmentsIcon from '../../images/icon_equipments.svg';
import FloorIcon from '../../images/icon_floor.svg';
import ForkIcon from '../../images/icon_fork.svg';
import FrontStepIcon from '../../images/icon_front_step.svg';
import HandrailIcon from '../../images/icon_handrail.svg';
import HotSpringIcon from '../../images/icon_hot_spring.svg';
import KitchenIcon from '../../images/icon_kitchen.svg';
import LandscapeIcon from '../../images/icon_landscape.svg';
import MicrowaveIcon from '../../images/icon_microwave.svg';
import ParkingIcon from '../../images/icon_parking.svg';
import RefrigeratorIcon from '../../images/icon_refrigerator.svg';
import StarFillIcon from '../../images/icon_star_fill.svg';
import StarOutlineIcon from '../../images/icon_star_outline.svg';
import TerraceIcon from '../../images/icon_terrace.svg';
import WasherIcon from '../../images/icon_washer.svg';
import WifiIcon from '../../images/icon_wifi.svg';

const Slide = Carousel.Slide;

const VIEWS_MAP = {
  sea_view: '海景',
  lake_view: '湖泊景',
  garden_view: '草園/花園景',
  farming_view: '農場景',
  mountain_view: '山景',
  city_view: '城市景',
};

const BEDS_MAP = {
  double_bed: '張雙人床',
  single_bed: '張單人床',
  bunk_bed: '張雙格床（上下格）',
  cot: '張嬰兒床',
  sofa_bed: '張沙發床',
};

const GET_HOMESTAY_CONTENT = gql`
  query getHomestayContent($country: String!, $name: String!) {
    content: vontravel_homestays(
      where: { city: { area: { country: { name: { _eq: $country } } } }, name: { _eq: $name } }
    ) {
      id
      name
      featured
      city {
        id
        name
        area {
          id
          name
          country {
            id
            name
          }
        }
      }
      code
      pictures(order_by: { created_at: asc }) {
        picture {
          link
        }
      }
      people
      description
      sea_view
      lake_view
      garden_view
      farming_view
      mountain_view
      city_view
      other_view
      air_conditioner
      wifi
      bedroom
      double_bed
      single_bed
      bunk_bed
      cot
      sofa_bed
      terrace
      bathroom
      kitchen
      dining_table
      kitchenwares
      microwave
      refrigerator
      washer
      dryer
      other_appliances
      floor
      baby_equipment
      parking
      hot_spring
      elevator
      front_step
      bathroom_safety_handrail
      other_equipments
      transportation_description
      address
      longitude
      latitude
      check_in
      check_out
      rule
      note
      reviews(where: { approved: { _eq: true } }, order_by: { created_at: desc }) {
        id
        rate
        staff
        cleanliness
        user_id
        user {
          name
        }
        created_at
        comment
      }
    }
  }
`;

const INSERT_HOMESTAY_REVIEW = gql`
  mutation insertHomestayReview($input: vontravel_homestay_reviews_insert_input!) {
    insert_vontravel_homestay_reviews(objects: [$input]) {
      affected_rows
    }
  }
`;

const Info = styled(Flex).attrs({
  mr: 5,
  mb: [5, 0],
  alignItems: 'center',
})``;

const InfoIcon = styled(Icon)`
  margin-right: 8px;
  font-size: 32px;
`;

const InfoText = styled(Text)`
  font-size: 16px;
  font-weight: 500;
`;

const Hr = styled.hr`
  margin: 32px 0;
  opacity: 0.1;
  background-color: #000;
`;

const DeviceBox = styled(Box).attrs({
  width: [1, 1 / 3],
  mb: '48px',
})``;

const RateBox = styled(Flex).attrs({
  width: [1, 1 / 3],
  alignItems: 'center',
  mt: [5, 0],
})``;

const FeedbackBox = styled(Box).attrs({
  width: [1, 1 / 2],
  mb: 6,
})``;

const DeviceInfo = styled(Flex).attrs({
  mt: 5,
})``;

const DeviceIcon = styled.img`
  width: 32px;
  height: 32px;
`;

const WhiteSection = styled(Box)`
  background-color: ${themeGet('colors.whites.0')};
`;

const RowMap = styled(Box).attrs({
  mt: 5,
})`
  width: 100%;
  height: 240px;
`;

const FormContent = styled(Box)`
  .react-stars {
    outline: none;
  }
`;

const FormItem = ({ label, input, children, ...rest }) => (
  <Box mb={5} {...rest}>
    <Text fontSize="13px" color="blacks.3" as="label" htmlFor={input.name}>
      {label}
    </Text>
    <Box mt={3}>{children}</Box>
  </Box>
);

const DeviceField = ({ value, icon, label, desc }) =>
  R.anyPass([R.equals(0), R.equals(false), R.empty, R.isNil])(value) ? null : (
    <DeviceInfo flexDirection="column" justifyContent={desc ? 'flex-start' : 'center'}>
      <Flex justifyContent="flex-start" alignItems="center">
        <DeviceIcon src={icon} />
        <Text ml={5} color="blacks.3">
          {label}
        </Text>
      </Flex>
      <Box>
        {desc && (
          <Text ml="48px" width={['auto', 240]} color="blacks.3">
            {desc}
          </Text>
        )}
      </Box>
    </DeviceInfo>
  );

const Row = styled(Box).attrs({
  display: ['block', 'flex'],
  mb: 5,
})`
  ${layout}
  ${flexbox}
  
    &:last-child {
    margin: 0;
  }
`;

const RowLabel = styled(Box).attrs({
  width: ['100%', 236],
  mb: [4, 0],
})`
  display: inline-block;
  font-size: ${themeGet('fontSizes.h5')};
  line-height: 1.43;
  color: ${themeGet('colors.blacks.5')};
`;

const RowValue = styled(Box)`
  flex: 1;
  font-size: ${themeGet('fontSizes.h5')};
  line-height: 1.43;
  font-weight: 500;
`;
const CustomBtn = styled(Box).attrs({as:'button'})`
    background: #f79646;
    color: #fff;
    padding: 8px 20px;
    border-radius: 7px;
    margin-left: 15px;
    margin-bottom: 20px;
    border:0;
    font-size:16px;
    cursor: pointer;
`;

const NoticeField = ({ label, value, render = R.identity, direction = 'row' }) =>
  !R.either(R.isEmpty, R.isNil)(value) ? (
    <Row flexDirection={direction}>
      <RowLabel>{label}</RowLabel>
      <RowValue ml={direction === 'row' ? [0, 5] : 0}>{render(value)}</RowValue>
    </Row>
  ) : null;

const CardBox = styled(Box).attrs({
  width: [1, 1 / 3],
  px: [5, 4],
  pb: ['24px', 0],
})``;

const starProps = {
  count: 5,
  isHalf: false,
  emptyIcon: (
    <Box mr={4}>
      <img src={StarOutlineIcon} alt="" />
    </Box>
  ),
  filledIcon: (
    <Box mr={4}>
      <img src={StarFillIcon} alt="" />
    </Box>
  ),
};

const HomestayDetail = () => {
  const { country, name } = useParams();
  const { user } = useUser();
  const { openLoginForm } = useMemberForm();
  const [allReviewsModalOpen, setAllReviewsModalOpen] = useState(false);
  const [shareReviewModalOpen, setshareReviewModalOpen] = useState(false);
  const [loadingCheckout, setLoadingCheckout ] = useState(false)
  // eslint-disable-next-line
  const [cookies, setCookie,  removeCookie] = useCookies(['tourFinal']);
  const [insertHomestayReview] = useMutation(INSERT_HOMESTAY_REVIEW);
  const homestayName = decodeURIComponent(name);
  const { data, loading } = useQuery(GET_HOMESTAY_CONTENT, {
    variables: { country, name: homestayName },
  });
  const [rhState, rhSend] = useMachine(recommendHomestayMachine);
  const { recommendHomestays } = rhState.context;

  const homestayContent = R.pathOr([], ['content', 0], data);
  const {
    id: homestayId,
    featured,
    code,
    people,
    bedroom,
    bathroom,
    description,
    other_view,
    air_conditioner,
    wifi,
    address,
    transportation_description,
    terrace,
    kitchen,
    dining_table,
    kitchenwares,
    microwave,
    refrigerator,
    washer,
    dryer,
    other_appliances,
    floor,
    baby_equipment,
    parking,
    hot_spring,
    elevator,
    front_step,
    bathroom_safety_handrail,
    other_equipments,
    reviews,
    latitude,
    longitude,
    check_in,
    check_out,
    rule,
    note,
  } = homestayContent;
  const pictures = R.pipe(R.propOr([], 'pictures'), R.take(26))(homestayContent);
  const area = R.pathOr('', ['city', 'area', 'name'], homestayContent);
  const city = R.pathOr('', ['city', 'name'], homestayContent);
  const totalRate = getRate(homestayContent);
  const staffRate = getStaffRate(homestayContent);
  const cleanlineRate = getCleanlinessRate(homestayContent);
  const reviewCount = R.length(R.propOr([], 'reviews')(homestayContent));
  const viewStr = R.keys(VIEWS_MAP)
    .filter(key => homestayContent[key])
    .map(key => VIEWS_MAP[key])
    .join('、');
  const landscape = viewStr + R.or(other_view, '');
  const bedStr = R.keys(BEDS_MAP)
    .filter(key => !R.equals(homestayContent[key], 0))
    .map(key => `${homestayContent[key]} ${BEDS_MAP[key]}`)
    .join('、');

  //function checkout 500$
  const onCheckout = async () => {
    if (!user) {
      Notification.warning({ message: '請先登入或註冊！' });
      openLoginForm();
      return;
    }
    removeCookie("tourFinal");
    const dataHotel = {
        "user_name": user.name,
        "user_email": user.email,
        "user_phone": user.phone,
        "homestay_name" : homestayName,
        "address" : `${area} - ${city}`,
        "checkout_on": "hotel",
    }
    try {
      if(!loadingCheckout){
            setLoadingCheckout(true);
            // const response = await axios.post("http://localhost:4000/create-checkout-hotel", dataHotel)
            const response = await axios.post("https://pay.vontravels.com/create-checkout-hotel", dataHotel)
            if(response.data.session) {
              dataHotel['payment_id'] = response.data.session.payment_intent;
              dataHotel['payment_method'] =  'card';
              setCookie('info', dataHotel);
              setCookie('payId', response.data.session.id);
              window.location = response.data.sessionUrl
            }
        }
      }
    catch (err) {
            console.log("Error", err)
            Notification.error({
                  message: '已送出表單'
            });
        }
  };
  useEffect(() => {
    if (!loading) {
      const areaId = R.pathOr('', ['city', 'area', 'id'], homestayContent);
      const cityId = R.pathOr('', ['city', 'id'], homestayContent);
      rhSend({
        type: RECOMMEND_HOMESTAY_EVENT.LOAD_HOMESTAYS,
        country,
        areas: [areaId],
        cities: [cityId],
        id: homestayId,
      });
    }
  }, [country, homestayContent, homestayId, loading, rhSend]);

  const toggleAllReviewsModal = useCallback(
    e => {
      e.stopPropagation();
      setAllReviewsModalOpen(!allReviewsModalOpen);
    },
    [allReviewsModalOpen]
  );

  const toggleShareReviewModal = useCallback(() => {
    if (!user) {
      Notification.warning({ message: '請先登入或註冊！' });
      openLoginForm();
      return;
    }
    setshareReviewModalOpen(!shareReviewModalOpen);
  }, [openLoginForm, shareReviewModalOpen, user]);

  const handleContactButton = useCallback(() => {
    window.open(
      `/更多/聯絡我們?${queryString.stringify({
        type: 'homestay',
        object: homestayName,
        no: code,
      })}`,
      '聯絡我們'
    );
  }, [code, homestayName]);

  const handleMarkerClick = useCallback(url => {
    window.open(url);
  }, []);

  const handleReviewSubmit = useCallback(
    values => {
      insertHomestayReview({
        variables: {
          input: {
            user_id: R.prop('id')(user),
            homestay_id: homestayId,
            ...values,
          },
        },
      }).then(res => {
        if (!res.errors) {
          Notification.success({ message: '已成功送出評價' });
        }
      });
      toggleShareReviewModal();
    },
    [homestayId, insertHomestayReview, toggleShareReviewModal, user]
  );

  if (loading) {
    return (
      <Section mx={[0, 'auto']} px={[5, 0]}>
        <HomestayDetailLoading />
      </Section>
    );
  }

  return (
    <>
      <Section flexDirection="column" m="0 auto">
        <Box px={[5, 0]}>
          <Box mt={6} mb={['24px', 6]}>
            <Breadcrumb>
              <Breadcrumb.Item linkProps={{ as: Link, to: '/' }}>首頁</Breadcrumb.Item>
              <Breadcrumb.Item linkProps={{ as: Link, to: '/民宿優選' }}>民宿優選</Breadcrumb.Item>
              <Breadcrumb.Item linkProps={{ as: Link, to: `/民宿優選/${country}` }}>
                {country}民宿
              </Breadcrumb.Item>
              <Breadcrumb.Item>{homestayName}</Breadcrumb.Item>
            </Breadcrumb>
          </Box>
          <Title mb={[5, 4]}>{homestayName}</Title>
          <Flex mb={[0, 5]} flexDirection={['column', 'row']}>
            {featured && (
              <Info>
                <InfoIcon name="LIKE" color="primary" />
                <InfoText color="primary">精選推介</InfoText>
              </Info>
            )}
            <Info>
              <InfoIcon name="LOCATION_FILL" color="primary" />
              <InfoText>{`${area} - ${city}`}</InfoText>
            </Info>
            <Info>
              <InfoIcon name="STAR_FILL" color="#ed9519" />
              <InfoText>{`${totalRate} (${reviewCount}個評價)`}</InfoText>
            </Info>
          </Flex>
          <Flex justifyContent="space-between">
            <Text mb={6} color="blacks.3">{`民宿編號 ${code}`}</Text> 
              <CustomBtn  onClick={onCheckout} className="checkout">
                {loadingCheckout ? '處理中' : '預訂付款'}
              </CustomBtn>
          </Flex>
        </Box>
        <Box width={1}>
          <Carousel variant="gallery" arrows index>
            {R.map(slide => (
              <Slide
                key={slide.picture.link}
                height={[304, 677]}
                backgroundImage={`url(${slide.picture.link})`}
              />
            ))(pictures)}
          </Carousel>
        </Box>
        <Box px={[5, 0]} width={1}>
          <Hr />
          <Text mb={5} color="blacks.3" fontWeight="bold">
            {`${people}人民宿 · ${bedroom}間睡房 · ${getBedAmount(homestayContent)}張床 · ${bathroom}間浴室`}
          </Text>
          <Text color="blacks.3">{description}</Text>
          <Hr />
          {(landscape || air_conditioner || wifi) && (
            <>
              <Title mb={5} fontSize={18}>
                民宿特色
              </Title>
              <Box mb="48px">
                <FeatureCardSection mx="auto" px={[5, 0]} py={[0, 5]}>
                  {landscape && (
                    <FeatureBox>
                      <FeatureCard
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        px={[0, 5]}
                        py={[5, 0]}
                        img={LandscapeIcon}
                        title="絕美景觀"
                        description={landscape}
                      />
                    </FeatureBox>
                  )}
                  {air_conditioner && (
                    <FeatureBox>
                      <FeatureCard
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        px={[0, 5]}
                        py={[5, 0]}
                        img={AirconditionerIcon}
                        title="空調設備"
                        description="提供空調設備可使用"
                      />
                    </FeatureBox>
                  )}
                  {wifi && (
                    <FeatureBox>
                      <FeatureCard
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        px={[0, 5]}
                        py={[5, 0]}
                        img={WifiIcon}
                        title="免費 Wifi"
                        description="提供免費 Wifi 供房客使用"
                      />
                    </FeatureBox>
                  )}
                </FeatureCardSection>
              </Box>
            </>
          )}
          <Title mb={5} fontSize={18}>
            床位安排
          </Title>
          <Box mb="48px">
            <FeatureCardSection mx="auto" px={[5, 0]} py={[0, 5]}>
              <FeatureCard
                justifyContent="flex-start"
                alignItems="flex-start"
                px={[0, 5]}
                py={[5, 0]}
                img={BedIcon}
                title={`${bedroom} 間睡房`}
                description={bedStr}
              />
            </FeatureCardSection>
          </Box>
          <Flex flexDirection={['column', 'row']}>
            <DeviceBox>
              <Title fontSize={18}>室內設施</Title>
              <DeviceField icon={TerraceIcon} value={terrace} label={`${terrace} 個露台`} />
              <DeviceField icon={BathIcon} value={bathroom} label={`${bathroom} 間浴室`} />
              <DeviceField icon={KitchenIcon} value={kitchen} label="廚房" />
              <DeviceField icon={DiningIcon} value={dining_table} label="飯廳連餐桌" />
              <DeviceField icon={ForkIcon} value={kitchenwares} label="廚房用具" desc={kitchenwares} />
            </DeviceBox>
            <DeviceBox>
              <Title fontSize={18}>電氣設施</Title>
              <DeviceField icon={MicrowaveIcon} value={microwave} label="微波爐" />
              <DeviceField icon={RefrigeratorIcon} value={refrigerator} label="雪櫃" />
              <DeviceField icon={WasherIcon} value={washer} label="洗衣機" />
              <DeviceField icon={DryerIcon} value={dryer} label="乾衣機" />
              <DeviceField
                icon={AppliancesIcon}
                value={other_appliances}
                label="其他家電"
                desc={other_appliances}
              />
            </DeviceBox>
            <DeviceBox>
              <Title fontSize={18}>其他設備</Title>
              <DeviceField icon={FloorIcon} value={floor} label={`${floor} 層樓`} />
              <DeviceField icon={BabyIcon} value={baby_equipment} label="嬰兒設施" />
              <DeviceField icon={ParkingIcon} value={parking} label="停車場" />
              <DeviceField icon={HotSpringIcon} value={hot_spring} label="溫泉" />
              <DeviceField icon={ElevatorIcon} value={elevator} label="大廈有電梯" />
              <DeviceField icon={FrontStepIcon} value={front_step} label="民宿入口有樓梯或台階" />
              <DeviceField icon={HandrailIcon} value={bathroom_safety_handrail} label="浴室內有安全扶手" />
              <DeviceField
                icon={EquipmentsIcon}
                value={other_equipments}
                label="其他設施或特點"
                desc={other_equipments}
              />
            </DeviceBox>
          </Flex>
        </Box>
      </Section>
      <WhiteSection py="48px" px={[5, 0]}>
        <Section flexDirection="column" m="0 auto">
          <Title mb={5} fontSize={18}>
            {`住客評價 (${reviewCount}個評價)`}
          </Title>
          {reviewCount >= 1 ? (
            <>
              <Flex flexDirection={['column', 'row']}>
                <RateBox>
                  <Title mr={5} fontSize={16}>
                    整體評價
                  </Title>
                  <Progress percent={totalRate / 5} />
                  <Title mr={5} fontSize={16}>
                    {totalRate}
                  </Title>
                </RateBox>
                <RateBox>
                  <Title mr={5} fontSize={16}>
                    服務態度
                  </Title>
                  <Progress percent={staffRate / 5} />
                  <Title mr={5} fontSize={16}>
                    {staffRate}
                  </Title>
                </RateBox>
                <RateBox>
                  <Title mr={5} fontSize={16}>
                    整潔程度
                  </Title>
                  <Progress percent={cleanlineRate / 5} />
                  <Title mr={5} fontSize={16}>
                    {cleanlineRate}
                  </Title>
                </RateBox>
              </Flex>
              <Hr />
              <Flex flexDirection={['column', 'row']} flexWrap="wrap">
                {R.pipe(
                  R.defaultTo([]),
                  R.take(6),
                  R.map(feedback => (
                    <FeedbackBox key={feedback.id}>
                      <Title mr={5} fontSize={16}>
                        {getSecurityName(feedback.user.name)}
                      </Title>
                      <Text color="blacks.5">{formatDate(feedback.created_at)}</Text>
                      <Text mt={4} color="blacks.3" width={['auto', 500]}>
                        {feedback.comment}
                      </Text>
                    </FeedbackBox>
                  ))
                )(reviews)}
              </Flex>
            </>
          ) : (
            <Text color="blacks.3">尚無評價</Text>
          )}
          <Flex mt={6} justifyContent="flex-end">
            {reviewCount > 1 && (
              <Button size="large" mr={5} onClick={toggleAllReviewsModal}>
                顯示所有評價
              </Button>
            )}
            <Button variant="primary" size="large" onClick={toggleShareReviewModal}>
              分享您的評價
            </Button>
          </Flex>
          <Hr />
          {(!R.isNil(longitude) || !R.isNil(latitude)) && (
            <>
              <Title mb={5} fontSize={18}>
                交通資訊
              </Title>
              <Text color="blacks.3">{transportation_description}</Text>
              <Map
                markers={[{ lat: latitude, lng: longitude }]}
                containerElement={<RowMap />}
                onMarkerClick={() => {
                  handleMarkerClick(composeGoogleMapUrl(address));
                }}
              />
            </>
          )}
          <Flex
            py={5}
            flexDirection={['column', 'row']}
            alignItems={['flex-end', 'center']}
            justifyContent="space-between"
          >
            <FeatureCard
              width={[1, 'auto']}
              img={DriverIcon}
              imgProps={{
                width: '56px',
                height: '56px',
              }}
              title="包車優遊，讓旅程更舒適"
              description="緻優遊另有私人座駕及司機服務，點對點接送，安全舒適"
            />
            <Button variant="primary" size="large" as={Link} to="/包車旅遊">
              了解包車優遊
            </Button>
          </Flex>
          <Hr />
          <Title mb={5} fontSize={18}>
            注意事項
          </Title>
          <NoticeField label="入住時間" value={check_in && `${formatTime(check_in)} 後`} />
          <NoticeField label="退房時間" value={check_out && formatTime(check_out)} />
          <NoticeField label="規則" value={rule} />
          <NoticeField label="備註" value={note} />
        </Section>
      </WhiteSection>
      <Section flexDirection="column" m="0 auto" mt="64px" mb="80px">
        <Title mb={6} textAlign="center">
          更多民宿優選
        </Title>
        <Flex
          flexDirection={['column', 'row']}
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          mx={['auto', -4]}
          mt={6}
          mb={['-24px', 0]}
        >
          {R.map(homestay => (
            <CardBox key={homestay.id}>
              <HomestayCard
                id={homestay.id}
                as={Link}
                to={`/民宿優選/${country}/${encodeURIComponent(homestay.name)}`}
                coverUrl={R.pipe(R.propOr([], 'pictures'), R.head, R.path(['picture', 'link']))(homestay)}
                title={homestay.name}
                description={homestay.description}
                people={homestay.people}
                bedroom={homestay.bedroom}
                bed={getBedAmount(homestay)}
                bathroom={homestay.bathroom}
                city={R.pathOr('', ['city', 'name'], homestay)}
                area={R.pathOr('', ['city', 'area', 'name'], homestay)}
                featured={homestay.featured}
                rate={`${getRate(homestay)} (${homestay.reviews.length})`}
              />
            </CardBox>
          ))(recommendHomestays)}
        </Flex>
      </Section>
      <Modal
        width={[1, 564]}
        mx={[0, 'auto']}
        my={[0, 100]}
        borderRadius={[0, '8px']}
        show={allReviewsModalOpen}
        onClose={toggleAllReviewsModal}
        closeButton
      >
        <ModalHeader height={56}>
          <Text ml={5} fontWeight={500} fontSize="16px">
            所有評價
          </Text>
        </ModalHeader>
        <ModalBody p={0} overflowY="scroll" height={['calc(100vh - 56px)', 643]}>
          <Box m={6}>
            {R.pipe(
              R.defaultTo([]),
              R.map(feedback => (
                <Box mb={6} key={feedback.comment}>
                  <Title mr={5} fontSize={16}>
                    {getSecurityName(feedback.user.name)}
                  </Title>
                  <Text color="blacks.5">{formatDate(feedback.created_at)}</Text>
                  <Text mt={4} color="blacks.3">
                    {feedback.comment}
                  </Text>
                </Box>
              ))
            )(reviews)}
          </Box>
        </ModalBody>
      </Modal>
      <Modal
        width={[1, 500]}
        mx={[0, 'auto']}
        my={[0, 100]}
        borderRadius={[0, '8px']}
        show={shareReviewModalOpen}
        onClose={toggleShareReviewModal}
        closeButton
      >
        <ModalHeader height={56}>
          <Text ml={5} fontWeight={500} fontSize="16px">
            分享您的評價
          </Text>
        </ModalHeader>
        <FinalForm
          initialValues={{
            rate: 1,
            staff: 1,
            cleanliness: 1,
          }}
          onSubmit={handleReviewSubmit}
          render={({ handleSubmit, submitting }) => (
            <>
              <ModalBody px={[5, 6]} pt={5} pb={0} height={['calc(100vh - 56px - 64px)', 'auto']}>
                <FormContent>
                  <Form id="shareReviewForm" onSubmit={handleSubmit} layout="vertical">
                    <Field>
                      {({ input }) => (
                        <FormItem input={input} label="姓名">
                          <Input pl={5} value={R.propOr('', 'name')(user)} size="large" disabled />
                        </FormItem>
                      )}
                    </Field>
                    <Field name="rate">
                      {({ input }) => (
                        <FormItem input={input} label="整體評級">
                          <ReactStars {...starProps} {...input} />
                        </FormItem>
                      )}
                    </Field>
                    <Field name="staff">
                      {({ input }) => (
                        <FormItem input={input} label="服務態度">
                          <ReactStars {...starProps} {...input} />
                        </FormItem>
                      )}
                    </Field>
                    <Field name="cleanliness">
                      {({ input }) => (
                        <FormItem input={input} label="整潔程度">
                          <ReactStars {...starProps} {...input} />
                        </FormItem>
                      )}
                    </Field>
                    <Field name="comment">
                      {({ input }) => (
                        <FormItem input={input} label="評語">
                          <Input as="textarea" p={5} height={127} placeholder="輸入您的評語" {...input} />
                        </FormItem>
                      )}
                    </Field>
                  </Form>
                </FormContent>
              </ModalBody>
              <ModalFooter>
                <Box mr={4}>
                  <Button onClick={toggleShareReviewModal}>取消</Button>
                </Box>
                <Button form="shareReviewForm" type="submit" variant="primary" disabled={submitting}>
                  送出
                </Button>
              </ModalFooter>
            </>
          )}
        />
      </Modal>
      <ContactButton onClick={handleContactButton} />
    </>
  );
};

export default HomestayDetail;
