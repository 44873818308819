import { FORM_STATUS } from '../../constants/form';

export const form = {
  defaults: {
    formStatus: FORM_STATUS.OFF,
  },
  resolvers: {
    Mutation: {
      openLoginForm: (_, __, { cache }) => {
        cache.writeData({ data: { formStatus: FORM_STATUS.LOGIN } });
      },
      openSignupForm: (_, __, { cache }) => {
        cache.writeData({ data: { formStatus: FORM_STATUS.SIGNUP } });
      },
      openForgetPasswordForm: (_, __, { cache }) => {
        cache.writeData({ data: { formStatus: FORM_STATUS.FORGET_PASSWORD } });
      },
      closeForm: (_, __, { cache }) => {
        cache.writeData({ data: { formStatus: FORM_STATUS.OFF } });
      },
    },
  },
};
