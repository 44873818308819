import styled from 'styled-components';
import { space, color, typography } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';

export const Title = styled.h2`
  font-size: ${themeGet('fontSizes.h1')};
  line-height: normal;
  font-weight: 500;
  margin: 0;
  ${space}
  ${color}
  ${typography}
`;
