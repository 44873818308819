import React, { useCallback } from 'react';
import styled from 'styled-components';
import { ellipsis } from 'polished';
import { themeGet } from '@styled-system/theme-get';
import { Card, Tag, Link, Icon, ICON_NAME_MAP } from '@cowsquare/design';
import { useCollect } from '../../utils/hooks';
import { TYPES } from '../../constants/wishList';

const CardInfo = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  padding-top: 16px;
  width: 100%;
  height: auto;
  align-items: center;
`;

const Text = styled.span`
  font-size: ${themeGet('cardInfo.fontSize')};
  color: ${themeGet('cardInfo.color')};
  margin-left: 4px;
`;

const HeartIcon = styled(Icon).attrs(props => ({
  name: props.collectible ? 'HEART_FILL' : 'HEART',
}))`
  color: ${props => (props.collectible ? themeGet('colors.danger') : themeGet('colors.blacks.6'))};
`;

const Info = styled.li`
  ${ellipsis()}
  height: 20px;
  padding: 0 16px;
  margin-bottom: 16px;

  &:last-child {
    cursor: pointer;
  }

  &:hover {
    ${HeartIcon}::before {
      content: "${ICON_NAME_MAP['HEART_FILL']}";
      color: ${themeGet('colors.danger')};
    }
  }
`;

const LocationCard = ({
  id,
  coverUrl,
  title,
  location,
  collectible,
  onCollect,
  tags,
  links,
  showCollectIcon = true,
  ...rest
}) => {
  const { toggleCollect } = useCollect({
    id,
    type: TYPES.優遊地點,
    collected: collectible,
  });

  const handleCollect = useCallback(
    async event => {
      event.preventDefault();
      await toggleCollect();
      if (onCollect) onCollect();
    },
    [onCollect, toggleCollect]
  );

  return (
    <Card
      height={410}
      variant="vertical"
      coverUrl={coverUrl}
      footer={
        <CardInfo>
          <Info key="LOCATION">
            <Icon name="LOCATION_FILL" />
            <Text>{location}</Text>
          </Info>
          {showCollectIcon && (
            <Info key="HEART" onClick={handleCollect}>
              <HeartIcon collectible={collectible} />
              <Text>{collectible ? '已收藏' : '收藏'}</Text>
            </Info>
          )}
        </CardInfo>
      }
      {...rest}
    >
      <Card.Meta title={title}>
        {tags && <Tag.Group maxCounts={3}>{tags}</Tag.Group>}
        {links && <Link.Group>{links}</Link.Group>}
      </Card.Meta>
    </Card>
  );
};

LocationCard.Meta = Card.Meta;

export default LocationCard;
