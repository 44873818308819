export const TYPES = {
  自由旅程: '自由旅程',
  優遊地點: '優遊地點',
};

export const GQL_TYPE_NAME = {
  [TYPES.自由旅程]: 'vontravel_tours',
  [TYPES.優遊地點]: 'vontravel_attractions',
};

export const USER_BRIDGE_TYPE_NAME = {
  [TYPES.自由旅程]: 'vontravel_users_tours_bridge',
  [TYPES.優遊地點]: 'vontravel_users_attractions_bridge',
};
