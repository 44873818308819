import styled from 'styled-components';
import { Box } from '@cowsquare/design';

const TourCardBox = styled(Box).attrs({
  mt: 5,
  width: [1, 1 / 2],
  px: '8px',
})``;

export default TourCardBox;
