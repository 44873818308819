import { pipe } from 'ramda';
import { parseISO, parse, format, formatWithOptions } from 'date-fns/fp';
import { zhCN } from 'date-fns/locale';

export const formatDate = pipe(parseISO, format('yyyy/MM/dd'));

export const formatTime = pipe(
  parse(new Date(), 'HH:mm:ssx'),
  formatWithOptions({ locale: zhCN }, 'aaaa h:mm')
);
