import React, { useCallback } from 'react';
import * as R from 'ramda';
import { Flex, Pagination } from '@cowsquare/design';
import { useMachine } from '@xstate/react';
import { COUNTRY_MAP } from '../../../constants/country';
import AddToPlanButton from '../AddToPlanButton';
import CardList from './CardList';
import TourCardBox from './TourCardBox';
import TourCard from '../TourCard';
import { getHTMLString } from '../../../utils/getHTMLString';
import { CARD_TYPE } from './constants/CARD_TYPE';
import { usePlanContext } from '../PlanContext';
import { useEffect } from 'react';
import { tourMachine, TOUR_EVENTS, TOUR_STATES } from 'machines/tourMachine';
import { TourCardLoading } from '../Loading';
import { assign } from 'xstate';

const TabTour = ({ country, feature, onOpenContentDrawerPage, closeDrawer }) => {
  const { addCard, selectedDate, canAddCardToDate } = usePlanContext();
  const [current, send] = useMachine(tourMachine, {
    context: { country, feature },
    actions: {
      refetch: assign({
        country,
        feature,
      }),
    },
  });
  const { data, page, pageSize } = current.context;
  useEffect(() => {
    send(TOUR_EVENTS.REFETCH);
  }, [country, feature, send]);

  const onPageChange = useCallback(
    page => {
      send({
        type: TOUR_EVENTS.PAGE_CHANGE,
        page,
      });
    },
    [send]
  );

  const total = R.pathOr(0, ['meta', 'aggregate', 'count'], data);
  const countryName = COUNTRY_MAP[country];

  return (
    <>
      <CardList>
        {current.matches(TOUR_STATES.LOADING) &&
          R.times(
            i => (
              <TourCardBox key={i}>
                <TourCardLoading />
              </TourCardBox>
            ),
            2
          )}
        {current.matches(TOUR_STATES.LOADED) &&
          R.pipe(
            R.propOr([], 'list'),
            R.map(item => (
              <TourCardBox key={item.id}>
                <TourCard
                  onClick={() => {
                    onOpenContentDrawerPage(item.name, CARD_TYPE.TOUR);
                  }}
                  coverUrl={R.pathOr('', ['cover', 'link'], item)}
                  fixedCoverSize="160px"
                  height="380px"
                  title={item.name}
                  country={countryName}
                  isCharter={item.isCharter}
                  description={R.pipe(R.pathOr('', ['description', 'content']), getHTMLString)(item)}
                  showCollectIcon={false}
                  links={
                    <AddToPlanButton
                      disabled={R.complement(canAddCardToDate)(selectedDate, CARD_TYPE.TOUR, item.days)}
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        addCard(selectedDate, { ...item, cardType: CARD_TYPE.TOUR });
                        closeDrawer();
                      }}
                    />
                  }
                />
              </TourCardBox>
            ))
          )(data)}
        {/* --- TODO: handle EMPTY & ERROR --- */}
        {current.matches(TOUR_STATES.EMPTY) && null}
        {current.matches(TOUR_STATES.ERROR) && null}
      </CardList>
      {total > pageSize && (
        <Flex py={6} justifyContent="center">
          <Pagination total={total} current={page} pageSize={pageSize} onChange={onPageChange} />
        </Flex>
      )}
    </>
  );
};

export default TabTour;
