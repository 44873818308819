import gql from 'graphql-tag';
import { TYPES } from '../../constants/wishList';

export const INSERT_TOUR_TO_WISH_LIST = gql`
  mutation insertTourToWishList($itemId: uuid!, $userId: uuid!) {
    insert_vontravel_users_tours_bridge(objects: [{ user_id: $userId, tour_id: $itemId }]) {
      affected_rows
    }
  }
`;

export const INSERT_ATTRACTION_TO_WISH_LIST = gql`
  mutation insertTourToWishList($itemId: uuid!, $userId: uuid!) {
    insert_vontravel_users_attractions_bridge(objects: [{ user_id: $userId, attraction_id: $itemId }]) {
      affected_rows
    }
  }
`;

export const DELETE_TOUR_FROM_WISH_LIST = gql`
  mutation deleteTourFromWishList($itemId: uuid!, $userId: uuid!) {
    delete_vontravel_users_tours_bridge(where: { user_id: { _eq: $userId }, tour_id: { _eq: $itemId } }) {
      affected_rows
    }
  }
`;

export const DELETE_ATTRACTION_FROM_WISH_LIST = gql`
  mutation deleteAttractionFromWishList($itemId: uuid!, $userId: uuid!) {
    delete_vontravel_users_attractions_bridge(
      where: { user_id: { _eq: $userId }, attraction_id: { _eq: $itemId } }
    ) {
      affected_rows
    }
  }
`;

export const composeTourCollectedUsersFragment = userId => gql`
  fragment item on vontravel_tours {
    users(where: { user_id: { _eq: "${userId}" } }) {
      user_id
    }
  }
`;

export const composeAttractionCollectedUsersFragment = userId => gql`
  fragment item on vontravel_attractions {
    users(where: { user_id: { _eq: "${userId}" } }) {
      user_id
    }
  }
`;

export const INSERT_MUTATION = {
  [TYPES.自由旅程]: INSERT_TOUR_TO_WISH_LIST,
  [TYPES.優遊地點]: INSERT_ATTRACTION_TO_WISH_LIST,
};

export const DELETE_MUTATION = {
  [TYPES.自由旅程]: DELETE_TOUR_FROM_WISH_LIST,
  [TYPES.優遊地點]: DELETE_ATTRACTION_FROM_WISH_LIST,
};

export const composeCollectedUsersFragment = userId => ({
  [TYPES.自由旅程]: composeTourCollectedUsersFragment(userId),
  [TYPES.優遊地點]: composeAttractionCollectedUsersFragment(userId),
});
