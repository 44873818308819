import React from 'react';
import { useParams } from 'react-router-dom';
import FreeTourContent from './FreeTourContent';

const FreeTourDetail = () => {
  const { country, name } = useParams();

  return <FreeTourContent country={country} name={name} />;
};

export default FreeTourDetail;
