import styled from 'styled-components';
import { Box } from '@cowsquare/design';

export const Section = styled(Box).attrs({
  display: 'flex',
  flexWrap: 'wrap',
  width: 1,
  maxWidth: '1032px',
  as: 'section',
})``;
