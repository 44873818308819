import { Box, Flex, Tag } from '@cowsquare/design';
import { themeGet } from '@styled-system/theme-get';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { getUri } from '../../utils/getUri';
import { useStaticContentQuery } from '../../utils/hooks';
import { HTMLContent } from '../components/HTMLContent';

const StyledTag = styled(Tag)`
  text-decoration: none;
  font-size: 13px;
  font-weight: normal;
  line-height: 1.54;
  letter-spacing: normal;

  &.active {
    background-color: ${themeGet('colors.primary')};
    color: ${themeGet('colors.whites.0')};
    border: none;
  }
`;

const AboutUs = () => {
  const location = useLocation();
  const uri = getUri(location);
  const { content } = useStaticContentQuery(uri);

  return (
    <>
      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        <Box maxWidth="994px" mt={6} mb={7} width={1} flexDirection="column">
          <Tag.Group>
            <StyledTag as={NavLink} to="/更多/使用條款" activeClassName="active">
              使用條款
            </StyledTag>
            <StyledTag as={NavLink} to="/更多/隱私政策" activeClassName="active">
              隱私政策
            </StyledTag>
          </Tag.Group>
          <Box mx="auto" mt={5}>
            <HTMLContent html={content} />
          </Box>
        </Box>
      </Flex>
    </>
  );
};

export default AboutUs;
