import { Box } from '@cowsquare/design';
import * as R from 'ramda';
import React from 'react';
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from 'react-google-maps';
import MarkerIcon from '../../images/icon-location-n.svg';
import ActivedMarkerIcon from '../../images/icon-location-s.svg';

const DEFAULT_CENTER = { lat: 22.3193, lng: 114.1694 };
const DEFAULT_ZOOM = 17;
const GESTURE_HANDLING = 'cooperative';

const DEFAULT_OPTIONS = {
  // these following 7 options turn certain controls off see link below
  streetViewControl: false,
  scaleControl: false,
  mapTypeControl: false,
  panControl: false,
  zoomControl: false,
  rotateControl: false,
  fullscreenControl: false,
};

const buildCoordinates = ({ lat, lng }) => `${lat},${lng}`;

const MapWithAMarker = withScriptjs(
  withGoogleMap(
    ({ markers, zoom = DEFAULT_ZOOM, onMarkerClick, activedCoordinates, options = DEFAULT_OPTIONS }) => {
      const center =
        markers.length === 1
          ? {
              lat: R.path([0, 'lat'], markers),
              lng: R.path([0, 'lng'], markers),
            }
          : DEFAULT_CENTER;
      return (
        <GoogleMap
          defaultZoom={zoom}
          defaultCenter={center}
          gestureHandling={GESTURE_HANDLING}
          defaultOptions={options}
        >
          {markers.map(({ lat, lng }) => {
            const coordinates = buildCoordinates({ lat, lng });
            const active = activedCoordinates === coordinates;
            return (
              <Marker
                key={coordinates}
                icon={active ? ActivedMarkerIcon : MarkerIcon}
                position={{ lat, lng }}
                onClick={() => onMarkerClick({ lat, lng })}
              />
            );
          })}
        </GoogleMap>
      );
    }
  )
);

const Map = ({ markers, zoom, containerElement, activedCoordinates, onMarkerClick, options }) => {
  return (
    <MapWithAMarker
      markers={markers}
      zoom={zoom}
      activedCoordinates={activedCoordinates}
      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCejV8qUXHtn2_AUOBb_Mj7pNPlBc3bSIk&v=3.exp&libraries=geometry,drawing,places"
      loadingElement={<Box height="100%" />}
      mapElement={<Box height="100%" />}
      containerElement={containerElement}
      onMarkerClick={onMarkerClick}
      options={options}
    />
  );
};

export default Map;
