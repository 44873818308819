import _defineProperty from "/opt/build/repo/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _TimeUnitMetadata;

/*
 * Copyright 2018 Palantir Technologies, Inc. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { get12HourFrom24Hour, get24HourFrom12Hour } from './dateUtils';
/** describes a component of time. `H:MM:SS.Units.MS` */

export var Units = {
  HOUR_24: 'hour24',
  HOUR_12: 'hour12',
  MINUTE: 'minute',
  SECOND: 'second',
  MS: 'ms'
}; // currently only support MINUTE

export var TimePrecision = {
  // MILLISECOND: 'millisecond',
  MINUTE: 'minute' // SECOND: 'second',

};
export var ClockFormats = {
  AM: 'am',
  PM: 'pm'
};
/** Returns the given time unit component of the date. */

export var getTimeUnit = function getTimeUnit(unit, date) {
  switch (unit) {
    case Units.HOUR_24:
      return date.getHours();

    case Units.HOUR_12:
      return get12HourFrom24Hour(date.getHours());

    case Units.MINUTE:
      return date.getMinutes();

    case Units.SECOND:
      return date.getSeconds();

    case Units.MS:
      return date.getMilliseconds();

    default:
      throw Error('Invalid TimeUnit');
  }
};
/** Sets the given time unit to the given time in date object. Modifies given `date` object and returns it. */

export var setTimeUnit = function setTimeUnit(unit, time, date, isPm) {
  switch (unit) {
    case Units.HOUR_24:
      date.setHours(time);
      break;

    case Units.HOUR_12:
      date.setHours(get24HourFrom12Hour(time, isPm));
      break;

    case Units.MINUTE:
      date.setMinutes(time);
      break;

    case Units.SECOND:
      date.setSeconds(time);
      break;

    case Units.MS:
      date.setMilliseconds(time);
      break;

    default:
      throw Error('Invalid TimeUnit');
  }

  return date;
};
/** Returns true if `time` is a valid value */

export var isTimeUnitValid = function isTimeUnitValid(unit, time) {
  return time != null && !isNaN(time) && getTimeUnitMin(unit) <= time && time <= getTimeUnitMax(unit);
};
/** If unit of time is greater than max, returns min. If less than min, returns max. Otherwise, returns time. */

export var wrapTimeAtUnit = function wrapTimeAtUnit(unit, time) {
  var max = getTimeUnitMax(unit);
  var min = getTimeUnitMin(unit);

  if (time > max) {
    return min;
  } else if (time < min) {
    return max;
  }

  return time;
};
export var getTimeUnitClassName = function getTimeUnitClassName(unit) {
  return TimeUnitMetadata[unit].className;
};
export var getTimeUnitMax = function getTimeUnitMax(unit) {
  return TimeUnitMetadata[unit].max;
};
export var getTimeUnitMin = function getTimeUnitMin(unit) {
  return TimeUnitMetadata[unit].min;
};
export var getDefaultMinTime = function getDefaultMinTime() {
  return new Date(0, 0, 0, DEFAULT_MIN_HOUR, DEFAULT_MIN_MINUTE, DEFAULT_MIN_SECOND, DEFAULT_MIN_MILLISECOND);
};
export var getDefaultMaxTime = function getDefaultMaxTime() {
  return new Date(0, 0, 0, DEFAULT_MAX_HOUR, DEFAULT_MAX_MINUTE, DEFAULT_MAX_SECOND, DEFAULT_MAX_MILLISECOND);
};
var DEFAULT_MIN_HOUR = 0;
var MERIDIEM_MIN_HOUR = 1;
var DEFAULT_MIN_MINUTE = 0;
var DEFAULT_MIN_SECOND = 0;
var DEFAULT_MIN_MILLISECOND = 0;
var DEFAULT_MAX_HOUR = 23;
var MERIDIEM_MAX_HOUR = 12;
var DEFAULT_MAX_MINUTE = 59;
var DEFAULT_MAX_SECOND = 59;
var DEFAULT_MAX_MILLISECOND = 999;
/**
 * A datastore (internal to this file) mapping TimeUnits to useful information about them.
 * Use the `get*` methods above to access these fields.
 */

var TimeUnitMetadata = (_TimeUnitMetadata = {}, _defineProperty(_TimeUnitMetadata, Units.HOUR_24, {
  max: DEFAULT_MAX_HOUR,
  min: DEFAULT_MIN_HOUR
}), _defineProperty(_TimeUnitMetadata, Units.HOUR_12, {
  max: MERIDIEM_MAX_HOUR,
  min: MERIDIEM_MIN_HOUR
}), _defineProperty(_TimeUnitMetadata, Units.MINUTE, {
  max: DEFAULT_MAX_MINUTE,
  min: DEFAULT_MIN_MINUTE
}), _defineProperty(_TimeUnitMetadata, Units.SECOND, {
  max: DEFAULT_MAX_SECOND,
  min: DEFAULT_MIN_SECOND
}), _defineProperty(_TimeUnitMetadata, Units.MS, {
  max: DEFAULT_MAX_MILLISECOND,
  min: DEFAULT_MIN_MILLISECOND
}), _TimeUnitMetadata);