import * as R from 'ramda';

const getSecurityName = (name, showLength = 5) =>
  R.cond([
    [
      R.pipe(R.length, R.gt(R.__, showLength)),
      name =>
        R.pipe(
          R.dropLast(name.length - showLength),
          R.append(R.join('', R.repeat('*', name.length - showLength)))
        )(name),
    ],
    [R.T, R.identity],
  ])(name);

export default getSecurityName;
