import React from 'react';
import styled from 'styled-components';
import { Box, Flex } from '@cowsquare/design';
import { themeGet } from '@styled-system/theme-get';
import { layout, typography } from 'styled-system';

const Icon = styled.img`
  width: 40px;
  height: 40px;
  ${layout}
`;

const Title = styled.h3`
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  ${typography}
`;

const Description = styled.p`
  font-size: 13px;
  line-height: 1.62;
  color: ${themeGet('colors.blacks.4')};
  margin: 0;
  margin-top: 8px;
`;

export const FeatureCard = ({ img, imgProps, title, description, ...rest }) => (
  <Flex alignItems="center" {...rest}>
    <Icon src={img} {...imgProps}></Icon>
    <Box ml={5}>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Box>
  </Flex>
);
