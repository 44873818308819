import React, { useEffect } from 'react';
import posed, { PoseGroup } from 'react-pose';
import styled, { css } from 'styled-components';
import { Box, Button, Flex, Icon } from '@cowsquare/design';
import { themeGet } from '@styled-system/theme-get';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import { DesktopLoginForm, MobileLoginForm } from './member/LoginForm';
import { DesktopSignupForm, MobileSignupForm } from './member/SignupForm';
import { DesktopForgetPasswordForm, MobileForgetPasswordForm } from './member/ForgetPasswordForm';
import { FORM_STATUS } from '../../constants/form';
import { useMemberForm, useLogin, useSignup, useForgetPassword } from '../../utils/hooks';
import { LogoLight } from '../layouts/Logo';

const Mask = styled(Flex).attrs({
  justifyContent: 'center',
  alignItems: 'center',
})`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: ${themeGet('colors.blacks.5')};
  z-index: ${themeGet('zIndex.modalMask')};
  overflow: scroll;
`;

const SideMenuHeader = styled.div`
  background-color: #0080c8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  padding: 0 24px;
`;

const SideMenuButton = styled(Button).attrs({
  variant: 'link',
})`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  padding: 0;
`;

const SideMenuAnimation = posed.div({
  enter: {
    x: 0,
    transition: { duration: 300, ease: 'circOut' },
  },
  exit: {
    x: '100%',
    transition: { duration: 200, ease: 'circIn' },
    delay: 0,
  },
});

const SideMenu = styled(SideMenuAnimation)`
  display: flex;
  flex-direction: column;
  background-color: ${themeGet('colors.background')};
  height: 100%;
  overflow: auto;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: ${themeGet('zIndex.fixed')};
  -webkit-overflow-scrolling: touch;
`;

const MaskContent = styled(Flex)`
  position: relative;
`;

const CloseButton = styled.button`
  outline: none;
  padding: 0;
  border: 0;
  cursor: pointer;
`;

const ModalCloseButton = styled(CloseButton)`
  ${props =>
    props.inside
      ? css`
          position: absolute;
          top: 16px;
          right: 16px;
          ${Icon} {
            color: black;
          }
        `
      : css`
          width: 40px;
          height: 40px;
          border-radius: 8px;
          background-color: white;
          margin-left: 4px;
        `}
`;

const Modal = ({ children, onClose, insideCloseButton }) => (
  <Mask>
    <MaskContent>
      {children}
      <ModalCloseButton inside={insideCloseButton} onClick={onClose}>
        <Icon name="close" />
      </ModalCloseButton>
    </MaskContent>
  </Mask>
);

const MemberForm = () => {
  const { closeForm, openLoginForm, openSignupForm, openForgetPasswordForm, formStatus } = useMemberForm();
  const [login, loginError] = useLogin();
  const [signup, signupError] = useSignup();
  const [forgetPasswrod, forgetPasswrodError] = useForgetPassword();

  return (
    <>
      <Box display={['none', 'block']}>
        {(formStatus === FORM_STATUS.LOGIN || formStatus === FORM_STATUS.SIGNUP) && (
          <Modal onClose={closeForm}>
            {formStatus === FORM_STATUS.LOGIN ? (
              <DesktopLoginForm
                onSubmit={login}
                errorMessage={loginError}
                onSignupClick={openSignupForm}
                onForgetPasswordClick={openForgetPasswordForm}
              />
            ) : (
              <DesktopSignupForm onSubmit={signup} errorMessage={signupError} onLoginClick={openLoginForm} />
            )}
          </Modal>
        )}
        {formStatus === FORM_STATUS.FORGET_PASSWORD && (
          <Modal onClose={closeForm} insideCloseButton>
            <DesktopForgetPasswordForm onSubmit={forgetPasswrod} errorMessage={forgetPasswrodError} />
          </Modal>
        )}
      </Box>
      <Box display={['block', 'none']}>
        <PoseGroup>
          <SideMenu key="mobile-menu">
            <div>
              <SideMenuHeader>
                <Box>
                  <LogoLight />
                </Box>
                <SideMenuButton onClick={closeForm}>
                  <Icon name="close" color="#fff" />
                </SideMenuButton>
              </SideMenuHeader>
            </div>
            {formStatus === FORM_STATUS.LOGIN && (
              <MobileLoginForm
                onSubmit={login}
                errorMessage={loginError}
                onSignupClick={openSignupForm}
                onForgetPasswordClick={openForgetPasswordForm}
              />
            )}
            {formStatus === FORM_STATUS.SIGNUP && (
              <MobileSignupForm onSubmit={signup} errorMessage={signupError} onLoginClick={openLoginForm} />
            )}
            {formStatus === FORM_STATUS.FORGET_PASSWORD && (
              <MobileForgetPasswordForm onSubmit={forgetPasswrod} errorMessage={forgetPasswrodError} />
            )}
          </SideMenu>
        </PoseGroup>
      </Box>
    </>
  );
};

const EnhancedMemberForm = () => {
  const { formStatus } = useMemberForm();

  useEffect(() => {
    if (formStatus !== FORM_STATUS.OFF) {
      disableBodyScroll();
    } else {
      clearAllBodyScrollLocks();
    }
  }, [formStatus]);

  return formStatus === FORM_STATUS.OFF ? null : <MemberForm />;
};

export default EnhancedMemberForm;
