import React from 'react';
import { Icon } from '@cowsquare/design';
import styled from 'styled-components';
import { space } from 'styled-system';

const Button = styled.button`
  ${space}
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  padding: 0;
  display: inline-flex;
`;

const IconButton = ({ name, ...otherProps }) => (
  <Button {...otherProps}>
    <Icon name={name} color="black" fontSize="18" />
  </Button>
);

export default IconButton;
