import _defineProperty from "/opt/build/repo/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { rgba } from 'polished';
var WHITE = 'white';
var BLACK = 'black';
var DANGER = '#FF4848';
var base = {
  space: [0, 1, 2, 4, 8, 16, 24, 32, 64],
  breakpoints: ['992px'],
  colors: {
    blacks: [BLACK, rgba(BLACK, 0.9), rgba(BLACK, 0.8), rgba(BLACK, 0.7), rgba(BLACK, 0.6), rgba(BLACK, 0.5), rgba(BLACK, 0.4), rgba(BLACK, 0.3), rgba(BLACK, 0.2), rgba(BLACK, 0.1), rgba(BLACK, 0.05)],
    whites: [WHITE, rgba(WHITE, 0.9), rgba(WHITE, 0.8), rgba(WHITE, 0.7), rgba(WHITE, 0.6), rgba(WHITE, 0.5), rgba(WHITE, 0.4), rgba(WHITE, 0.3), rgba(WHITE, 0.2), rgba(WHITE, 0.1)],
    primary: '#4560B0',
    primaryDark: '#233771',
    primaryHover: '#6A7FBF',
    primaryLight: '#B9C4E3',
    primaryUltralight: '#E4F1FF',
    success: '#6CC978',
    danger: DANGER,
    errorBackground: rgba(DANGER, 0.1),
    icon: rgba(BLACK, 0.4),
    link: '#4A90E2',
    border: rgba(BLACK, 0.2),
    borderSeparator: '#E5E5E5',
    transparent: 'transparent',
    background: '#f4f6f8'
  },
  borders: [0, '1px solid', '2px solid', '4px solid', '8px solid', '16px solid', '32px solid'],
  fonts: {
    chinese: 'Noto Sans CJK TC',
    english: 'Open Sans'
  },
  fontSizes: {
    h1: '36px',
    h2: '24px',
    h3: '18px',
    h4: '16px',
    h5: '14px',
    text: '13px',
    icon: '20px',
    smallIcon: '15px'
  },
  fontWeights: {
    normal: 400,
    semibold: 600
  },
  radii: [0, 2, 5, 9999, '50%'],
  zIndex: {
    select: 1000,
    sticky: 1010,
    fixed: 1030,
    modalMask: 1040,
    modal: 1050,
    popover: 1060,
    tooltip: 1070
  }
};
var checkbox = {
  text: {
    fontSize: base.fontSizes.text,
    color: base.colors.blacks[0],
    disabled: {
      color: base.colors.blacks[8]
    }
  },
  indicator: {
    size: '16px',
    border: base.borders[1],
    borderRadius: '2px',
    borderColor: base.colors.blacks[8],
    backgroundColor: base.colors.whites[0],
    checked: {
      borderColor: base.colors.primary,
      backgroundColor: base.colors.primary
    },
    disabled: {
      borderColor: base.colors.blacks[8],
      backgroundColor: base.colors.blacks[9]
    },
    hover: {
      borderColor: base.colors.primary
    },
    icon: {
      borderColor: base.colors.whites[0]
    },
    indeterminate: {
      backgroundColor: base.colors.primary,
      disabled: {
        backgroundColor: base.colors.blacks[8]
      }
    }
  }
};
var radio = {
  text: checkbox.text,
  indicator: _objectSpread({}, checkbox.indicator, {
    borderRadius: "".concat(base.radii[3], "px"),
    disabledChecked: {
      borderColor: base.colors.transparent,
      backgroundColor: base.colors.blacks[8]
    },
    icon: {
      backgroundColor: base.colors.whites[0]
    }
  }),
  tag: {
    active: {
      color: base.colors.whites[0],
      backgroundColor: base.colors.primary,
      borderColor: base.colors.primary,
      '&:hover': {
        color: base.colors.whites[0],
        backgroundColor: base.colors.primary
      }
    }
  }
};
var button = {
  border: base.borders[1],
  borderRadius: "".concat(base.radii[2], "px"),
  borderTop: null,
  borderBottom: null,
  borderLeft: null,
  borderRight: null,
  boxShadow: null,
  fontSize: base.fontSizes.text,
  iconSize: '20px'
};
var buttons = {
  primary: {
    color: base.colors.whites[0],
    backgroundColor: base.colors.primary,
    borderColor: base.colors.primary,
    '&:hover': {
      color: base.colors.whites[0],
      borderColor: base.colors.primaryHover,
      backgroundColor: base.colors.primaryHover
    },
    '&:active': {
      color: base.colors.whites[0],
      borderColor: base.colors.primaryDark,
      backgroundColor: base.colors.primaryDark
    },
    '&:disabled': {
      color: base.colors.blacks[7],
      borderColor: base.colors.blacks[8],
      backgroundColor: base.colors.blacks[9]
    }
  },
  link: {
    color: base.colors.link,
    backgroundColor: 'transparent',
    border: 'none',
    '&:hover': {
      color: base.colors.primary
    },
    '&:active': {
      color: base.colors.primary
    },
    '&:disabled': {
      color: base.colors.blacks[7]
    }
  },
  default: {
    color: base.colors.blacks[3],
    backgroundColor: base.colors.transparent,
    borderColor: base.colors.blacks[6],
    '&:hover': {
      color: base.colors.primary,
      borderColor: base.colors.primary,
      backgroundColor: base.colors.transparent
    },
    '&:active': {
      color: base.colors.whites[0],
      borderColor: base.colors.primary,
      backgroundColor: base.colors.primary
    },
    '&:disabled': {
      color: base.colors.blacks[7],
      borderColor: base.colors.blacks[8],
      backgroundColor: base.colors.blacks[9]
    }
  },
  size: {
    large: {
      height: 40,
      padding: '0 10px'
    },
    small: {
      height: 26,
      padding: '0 10px'
    },
    default: {
      height: 32,
      padding: '0 10px'
    }
  }
};
var cardInfo = {
  fontSize: base.fontSizes.text,
  color: base.colors.blacks[3]
};
var carousel = {
  dots: {
    default: {
      position: 'absolute'
    },
    gallery: {
      position: 'static'
    },
    left: 0,
    bottom: '16px',
    textAlign: 'center'
  },
  dot: {
    width: '5px',
    height: '5px',
    margin: '0px 2px',
    backgroundColor: BLACK,
    border: 'none',
    borderRadius: '1px',
    active: {
      width: '20px',
      height: '5px',
      borderRadius: '1px',
      backgroundColor: WHITE
    }
  },
  slideTitle: {
    fontSize: '36px',
    fontWeight: 700,
    color: WHITE
  },
  slideDescription: {
    fontSize: '24px',
    color: WHITE
  },
  arrow: {
    default: {
      bottom: '16px'
    },
    gallery: {
      // add thumbnail height
      bottom: '120px'
    },
    top: 'auto',
    width: '32px',
    height: '32px',
    border: 0,
    borderRadius: 0,
    backgroundColor: base.colors.blacks[6],
    prev: {
      left: 'auto',
      right: '52px'
    },
    next: {
      left: 'auto',
      right: '16px'
    }
  },
  customPage: {
    width: '100px',
    height: '100px',
    margin: '4px 4px 0 0',
    border: '4px solid',
    borderColor: base.colors.primary
  },
  index: {
    position: 'absolute',
    top: '16px',
    right: '16px',
    bottom: 'auto',
    left: 'auto',
    padding: '4px 8px',
    backgroundColor: base.colors.blacks[6],
    color: base.colors.whites[0],
    borderRadius: '4px',
    fontSize: '14px'
  }
};
var drawer = {
  maskColor: base.colors.blacks[5],
  header: {
    justifyContent: 'flex-start',
    height: '52px',
    px: '16px',
    heading: {
      fontSize: '18px'
    }
  },
  closeButton: {
    size: '20px',
    fontSize: '20px',
    color: base.colors.blacks[3],
    top: '16px',
    right: '16px',
    backgroundColor: base.colors.whites[0],
    borderRadius: 0
  },
  body: {
    padding: 0,
    backgroundColor: base.colors.background
  },
  content: {
    boxShadow: null,
    backgroundColor: base.colors.whites[0],
    border: null,
    borderRadius: 0,
    borderColor: null
  },
  footer: {
    justifyContent: 'flex-end',
    height: '64px',
    px: '16px',
    borderTop: null,
    borderColor: null,
    boxShadow: null
  }
};
var select = {
  arrow: {
    color: base.colors.blacks[6],
    up: {
      color: base.colors.blacks[3]
    }
  },
  menu: {
    maxHeight: '300px',
    border: base.borders[1],
    borderColor: base.colors.border,
    borderRadius: "".concat(base.radii[2], "px"),
    boxShadow: null,
    backgroundColor: base.colors.whites[0],
    zIndex: base.zIndex.select
  },
  option: {
    height: '36px',
    fontSize: base.fontSizes.text,
    color: base.colors.blacks[3],
    backgroundColor: null,
    border: {
      top: null,
      left: null,
      right: null,
      bottom: null
    },
    borderRadius: null,
    boxShadow: null,
    hover: {
      color: base.colors.blacks[3],
      backgroundColor: base.colors.primaryUltralight
    },
    selected: {
      color: WHITE,
      backgroundColor: base.colors.primary
    },
    disabled: {
      color: base.colors.blacks[8],
      backgroundColor: WHITE
    }
  },
  group: {
    title: {
      fontSize: base.fontSizes.text,
      color: base.colors.primaryHover,
      backgroundColor: null,
      border: {
        top: null,
        left: null,
        right: null,
        bottom: null
      },
      borderRadius: null
    }
  }
};
var dateInput = {
  border: "".concat(base.borders[1], " ").concat(base.colors.border),
  borderRadius: "".concat(base.radii[2], "px"),
  boxShadow: null,
  backgroundColor: base.colors.whites[0],
  zIndex: base.zIndex.select
};
var dateRangeInput = {
  border: "".concat(base.borders[1], " ").concat(base.colors.border),
  borderRadius: "".concat(base.radii[2], "px"),
  boxShadow: null,
  backgroundColor: base.colors.whites[0],
  zIndex: base.zIndex.select
};
var dropdown = {
  menu: {
    border: base.borders[1],
    borderColor: base.colors.border,
    borderRadius: "".concat(base.radii[2], "px"),
    boxShadow: null,
    backgroundColor: base.colors.whites[0],
    zIndex: base.zIndex.select,
    maxHeight: '300px'
  },
  option: {
    height: '50px',
    fontSize: base.fontSizes.text,
    color: base.colors.blacks[3],
    padding: '0 15px',
    backgroundColor: null,
    border: {
      top: null,
      left: null,
      right: null,
      bottom: null
    },
    borderRadius: null,
    boxShadow: null,
    hover: {
      color: base.colors.blacks[3],
      backgroundColor: base.colors.primaryUltralight
    },
    selected: {
      color: WHITE,
      backgroundColor: base.colors.primary
    },
    disabled: {
      color: base.colors.blacks[8],
      backgroundColor: WHITE
    }
  },
  group: {
    title: {
      fontSize: base.fontSizes.text,
      color: base.colors.primaryHover,
      backgroundColor: null,
      border: {
        top: null,
        left: null,
        right: null,
        bottom: null
      },
      borderRadius: null
    }
  }
};
var lightbox = {
  maskColor: base.colors.blacks[2],
  closeButton: {
    size: '32px',
    fontSize: '16px',
    top: '32px',
    right: '32px',
    backgroundColor: base.colors.blacks[7],
    borderRadius: '50%'
  },
  slideImage: {
    desktopMaxWidth: 'calc(100vw - 160px)',
    desktopMaxHeight: 'calc(100vh - 160px)'
  },
  arrow: {
    top: 'calc(50% - 16px)',
    bottom: 'auto',
    width: '32px',
    height: '32px',
    border: 0,
    borderRadius: '50%',
    backgroundColor: base.colors.blacks[6],
    prev: {
      left: '32px',
      right: 'auto'
    },
    next: {
      left: 'auto',
      right: '32px'
    }
  }
};
var INPUT_TRANSPARENT_BORDER = "".concat(base.borders[1], " ").concat(base.colors.transparent);
var INPUT_FOCUS_BORDER = "".concat(base.borders[1], " ").concat(base.colors.primary);
var INPUT_ERROR_BORDER = "".concat(base.borders[1], " ").concat(base.colors.danger);
var input = {
  fontSize: base.fontSizes.text,
  borderRadius: "".concat(base.radii[2], "px"),
  backgroundColor: base.colors.blacks[10],
  placeHolderColor: base.colors.blacks[6],
  border: {
    top: INPUT_TRANSPARENT_BORDER,
    left: INPUT_TRANSPARENT_BORDER,
    right: INPUT_TRANSPARENT_BORDER,
    bottom: INPUT_TRANSPARENT_BORDER
  },
  hover: {
    border: {
      top: INPUT_TRANSPARENT_BORDER,
      left: INPUT_TRANSPARENT_BORDER,
      right: INPUT_TRANSPARENT_BORDER,
      bottom: INPUT_TRANSPARENT_BORDER
    },
    backgroundColor: base.colors.primaryUltralight
  },
  focus: {
    border: {
      top: INPUT_FOCUS_BORDER,
      left: INPUT_FOCUS_BORDER,
      right: INPUT_FOCUS_BORDER,
      bottom: INPUT_FOCUS_BORDER
    },
    backgroundColor: base.colors.primaryUltralight
  },
  disabled: {
    border: {
      top: INPUT_TRANSPARENT_BORDER,
      left: INPUT_TRANSPARENT_BORDER,
      right: INPUT_TRANSPARENT_BORDER,
      bottom: INPUT_TRANSPARENT_BORDER
    },
    backgroundColor: base.colors.blacks[9],
    placeHolderColor: base.colors.blacks[8],
    color: base.colors.blacks[7]
  },
  error: {
    border: {
      top: INPUT_ERROR_BORDER,
      left: INPUT_ERROR_BORDER,
      right: INPUT_ERROR_BORDER,
      bottom: INPUT_ERROR_BORDER
    },
    backgroundColor: rgba(base.colors.danger, 0.1)
  },
  clearButton: {
    hover: {
      color: base.colors.primary
    },
    active: {
      color: base.colors.primaryDark
    }
  },
  sizes: {
    large: '40px',
    default: '32px',
    small: '26px'
  }
};
var modal = {
  maskColor: base.colors.blacks[5],
  header: {
    justifyContent: 'center',
    height: '52px',
    px: 'unset',
    borderBottom: '1px solid',
    borderColor: base.colors.blacks[9],
    heading: {
      fontSize: '16px'
    }
  },
  closeButton: {
    size: '20px',
    fontSize: '20px',
    color: base.colors.blacks[3],
    top: '16px',
    right: '16px',
    backgroundColor: base.colors.whites[0],
    borderRadius: 0
  },
  body: {
    padding: '32px'
  },
  content: {
    width: ['auto', '500px'],
    mx: ['16px', 'auto'],
    my: '100px',
    boxShadow: "0 5px 10px 0 ".concat(base.colors.blacks[8]),
    backgroundColor: base.colors.whites[0],
    border: 'none',
    borderRadius: "".concat(base.radii[2], "px"),
    borderColor: 'inherit'
  },
  footer: {
    justifyContent: 'flex-end',
    height: '64px',
    px: '16px',
    borderTop: '1px solid',
    borderColor: base.colors.blacks[9]
  }
};
var notification = {
  width: '288px',
  borderRadius: "".concat(base.radii[2], "px"),
  boxShadow: "0 3px 10px 0 ".concat(base.colors.blacks[9]),
  backgroundColor: base.colors.whites[0],
  title: {
    fontSize: base.fontSizes.text,
    color: base.colors.blacks[0]
  },
  description: {
    fontSize: base.fontSizes.text,
    color: base.colors.blacks[3]
  },
  closeButton: {
    fontSize: '16px',
    top: '8px',
    right: '8px'
  }
};
var pagination = {
  border: {
    bottom: 0
  },
  item: {
    minWidth: '30px',
    fontSize: base.fontSizes.h5,
    color: base.colors.blacks[6],
    backgroundColor: base.colors.transparent,
    border: {
      top: 0,
      left: 0,
      right: 0,
      bottom: "".concat(base.borders[2], " ").concat(base.colors.transparent)
    },
    borderRadius: 0,
    hover: {
      color: base.colors.primary,
      backgroundColor: base.colors.transparent,
      border: {
        top: 0,
        left: 0,
        right: 0,
        bottom: "".concat(base.borders[2], " ").concat(base.colors.transparent)
      },
      borderRadius: 0
    },
    selected: {
      color: BLACK,
      backgroundColor: base.colors.transparent,
      border: {
        top: 0,
        left: 0,
        right: 0,
        bottom: "".concat(base.borders[2], " ").concat(base.colors.primary)
      },
      borderRadius: 0
    }
  },
  arrow: {
    color: base.colors.blacks[7],
    hover: {
      color: base.colors.primary
    },
    disabled: {
      color: base.colors.blacks[9]
    }
  },
  more: {
    color: base.colors.blacks[6]
  }
};
var statusLabel = {
  fontSize: base.fontSizes.text,
  color: base.colors.whites[0],
  maxWidth: '120px',
  border: 0,
  borderRadius: "".concat(base.radii[2], "px"),
  types: {
    info: base.colors.primary,
    success: base.colors.success,
    danger: base.colors.danger
  }
};
var tag = {
  maxWidth: '120px',
  fontSize: base.fontSizes.text,
  lineHeight: 1.4,
  color: base.colors.blacks[3],
  backgroundColor: base.colors.transparent,
  border: "".concat(base.borders[1], " ").concat(base.colors.border),
  borderRadius: "".concat(base.radii[3], "px"),
  boxShadow: null,
  hover: {
    color: base.colors.primary,
    border: "".concat(base.borders[1], " ").concat(base.colors.transparent),
    backgroundColor: base.colors.primaryUltralight
  },
  active: {
    color: base.colors.whites[0],
    border: "".concat(base.borders[1], " ").concat(base.colors.transparent),
    backgroundColor: base.colors.primary
  }
};
var link = {
  color: base.colors.link,
  fontSize: base.fontSizes.text,
  hover: {
    color: base.colors.primaryHover
  },
  active: {
    color: base.colors.primaryDark
  }
};
var form = {
  label: {
    fontSize: '13px',
    color: base.colors.blacks[3],
    requiredColor: base.colors.danger
  },
  errorMessage: {
    minHeight: '16px',
    fontSize: '13px',
    color: base.colors.danger
  }
};
var card = {
  backgroundColor: base.colors.whites[0],
  border: base.borders[1],
  borderColor: base.colors.blacks[8],
  borderRadius: base.radii[0],
  boxShadow: 'none',
  wrapper: {
    vertical: {
      flexDirection: 'column'
    },
    horizontal: {
      flexDirection: 'row'
    }
  },
  cover: {
    vertical: {
      width: '100%',
      height: '200px'
    },
    horizontal: {
      height: 'auto',
      width: '200px'
    }
  },
  content: {
    vertical: {
      minWidth: '300px'
    },
    horizontal: {
      minWidth: '400px'
    }
  },
  header: {
    float: {
      position: 'absolute',
      marginTop: '-56px',
      color: base.colors.whites[0],
      backgroundColor: base.colors.blacks[7]
    },
    static: {
      position: 'static',
      marginTop: '0',
      color: base.colors.blacks[0],
      backgroundColor: base.colors.whites[0]
    }
  },
  title: {
    color: base.colors.blacks[0],
    fontSize: base.fontSizes.h3,
    lineClamp: 2
  },
  description: {
    marginBottom: '16px',
    color: base.colors.blacks[6],
    fontSize: base.fontSizes.h5,
    lineClamp: 3
  },
  footer: {
    borderTop: base.borders[1],
    borderColor: base.colors.blacks[9]
  },
  event: {
    date: {
      static: {
        color: base.colors.primary
      },
      float: {
        color: base.colors.whites[0]
      },
      fontSize: base.fontSizes.h2,
      fontWeight: base.fontWeights.semibold
    }
  },
  article: {
    avatar: {
      size: '26px'
    },
    author: {
      fontSize: base.fontSizes.text,
      fontWeight: base.fontWeights.semibold,
      static: {
        color: base.colors.blacks[0]
      },
      float: {
        color: base.colors.whites[0]
      }
    },
    date: {
      static: {
        color: base.colors.blacks[6]
      },
      float: {
        color: base.colors.whites[0]
      },
      fontSize: base.fontSizes.text
    }
  }
};
var calendar = {
  day: {
    color: base.colors.blacks[0],
    hover: {
      backgroundColor: base.colors.primaryUltralight,
      color: base.colors.blacks[0]
    },
    active: {
      backgroundColor: base.colors.primaryLight,
      color: base.colors.blacks[0]
    },
    selected: {
      backgroundColor: base.colors.primary,
      color: base.colors.whites[0],
      hover: {
        backgroundColor: base.colors.primaryHover
      },
      range: {
        backgroundColor: base.colors.primaryUltralight,
        color: base.colors.blacks[0]
      }
    },
    disabled: {
      backgroundColor: base.colors.transparent,
      color: base.colors.blacks[8]
    }
  },
  separator: {
    border: "".concat(base.borders[1], " ").concat(base.colors.borderSeparator)
  }
};
var breadcrumb = {
  text: {
    fontSize: base.fontSizes.text,
    color: base.colors.blacks[6],
    current: {
      color: base.colors.blacks[3]
    }
  },
  link: {
    fontSize: base.fontSizes.text,
    color: base.colors.blacks[6],
    hover: {
      color: base.colors.link
    },
    active: {
      color: base.colors.primary
    }
  }
};
var slider = {
  rail: {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    height: '4px',
    borderRadius: '2px',
    hover: {
      backgroundColor: 'rgba(0, 0, 0, 0.3)'
    }
  },
  track: {
    color: '#f25835',
    borderRadius: '2px',
    height: '4px'
  },
  thumb: {
    height: '16px',
    width: '16px',
    marginTop: '-6px',
    border: 'solid 2px #f15835',
    backgroundColor: '#ffffff',
    borderRadius: '50%',
    hover: {
      boxShadow: '0 0 3px #f15835'
    }
  }
};
export var theme = _objectSpread({}, base, {
  card: card,
  carousel: carousel,
  checkbox: checkbox,
  radio: radio,
  button: button,
  buttons: buttons,
  cardInfo: cardInfo,
  drawer: drawer,
  select: select,
  slider: slider,
  dropdown: dropdown,
  tag: tag,
  link: link,
  lightbox: lightbox,
  input: input,
  modal: modal,
  notification: notification,
  pagination: pagination,
  statusLabel: statusLabel,
  breadcrumb: breadcrumb,
  form: form,
  dateInput: dateInput,
  dateRangeInput: dateRangeInput,
  calendar: calendar
});