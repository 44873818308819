import React, { useCallback } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { Button, Box, Breadcrumb, Form, Flex, Input, Notification, Select } from '@cowsquare/design';
import { Field, Form as FinalForm } from 'react-final-form';
import * as R from 'ramda';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { Banners } from '../components/Banners';
import { Section } from '../components/Section';
import { HTMLContent } from '../components/HTMLContent';
import { getUri } from '../../utils/getUri';
import { useBannersQuery, useStaticContentQuery } from '../../utils/hooks';
import * as rules from '../../utils/validate';

const TITLE = [
  { value: 'mr', name: '先生' },
  { value: 'ms', name: '女士' },
  { value: 'miss', name: '小姐' },
];

const INQUIRY_TYPE_OPTIONS = [
  { value: 'tour', name: '自由旅程' },
  { value: 'attraction', name: '優遊地點' },
  { value: 'homestay', name: '民宿優選' },
  { value: 'booked', name: '已訂的旅程' },
  { value: 'private', name: '私人定製行程' },
  { value: 'travel', name: '旅遊套餐' },
  { value: 'chartered', name: '包車旅遊' },
  { value: 'free', name: '網上自助設計行程' },
  { value: 'study', name: '遊學旅程' },
  { value: 'bussiness', name: '商務合作' },
  { value: 'other', name: '其他' },
];

const OBJECT_PLACEHOLDER = {
  tour: '請輸入您想查詢的旅程名稱與編號',
  attraction: '請輸入您想查詢的景點名稱',
  homestay: '請輸入您想查詢的民宿名稱與編號',
};

const INSERT_CONTACT_US_ENQUIRY = gql`
  mutation INSERT_CONTACT_US_ENQUIRY($input: vontravel_contact_us_enquiries_insert_input!) {
    insert_vontravel_contact_us_enquiries(objects: [$input]) {
      returning {
        id
        created_at
        title
        name
        phone
        email
        inquiry_type
        object
        content {
          content
        }
      }
    }
  }
`;

const ContactUs = () => {
  const history = useHistory();
  const location = useLocation();
  const uri = getUri(location);
  const { banners } = useBannersQuery(uri);
  const { content } = useStaticContentQuery(uri);
  const [insertContactUsEnquiry] = useMutation(INSERT_CONTACT_US_ENQUIRY);
  const { type: inquiryType, object: objectName, no } = queryString.parse(location.search);
  const onSubmit = useCallback(
    async values => {
      const content = R.pipe(R.prop('description'), value => ({ data: { content: value } }))(values);

      const input = R.pipe(
        R.dissoc('description'),
        R.assoc('content', content),
        R.evolve({
          title: R.prop('name'),
          inquiry_type: R.prop('name'),
        })
      )(values);
      await insertContactUsEnquiry({
        variables: { input },
      });

      Notification.success({
        message: '已送出表單',
        description: '感謝您填寫表單！專員會儘快與您聯絡！',
      });
    },
    [insertContactUsEnquiry]
  );
  return (
    <>
      <Banners banners={banners} itemProps={{ height: '424px' }} />
      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        <Section mt={6} mb={7} px={[5, 0]} flexDirection="column">
          <Breadcrumb>
            <Breadcrumb.Item linkProps={{ as: Link, to: '/' }}>首頁</Breadcrumb.Item>
            <Breadcrumb.Item>更多</Breadcrumb.Item>
            <Breadcrumb.Item>聯絡我們</Breadcrumb.Item>
          </Breadcrumb>
          <Box maxWidth="856px" mx="auto" mt={7}>
            <HTMLContent html={content} />
          </Box>
          <Flex flexDirection="column" alignItems="center" mt={5} mb={'80px'}>
            <Box width={[1, '504px']}>
              <FinalForm
                onSubmit={onSubmit}
                initialValues={{
                  inquiry_type: R.find(R.propEq('value', inquiryType))(INQUIRY_TYPE_OPTIONS),
                  object: no ? `${objectName}（${no}）` : objectName,
                }}
                render={({ handleSubmit, submitting, form, values }) => {
                  const inquiryType = R.pathOr(null, ['inquiry_type', 'value'], values);
                  return (
                    <>
                      <Form
                        onSubmit={event => {
                          handleSubmit(event).then(() => {
                            // 為了清空 location.search
                            history.push(`${location.pathname}`);
                            form.reset();
                          });
                        }}
                      >
                        <Field name="title" validate={rules.required('此欄位必填')}>
                          {({ input, meta }) => (
                            <Form.FormItem input={input} meta={meta} label="稱謂" required>
                              <Select {...input} placeholder="請選擇您的稱謂" options={TITLE} size="large" />
                            </Form.FormItem>
                          )}
                        </Field>
                        <Field name="name" validate={rules.required('此欄位必填')}>
                          {({ input, meta }) => (
                            <Form.FormItem input={input} meta={meta} label="姓名" required>
                              <Input {...input} placeholder="請填寫您的真實姓名" size="large" />
                            </Form.FormItem>
                          )}
                        </Field>
                        <Field name="email" validate={rules.isEmail('請輸入正確的電郵地址')}>
                          {({ input, meta }) => (
                            <Form.FormItem input={input} meta={meta} label="電郵" required>
                              <Input {...input} type="email" placeholder="請填寫您的電郵地址" size="large" />
                            </Form.FormItem>
                          )}
                        </Field>
                        <Field name="phone" validate={rules.required('此欄位必填')}>
                          {({ input, meta }) => (
                            <Form.FormItem input={input} meta={meta} label="電話" required>
                              <Input {...input} placeholder="請填寫您的電話" size="large" />
                            </Form.FormItem>
                          )}
                        </Field>
                        <Field name="inquiry_type" validate={rules.required('此欄位必填')}>
                          {({ input, meta }) => (
                            <Form.FormItem input={input} meta={meta} label="查詢項目" required>
                              <Select
                                {...input}
                                onChange={event => {
                                  form.change('object', undefined);
                                  input.onChange(event);
                                }}
                                placeholder="請選擇您想查詢的項目"
                                options={INQUIRY_TYPE_OPTIONS}
                                size="large"
                              />
                            </Form.FormItem>
                          )}
                        </Field>
                        {R.contains(inquiryType, ['tour', 'attraction', 'homestay']) ? (
                          <Field name="object" validate={rules.required('此欄位必填')}>
                            {({ input, meta }) => (
                              <Form.FormItem input={input} meta={meta} label="查詢物件" required>
                                <Input
                                  {...input}
                                  placeholder={OBJECT_PLACEHOLDER[inquiryType]}
                                  size="large"
                                />
                              </Form.FormItem>
                            )}
                          </Field>
                        ) : null}
                        <Field name="description" validate={rules.required('此欄位必填')}>
                          {({ input, meta }) => (
                            <Form.FormItem input={input} meta={meta} label="問題描述" required>
                              <Input
                                {...input}
                                as="textarea"
                                p={4}
                                height="96px"
                                placeholder="請輸入您的問題"
                              />
                            </Form.FormItem>
                          )}
                        </Field>
                        <Flex justifyContent="flex-end" alignItems="center">
                          <Button disabled={submitting} type="submit" variant="primary" size="large">
                            送出
                          </Button>
                        </Flex>
                      </Form>
                    </>
                  );
                }}
              />
            </Box>
          </Flex>
        </Section>
      </Flex>
    </>
  );
};

export default ContactUs;
