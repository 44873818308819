import styled from 'styled-components';
import { Flex } from '@cowsquare/design';

const CardList = styled(Flex).attrs({
  pb: 5,
  px: [5, '24px'],
  flexDirection: ['column', 'row'],
  flexWrap: ['nowrap', 'wrap'],
  justifyContent: 'center',
})`
  flex: 1;
`;

export default CardList;
