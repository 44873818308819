import React from 'react';
import styled from 'styled-components';
import { border } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import { Box } from '@cowsquare/design';
import { Section } from './Section';
import { InfoCard } from './InfoCard';
import SmileIcon from '../../images/icon_2smile.svg';
import DriverIcon from '../../images/icon_driver.svg';
import PlannerIcon from '../../images/icon_planner.svg';

const InfoCardBox = styled(Box).attrs({
  width: [1, 1 / 3],
  borderRightWidth: [0, 1],
  borderBottomWidth: [1, 0],
})`
  border: 0;
  border-style: solid;
  border-color: ${themeGet('colors.blacks.9')};
  ${border}

  &:last-child {
    border-right: 0;
    border-bottom: 0;
  }
`;

export const InfoCardSection = () => (
  <Section mx="auto">
    <InfoCardBox>
      <InfoCard img={SmileIcon} title="靈活自由" description="獨立成行，自選日期，靈活輕鬆"></InfoCard>
    </InfoCardBox>
    <InfoCardBox>
      <InfoCard img={DriverIcon} title="私人座駕及司機" description="點對點接送，安全舒適"></InfoCard>
    </InfoCardBox>
    <InfoCardBox>
      <InfoCard img={PlannerIcon} title="悉心設計安排" description="精心定製行程，切合個人需要"></InfoCard>
    </InfoCardBox>
  </Section>
);

export default InfoCardSection;
