import gql from 'graphql-tag';

export const GET_FORM_STATUS = gql`
  query GetFormStatus {
    formStatus @client
  }
`;

export const OPEN_LOGIN_FORM = gql`
  mutation OpenLoginForm {
    openLoginForm @client
  }
`;

export const OPEN_SIGNUP_FORM = gql`
  mutation OpenSignupForm {
    openSignupForm @client
  }
`;

export const OPEN_FORGET_PASSWORD_FORM = gql`
  mutation OpenForgetPasswordForm {
    openForgetPasswordForm @client
  }
`;

export const CLOSE_FORM = gql`
  mutation CloseForm {
    closeForm @client
  }
`;
