import { Box } from '@cowsquare/design';
import { themeGet } from '@styled-system/theme-get';
import React,{useEffect, useState} from 'react';
import { useCookies } from 'react-cookie';
import gql from 'graphql-tag';
import { useHistory } from 'react-router-dom';
import { useMutation , useQuery} from 'react-apollo';
import styled from 'styled-components';
import { Logo } from '../layouts/Logo';
import axios from "axios";


const Title = styled(Box).attrs({ as: 'h3', m: 0, mb: 5 })`
  font-size: ${themeGet('fontSizes.h2')};
  line-height: 1.5;
  font-weight: 500;
  color:#0080c8;
  &.text-center{
    text-align:center;
  }
`;

const MainBox = styled(Box).attrs({
  maxWidth: '904px',
  margin:'30px auto',
})`
  position:relative;
  margin-bottom: 100px;
  @media (max-width:640px){
    padding:10px;
    margin-bottom:20px;
  }
`;
const FlexBox = styled(Box).attrs({
  display: 'flex',
})`
&.m-col{
   @media (max-width:640px){
     flex-direction: column
   }
  }
`;

const ColBox = styled(Box).attrs({
  display: 'block',
})`
  text-align:center;
  h3{
    margin-top:10px;
  }
`;

const BorderBox = styled(Box).attrs({
  padding: '15px'
})`
  margin-bottom:20px;
`;

const TextBlue = styled(Box).attrs({ as: 'p', m: 0, mb: 5 })`
  color: #0080c8;
  &.notSpace{
    margin-bottom:0;
  }
  &.w-30{
    width:30%
  }
   &.w-70{
    width:70%;
    @media (max-width:640px){
       width:100%;
    }
  }
  &.text-black{
    color: #000
  }
  >span{
    text-decoration: underline;
  }
  &.btn-home{
    color: #000;
    background: #c8cdd9;
    padding: 10px 5px;
    margin-top: -5px;
    border: 1px solid #6597ff;
     @media (max-width:640px){
       width:40%;
       text-align:center;
     }
  }
`;
const INSERT_ORDER_ENQUIRY = gql`
mutation insert_vontravel_new_transactions($input: vontravel_new_transactions_insert_input!) {
insert_vontravel_new_transactions(
 objects: [$input]
) {
  returning {
    first_name,
		address
    }
  }
}
`;

const FETCH_ORDER_ENQUIRY = gql`
 query getOrderId($pid: String!) {
    content: vontravel_new_transactions(
      where: {payment_id: {_eq: $pid} }
    ) {
      id
  }
}
`;

const Thankyou = () => {
  const history = useHistory();
  // eslint-disable-next-line
  const [cookies, removeCookie] = useCookies(['tourFinal']);
  const [clientHotelCheckout, setClientHotelCheckout] =  useState({})
  const info = cookies.info || {};
  const [insertOrder] = useMutation(INSERT_ORDER_ENQUIRY);
  const pId = cookies.payId;
  const input = info;
  const { data, loading } = useQuery(FETCH_ORDER_ENQUIRY, {
    variables: { pid: pId },
  });

  const tour = cookies.tourFinal || {};

  const onContact = () => {
    history.push(`/更多/聯絡我們`)
  }

  const onHome = () => {
    history.push(`/`);
  }
  const onSave = async () => {
    let lengthId = 1
    input['payment_status'] = 'successful';
    delete input.checkout_on;
    delete input.number_package;
    if (data) lengthId = data.content.length
    if (lengthId === 0 && !loading) {
      await insertOrder({
          variables: { input },
      });
   }
  }

  // fetch client 
  const onFetchClient = async () => {
    // const response = await axios.post("http://localhost:4000/get-user", {session_id : pId})
    const response = await axios.post("https://pay.vontravels.com/get-user", {session_id : pId})
    console.log(response)
    if(response.data) setClientHotelCheckout(response.data)
  }
console.log(info.checkout_on)
  useEffect(() => {
    if(!pId || pId === 'undefined' || !info) history.replace(`/`);
    else if(info.checkout_on === "hotel") onFetchClient()
    else onSave();
    // eslint-disable-next-line
  }, [data])

  return (
    <MainBox>
      {info && info.checkout_on !== "hotel"? <>
      <ColBox>
        <Logo/>
        <Title className="text-center">多謝您選擇在緻優遊訂購商品。以下是您的訂購詳情:</Title>
      </ColBox>
      <BorderBox>
          <Title><strong>訂購詳情</strong></Title>
          <FlexBox><TextBlue className="w-30">預訂編號</TextBlue><TextBlue>{info.payment_id }</TextBlue></FlexBox>
          <FlexBox><TextBlue className="w-30">日期</TextBlue><TextBlue>{tour.planDate}</TextBlue></FlexBox>
          <FlexBox><TextBlue className="w-30">旅程/項目</TextBlue><TextBlue className="text-black">{tour.name}</TextBlue></FlexBox>
          
          <FlexBox><TextBlue className="w-30">人數</TextBlue>
          <TextBlue className="text-right">
          {tour.package && tour.package.map((item) => ( 
            item.value !== 0 ?
              <strong key={ item.key}><span>{item.key}: {item.value}位{'  '}</span></strong>: null
          ))
          }
          </TextBlue>
          </FlexBox>
            <FlexBox><TextBlue className="w-30">旅遊/項目費用小計:</TextBlue>
          <TextBlue className="text-right">
          {tour.package && tour.package.map((item) => ( 
            item.value !== 0 ?
              <strong key={ item.key}><span>{item.key}: {item.value * tour.itemPrice}{'  '}</span></strong>: null
          ))
          }
          </TextBlue>
          </FlexBox>
          
          <FlexBox><TextBlue  className="w-30">總額 (港幣):</TextBlue><TextBlue className="text-black">{Number((tour.price || 0).toFixed(1)).toLocaleString()}</TextBlue></FlexBox>
          <FlexBox><TextBlue  className="w-30">付款方式：</TextBlue><TextBlue className="text-black">VISA (viaStripe)</TextBlue></FlexBox>
      </BorderBox>

        <BorderBox>
          <Title><strong>付款人資料</strong></Title>
          <FlexBox><TextBlue className="w-30">稱謂：</TextBlue><TextBlue>{info.salutation}</TextBlue></FlexBox>
          <FlexBox><TextBlue className="w-30">名字: {info.first_name}</TextBlue><TextBlue>姓氏: {info.last_name}</TextBlue></FlexBox>
          <FlexBox><TextBlue className="w-30">手提電話:</TextBlue><TextBlue>{info.phone}</TextBlue></FlexBox>
          <FlexBox><TextBlue className="w-30">電郵地址:</TextBlue><TextBlue>{info.email}</TextBlue></FlexBox>
          
          <FlexBox><TextBlue className="w-30">地址:</TextBlue><TextBlue>{info.address}{' , '}{info.other_address}</TextBlue></FlexBox>
          <FlexBox className="m-col"><TextBlue className="w-70">如有查詢，歡迎致電我們（電話：3841 7400）或<span onClick={onContact}> 按此 </span>進入客戶服務頁面與我們聯絡</TextBlue><TextBlue className="btn-home" onClick={onHome}>返回自由旅程</TextBlue></FlexBox>
      </BorderBox>
    </>
       : <>
      <ColBox>
        <Logo/>
        <Title className="text-center">多謝您選擇在緻優遊訂購商品。以下是您的訂購詳情:</Title>
      </ColBox>
      <BorderBox>
          <Title><strong>訂購詳情</strong></Title>
          <FlexBox><TextBlue className="w-30">預訂編號:</TextBlue><TextBlue>{info.payment_id}</TextBlue></FlexBox>
          <FlexBox><TextBlue className="w-30">酒店:</TextBlue><TextBlue className="text-black">{info.homestay_name}</TextBlue></FlexBox>
          <FlexBox><TextBlue  className="w-30">地點:</TextBlue><TextBlue className="text-black">{info.address}</TextBlue></FlexBox>
          <FlexBox><TextBlue  className="w-30">總額 (港幣):</TextBlue><TextBlue className="text-black">{500}</TextBlue></FlexBox>
          <FlexBox><TextBlue  className="w-30">付款方式：</TextBlue><TextBlue className="text-black">VISA (viaStripe)</TextBlue></FlexBox>
      </BorderBox>

        <BorderBox>
          <Title><strong>付款人資料</strong></Title>
          <FlexBox><TextBlue className="w-30">名字: {info.user_name}</TextBlue></FlexBox>
          <FlexBox><TextBlue className="w-30">手提電話:</TextBlue><TextBlue>{info.user_phone}</TextBlue></FlexBox>
          <FlexBox><TextBlue className="w-30">電郵地址:</TextBlue><TextBlue>{info.user_email}</TextBlue></FlexBox>
          
          <FlexBox><TextBlue className="w-30">地址:</TextBlue><TextBlue>{clientHotelCheckout.address ? clientHotelCheckout.address.country : ''}</TextBlue></FlexBox>
          <FlexBox className="m-col"><TextBlue className="w-70">如有查詢，歡迎致電我們（電話：3841 7400）或<span onClick={onContact}> 按此 </span>進入客戶服務頁面與我們聯絡</TextBlue><TextBlue className="btn-home" onClick={onHome}>返回自由旅程</TextBlue></FlexBox>
      </BorderBox>
       </> }
    </MainBox>
  );
};

export default Thankyou;
