import React from 'react';
import * as R from 'ramda';
import * as D from 'date-fns/fp';
import { Box, Select, Button, Flex, Form, DateInput, Input } from '@cowsquare/design';
import { Form as FinalForm, Field } from 'react-final-form';
import { COUNTRIES } from '../../constants/country';

const required = message => value => (R.isNil(value) ? message : undefined);

const FormItem = Form.FormItem;

const CountryField = () => {
  return (
    <Box width={['100%', '328px']}>
      <Field name="country" validate={required('請選擇您要去的國家')}>
        {({ input, meta }) => (
          <FormItem label="您想去哪裡？" input={input} meta={meta}>
            <Select
              {...input}
              value={COUNTRIES.find(({ name }) => name === input.value)}
              onChange={({ name } = {}) => {
                input.onChange(name);
              }}
              placeholder="選擇國家"
              options={COUNTRIES}
              size="large"
            />
          </FormItem>
        )}
      </Field>
    </Box>
  );
};

const NumberOfPeopleField = ({ name, label }) => (
  <Box width={['100%', '152px']}>
    <Field name={name}>
      {({ input, meta }) => (
        <FormItem label={label} input={input} meta={{ ...meta, touched: true }}>
          <Input {...input} size="large" placeholder="請輸入人數" type="number" min={0} step={1} />
        </FormItem>
      )}
    </Field>
  </Box>
);

const CreateTourForm = ({
  onSubmit,
  initialValues,
  submitButtonLabel = '開始建立',
  minPlanDate,
  maxPlanDate,
  minDuration = 0,
}) => {
  return (
    <FinalForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={({ startDate, endDate }) => {
        if (minPlanDate && minPlanDate < D.startOfDay(startDate)) {
          return {
            startDate: '已放置行程或地點的日期無法被移除，請清空該日計劃後再調整日期',
          };
        }
        if (maxPlanDate && maxPlanDate > D.endOfDay(endDate)) {
          return {
            endDate: '已放置行程或地點的日期無法被移除，請清空該日計劃後再調整日期',
          };
        }
        if (D.differenceInDays(D.endOfDay(startDate), D.endOfDay(endDate)) + 1 < minDuration) {
          return {
            startDate: '您的旅遊天數必需長於自由旅程天數',
          };
        }
        if (D.endOfDay(startDate) > D.endOfDay(endDate)) {
          return {
            startDate: '開始日期不可以在結束日期之後',
          };
        }
        return undefined;
      }}
    >
      {({ handleSubmit, form }) => (
        <Form onSubmit={handleSubmit}>
          <Flex flexDirection={['column', 'row']} justifyContent="space-between">
            <CountryField />
            <Box width={['100%', '236px']}>
              <Field name="startDate" validate={required('請選擇旅行日期')}>
                {({ input, meta }) => (
                  <FormItem label="旅行開始日期" input={input} meta={meta}>
                    <DateInput
                      {...input}
                      size="large"
                      minDate={new Date()}
                      onChange={e => {
                        input.onChange(e);
                        // set endDate if the plan is created from a tour card (minDuration > 0)
                        // ref: https://github.com/cowsquare/cowsquare-mono/pull/233#issuecomment-554827597
                        if (minDuration > 0) {
                          form.change('endDate', D.addDays(minDuration, e.target.value));
                        }
                      }}
                    />
                  </FormItem>
                )}
              </Field>
            </Box>
            <Box width={['100%', '236px']}>
              <Field name="endDate" validate={required('請選擇旅行日期')}>
                {({ input, meta }) => (
                  <FormItem label="旅行結束日期" input={input} meta={meta}>
                    <DateInput {...input} size="large" minDate={form.getState().values.startDate} />
                  </FormItem>
                )}
              </Field>
            </Box>
          </Flex>
          <Flex flexDirection={['column', 'row']} justifyContent="space-between">
            <NumberOfPeopleField name="infants" label="嬰兒 / 幼童（0-5歲）" />
            <NumberOfPeopleField name="children" label="小童（6-12歲）" />
            <NumberOfPeopleField name="teenagers" label="青少年（13-17歲）" />
            <NumberOfPeopleField name="adults" label="成人（18-59歲）" />
            <NumberOfPeopleField name="elders" label="長者（60歲以上）" />
          </Flex>
          <Flex justifyContent="flex-end">
            <Button w="84px" size="large" variant="primary">
              {submitButtonLabel}
            </Button>
          </Flex>
        </Form>
      )}
    </FinalForm>
  );
};

export default CreateTourForm;
