export const LOGOUT = 'LOGOUT';
export const MY_ACCOUNT = 'MY_ACCOUNT';
export const WISH_LIST = 'WISH_LIST';

export const headerMenu = {
  default: [
    {
      name: '自由旅程',
      children: [
        {
          name: '中國',
          link: '/自由旅程/中國',
        },
        {
          name: '台灣',
          link: '/自由旅程/台灣',
        },
        {
          name: '日本',
          link: '/自由旅程/日本',
        },
      ],
    },
    {
      name: '優遊地點',
      children: [
        {
          name: '中國',
          link: '/優遊地點/中國',
        },
        {
          name: '台灣',
          link: '/優遊地點/台灣',
        },
        {
          name: '日本',
          link: '/優遊地點/日本',
        },
      ],
    },
    {
      name: '主題優遊',
      link: '/主題優遊',
    },
    {
      name: '自助設計優遊',
      link: '/自助設計優遊',
    },
    {
      name: '民宿優選',
      link: '/民宿優選',
    },
    {
      name: '包車旅遊',
      link: '/包車旅遊',
    },
    {
      name: '更多',
      children: [
        {
          name: '關於我們',
          link: '/更多/關於我們',
        },
        {
          name: '聯絡我們',
          link: '/更多/聯絡我們',
        },
        {
          name: '遊學團',
          link: '/更多/遊學團',
        },
      ],
    },
  ],
};

export const MENU_STATUS = {
  OFF: 'off',
  ON: 'on',
  SIGNUP: 'signup',
  LOGIN: 'login',
};
