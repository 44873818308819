import React, { useEffect } from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import {
  Box,
  Form,
  Flex,
  Input,
  Select,
  DateInput,
  Notification,
  Button,
  DrawerBody,
  DrawerFooter,
  Icon,
  Checkbox,
  Text,
  Link,
} from '@cowsquare/design';
import { Field, Form as FinalForm } from 'react-final-form';
import { COUNTRIES } from '../../../constants/country';
import gql from 'graphql-tag';
import { useQuery, useMutation } from 'react-apollo';
import { CARD_TYPE } from './constants/CARD_TYPE';
import { differenceInDays } from 'date-fns/fp';
import { usePlanContext } from '../PlanContext';
import { format } from 'date-fns/esm/fp';
import { useUser, useMemberForm } from '../../../utils/hooks';
import { useHistory, Link as RouteLink } from 'react-router-dom';
import { useMachine } from '@xstate/react';
import {
  recommendHomestayMachine,
  RECOMMEND_HOMESTAY_STATE,
  RECOMMEND_HOMESTAY_EVENT,
} from 'machines/recommendHomestayMachine';

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  /* 96px = Valuation Drawer Header's height */
  /* height: calc(100vh - 96px); */
  height: 100% ;
  overflow-y: auto;

`;

const Warning = styled(Box)`
  font-size: 14px;
  line-height: 1.5;
  color: #ff4c4c;
`;

const RowLabel = styled(Box)`
  display: inline-block;
  font-weight: 500;
  font-size: ${themeGet('fontSizes.h5')};
  line-height: 1.5;
  color: ${themeGet('colors.blacks.0')};
`;

const Loading = styled(Box)`
  object-fit: contain;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 0.05) 75%,
    rgba(0, 0, 0, 0.1) 100%
  );
`;

const Description = styled(Box)`
  font-size: 13px;
  line-height: 1.62;
  color: ${themeGet('colors.blacks.3')};
`;

const Price = styled(Box)`
  font-size: 14px;
  font-weight: 500;
  color: ${themeGet('colors.blacks.3')};
  line-height: 1.5;
`;

const HighlightPrice = styled(Price)`
  font-size: 18px;
  color: ${themeGet('colors.primary')};
`;

const CHAUFFEUR_PREFERENCE_OPTIONS = [
  { name: '需要' },
  { name: '部分行程需要' },
  { name: '不需要' },
  { name: '可以考慮' },
];

const required = message => value => (R.isNil(value) ? message : undefined);
const FormItem = Form.FormItem;

const getOptions = gql`
  query GetOptions($country: String!) {
    carTypes: vontravel_car_types(where: { country: { name: { _eq: $country } } }) {
      id
      name
      price
    }

    roomTypes: vontravel_room_types {
      id
      name
      price
    }

    languages: vontravel_guide_languages(where: { country: { name: { _eq: $country } } }) {
      id
      name
      price
    }
  }
`;

/**
 * Tour Packages （各別單價） x 旅行人數 + Attractions（固定單價）x 旅行人數 + 車型價錢 x 不包車的天數 + 導遊語言價錢 x 導遊天數
 * 目前後台資料中， attractions 的價格是存文字格式，每個景點是寫死的統一人頭價（不能在後台改）
 * ex. 所有景點都是估計每人 100 HKD，不管那個景點是需要門票的 101 觀覽台還是不需要門票的士林夜市，都是會用每個景點每人100 HKD 做即時估價
 * Attractions（固定單價）：100 HKD
 *
 * Updated: attractions 追加 evaluted_price 欄位 已經可以修改
 */

const getToursPrice = R.pipe(R.map(R.propOr(0, 'price')), R.sum);
const getAttractionsPrice = headCountByAge =>
  R.pipe(
    R.map(
      R.pick([
        'baby_evaluted_price',
        'children_evaluted_price',
        'teen_evaluted_price',
        'adult_evaluted_price',
        'elder_evaluted_price',
      ])
    ),
    R.reduce(R.mergeWith(R.add), {
      baby_evaluted_price: 0,
      children_evaluted_price: 0,
      teen_evaluted_price: 0,
      adult_evaluted_price: 0,
      elder_evaluted_price: 0,
    }),
    x => ({
      infants: x.baby_evaluted_price,
      children: x.children_evaluted_price,
      teenagers: x.teen_evaluted_price,
      adults: x.adult_evaluted_price,
      elders: x.elder_evaluted_price,
    }),
    R.mergeWith(R.multiply, headCountByAge),
    R.values,
    R.sum
  );
const getGuideDays = R.pipe(R.filter(R.identity), R.length);
const getTourGuideDays = R.pipe(R.filter(R.propOr(false, 'hasGuide')), R.map(R.propOr(0, 'days')), R.sum);

const caculateValuation = (plans = [], guides = []) => ({
  startDate,
  endDate,
  infants,
  children,
  teenagers,
  adults,
  elders,
  guideLanguage,
  foodBudget,
}) => {
  const totalPeople = R.sum([infants, children, teenagers, adults, elders]);
  const totalDays = differenceInDays(startDate, endDate) + 1;
  const [attractions, tours] = R.partition(R.propEq('cardType', CARD_TYPE.ATTRACTION), plans);
  const food = parseInt(foodBudget, 10) || 0;
  const total =
    getAttractionsPrice({ infants, children, teenagers, adults, elders })(attractions) +
    totalPeople * (getToursPrice(tours) + food * totalDays) +
    R.propOr(0, 'price', guideLanguage) * (getGuideDays(guides) - getTourGuideDays(tours));

  return [total, Math.round(total / totalPeople)];
};

const CountryField = () => {
  return (
    <Box width={1}>
      <Field name="country" validate={required('請選擇您要去的國家')}>
        {({ input, meta }) => (
          <FormItem label="我想去的地方" input={input} meta={meta}>
            <Select
              disabled
              {...input}
              value={COUNTRIES.find(({ name }) => name === input.value)}
              onChange={({ name } = {}) => {
                input.onChange(name);
              }}
              placeholder="選擇國家"
              options={COUNTRIES}
              size="large"
            />
          </FormItem>
        )}
      </Field>
    </Box>
  );
};

const NumberOfPeopleField = ({ name, label }) => (
  <Box width={['100%', '88px']}>
    <Field name={name}>
      {({ input, meta }) => (
        <FormItem label={label} input={input} meta={{ ...meta, touched: true }}>
          <Input disabled {...input} size="large" placeholder="" type="number" min={0} step={1} />
        </FormItem>
      )}
    </Field>
  </Box>
);

const DateField = ({ name, label }) => (
  <Box width={['100%', '228px']}>
    <Field name={name} validate={required('請選擇旅行日期')}>
      {({ input, meta }) => (
        <FormItem label={label} input={input} meta={meta}>
          <DateInput disabled {...input} size="large" minDate={new Date()} />
        </FormItem>
      )}
    </Field>
  </Box>
);

const HomestayField = ({ country, data }) => {
  return (
    <Box mb={5}>
      <Field name="homestayPreference" type="checkbox" value={data.code}>
        {({ input }) => (
          <Flex>
            <Checkbox {...input}>
              <Text fontWeight="500">{data.name}</Text>
            </Checkbox>
            <Icon as={Link} name="LINK" color="primary" href={`/民宿優選/${country}/${data.name}`} />
          </Flex>
        )}
      </Field>
    </Box>
  );
};

const INSERT_RECORD = gql`
  mutation insertRecord($input: vontravel_free_planner_records_insert_input!) {
    insert_vontravel_free_planner_records(objects: [$input]) {
      affected_rows
    }
  }
`;

const getHomestayPreference = R.pipe(R.defaultTo([]), R.reject(R.equals('others')));

const ValuationDrawerContent = ({ initialValues }) => {
  const history = useHistory();
  const country = R.propOr('', 'country', initialValues);
  const { data = {} } = useQuery(getOptions, {
    variables: {
      country,
    },
  });
  const { roomTypes = [], carTypes = [], languages = [] } = data;
  const { getAllPlans, getAllGuides, setIsEditing } = usePlanContext();
  const guides = getAllGuides();
  const dedupedPlans = R.uniqBy(R.prop('cardId'), getAllPlans());
  const [inserRecord] = useMutation(INSERT_RECORD);
  const { user } = useUser();
  const { openLoginForm } = useMemberForm();
  const [rhState, rhSend] = useMachine(recommendHomestayMachine);
  const { recommendHomestays } = rhState.context;

  useEffect(() => {
    const plans = R.uniqBy(R.prop('cardId'), getAllPlans());
    const areas = R.pipe(R.map(R.path(['city', 'area', 'id'])), R.reject(R.isNil), R.uniq)(plans);
    const cities = R.pipe(R.map(R.path(['city', 'id'])), R.reject(R.isNil), R.uniq)(plans);
    rhSend({ type: RECOMMEND_HOMESTAY_EVENT.LOAD_HOMESTAYS, country, areas, cities });
  }, [country, getAllPlans, rhSend]);

  return (
    <FinalForm
      initialValues={initialValues}
      onSubmit={({
        country,
        startDate,
        endDate,
        infants,
        children,
        teenagers,
        adults,
        elders,
        carType,
        roomType,
        guideLanguage,
        foodBudget,
        otherRequirement,
        chauffeurPreference,
        homestayPreference,
        customHomestayPreference,
      }) => {
        if (user) {
          setIsEditing(false);
          inserRecord({
            variables: {
              input: {
                user_id: user.id,
                country: {
                  on_conflict: {
                    constraint: 'countries_name_key',
                    update_columns: ['name'],
                  },
                  data: {
                    name: country,
                  },
                },
                area: {
                  on_conflict: {
                    constraint: 'areas_name_key',
                    update_columns: ['name'],
                  },
                  data: {
                    name: country,
                  },
                },
                baby_count: infants,
                children_count: children,
                teen_count: teenagers,
                adult_count: adults,
                elder_count: elders,
                car_type_id: R.propOr(null, 'id', carType),
                room_type_id: R.propOr(null, 'id', roomType),
                guide_language_id: R.propOr(null, 'id', guideLanguage),
                other_requirement: otherRequirement,
                food_budget: parseInt(foodBudget, 0),
                start_date: format('yyyy-MM-dd')(startDate),
                end_date: format('yyyy-MM-dd')(endDate),
                plan: { plans: dedupedPlans, guides },
                chauffeur_preference: R.propOr(null, 'name', chauffeurPreference),
                homestay_preference: R.concat(
                  R.join(', ', getHomestayPreference(homestayPreference)),
                  `, ${customHomestayPreference || ''}`
                ),
              },
            },
          }).then(res => {
            if (!res.errors) {
              Notification.success({ message: '成功送出估價' });
              history.push('/');
            }
            setIsEditing(true);
          });
        } else {
          Notification.warning({ message: '請先登入或註冊！' });
          openLoginForm();
        }
      }}
    >
      {({ handleSubmit, values }) => {
        const [total, average] = caculateValuation(dedupedPlans, guides)(values);
        const anyGuide = getGuideDays(guides) > 0;

        return (
          <StyledForm onSubmit={handleSubmit}>
            <DrawerBody>
              <Box p={5}>
                <RowLabel mb={5}>基本旅遊資訊</RowLabel>
                <Flex>
                  <CountryField />
                </Flex>
                <Flex flexDirection={['column', 'row']} justifyContent="space-between">
                  <DateField name="startDate" label="旅行開始日期" />
                  <DateField name="endDate" label="旅行結束日期" />
                </Flex>
                <Flex flexDirection={['column', 'row']} justifyContent="space-between">
                  <NumberOfPeopleField name="infants" label="幼童 0-5歲" />
                  <NumberOfPeopleField name="children" label="小童 6-12歲" />
                  <NumberOfPeopleField name="teenagers" label="青少年 13-17歲" />
                  <NumberOfPeopleField name="adults" label="成人 18-59歲" />
                  <NumberOfPeopleField name="elders" label="長者 60歲以上" />
                </Flex>
                <Flex flexDirection={['column', 'row']} mt={6} mb={[5, 0]}>
                  <RowLabel mr={[0, 3]} mb={5}>
                    行程需求
                  </RowLabel>
                  <Warning>(務請填寫，讓本公司為您提供更適切的建議及服務)</Warning>
                </Flex>
                <Field name="guideLanguage" validate={anyGuide && required('請選擇語言')}>
                  {({ input, meta }) => (
                    <FormItem label="導遊語言偏好" input={input} meta={meta}>
                      <Select {...input} placeholder="選擇語言" options={languages} size="large" />
                    </FormItem>
                  )}
                </Field>
                <Field name="chauffeurPreference">
                  {({ input, meta }) => {
                    return (
                      <FormItem label="行程套餐以外的旅程中，您需要包車服務嗎？" input={input} meta={meta}>
                        <Select
                          {...input}
                          placeholder="選擇包車服務需求"
                          options={CHAUFFEUR_PREFERENCE_OPTIONS}
                          size="large"
                        />
                      </FormItem>
                    );
                  }}
                </Field>
                <Field name="carType">
                  {({ input, meta }) => (
                    <FormItem label="包車車型" input={input} meta={meta}>
                      <Select {...input} placeholder="選擇車型" options={carTypes} size="large" />
                    </FormItem>
                  )}
                </Field>

                <Field name="roomType">
                  {({ input, meta }) => (
                    <FormItem label="住宿喜好" input={input} meta={meta}>
                      <Select {...input} placeholder="選擇住宿喜好" options={roomTypes} size="large" />
                    </FormItem>
                  )}
                </Field>
                {R.includes('民宿', R.pathOr('', ['roomType', 'name'], values)) &&
                  recommendHomestays.length > 0 && (
                    <>
                      {rhState.matches(RECOMMEND_HOMESTAY_STATE.LOADING_HOMESTAYS) ? (
                        <>
                          <Loading height="14px" width="400px" mb={5} />
                          <Loading height="14px" width="400px" mb={5} />
                          <Loading height="14px" width="350px" mb="177px" />
                        </>
                      ) : (
                        <Box>
                          <Description mb={5}>
                            以下是緻優遊推薦，請選擇民宿（可多於一間），或瀏覽「
                            <Link as={RouteLink} to="/民宿優選">
                              民宿優選
                            </Link>
                            」搜尋理想民宿，然後在「其他民宿」欄位輸入民宿編號（可輸入多於一間民宿）:
                          </Description>
                          {R.map(
                            homestay => (
                              <HomestayField key={homestay.id} data={homestay} country={values.country} />
                            ),
                            recommendHomestays
                          )}
                          <Box mb="5px">
                            <Field name="homestayPreference" type="checkbox" value="others">
                              {({ input }) => (
                                <Flex>
                                  <Checkbox {...input}>
                                    <Text fontWeight="500">其他民宿</Text>
                                  </Checkbox>
                                </Flex>
                              )}
                            </Field>
                          </Box>
                          <Box ml="24px" mb={5}>
                            <Field name="customHomestayPreference">
                              {({ input }) => (
                                <Input
                                  {...input}
                                  type="text"
                                  placeholder="請註明或輸入民宿編號（ 可輸入多於一間民宿 ）"
                                  size="large"
                                  disabled={!R.includes('others', values.homestayPreference || [])}
                                />
                              )}
                            </Field>
                          </Box>
                        </Box>
                      )}
                    </>
                  )}
                <Field name="foodBudget">
                  {({ input, meta }) => (
                    <FormItem label="飲食預算 (HKD / 每人 / 每日)" input={input} meta={meta}>
                      <Input
                        {...input}
                        type="number"
                        min="0"
                        placeholder="填寫預算 (例如：300)"
                        size="large"
                      />
                    </FormItem>
                  )}
                </Field>
                <Flex flexDirection={['column', 'row']} mt={6} mb={[5, 0]}>
                  <RowLabel mr={[0, 3]} mb={5}>
                    其他需求
                  </RowLabel>
                  <Warning>(例如：特別膳食、住宿、司機語言、有輪椅使用者等)</Warning>
                </Flex>
                <Field name="otherRequirement">
                  {({ input, meta }) => (
                    <FormItem label="其他需求" input={input} meta={meta}>
                      <Input {...input} placeholder="輸入文字 (中文或英文皆可)" size="large" />
                    </FormItem>
                  )}
                </Field>
                <Flex flexDirection="column">
                  <RowLabel mb={5}>預估價格</RowLabel>
                  <HighlightPrice>約港幣HKD{total}</HighlightPrice>
                  <Price mb={5}>每人約港幣HKD{average}</Price>
                  <Description mb={5}>
                    預估價格包括：
                    <ul>
                      <li>已選的優遊旅程費用</li>
                      <li>
                        已選的景點而需付入場費用。由於不同景點有其入場收費準則，因此估算未能完全涵蓋而只能作參考
                      </li>
                      <li>已選的旅程套餐中同時包括當地包車連司機費用</li>
                      <li>
                        已選額外導遊（「需要導遊」）的相關費用（註：當地導遊隨程講解及介紹當地景點、文化及特色，會佔一個車位）
                      </li>
                    </ul>
                  </Description>
                  <Description mb={5}>
                    預估價格未包括：
                    <ul>
                      <li>所有機票及機場稅</li>
                      <li>住宿（套餐以外的住宿費用）</li>
                      <li>膳食（部分套餐內按團員的喜好而定，而未有飲食安排）</li>
                      <li>當地額外包車或包車連司機的費用</li>
                      <li>包車以外的交通費（例如：火車、船等）</li>
                    </ul>
                  </Description>
                  <Description mb={5}>
                    以上尚未包括的項目，待您送出估價後，我們的服務專員會和您聯絡作進一步了解需要或即時提出相關建議，然後一併再報價。
                  </Description>
                  <Description>
                    自由旅程（套餐）的預估價格是以4至6人包車私旅為計算基礎，如團員多於此數目，歡迎與我們聯絡作更確實的報價。
                  </Description>
                </Flex>
              </Box>
            </DrawerBody>
            <DrawerFooter>
              <Button variant="primary" size="large">
                送出估價
              </Button>
            </DrawerFooter>
          </StyledForm>
        );
      }}
    </FinalForm>
  );
};

export default ValuationDrawerContent;
