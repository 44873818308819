import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { Box, Flex, Radio } from '@cowsquare/design';
import { themeGet } from '@styled-system/theme-get';
import * as R from 'ramda';
import { Section } from '../../components/Section';
import InfoForm from './InfoForm';
import ResetPasswordForm from './ResetPasswordForm';

const TABS = {
  info: '基本資料',
  resetPassword: '重設密碼',
  // evaluatedRecord: '估價紀錄',
};

const CardFlex = styled(Flex).attrs(() => ({
  width: 1,
  maxWidth: '944px',
  px: 5,
  py: 6,
  flexDirection: 'column',
  alignItems: 'center',
}))`
  box-shadow: ${themeGet('card.boxShadow')};
  background-color: ${themeGet('card.backgroundColor')};
  border: ${themeGet('card.border')};
  border-radius: ${themeGet('card.borderRadius')};
  border-color: ${themeGet('card.borderColor')};
`;

const UserInfo = () => {
  const [tab, setTab] = useState(TABS.info);
  const form = useMemo(() => {
    switch (tab) {
      case TABS.info:
        return <InfoForm />;
      case TABS.resetPassword:
        return <ResetPasswordForm />;
      default:
        return null;
    }
  }, [tab]);

  return (
    <Section flexDirection="column" mx="auto" my={[5, 6]} px={[5, 0]}>
      <Box mb={5}>
        <Radio.Group onChange={setTab} value={tab}>
          {R.map(
            tab => (
              <Radio.Tag key={tab} value={tab}>
                {tab}
              </Radio.Tag>
            ),
            R.values(TABS)
          )}
        </Radio.Group>
      </Box>
      <CardFlex>
        <Box width={[1, '280px']}>{form}</Box>
      </CardFlex>
    </Section>
  );
};

export default UserInfo;
