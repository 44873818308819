import React from 'react';
import styled from 'styled-components';
import { Box, Button, Flex, Form, Icon, Input, Notification } from '@cowsquare/design';
import { themeGet } from '@styled-system/theme-get';
import { useQueryParams, StringParam } from 'use-query-params';
import { useHistory } from 'react-router-dom';
import { Field, Form as FinalForm } from 'react-final-form';
import { useMutation } from 'react-apollo';
import { RESET_PASSWORD } from '../../apollo/graphql/user';
import { AUTH_TOKEN } from '../../constants';
import * as rules from '../../utils/validate';

const wait = ms => new Promise(resolve => setTimeout(resolve, ms));
const logout = () => {
  localStorage.removeItem(AUTH_TOKEN);
  window.location.assign('/');
};

const Title = styled(Box)`
  font-size: 24px;
  line-height: 1.5;
  font-weight: 500;
  color: ${themeGet('colors.blacks.0')};
`;

const ErrorMessage = styled(Box).attrs({
  p: 4,
})`
  font-size: 13px;
  height: 28px;
  color: ${themeGet('colors.danger')};
`;

const ResetPassword = () => {
  const [query] = useQueryParams({
    token: StringParam,
  });
  const history = useHistory();
  const [resetPassword] = useMutation(RESET_PASSWORD);
  const { token } = query;

  return (
    <Flex my={[7, '128px']} justifyContent="center">
      <Box width={[1, '272px']} px={[5, 0]}>
        <FinalForm
          initialValues={{
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
          }}
          validate={({ newPassword, confirmPassword }) => {
            if (newPassword !== confirmPassword) {
              return {
                confirmPassword: '輸入的密碼不一致',
              };
            }
            return undefined;
          }}
          onSubmit={formValues =>
            resetPassword({
              variables: {
                token,
                newPassword: formValues.newPassword,
              },
            })
              .then(() => {
                Notification.success({ message: '已成功重設密碼成功', description: '請以新密碼登入' });
                // 直接 logout 會看不到 Notification
                return wait(1500).then(logout);
              })
              .catch(err => {
                // TODO: when any error occurs, go to error page
                console.log(err);
                history.push('/');
              })
          }
        >
          {({ handleSubmit, submitting }) => (
            <Form onSubmit={handleSubmit}>
              <Title mb="28px">重設密碼</Title>
              <Field name="newPassword" validate={rules.required('請輸入新密碼')}>
                {({ input, meta }) => (
                  <>
                    <Input
                      {...input}
                      size="large"
                      placeholder="請輸入新密碼"
                      error={meta.touched && meta.error}
                      prefix={<Icon name="PASSWORD" />}
                      type="password"
                    />
                    <ErrorMessage>{meta.touched && meta.error}</ErrorMessage>
                  </>
                )}
              </Field>
              <Field name="confirmPassword" validate={rules.required('請再次輸入新密碼')}>
                {({ input, meta }) => (
                  <>
                    <Input
                      {...input}
                      size="large"
                      placeholder="再次輸入新密碼"
                      error={meta.touched && meta.error}
                      prefix={<Icon name="PASSWORD" />}
                      type="password"
                    />
                    <ErrorMessage>{meta.touched && meta.error}</ErrorMessage>
                  </>
                )}
              </Field>
              <Flex justifyContent="flex-end">
                <Button
                  onClick={handleSubmit}
                  disabled={submitting}
                  type="submit"
                  variant="primary"
                  size="large"
                >
                  儲存
                </Button>
              </Flex>
            </Form>
          )}
        </FinalForm>
      </Box>
    </Flex>
  );
};
export default ResetPassword;
