import { Box, Button, Flex, Form, Icon, Input, Link } from '@cowsquare/design';
import { themeGet } from '@styled-system/theme-get';
import React from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import styled from 'styled-components';
import { color, space, typography } from 'styled-system';
import { Logo } from '../../layouts/Logo';
import * as rules from '../../../utils/validate';
import { imageSwitch } from '../ResponsiveImage';

const Wrapper = styled(Box).attrs({
  p: 4,
})`
  background-color: ${themeGet('colors.whites.0')};
  width: 720px;
  height: 480px;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 ${themeGet('colors.blacks.8')};
`;

const LogoWrapper = styled(Box).attrs({
  p: 4,
})`
  position: absolute;
`;

const FormWrapper = styled(Flex).attrs({
  p: [6, 7],
})`
  background-color: ${themeGet('colors.whites.0')};
`;

const InfoBoxWrapper = styled(Box)`
  border-radius: 4px;
  background-image: url(${imageSwitch('img_login.png')});
`;

const InfoBoxButton = styled(Box)`
  background-color: ${themeGet('colors.whites.0')};
  padding: 10px 16px;
  border-radius: 8px;
  border: 1px solid ${themeGet('colors.primary')};
  cursor: pointer;
`;

const Text = styled.h3.attrs({
  py: 4,
})`
  margin: 0;
  font-weight: 500;
  letter-spacing: normal;
  color: ${themeGet('colors.whites.0')};
  ${space}
  ${color}
  ${typography}
`;

const ErrorMessage = styled(Box).attrs({
  p: 4,
})`
  color: ${themeGet('colors.danger')};
`;

export const DesktopSignupForm = ({ onSubmit, errorMessage, onLoginClick }) => {
  return (
    <Wrapper>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <Flex height="100%">
        <FormWrapper flex="1" flexDirection="column" justifyContent="center">
          <Text mb={5} fontSize="24px" color="black">
            註冊
          </Text>
          <FinalForm
            onSubmit={onSubmit}
            render={({ handleSubmit, submitting }) => (
              <>
                <Form>
                  <Field name="name" validate={rules.required('請輸入您的真實姓名')}>
                    {({ input, meta }) => (
                      <Form.FormItem input={input} meta={meta}>
                        <Input
                          {...input}
                          placeholder="請輸入您的真實姓名"
                          prefix={<Icon name="user" />}
                          size="large"
                        />
                      </Form.FormItem>
                    )}
                  </Field>
                  <Field
                    name="email"
                    validate={rules.pipe(
                      rules.required('請輸入您的電子郵件帳號'),
                      rules.isEmail('請輸入正確的電子郵件帳號')
                    )}
                  >
                    {({ input, meta }) => (
                      <Form.FormItem input={input} meta={meta}>
                        <Input
                          {...input}
                          placeholder="請輸入您的電子郵件帳號"
                          prefix={<Icon name="email" />}
                          size="large"
                          type="email"
                        />
                      </Form.FormItem>
                    )}
                  </Field>
                  <Field name="password" validate={rules.required('請輸入您的密碼')}>
                    {({ input, meta }) => (
                      <Form.FormItem input={input} meta={meta}>
                        <Input
                          {...input}
                          placeholder="請輸入您的密碼"
                          prefix={<Icon name="password" />}
                          size="large"
                          type="password"
                        />
                      </Form.FormItem>
                    )}
                  </Field>
                </Form>
                <ErrorMessage>{errorMessage}</ErrorMessage>
                <Flex justifyContent="flex-end" alignItems="center">
                  <Button
                    onClick={handleSubmit}
                    disabled={submitting}
                    type="submit"
                    variant="primary"
                    size="large"
                  >
                    註冊新帳號
                  </Button>
                </Flex>
              </>
            )}
          />
        </FormWrapper>
        <InfoBoxWrapper>
          <Flex
            width="280px"
            height="100%"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Text fontSize="36px">Hello!</Text>
            <Text fontSize="16px" lineHeight="2.25">
              無法忘懷精彩旅程？
            </Text>
            <Text fontSize="16px" lineHeight="1.69">
              立即登入定製個人行程吧
            </Text>
            <Box mt={5}>
              <InfoBoxButton onClick={onLoginClick}>
                <Link>點我登入</Link>
              </InfoBoxButton>
            </Box>
          </Flex>
        </InfoBoxWrapper>
      </Flex>
    </Wrapper>
  );
};

export const MobileSignupForm = ({ onSubmit, errorMessage, onLoginClick }) => {
  return (
    <FormWrapper flexDirection="column" flex="1">
      <Text mb={5} fontSize="24px" color="black">
        註冊
      </Text>
      <FinalForm
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting }) => (
          <>
            <Form>
              <Field name="name" validate={rules.required('請輸入您的真實姓名')}>
                {({ input, meta }) => (
                  <Form.FormItem input={input} meta={meta}>
                    <Input
                      {...input}
                      placeholder="請輸入您的真實姓名"
                      prefix={<Icon name="user" />}
                      size="large"
                    />
                  </Form.FormItem>
                )}
              </Field>
              <Field name="email" validate={rules.isEmail('請輸入正確的 Email')}>
                {({ input, meta }) => (
                  <Form.FormItem input={input} meta={meta} type="email">
                    <Input
                      {...input}
                      placeholder="請輸入您的電子郵件帳號"
                      prefix={<Icon name="email" />}
                      size="large"
                      type="email"
                    />
                  </Form.FormItem>
                )}
              </Field>
              <Field name="password" validate={rules.required('請輸入您的密碼')}>
                {({ input, meta }) => (
                  <Form.FormItem input={input} meta={meta}>
                    <Input
                      {...input}
                      placeholder="請輸入您的密碼"
                      prefix={<Icon name="password" />}
                      size="large"
                      type="password"
                    />
                  </Form.FormItem>
                )}
              </Field>
            </Form>
            <ErrorMessage>{errorMessage}</ErrorMessage>
            <Flex justifyContent="flex-end" alignItems="center">
              <Button
                onClick={handleSubmit}
                disabled={submitting}
                type="submit"
                variant="primary"
                size="large"
              >
                註冊
              </Button>
            </Flex>
          </>
        )}
      />
      <Flex mt="auto" justifyContent="center">
        <InfoBoxButton onClick={onLoginClick}>
          <Link>已有帳號，點我登入</Link>
        </InfoBoxButton>
      </Flex>
    </FormWrapper>
  );
};
