import { useCallback, useState, useContext, useMemo } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import * as R from 'ramda';
import axios from 'axios';
import { useEffect } from 'react';
import { ThemeContext } from 'styled-components';
import { Notification } from '@cowsquare/design';
import { GET_BANNERS, GET_FEATURE_BANNER } from '../apollo/graphql/banners';
import { GET_STATIC_CONTENTS } from '../apollo/graphql/staticContents';
import { GET_USER_INFO, LOGIN, FORGET_PASSWORD } from '../apollo/graphql/user';
import * as wishListGQL from '../apollo/graphql/wishList';
import {
  OPEN_LOGIN_FORM,
  GET_FORM_STATUS,
  OPEN_SIGNUP_FORM,
  OPEN_FORGET_PASSWORD_FORM,
  CLOSE_FORM,
} from '../apollo/graphql/form';
import { AUTH_TOKEN, ORG_ID } from '../constants';
import * as wishListConstants from '../constants/wishList';
import { REGISTER_API_URL } from '../constants/endpoint';
import {
  EMAIL_ALREADY_IN_USED,
  SIGNUP_ERROR,
  LOGIN_ERROR,
  FORGET_PASSWORD_ERROR,
} from '../constants/errorMessage';
import { EMAIL_ALREADY_IN_USED as EMAIL_ALREADY_IN_USED_FROM_SERVER } from '../constants/errorResponse';

export const useBannersQuery = (uri = '') => {
  const { data, loading, error } = useQuery(GET_BANNERS, {
    variables: { name: `${uri}/` },
  });

  return {
    banners: R.pathOr([], ['banners', 0, 'banners'], data),
    loading,
    error,
  };
};

export const useFeatureBannerQuery = (name = '') => {
  const { data, loading, error } = useQuery(GET_FEATURE_BANNER, {
    variables: { name },
  });

  const banner = {
    name,
    file: {
      link: R.pathOr('', ['banner', 0, 'banner', 'link'], data),
    },
  };

  return {
    banner,
    loading,
    error,
  };
};

export const useUser = () => {
  const userInfoQuery = useQuery(GET_USER_INFO, {
    fetchPolicy: 'cache-and-network',
  });

  return {
    user: R.pathOr(null, ['data', 'user'], userInfoQuery),
    loading: R.pathOr(true, ['loading'], userInfoQuery),
  };
};

export const useLogin = () => {
  const [loginMutation, { loading, data, error }] = useMutation(LOGIN);

  useEffect(() => {
    if (!loading && data && !error) {
      const {
        login: { token },
      } = data;

      const url = new URL(window.location);
      localStorage.setItem(AUTH_TOKEN, token);
      window.location.assign(url);
    }
  }, [data, error, loading]);

  const login = useCallback(
    values => {
      loginMutation({ variables: { ...values, organizationId: ORG_ID } });
    },
    [loginMutation]
  );

  return [login, error && LOGIN_ERROR];
};

export const useForgetPassword = () => {
  const [forgetPasswordMutation, { error }] = useMutation(FORGET_PASSWORD);
  const { closeForm } = useMemberForm();
  const forgetPassword = useCallback(
    values => {
      forgetPasswordMutation({ variables: { ...values, organizationId: ORG_ID } }).then(() => {
        closeForm();
        Notification.success({ message: '已發送密碼重設信件', description: '點擊信件連結後即可重設密碼' });
      });
    },
    [closeForm, forgetPasswordMutation]
  );
  return [forgetPassword, error && FORGET_PASSWORD_ERROR];
};

export const useSignup = () => {
  const [signupError, setSignupError] = useState('');
  const signup = useCallback(data => {
    setSignupError('');
    axios({
      method: 'post',
      url: REGISTER_API_URL,
      data,
    })
      .then(response => {
        const token = R.pathOr('', ['data', 'data', 'admin_register', 'token'], response);
        if (token) {
          const url = new URL(window.location);
          localStorage.setItem(AUTH_TOKEN, token);
          window.location.assign(url);
        } else {
          if (response.status === 203) {
            setSignupError(EMAIL_ALREADY_IN_USED);
            return;
          }else {
            setSignupError("註冊失敗");
          }
        }

      })
      .catch(({ response }) => {
        const primitiveErrorMessage = R.pathOr(SIGNUP_ERROR, ['data', 'errors', 0, 'message'], response);
        if (primitiveErrorMessage === EMAIL_ALREADY_IN_USED_FROM_SERVER) {
          setSignupError(EMAIL_ALREADY_IN_USED);
          return;
        }
        setSignupError(primitiveErrorMessage);
      });
  }, []);
  return [signup, signupError];
};

export const useStaticContentQuery = (uri = '') => {
  const { data, loading, error } = useQuery(GET_STATIC_CONTENTS, {
    variables: { name: `${uri}/` },
  });

  return {
    content: R.pathOr('', ['contents', 0, 'content', 'content'], data),
    loading,
    error,
  };
};

export const useMemberForm = () => {
  const {
    data: { formStatus },
  } = useQuery(GET_FORM_STATUS);
  const [openLoginForm] = useMutation(OPEN_LOGIN_FORM);
  const [openSignupForm] = useMutation(OPEN_SIGNUP_FORM);
  const [openForgetPasswordForm] = useMutation(OPEN_FORGET_PASSWORD_FORM);
  const [closeForm] = useMutation(CLOSE_FORM);

  return {
    formStatus,
    openLoginForm,
    openSignupForm,
    openForgetPasswordForm,
    closeForm,
  };
};

export const useCollect = ({ id, type, collected }) => {
  const { user } = useUser();
  const { openLoginForm } = useMemberForm();
  const [insertItemToWishList, { loading: insertLoading }] = useMutation(wishListGQL.INSERT_MUTATION[type]);
  const [deleteItemFromWishList, { loading: deleteLoading }] = useMutation(wishListGQL.DELETE_MUTATION[type]);
  const loading = insertLoading || deleteLoading;
  const userId = R.propOr('', 'id', user);
  const variables = {
    itemId: id,
    userId,
  };

  const deleteFromCollection = useCallback(
    () =>
      deleteItemFromWishList({
        variables,
        update: (cache, { errors }) => {
          if (errors) return;
          const data = cache.readFragment({
            id: `${wishListConstants.GQL_TYPE_NAME[type]}:${id}`,
            fragment: wishListGQL.composeCollectedUsersFragment(userId)[type],
          });
          cache.writeFragment({
            id: `${wishListConstants.GQL_TYPE_NAME[type]}:${id}`,
            fragment: wishListGQL.composeCollectedUsersFragment(userId)[type],
            data: {
              users: R.pipe(R.propOr([], 'users'), R.reject(R.propEq('user_id', user.id)))(data),
            },
          });
        },
      }),
    [deleteItemFromWishList, id, type, user, userId, variables]
  );

  const insertToCollection = useCallback(
    () =>
      insertItemToWishList({
        variables,
        update: (cache, { errors }) => {
          if (errors) return;

          const data = cache.readFragment({
            id: `${wishListConstants.GQL_TYPE_NAME[type]}:${id}`,
            fragment: wishListGQL.composeCollectedUsersFragment(userId)[type],
          });

          cache.writeFragment({
            id: `${wishListConstants.GQL_TYPE_NAME[type]}:${id}`,
            fragment: wishListGQL.composeCollectedUsersFragment(userId)[type],
            data: {
              users: R.pipe(
                R.propOr([], 'users'),
                R.append({
                  user_id: user.id,
                  __typename: type === wishListConstants.USER_BRIDGE_TYPE_NAME[type],
                })
              )(data),
            },
          });
        },
      }),
    [id, insertItemToWishList, type, user, userId, variables]
  );

  const toggleCollect = useCallback(() => {
    if (!user) {
      return openLoginForm();
    }
    if (loading) return;
    return collected ? deleteFromCollection() : insertToCollection();
  }, [collected, deleteFromCollection, insertToCollection, loading, openLoginForm, user]);

  return {
    toggleCollect,
  };
};

const isClient = typeof window !== 'undefined';

export const useMedia = (query, defaultState = false) => {
  const [state, setState] = useState(isClient ? () => window.matchMedia(query).matches : defaultState);

  useEffect(() => {
    let mounted = true;
    const mql = window.matchMedia(query);
    const onChange = event => {
      if (!mounted) return;
      setState(event.matches);
    };

    mql.addListener(onChange);
    setState(mql.matches);

    return () => {
      mounted = false;
      mql.removeListener(onChange);
    };
  }, [query]);

  return state;
};

export const useMobileMedia = () => {
  const { breakpoints } = useContext(ThemeContext);
  const mobileBreakpoints = breakpoints[0];
  const query = `(max-width: ${mobileBreakpoints})`;
  const isMobileMedia = useMedia(query);

  return isMobileMedia;
};

export const useIosSafari = () =>
  useMemo(() => {
    const userAgent = window.navigator.userAgent;
    return !!(userAgent.match(/iPad/i) || userAgent.match(/iPhone/i));
  }, []);
