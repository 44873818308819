import { Machine, assign } from 'xstate';
import gql from 'graphql-tag';
import { client } from 'apollo/client';

const STATES = {
  LOADING: 'LOADING',
  UNKNOWN: 'UNKNOWN',
  LOADED: 'LOADED',
  ERROR: 'ERROR',
  EMPTY: 'EMPTY',
};

const EVENTS = {
  PAGE_CHANGE: 'PAGE_CHANGE',
  REFETCH: 'REFETCH',
};

const GET_ATTRACTION_WISH_LIST = gql`
  query getAttractionWishList(
    $userId: uuid!
    $country: String!
    $area: String
    $feature: String
    $limit: Int
    $offset: Int
  ) {
    list: vontravel_users_attractions_bridge(
      limit: $limit
      offset: $offset
      order_by: { created_at: desc }
      where: {
        user_id: { _eq: $userId }
        attraction: {
          area: { _and: { name: { _eq: $area }, country: { name: { _eq: $country } } } }
          features: { feature: { name: { _eq: $feature } } }
        }
      }
    ) {
      item: attraction {
        id
        name
        cover {
          link
        }
        area {
          name
        }
        description {
          content
        }
      }
    }
    meta: vontravel_users_attractions_bridge_aggregate(
      where: {
        user_id: { _eq: $userId }
        attraction: {
          area: { _and: { name: { _eq: $area }, country: { name: { _eq: $country } } } }
          features: { feature: { name: { _eq: $feature } } }
        }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

const favoriteAttractionMachine = Machine(
  {
    context: {
      data: [],
      page: 1,
      pageSize: 12,
      country: null,
      feature: null,
      userId: null,
      area: null,
    },
    initial: STATES.LOADING,
    states: {
      [STATES.LOADING]: {
        invoke: {
          src: ({ page, pageSize, country, feature, userId, area }) =>
            client.query({
              query: GET_ATTRACTION_WISH_LIST,
              variables: {
                limit: pageSize,
                offset: (page - 1) * pageSize,
                userId,
                area,
                feature,
                country,
              },
            }),
          onDone: {
            target: STATES.UNKNOWN,
            actions: 'assignData',
          },
          onError: STATES.ERROR,
        },
      },
      [STATES.UNKNOWN]: {
        on: {
          '': [
            {
              target: STATES.EMPTY,
              cond: 'isEmpty',
            },
            {
              target: STATES.LOADED,
            },
          ],
        },
      },
      [STATES.LOADED]: {
        on: {
          [EVENTS.PAGE_CHANGE]: {
            target: STATES.LOADING,
            actions: 'changePage',
          },
        },
      },
      [STATES.EMPTY]: {},
      [STATES.ERROR]: {},
    },
    on: {
      [EVENTS.REFETCH]: {
        target: STATES.LOADING,
        actions: 'refetch',
      },
    },
  },
  {
    actions: {
      refetch: () => {},
      changePage: assign({
        page: (_, event) => event.page,
      }),
      assignData: assign({
        data: (context, event) => event.data.data,
      }),
    },
    guards: {
      isEmpty: context => context.data.length === 0,
    },
  }
);

export {
  favoriteAttractionMachine,
  STATES as FAVORITE_ATTRACTION_STATES,
  EVENTS as FAVORITE_ATTRACTION_EVENTS,
};
