import { useQuery } from '@apollo/react-hooks';
import { Box, Button, Carousel, Flex, Tag } from '@cowsquare/design';
import { themeGet } from '@styled-system/theme-get';
import gql from 'graphql-tag';
import * as R from 'ramda';
import React, { useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { getHTMLString } from '../../utils/getHTMLString';
import { EasyCard } from '../components/EasyCard';
import { Section } from '../components/Section';
import TourCard from '../components/TourCard';
import AddToPlanButton from '../components/AddToPlanButton';
import { imageSwitch } from '../components/ResponsiveImage';
import FreePlannerIntroSection from '../components/FreePlannerIntroSection';
import { TourCardLoading } from 'views/components/Loading';

const topBanner = {
  title: '探索各式各樣的體驗',
  description: '其他煩惱的一切，交給 Von Travel',
  url: imageSwitch('thomas-tucker-au3CYbd7vCU-unsplash.jpg'),
};

const Title = styled(Box).attrs({ as: 'h1' })`
  text-align: center;
  font-size: ${themeGet('fontSizes.h1')};
  line-height: 1.5;
  font-weight: 500;
`;

const SubTitle = styled(Box).attrs({ as: 'h4', m: 0 })`
  text-align: center;
  font-size: ${themeGet('fontSizes.h4')};
  line-height: 1.5;
  font-weight: normal;
`;

const TourCardBox = styled(Box).attrs({
  width: [1, 1 / 3],
  pb: ['24px', '48px'],
  px: [0, 4],
})``;

const EasyCardBox = styled(Box).attrs({
  width: [1, 1 / 3],
  px: [5, 4],
  pb: [5, '48px'],
})``;

const SectionTitle = styled.div`
  font-size: 36px;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
`;

const HotSection = styled(Section).attrs({
  mt: 6,
  mb: [-5, '-48px'],
})``;

const StyledLink = styled(Link)`
  font-size: 16px;
  line-height: 1.5;
  color: ${themeGet('colors.link')};
  text-decoration: none;
`;

const TourCardList = ({ title, data = [], loading = false, renderMoreButton, limit = 1, history }) => (
  <Section flexDirection="column" margin="48px auto 80px" px={[5, 0]}>
    <Box mb={6}>
      <SectionTitle>{title}</SectionTitle>
    </Box>
    <Flex
      flexDirection={['column', 'row']}
      justifyContent="center"
      alignItems="center"
      flexWrap="wrap"
      mx={-4}
      mb={['-24px', '-48px']}
    >
      {loading
        ? R.times(
            i => (
              <TourCardBox key={i}>
                <TourCardLoading />
              </TourCardBox>
            ),
            3
          )
        : R.map(
            item => (
              <TourCardBox key={item.id}>
                <TourCard
                  coverUrl={item.cover.link}
                  height="460px"
                  title={item.name}
                  description={R.pipe(R.pathOr('', ['description', 'content']), getHTMLString)(item)}
                  tags={R.pipe(
                    R.chain(R.path(['feature', 'name'])),
                    R.filter(R.identity),
                    R.map(name => <Tag key={name}>{name}</Tag>)
                  )(item.features)}
                  as={Link}
                  to={`/自由旅程/${item.country.name}/所有旅程/${item.name}`}
                  links={
                    <AddToPlanButton
                      onClick={e => {
                        e.preventDefault();
                        history.push(
                          `/自助設計優遊/新計劃?cardType=tour&cardName=${item.name}&country=${R.pathOr(
                            '',
                            ['country', 'name'],
                            item
                          )}`
                        );
                      }}
                    />
                  }
                  country={R.pathOr('', ['country', 'name'], item)}
                  isCharter={item.isCharter}
                />
              </TourCardBox>
            ),
            data
          )}
    </Flex>
    {data.length > limit && renderMoreButton()}
  </Section>
);

const GET_HOT_FETURES = gql`
  query getTourListByFeature {
    list: vontravel_hot_features(order_by: { created_at: desc }) {
      id
      expanded
      feature {
        id
        name
        banner {
          id
          key
          name
          link
        }
        tours(limit: 6, order_by: { tour: { created_at: desc } }) {
          tour {
            id
            name
            price
            days
            cover {
              link
            }
            content {
              content
            }
            description {
              content
            }
            country {
              name
            }
            features {
              feature {
                id
                name
              }
            }
            isCharter
          }
        }
      }
    }
  }
`;

const ThemeTour = () => {
  const history = useHistory();
  const { data, loading } = useQuery(GET_HOT_FETURES);
  const list = R.pathOr([], ['list'], data);
  const expandedList = R.pipe(R.filter(R.propEq('expanded', true)))(list);

  const onClickMore = useCallback(feature => () => history.push(`/主題優遊/${feature}`), [history]);
  return (
    <>
      <Carousel>
        <Carousel.Slide
          title={topBanner.title}
          description={topBanner.description}
          backgroundImage={`url(${topBanner.url})`}
          height="424px"
        />
      </Carousel>
      <Flex Flex flexDirection="column" justifyContent="center" alignItems="center">
        <Title mt="80px" mb={0}>
          熱門主題優遊
        </Title>
        <SubTitle>
          遠離人潮，展開自己喜愛的主題旅程。現在您可透過「
          <StyledLink to="/自助設計優遊">自助設計優遊</StyledLink>
          」工具輕鬆地把各類型的主題旅遊合併為專屬行程，創造更美好的回憶。
        </SubTitle>
        <HotSection>
          {R.map(({ feature: { id, name, banner } }) => (
            <EasyCardBox key={id}>
              <EasyCard
                as={Link}
                to={`/主題優遊/${name}`}
                img={R.propOr('', 'link', banner)}
                title={name}
              ></EasyCard>
            </EasyCardBox>
          ))(list)}
        </HotSection>
        {expandedList.map(({ feature: { id, name, tours } }) => (
          <TourCardList
            key={id}
            history={history}
            title={name}
            data={R.pluck('tour', tours)}
            limit={3}
            renderMoreButton={() => (
              <Box mt={6} width={1}>
                <Flex justifyContent="center">
                  <Button size="large" variant="primary" onClick={onClickMore(name)}>
                    更多{name}旅程
                  </Button>
                </Flex>
              </Box>
            )}
            loading={loading}
          />
        ))}
        <FreePlannerIntroSection />
      </Flex>
    </>
  );
};

export default ThemeTour;
