import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import fetch from 'isomorphic-fetch';
import { HASURA_ENDPOINT } from '../constants/endpoint';
import { AUTH_TOKEN } from '../constants/index';
import { history } from '../history';

export const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(graphQLError => {
      console.warn(`[GraphQL error]:`, graphQLError);
    });
  }

  if (networkError) {
    console.warn(`[Network error]:`, networkError);
    history.push(`/error/${networkError.statusCode}`);
  }
});

export const httpLink = createHttpLink({
  uri: HASURA_ENDPOINT,
  credentials: 'include',
  fetch,
});

export const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(AUTH_TOKEN);
  const anonymous = { 'X-HASURA-ROLE': 'anonymous' };
  const authHeader = token ? { authorization: `Bearer ${token}` } : anonymous;

  return {
    headers: {
      ...headers,
      ...authHeader,
    },
  };
});
