import { Box, Breadcrumb, Flex } from '@cowsquare/design';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Banners } from '../components/Banners';
import { Section } from '../components/Section';
import { HTMLContent } from '../components/HTMLContent';
import { getUri } from '../../utils/getUri';
import { useBannersQuery, useStaticContentQuery } from '../../utils/hooks';

const AboutUs = () => {
  const location = useLocation();
  const uri = getUri(location);
  const { banners } = useBannersQuery(uri);
  const { content } = useStaticContentQuery(uri);

  return (
    <>
      <Banners banners={banners} itemProps={{ height: '424px' }} />
      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        <Section mt={6} mb={7} px={[5, 0]} flexDirection="column">
          <Breadcrumb>
            <Breadcrumb.Item linkProps={{ as: Link, to: '/' }}>首頁</Breadcrumb.Item>
            <Breadcrumb.Item>更多</Breadcrumb.Item>
            <Breadcrumb.Item>關於我們</Breadcrumb.Item>
          </Breadcrumb>
          <Box maxWidth="856px" mx="auto" mt={7}>
            <HTMLContent html={content} />
          </Box>
        </Section>
      </Flex>
    </>
  );
};

export default AboutUs;
