import React from 'react';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { Box } from '@cowsquare/design';

const Inner = styled(Box)`
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
  border-radius: 4px;
  background-color: ${themeGet('colors.blacks.9')};
`;

const Bg = styled(Box)`
  position: relative;
  border-radius: 4px;
  background-color: ${themeGet('colors.primary')};
`;

const Progress = ({ percent, mr = 5, width = 150, height = 4, ...rest }) => {
  return (
    <Box mr={mr} width={width} {...rest}>
      <Inner>
        <Bg width={percent} height={height}></Bg>
      </Inner>
    </Box>
  );
};

export default Progress;
