import { Machine, assign } from 'xstate';
import gql from 'graphql-tag';
import { client } from 'apollo/client';

const STATES = {
  LOADING: 'LOADING',
  UNKNOWN: 'UNKNOWN',
  LOADED: 'LOADED',
  ERROR: 'ERROR',
  EMPTY: 'EMPTY',
};

const EVENTS = {
  PAGE_CHANGE: 'PAGE_CHANGE',
  REFETCH: 'REFETCH',
};

const GET_ATTRACTIONS = gql`
  query GET_ATTRACTIONS(
    $where: vontravel_attractions_bool_exp
    $limit: Int!
    $offset: Int!
    $userId: uuid
    $isLogin: Boolean!
  ) {
    meta: vontravel_attractions_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    list: vontravel_attractions(
      limit: $limit
      order_by: { created_at: desc, name: asc }
      offset: $offset
      where: $where
    ) {
      id
      name
      cover {
        link
      }
      area {
        name
      }
      city {
        id
        name
        area {
          id
        }
      }
      description {
        content
      }
      features {
        feature {
          id
          name
        }
      }
      cover {
        link
      }
      baby_evaluted_price
      children_evaluted_price
      teen_evaluted_price
      adult_evaluted_price
      elder_evaluted_price
      users(where: { user_id: { _eq: $userId } }) @include(if: $isLogin) {
        user_id
      }
    }
  }
`;

const attractionMachine = Machine(
  {
    context: {
      data: [],
      page: 1,
      pageSize: 12,
      country: null,
      feature: null,
      area: null,
      audience: null,
      userId: null,
    },
    initial: STATES.LOADING,
    states: {
      [STATES.LOADING]: {
        invoke: {
          src: ({ page, pageSize, country, feature, area, audience, userId }) =>
            client.query({
              query: GET_ATTRACTIONS,
              variables: {
                limit: pageSize,
                offset: pageSize * (page - 1),
                userId,
                isLogin: Boolean(userId),
                where: {
                  area: area
                    ? { _and: { name: { _eq: area } } }
                    : country && { _and: { country: { name: { _eq: country } } } },
                  audience: audience && { audience: { name: { _eq: audience } } },
                  features: feature && { feature: { name: { _eq: feature } } },
                },
              },
            }),
          onDone: {
            target: STATES.UNKNOWN,
            actions: 'assignData',
          },
          onError: STATES.ERROR,
        },
      },
      [STATES.UNKNOWN]: {
        on: {
          '': [
            {
              target: STATES.EMPTY,
              cond: 'isEmpty',
            },
            {
              target: STATES.LOADED,
            },
          ],
        },
      },
      [STATES.LOADED]: {
        on: {
          [EVENTS.PAGE_CHANGE]: {
            target: STATES.LOADING,
            actions: 'changePage',
          },
        },
      },
      [STATES.EMPTY]: {},
      [STATES.ERROR]: {},
    },
    on: {
      [EVENTS.REFETCH]: {
        target: STATES.LOADING,
        actions: 'refetch',
      },
    },
  },
  {
    actions: {
      refetch: () => {},
      changePage: assign({
        page: (_, event) => event.page,
      }),
      assignData: assign({
        data: (context, event) => event.data.data,
      }),
    },
    guards: {
      isEmpty: context => context.data.length === 0,
    },
  }
);

export { attractionMachine, STATES as ATTRACTION_STATES, EVENTS as ATTRACTION_EVENTS };
