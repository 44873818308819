import React, { useCallback, useEffect } from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { Link, useParams, useHistory, useLocation } from 'react-router-dom';
import { useQueryParams, StringParam, NumberParam } from 'use-query-params';
import { Button, Box, Breadcrumb, Form, Flex, Select, Pagination, Tag } from '@cowsquare/design';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { themeGet } from '@styled-system/theme-get';
import { Form as FinalForm, Field } from 'react-final-form';
import { Section } from '../components/Section';
import TourCard from '../components/TourCard';
import { Banners } from '../components/Banners';
import { COUNTRY_MAP } from '../../constants/country';
import { getHTMLString } from '../../utils/getHTMLString';
import { getUri } from '../../utils/getUri';
import { useBannersQuery, useUser } from '../../utils/hooks';
import AddToPlanButton from '../components/AddToPlanButton';
import { TourCardLoading } from 'views/components/Loading';
import { assign } from 'xstate';
import { useMachine } from '@xstate/react';
import { tourMachine, TOUR_STATES, TOUR_EVENTS } from 'machines/tourMachine';

const TravelFilterFlex = styled(Flex).attrs({
  width: 1,
  mt: '-56px',
  flexDirection: ['column', 'row'],
  alignItems: ['initial', 'center'],
})`
  z-index: 1;
  background-color: ${themeGet('colors.whites.0')};
  border-radius: 8px;

  & > ${Form} {
    flex: 1;
  }
`;

const StyledFormItem = styled(Form.FormItem)`
  margin-bottom: 0;
`;

const TourCardBox = styled(Box).attrs({
  width: [1, 1 / 3],
  pb: ['24px', '48px'],
  px: [0, 4],
})``;

const GET_ALL_OPTIONS = gql`
  query GET_ALL_OPTIONS($country: String!) {
    areas: vontravel_areas(where: { country: { name: { _eq: $country } } }) {
      name
    }

    audience: vontravel_audience(where: { tours: { tour: { country: { name: { _eq: $country } } } } }) {
      id
      name
    }

    features: vontravel_features(where: { tours: { tour: { country: { name: { _eq: $country } } } } }) {
      id
      name
    }
  }
`;

const defualtOption = { name: '全部', value: '' };
const getOptions = (name, data) =>
  R.pipe(
    R.propOr([], name),
    R.map(x => ({ ...x, value: x.name })),
    R.prepend(defualtOption)
  )(data);
const getOptionByName = (value, data, defaultValue = defualtOption) =>
  R.pipe(R.find(R.propEq('name', value)), R.defaultTo(defaultValue))(data);

const FreeTourList = () => {
  const { country } = useParams();
  const location = useLocation();
  const history = useHistory();
  const { user } = useUser();
  const userId = R.propOr(null, 'id', user);
  const countryName = COUNTRY_MAP[country];

  if (!countryName) {
    // TODO: When 404 page is done, change redirect url to 404 page.
    history.push('/');
  }

  const [query, setQuery] = useQueryParams({
    feature: StringParam,
    audience: StringParam,
    page: NumberParam,
  });
  const { data: optionData } = useQuery(GET_ALL_OPTIONS, {
    variables: {
      country: countryName,
    },
  });
  const { feature, audience, page = 1 } = query;
  const [current, send] = useMachine(tourMachine, {
    context: {
      feature,
      audience,
      country: countryName,
      userId,
      page,
    },
    actions: {
      refetch: assign({
        feature,
        audience,
        country: countryName,
        userId,
        page,
      }),
    },
  });

  useEffect(() => {
    send(TOUR_EVENTS.REFETCH);
  }, [send, feature, audience, country, userId, page]);

  const onQuery = useCallback(
    values => {
      setQuery(
        {
          page: 1,
          feature: R.path(['feature', 'value'], values),
          audience: R.path(['audience', 'value'], values),
        },
        'pushIn'
      );
    },
    [setQuery]
  );
  const onPageChange = useCallback(
    nextPage => {
      setQuery({ page: nextPage }, 'pushIn');
    },
    [setQuery]
  );
  const { data, pageSize } = current.context;
  const uri = getUri(location);
  const { banners } = useBannersQuery(uri);
  const list = R.pathOr([], ['list'], data);
  const total = R.pathOr(0, ['meta', 'aggregate', 'count'], data);
  const audienceOptions = getOptions('audience', optionData);
  const featureOptions = getOptions('features', optionData);

  return (
    <>
      <Banners banners={banners} itemProps={{ height: '304px' }} />
      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        <Section px={[5, 0]}>
          <TravelFilterFlex px={5} pt={5} pb={[5, 0]}>
            <FinalForm
              onSubmit={onQuery}
              initialValues={{
                audience: getOptionByName(audience, audienceOptions, defualtOption),
                feature: getOptionByName(feature, featureOptions, defualtOption),
              }}
              render={({ handleSubmit, submitting }) => (
                <>
                  <Form>
                    <Flex flexDirection={['column', 'row']} justifyContent="space-between">
                      <Box flex={1} pr={[0, 5]}>
                        <Field name="audience">
                          {({ input, meta }) => (
                            <StyledFormItem label="對象" input={input} meta={meta}>
                              <Select {...input} placeholder="全部" options={audienceOptions} size="large" />
                            </StyledFormItem>
                          )}
                        </Field>
                      </Box>
                      <Box flex={1} pr={[0, 5]}>
                        <Field name="feature">
                          {({ input, meta }) => (
                            <StyledFormItem label="特色" input={input} meta={meta}>
                              <Select {...input} placeholder="全部" options={featureOptions} size="large" />
                            </StyledFormItem>
                          )}
                        </Field>
                      </Box>
                    </Flex>
                  </Form>
                  <Box>
                    <Flex justifyContent="flex-end">
                      <Button onClick={handleSubmit} disabled={submitting} variant="primary" size="large">
                        搜尋
                      </Button>
                    </Flex>
                  </Box>
                </>
              )}
            ></FinalForm>
          </TravelFilterFlex>
          <Box mt={6}>
            <Breadcrumb>
              <Breadcrumb.Item linkProps={{ as: Link, to: '/' }}>首頁</Breadcrumb.Item>
              <Breadcrumb.Item linkProps={{ as: Link, to: `/自由旅程/${countryName}` }}>
                自由旅程-{countryName}
              </Breadcrumb.Item>
              <Breadcrumb.Item>{`所有${countryName}自由旅程`}</Breadcrumb.Item>
            </Breadcrumb>
          </Box>
        </Section>
      </Flex>
      <Section flexDirection="column" margin="48px auto 80px" px={[5, 0]}>
        <Flex
          flexDirection={['column', 'row']}
          alignItems="center"
          flexWrap="wrap"
          mx={[0, -4]}
          mb={['-24px', '-48px']}
        >
          {current.matches(TOUR_STATES.LOADING) &&
            R.times(
              i => (
                <TourCardBox key={i}>
                  <TourCardLoading />
                </TourCardBox>
              ),
              3
            )}
          {current.matches(TOUR_STATES.LOADED) &&
            R.map(
              item => (
                <TourCardBox key={item.id}>
                  <TourCard
                    id={item.id}
                    collectible={!R.pipe(R.propOr([], 'users'), R.isEmpty)(item)}
                    as={Link}
                    to={`/自由旅程/${countryName}/所有旅程/${item.name}`}
                    coverUrl={R.pathOr('', ['cover', 'link'], item)}
                    height="460px"
                    title={item.name}
                    description={R.pipe(R.pathOr('', ['description', 'content']), getHTMLString)(item)}
                    tags={R.pipe(
                      R.propOr([], 'features'),
                      R.pluck('feature'),
                      R.map(x => <Tag key={x.id}>{x.name}</Tag>)
                    )(item)}
                    links={
                      <AddToPlanButton
                        onClick={e => {
                          e.preventDefault();
                          history.push(
                            `/自助設計優遊/新計劃?cardType=tour&cardName=${item.name}&country=${countryName}`
                          );
                        }}
                      />
                    }
                    country={countryName}
                    isCharter={item.isCharter}
                  />
                </TourCardBox>
              ),
              list
            )}
          {/* --- TODO: handle EMPTY & ERROR --- */}
          {current.matches(TOUR_STATES.ERROR) && null}
          {current.matches(TOUR_STATES.EMPTY) && null}
        </Flex>
        {total > pageSize && (
          <Box mt={7}>
            <Flex justifyContent="center">
              <Pagination current={page} total={total} pageSize={pageSize} onChange={onPageChange} />
            </Flex>
          </Box>
        )}
      </Section>
    </>
  );
};

export default FreeTourList;
