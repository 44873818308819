import React, { useCallback } from 'react';
import * as R from 'ramda';
import { Link, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { Box, Breadcrumb, Carousel, Flex, Icon, Tag } from '@cowsquare/design';
import { Section } from '../../components/Section';
import Map from '../../components/Map';
import LocationCard from '../../components/LocationCard';
import { HTMLContent } from '../../components/HTMLContent';
import { composeGoogleMapUrl } from '../../../utils/composeGoogleMapUrl';
import { getFeature } from '../../../utils/getFeature';
import { COUNTRY_MAP } from '../../../constants/country';
import { useUser } from '../../../utils/hooks';
import AddToPlanButton from '../../components/AddToPlanButton';
import AddToFreePlannerBar from 'views/components/AddToFreePlannerBar';
import ContactButton from 'views/components/ContactButton';
import { LocationCardLoading } from 'views/components/Loading';

const Name = styled(Box).attrs({
  as: 'h1',
})`
  margin: 24px 0 ${themeGet('space.5')}px;
  font-size: ${themeGet('fontSizes.h1')};
  line-height: 1.5;
  font-weight: 500;
`;

const Country = styled(Box).attrs({
  ml: 4,
})`
  font-weight: 500;
  font-size: ${themeGet('fontSizes.h4')};
`;

const Description = styled(Box).attrs({
  mt: 5,
})`
  font-size: ${themeGet('fontSizes.h5')};
  line-height: 1.5;
  color: ${themeGet('colors.blacks.3')};
`;

const Row = styled(Box).attrs({
  display: ['block', 'flex'],
  mb: 5,
})`
  &:last-child {
    margin: 0;
  }
`;

const RowLabel = styled(Box).attrs({
  width: ['100%', '240px'],
  mb: [4, 0],
})`
  display: inline-block;
  font-size: ${themeGet('fontSizes.h5')};
  line-height: 1.5;
  color: ${themeGet('colors.blacks.3')};
`;

const RowValue = styled(Box)`
  flex: 1;
  font-size: ${themeGet('fontSizes.h5')};
  line-height: 1.5;
  font-weight: 500;
`;

const RowLink = styled.a`
  text-decoration: none;
  color: ${themeGet('colors.link')};
`;

const RowMap = styled(Box).attrs({
  mt: 5,
})`
  width: 100%;
  height: 200px;
`;

const InfoWrapper = styled(Box).attrs({
  px: [5, 0],
  py: '48px',
  mb: '80px',
})`
  background-color: ${themeGet('colors.whites.0')};
`;

const Field = ({ label, value, render = R.identity, direction = 'row' }) =>
  !R.either(R.isEmpty, R.isNil)(value) ? (
    <Row flexDirection={direction}>
      <RowLabel>{label}</RowLabel>
      <RowValue ml={direction === 'row' ? [0, 5] : 0}>{render(value)}</RowValue>
    </Row>
  ) : null;

const Title = styled(Box).attrs({
  mb: 6,
})`
  font-size: ${themeGet('fontSizes.h1')};
  line-height: 1.5;
  font-weight: 500;
  text-align: center;
`;

const LocationCardBox = styled(Box).attrs({
  width: [1, 1 / 3],
  px: [0, 4],
  mb: [5, '48px'],
})``;

const Memo = styled(Box)`
  font-size: ${themeGet('fontSizes.h5')};
  line-height: 1.5;
  color: ${themeGet('colors.blacks.3')};
`;

const GET_ATTRACTIONS_CONTENT = gql`
  query getAttractionsContent($name: String!, $userId: uuid) {
    record: vontravel_attractions(where: { name: { _eq: $name } }) {
      id
      name
      cover {
        link
      }
      area {
        name
        country {
          id
        }
      }
      price
      address
      latitude
      longitude
      description {
        content
      }
      festival
      open_time {
        content
      }
      audience {
        id
        audience {
          name
        }
      }
      features {
        feature {
          id
          name
        }
      }
      users(where: { user_id: { _eq: $userId } }) {
        user_id
      }
    }
  }
`;

const GET_RECOMMEND_ATTRACTIONS = gql`
  query getRecommendAttractions(
    $name: String!
    $features: [String!]
    $country_id: uuid
    $isLogin: Boolean!
    $userId: uuid
  ) {
    attractions: vontravel_attractions(
      limit: 3
      where: {
        _not: { name: { _eq: $name } }
        _or: [
          { features: { feature: { name: { _in: $features } } } }
          { area: { country_id: { _eq: $country_id } } }
        ]
      }
    ) {
      id
      name
      cover {
        link
      }
      area {
        name
        country {
          name
        }
      }
      features {
        feature {
          id
          name
        }
      }
      users(where: { user_id: { _eq: $userId } }) @include(if: $isLogin) {
        user_id
      }
    }
  }
`;

const Content = ({ country, name }) => {
  const history = useHistory();
  const handleMarkerClick = useCallback(url => {
    window.open(url);
  }, []);
  const handleContactButton = useCallback(() => {
    window.open(
      `/更多/聯絡我們?${queryString.stringify({
        type: 'attraction',
        object: name,
      })}`,
      '聯絡我們'
    );
  }, [name]);
  const { user } = useUser();
  const userId = R.propOr(null, 'id', user);

  const countryName = COUNTRY_MAP[country];
  if (!countryName) {
    // TODO: When 404 page is done, change redirect url to 404 page.
    history.push('/');
  }
  const { data: attractionsContent, loading: attractionsLoading } = useQuery(GET_ATTRACTIONS_CONTENT, {
    variables: { name, userId },
  });
  const record = R.pathOr({}, ['record', 0], attractionsContent);
  const cover = R.pathOr('', ['cover', 'link'], record);
  const { data: recommendAttractions } = useQuery(GET_RECOMMEND_ATTRACTIONS, {
    variables: {
      name,
      country_id: R.pathOr(null, ['area', 'country', 'id'])(record),
      features: R.pipe(getFeature, R.pluck('name'))(record),
      isLogin: Boolean(userId),
      userId,
    },
  });
  const attractions = R.propOr([], 'attractions', recommendAttractions);
  const showAttractions = R.gt(attractions.length, 1);

  return (
    <>
      {cover && (
        <Carousel>
          <Carousel.Slide backgroundImage={`url(${cover})`} height="304px" />
        </Carousel>
      )}
      <Section flexDirection="column" margin="0 auto 48px" px={[5, 0]}>
        <Box mt={6}>
          <Breadcrumb>
            <Breadcrumb.Item linkProps={{ as: Link, to: '/' }}>首頁</Breadcrumb.Item>
            <Breadcrumb.Item
              linkProps={{ as: Link, to: `/優遊地點/${country}` }}
            >{`優遊地點-${countryName}`}</Breadcrumb.Item>
            <Breadcrumb.Item>{name}</Breadcrumb.Item>
          </Breadcrumb>
        </Box>
        <Name>{name}</Name>
        <Flex alignItems={['flex-start', 'center']} flexDirection={['column', 'row']}>
          <Flex alignItems="center">
            <Icon name="LOCATION_FILL" fontSize="32px" color="primary" />
            <Country>{R.pathOr('', ['area', 'name'])(record)}</Country>
          </Flex>
          <Box ml={[0, '24px']} mt={[5, 0]}>
            <Tag.Group flexWrap="wrap" itemStyle={{ mr: '4px', mb: [4, 0] }}>
              {R.pipe(
                getFeature,
                R.map(tag => <Tag key={tag.id}>{tag.name}</Tag>)
              )(record)}
            </Tag.Group>
          </Box>
        </Flex>
        <Description>
          <HTMLContent html={R.pathOr('', ['description', 'content'], record)} />
        </Description>
      </Section>
      <InfoWrapper>
        <Section flexDirection="column" margin="0 auto">
          <Field label="熱門節慶" value={R.propOr('', 'festival', record)} />
          <Field
            label="適合人士"
            value={R.pipe(
              R.propOr([], 'audience'),
              R.map(R.path(['audience', 'name'])),
              R.join('、')
            )(record)}
          />
          <Field
            label="地址"
            value={R.propOr('', 'address')(record)}
            render={value => (
              <>
                <RowLink href={composeGoogleMapUrl(value)} target="_blank" rel="noopener">
                  {value}
                </RowLink>
                <Map
                  markers={[{ lat: record.latitude, lng: record.longitude }]}
                  containerElement={<RowMap />}
                  onMarkerClick={() => {
                    handleMarkerClick(composeGoogleMapUrl(value));
                  }}
                />
              </>
            )}
          />
          <Field
            label="開放/營業時間"
            value={R.pathOr('', ['open_time', 'content'])(record)}
            render={html => <HTMLContent html={html} />}
          />
          <Field label="入場費用" value={R.propOr('', 'price')(record)} />
          <Memo>*以上資料及圖片只供參考，建議在訂購行程前瀏覽景點主辦單位網站內的相關詳情。</Memo>
        </Section>
      </InfoWrapper>
      {showAttractions && (
        <Section flexDirection="column" margin="0 auto 80px" px={[5, 0]}>
          <Title>相關的優遊地點</Title>
          <Box
            display="flex"
            flexDirection={['column', 'row']}
            justifyContent="center"
            alignItems="center"
            mx={-4}
            mb="-48px"
          >
            {attractionsLoading
              ? R.times(
                  () => (
                    <LocationCardBox>
                      <LocationCardLoading />
                    </LocationCardBox>
                  ),
                  3
                )
              : R.map(item => {
                  const country = R.pathOr('', ['area', 'country', 'name'], item);
                  const name = item.name;
                  return (
                    <LocationCardBox>
                      <LocationCard
                        id={item.id}
                        collectible={!R.pipe(R.propOr([], 'users'), R.isEmpty)(item)}
                        as={Link}
                        to={`/優遊地點/${country}/${name}`}
                        coverUrl={R.pathOr('', ['cover', 'link'], item)}
                        title={name}
                        location={`${country} ${R.pathOr('', ['area', 'name'])(item)}`}
                        tags={R.pipe(
                          getFeature,
                          R.map(tag => <Tag key={tag.id}>{tag.name}</Tag>)
                        )(item)}
                        links={
                          <AddToPlanButton
                            onClick={e => {
                              e.preventDefault();
                              history.push(
                                `/自助設計優遊/新計劃?cardType=attraction&cardName=${item.name}&country=${country}`
                              );
                            }}
                          />
                        }
                      ></LocationCard>
                    </LocationCardBox>
                  );
                })(attractions)}
          </Box>
        </Section>
      )}
      <AddToFreePlannerBar country={country} name={name} type="attraction"></AddToFreePlannerBar>
      <ContactButton onClick={handleContactButton} />
    </>
  );
};

export default Content;
