import { Breadcrumb, Flex } from '@cowsquare/design';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getUri } from '../../utils/getUri';
import { useBannersQuery, useStaticContentQuery } from '../../utils/hooks';
import { Banners } from '../components/Banners';
import { HTMLContent } from '../components/HTMLContent';
import { Section } from '../components/Section';

const CharterService = () => {
  const location = useLocation();
  const uri = getUri(location);
  const { banners } = useBannersQuery(uri);
  const { content } = useStaticContentQuery(uri);

  return (
    <>
      <Banners banners={banners} itemProps={{ height: '424px' }} />
      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        <Section mt={6} mb={7} px={[5, 0]} flexDirection="column">
          <Breadcrumb>
            <Breadcrumb.Item linkProps={{ as: Link, to: '/' }}>首頁</Breadcrumb.Item>
            <Breadcrumb.Item>包車旅遊</Breadcrumb.Item>
          </Breadcrumb>
          <HTMLContent html={content} />
        </Section>
      </Flex>
    </>
  );
};

export default CharterService;
