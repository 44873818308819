import React, { useCallback } from 'react';
import * as R from 'ramda';
import { useHistory } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import {
  Box,
  Carousel,
  Flex,
  Icon,
  Tag,
  Button,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
} from '@cowsquare/design';
import { Section } from '../Section';
import Map from '../Map';
import { HTMLContent } from '../HTMLContent';
import { composeGoogleMapUrl } from '../../../utils/composeGoogleMapUrl';
import { getFeature } from '../../../utils/getFeature';
import { COUNTRY_MAP } from '../../../constants/country';
import { useUser } from '../../../utils/hooks';
import IconButton from './IconButton';
import { usePlanContext } from '../PlanContext';
import { CARD_TYPE } from './constants/CARD_TYPE';

const Country = styled(Box).attrs({
  ml: 4,
})`
  font-weight: 500;
  font-size: ${themeGet('fontSizes.h4')};
`;

const Description = styled(Box).attrs({
  mt: 5,
})`
  font-size: ${themeGet('fontSizes.h5')};
  line-height: 1.5;
  color: ${themeGet('colors.blacks.3')};
`;

const Row = styled(Box).attrs({
  display: ['block', 'flex'],
  mb: 5,
})`
  &:last-child {
    margin: 0;
  }
`;

const RowLabel = styled(Box).attrs({
  width: ['100%', '240px'],
  mb: 4,
})`
  display: inline-block;
  font-size: ${themeGet('fontSizes.h5')};
  line-height: 1.5;
  color: ${themeGet('colors.blacks.3')};
`;

const RowValue = styled(Box)`
  flex: 1;
  font-size: ${themeGet('fontSizes.h5')};
  line-height: 1.5;
  font-weight: 500;
`;

const RowLink = styled.a`
  text-decoration: none;
  color: ${themeGet('colors.link')};
`;

const RowMap = styled(Box).attrs({
  mt: 5,
})`
  width: 100%;
  height: 200px;
`;

const InfoWrapper = styled(Box).attrs({
  px: [5, 0],
  py: '48px',
  mb: '80px',
})``;

const Field = ({ label, value, render = R.identity, direction = 'column' }) =>
  !R.either(R.isEmpty, R.isNil)(value) ? (
    <Row flexDirection={direction}>
      <RowLabel>{label}</RowLabel>
      <RowValue ml={direction === 'row' ? [0, 5] : 0}>{render(value)}</RowValue>
    </Row>
  ) : null;

const Memo = styled(Box)`
  font-size: ${themeGet('fontSizes.h5')};
  line-height: 1.5;
  color: ${themeGet('colors.blacks.3')};
`;

const GET_ATTRACTIONS_CONTENT = gql`
  query getAttractionsContent($name: String!, $userId: uuid) {
    record: vontravel_attractions(where: { name: { _eq: $name } }) {
      id
      name
      cover {
        link
      }
      area {
        name
        country {
          id
        }
      }
      baby_evaluted_price
      children_evaluted_price
      teen_evaluted_price
      adult_evaluted_price
      elder_evaluted_price
      address
      latitude
      longitude
      description {
        content
      }
      festival
      open_time {
        content
      }
      audience {
        id
        audience {
          name
        }
      }
      features {
        feature {
          id
          name
        }
      }
      users(where: { user_id: { _eq: $userId } }) {
        user_id
      }
    }
  }
`;

const InfoBox = styled(Box).attrs({
  pl: ['16px', '24px'],
  display: 'flex',
  alignItems: 'center',
  height: ['24px', '56px'],
})``;

const InfoFlex = styled(Flex).attrs({
  width: 1,
  flexDirection: ['column', 'row'],
  justifyContent: 'flex-start',
  alignItems: ['flex-start', 'center'],
  py: ['16px', 0],
})`
  font-size: 14px;
  font-weight: 500;
  background-color: ${themeGet('colors.whites.0')};
`;

const StyledIcon = styled(Icon).attrs({
  fontSize: 24,
})`
  color: ${props => props.color || themeGet('colors.primary')(props)};
  margin-right: 8px;
`;

const AttractionCardDrawerPage = ({ country, cardName: name, onClose, onBackList }) => {
  const { addCard, selectedDate, canAddCardToDate } = usePlanContext();
  const history = useHistory();
  const handleMarkerClick = useCallback(url => {
    window.open(url);
  }, []);
  const { user } = useUser();
  const userId = R.propOr(null, 'id', user);

  const countryName = COUNTRY_MAP[country];
  if (!countryName) {
    // TODO: When 404 page is done, change redirect url to 404 page.
    history.push('/');
  }
  const { data: attractionsContent } = useQuery(GET_ATTRACTIONS_CONTENT, {
    variables: { name, userId },
  });
  const record = R.pathOr({}, ['record', 0], attractionsContent);
  const cover = R.pathOr('', ['cover', 'link'], record);

  return (
    <>
      <DrawerHeader divider={false} onClose={onClose}>
        <Flex alignItems="center">
          <IconButton onClick={onBackList} name="ARROW_LEFT" />
          <Box ml={5}>{name}</Box>
        </Flex>
      </DrawerHeader>
      <DrawerBody>
        {cover && (
          <Carousel>
            <Carousel.Slide backgroundImage={`url(${cover})`} height="304px" />
          </Carousel>
        )}

        <InfoFlex>
          <InfoBox>
            <StyledIcon name="LOCATION_FILL" color="primary" />
            <Country>{R.pathOr('', ['area', 'name'])(record)}</Country>
          </InfoBox>
        </InfoFlex>
        <Box p={5}>
          <Tag.Group>
            {R.pipe(
              getFeature,
              R.map(tag => <Tag key={tag.id}>{tag.name}</Tag>)
            )(record)}
          </Tag.Group>
          <Description>
            <HTMLContent html={R.pathOr('', ['description', 'content'], record)} />
          </Description>
          <InfoWrapper>
            <Section flexDirection="column" margin="0 auto">
              <Field label="熱門節慶" value={R.propOr('', 'festival', record)} />
              <Field
                label="適合人士"
                value={R.pipe(
                  R.propOr([], 'audience'),
                  R.map(R.path(['audience', 'name'])),
                  R.join('、')
                )(record)}
              />
              <Field
                label="地址"
                value={R.propOr('', 'address')(record)}
                render={value => (
                  <>
                    <RowLink href={composeGoogleMapUrl(value)} target="_blank" rel="noopener">
                      {value}
                    </RowLink>
                    <Map
                      markers={[{ lat: record.latitude, lng: record.longitude }]}
                      containerElement={<RowMap />}
                      onMarkerClick={() => {
                        handleMarkerClick(composeGoogleMapUrl(value));
                      }}
                    />
                  </>
                )}
              />
              <Field
                label="開放/營業時間"
                value={R.pathOr('', ['open_time', 'content'])(record)}
                render={html => <HTMLContent html={html} />}
              />
              <Field label="入場費用" value={R.propOr('', 'price')(record)} />
              <Memo>*以上資料及圖片只供參考，建議在訂購行程前瀏覽景點主辦單位網站內的相關詳情。</Memo>
            </Section>
          </InfoWrapper>
        </Box>
      </DrawerBody>
      <DrawerFooter>
        <Button
          size="large"
          variant="primary"
          disabled={R.complement(canAddCardToDate)(selectedDate, CARD_TYPE.ATTRACTION)}
          onClick={() => {
            addCard(selectedDate, { ...record, cardType: CARD_TYPE.ATTRACTION });
            onClose();
          }}
        >
          加入
        </Button>
      </DrawerFooter>
    </>
  );
};

export default AttractionCardDrawerPage;
