import * as R from 'ramda';

const pattern = {
  // http://emailregex.com/
  // eslint-disable-next-line no-useless-escape
  email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};

export const required = message => value => (value ? undefined : message);
export const isEmail = message => value =>
  typeof value === 'string' && value.match(pattern.email) ? undefined : message;

export const pipe = (...ruleFns) => R.pipe(R.juxt(ruleFns), R.reject(R.isNil), R.head);
