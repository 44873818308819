import { isSameDay, isSameHour, isSameMinute, isSameSecond, isValid } from 'date-fns/fp';
import { allPass, curry, gte, lte } from 'ramda';
/*
 * Copyright 2015 Palantir Technologies, Inc. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export var Months = {
  JANUARY: 0,
  FEBRUARY: 1,
  MARCH: 2,
  APRIL: 3,
  MAY: 4,
  JUNE: 5,
  JULY: 6,
  AUGUST: 7,
  SEPTEMBER: 8,
  OCTOBER: 9,
  NOVEMBER: 10,
  DECEMBER: 11
};
export var Modifiers = {
  DISABLED: 'disabled',
  HOVERED_RANGE: 'hovered-range',
  HOVERED_RANGE_START: 'hoverd-range-start',
  HOVERED_RANGE_END: 'hoverd-range-end',
  OUTSIDE: 'outside',
  SELECTED: 'selected',
  SELECTED_RANGE: 'selected-range',
  SELECTED_RANGE_START: 'selected-range-start',
  SELECTED_RANGE_END: 'selected-range-end'
};
var isSameMilliseconds = curry(function (date1, date2) {
  return isValid(date1) && isValid(date2) && date1.getMilliseconds() === date2.getMilliseconds();
});
export var isSameTime = allPass([isSameHour, isSameMinute, isSameSecond, isSameMilliseconds]);
export var clone = function clone(d) {
  return new Date(d.getTime());
};
export var isDayInRange = function isDayInRange(date, dateRange) {
  var exclusive = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

  if (date == null || dateRange[0] == null || dateRange[1] == null) {
    return false;
  }

  var day = clone(date);
  var start = clone(dateRange[0]);
  var end = clone(dateRange[1]);
  day.setHours(0, 0, 0, 0);
  start.setHours(0, 0, 0, 0);
  end.setHours(0, 0, 0, 0);
  return start <= day && day <= end && (!exclusive || !isSameDay(start, day) && !isSameDay(day, end));
};
export var isTimeEqualOrGreaterThan = gte;
export var isTimeEqualOrSmallerThan = lte;
export var isTimeInRange = function isTimeInRange(date, minDate, maxDate) {
  var time = getDateOnlyWithTime(date);
  var minTime = getDateOnlyWithTime(minDate);
  var maxTime = getDateOnlyWithTime(maxDate);
  var isTimeGreaterThanMinTime = isTimeEqualOrGreaterThan(time, minTime);
  var isTimeSmallerThanMaxTime = isTimeEqualOrSmallerThan(time, maxTime);

  if (isTimeEqualOrSmallerThan(maxTime, minTime)) {
    return isTimeGreaterThanMinTime || isTimeSmallerThanMaxTime;
  }

  return isTimeGreaterThanMinTime && isTimeSmallerThanMaxTime;
};
export var getTimeInRange = function getTimeInRange(time, minTime, maxTime) {
  if (isSameTime(minTime, maxTime)) {
    return maxTime;
  } else if (isTimeInRange(time, minTime, maxTime)) {
    return time;
  } else if (isTimeSameOrAfter(time, maxTime)) {
    return maxTime;
  }

  return minTime;
};
/**
 * Returns true if the time part of `date` is later than or equal to the time
 * part of `dateToCompare`. The day, month, and year parts will not be compared.
 */

export var isTimeSameOrAfter = function isTimeSameOrAfter(date, dateToCompare) {
  var time = getDateOnlyWithTime(date);
  var timeToCompare = getDateOnlyWithTime(dateToCompare);
  return isTimeEqualOrGreaterThan(time, timeToCompare);
};
/**
 * @returns a Date at the exact time-wise midpoint between startDate and endDate
 */

export var getDateBetween = function getDateBetween(dateRange) {
  var start = dateRange[0].getTime();
  var end = dateRange[1].getTime();
  var middle = start + (end - start) * 0.5;
  return new Date(middle);
};
/**
 * @returns a Date at the exact time-wise midpoint between startDate and endDate
 */

export var getYearsBetween = function getYearsBetween(yearRange) {
  var start = yearRange[0];
  var end = yearRange[1];
  var years = [start];
  var year = start;

  while (++year <= end) {
    years.push(year);
  }

  return years;
};
export var getDateTime = function getDateTime(date, time) {
  if (date == null) {
    return null;
  } else if (time == null) {
    // cannot use default argument because `null` is a common value in this package.
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
  } else {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), time.getHours(), time.getMinutes(), time.getSeconds(), time.getMilliseconds());
  }
};
export var getDateOnlyWithTime = function getDateOnlyWithTime(date) {
  return new Date(0, 0, 0, date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());
};
export var convert24HourMeridiem = function convert24HourMeridiem(hour, toPm) {
  if (hour < 0 || hour > 23) {
    throw new Error("hour must be between [0,23] inclusive: got ".concat(hour));
  }

  return toPm ? hour % 12 + 12 : hour % 12;
};
export var getIsPmFrom24Hour = function getIsPmFrom24Hour(hour) {
  if (hour < 0 || hour > 23) {
    throw new Error("hour must be between [0,23] inclusive: got ".concat(hour));
  }

  return hour >= 12;
};
export var get12HourFrom24Hour = function get12HourFrom24Hour(hour) {
  if (hour < 0 || hour > 23) {
    throw new Error("hour must be between [0,23] inclusive: got ".concat(hour));
  }

  var newHour = hour % 12;
  return newHour === 0 ? 12 : newHour;
};
export var get24HourFrom12Hour = function get24HourFrom12Hour(hour, isPm) {
  if (hour < 1 || hour > 12) {
    throw new Error("hour must be between [1,12] inclusive: got ".concat(hour));
  }

  var newHour = hour === 12 ? 0 : hour;
  return isPm ? newHour + 12 : newHour;
};
export var getDefaultMaxDate = function getDefaultMaxDate() {
  var date = new Date();
  date.setFullYear(date.getFullYear() + 10);
  date.setMonth(Months.DECEMBER, 31);
  return date;
};
export var getDefaultMinDate = function getDefaultMinDate() {
  var date = new Date();
  date.setFullYear(date.getFullYear() - 10);
  date.setMonth(Months.JANUARY, 1);
  return date;
};
export var getInitialDate = function getInitialDate(props, value) {
  var today = new Date(); // != because we must have a real `Date` to begin the calendar on.

  if (isValid(value)) {
    return value;
  } else if (isDayInRange(today, [props.minDate, props.maxDate])) {
    return today;
  } else {
    return getDateBetween([props.minDate, props.maxDate]);
  }
};