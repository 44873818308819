import React, { useCallback, useEffect } from 'react';
import * as R from 'ramda';
import { assign } from 'xstate';
import { useMachine } from '@xstate/react';
import { Flex, Pagination } from '@cowsquare/design';
import LocationCard from '../LocationCard';
import { COUNTRY_MAP } from '../../../constants/country';
import AddToPlanButton from '../AddToPlanButton';
import CardList from './CardList';
import TourCardBox from './TourCardBox';
import { CARD_TYPE } from './constants/CARD_TYPE';
import { usePlanContext } from '../PlanContext';
import { LocationCardLoading } from '../Loading';
import { attractionMachine, ATTRACTION_EVENTS, ATTRACTION_STATES } from 'machines/attractionMachines';

const TabAttraction = ({ country, area, feature, onOpenContentDrawerPage, closeDrawer }) => {
  const { addCard, selectedDate, canAddCardToDate } = usePlanContext();
  const [current, send] = useMachine(attractionMachine, {
    context: { country, area, feature },
    actions: {
      refetch: assign({
        country,
        area,
        feature,
      }),
    },
  });

  const { data, pageSize, page } = current.context;

  const total = R.pathOr(0, ['meta', 'aggregate', 'count'], data);
  const countryName = COUNTRY_MAP[country];

  useEffect(() => {
    send(ATTRACTION_EVENTS.REFETCH);
  }, [country, feature, area, send]);

  const onPageChange = useCallback(
    page => {
      send({ type: ATTRACTION_EVENTS.PAGE_CHANGE, page });
    },
    [send]
  );

  return (
    <>
      <CardList>
        {current.matches(ATTRACTION_STATES.LOADING) &&
          R.times(
            i => (
              <TourCardBox key={i}>
                <LocationCardLoading />
              </TourCardBox>
            ),
            2
          )}
        {current.matches(ATTRACTION_STATES.LOADED) &&
          R.pipe(
            R.propOr([], 'list'),
            R.map(item => (
              <TourCardBox key={item.id}>
                <LocationCard
                  onClick={() => {
                    onOpenContentDrawerPage(item.name, CARD_TYPE.ATTRACTION);
                  }}
                  coverUrl={R.path(['cover', 'link'], item)}
                  fixedCoverSize="160px"
                  title={item.name}
                  height="380px"
                  location={`${countryName} ${R.pathOr('', ['area', 'name'], item)}`}
                  showCollectIcon={false}
                  disabled={R.complement(canAddCardToDate)(selectedDate, CARD_TYPE.ATTRACTION)}
                  links={
                    <AddToPlanButton
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        addCard(selectedDate, { ...item, cardType: CARD_TYPE.ATTRACTION });
                        closeDrawer();
                      }}
                    />
                  }
                />
              </TourCardBox>
            ))
          )(data)}
        {/* --- TODO: handle EMPTY & ERROR --- */}
        {current.matches(ATTRACTION_STATES.EMPTY) && null}
        {current.matches(ATTRACTION_STATES.ERROR) && null}
      </CardList>
      {total > pageSize && (
        <Flex py={6} justifyContent="center">
          <Pagination total={total} current={page} pageSize={pageSize} onChange={onPageChange} />
        </Flex>
      )}
    </>
  );
};

export default TabAttraction;
