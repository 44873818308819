import * as R from 'ramda';
import { imageSwitch } from '../views/components/ResponsiveImage';

export const COUNTRIES = [
  {
    name: '中國',
    cover: imageSwitch('china-cover.jpg'),
  },
  {
    name: '台灣',
    cover: imageSwitch('taiwan-cover.jpg'),
  },
  {
    name: '日本',
    cover: imageSwitch('japan-cover.jpg'),
  },
];

export const HOMESTAY_COUNTRIES = [
  {
    name: '中國',
    cover: imageSwitch('homestay_china.jpg'),
    available: true,
  },
  {
    name: '台灣',
    cover: imageSwitch('homestay_taiwan.jpg'),
    available: true,
  },
  {
    name: '日本',
    cover: imageSwitch('homestay_japan.jpg'),
    available: false,
  },
];

export const COUNTRY_MAP = {
  台灣: '台灣',
  日本: '日本',
  中國: '中國',
};

export const COUNTRY_BANNER_MAP = R.pipe(R.indexBy(R.prop('name')), R.map(R.prop('cover')))(COUNTRIES);
