import * as R from 'ramda';
import gql from 'graphql-tag';
import { Machine, assign } from 'xstate';
import { client } from 'apollo/client';
import { shuffle } from 'utils/random';

const GET_RECOMMEND_HOMESTAYS = gql`
  query getRecommendHomestays($country: String, $areas: [uuid!], $cities: [uuid!], $id: uuid) {
    match_cities_homestays: vontravel_homestays(
      where: { id: { _neq: $id }, city: { id: { _in: $cities } } }
    ) {
      id
      name
      code
      featured
      city {
        id
        name
        area {
          id
          name
          country {
            id
            name
          }
        }
      }
      people
      description
      bedroom
      double_bed
      single_bed
      bunk_bed
      sofa_bed
      bathroom
      view_type {
        name
      }
      pictures(order_by: { created_at: asc }) {
        picture {
          link
        }
      }
      reviews(where: { approved: { _eq: true } }) {
        rate
      }
    }
    match_areas_homestays: vontravel_homestays(
      where: { id: { _neq: $id }, city: { area: { id: { _in: $areas } }, id: { _nin: $cities } } }
    ) {
      id
      name
      code
      featured
      city {
        id
        name
        area {
          id
          name
          country {
            id
            name
          }
        }
      }
      people
      description
      bedroom
      double_bed
      single_bed
      bunk_bed
      sofa_bed
      bathroom
      view_type {
        name
      }
      pictures(order_by: { created_at: asc }) {
        picture {
          link
        }
      }
      reviews(where: { approved: { _eq: true } }) {
        rate
      }
    }
    other_homestays: vontravel_homestays(
      where: {
        id: { _neq: $id }
        city: { area: { country: { name: { _eq: $country } }, id: { _nin: $areas } }, id: { _nin: $cities } }
      }
    ) {
      id
      name
      code
      featured
      city {
        id
        name
        area {
          id
          name
          country {
            id
            name
          }
        }
      }
      people
      description
      bedroom
      double_bed
      single_bed
      bunk_bed
      sofa_bed
      bathroom
      view_type {
        name
      }
      pictures(order_by: { created_at: asc }) {
        picture {
          link
        }
      }
      reviews(where: { approved: { _eq: true } }) {
        rate
      }
    }
  }
`;

const S = {
  IDLE: 'IDLE',
  LOADING_HOMESTAYS: 'LOADING',
  ERROR: 'ERROR',
};

const E = {
  LOAD_HOMESTAYS: 'LOAD_HOMESTAYS',
};

const getRandomSortedHomestays = R.pipe(R.defaultTo([]), shuffle, R.sort(R.descend(R.prop('featured'))));

const recommendHomestayMachine = Machine(
  {
    context: {
      recommendHomestays: [],
    },
    initial: S.IDLE,
    states: {
      [S.IDLE]: {
        on: {
          [E.LOAD_HOMESTAYS]: { target: S.LOADING_HOMESTAYS },
        },
      },
      [S.LOADING_HOMESTAYS]: {
        invoke: {
          src: 'getRecommendHomestays',
          onDone: {
            target: S.IDLE,
            actions: ['assignRecommendHomestays'],
          },
          onError: { target: S.ERROR },
        },
      },
      [S.ERROR]: {},
    },
  },
  {
    actions: {
      assignRecommendHomestays: assign({
        recommendHomestays: (_, event) =>
          R.pipe(
            R.props(['match_cities_homestays', 'match_areas_homestays', 'other_homestays']),
            R.map(getRandomSortedHomestays),
            R.flatten,
            R.take(3)
          )(event.data),
      }),
    },
    services: {
      getRecommendHomestays: (_, { country, areas, cities, id }) =>
        client
          .query({
            query: GET_RECOMMEND_HOMESTAYS,
            variables: {
              country,
              areas,
              cities,
              id,
            },
          })
          .then(res => res.data),
    },
  }
);

export { recommendHomestayMachine, S as RECOMMEND_HOMESTAY_STATE, E as RECOMMEND_HOMESTAY_EVENT };
