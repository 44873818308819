import React from 'react';
import LazyLoad from 'react-lazyload';

export const IS_SUPPORT_WEBP = (() => {
  const _typeof =
    typeof Symbol === 'function' && typeof Symbol.iterator === 'symbol'
      ? function(obj) {
          return typeof obj;
        }
      : function(obj) {
          return obj && typeof Symbol === 'function' && obj.constructor === Symbol && obj !== Symbol.prototype
            ? 'symbol'
            : typeof obj;
        };

  let support = void 0;

  if (typeof support !== 'undefined') return support;

  const elem =
    (typeof document === 'undefined' ? 'undefined' : _typeof(document)) === 'object'
      ? document.createElement('canvas')
      : {};

  support = elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;

  return support;
})();

export const imageSwitch = filename => {
  const [name, ext] = filename.split('.');
  return IS_SUPPORT_WEBP ? require(`images/webp/${name}.webp`) : require(`images/optimized/${name}.${ext}`);
};

const ResponsiveImage = ({ name, as: Component, ...rest }) => {
  const Image = Component || 'img';

  return (
    <LazyLoad>
      <Image src={imageSwitch(name)} {...rest} />
    </LazyLoad>
  );
};

export default ResponsiveImage;
