import ApolloClient from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import * as R from 'ramda';
import { cache } from './cache';
import { authLink, httpLink, errorLink } from './links';
import { form } from './resolvers/form';

const resolvers = R.compose(R.mergeDeepRight(form.resolvers))({});
const defaults = R.compose(R.mergeDeepRight(form.defaults))({});

export const client = new ApolloClient({
  cache,
  link: ApolloLink.from([authLink, errorLink, httpLink]),
  resolvers,
});

cache.writeData({
  data: defaults,
});
