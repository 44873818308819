import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { useQueryParams, StringParam, NumberParam } from 'use-query-params';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import * as R from 'ramda';
import { Box, Carousel, Flex, Tag, Pagination, Radio } from '@cowsquare/design';
import { Section } from '../components/Section';
import TourCard from '../components/TourCard';
import LocationCard from '../components/LocationCard';
import { getHTMLString } from '../../utils/getHTMLString';
import { useUser } from '../../utils/hooks';
import AddToPlanButton from '../components/AddToPlanButton';
import { imageSwitch } from '../components/ResponsiveImage';
import { TourCardLoading, LocationCardLoading } from 'views/components/Loading';

const TAGS = {
  自由旅程: '自由旅程',
  優遊地點: '優遊地點',
};

const PAGE_SIZE = 12;

const TourCardBox = styled(Box).attrs({
  width: [1, 1 / 3],
  pb: ['24px', '48px'],
  px: [0, 4],
})``;

const GET_TOUR_WISH_LIST = gql`
  query getTourWishList($userId: uuid!, $limit: Int, $offset: Int) {
    list: vontravel_users_tours_bridge(
      limit: $limit
      offset: $offset
      order_by: { created_at: desc }
      where: { user_id: { _eq: $userId } }
    ) {
      item: tour {
        id
        name
        days
        features {
          feature {
            id
            name
          }
        }
        price
        description {
          content
        }
        cover {
          link
        }
        country {
          name
        }
        users(where: { user_id: { _eq: $userId } }) {
          user_id
        }
        isCharter
      }
    }
    total: vontravel_users_tours_bridge_aggregate(where: { user_id: { _eq: $userId } }) {
      aggregate {
        count
      }
    }
  }
`;

const GET_ATTRACTION_WISH_LIST = gql`
  query getAttractionWishList($userId: uuid!, $limit: Int, $offset: Int) {
    list: vontravel_users_attractions_bridge(
      limit: $limit
      offset: $offset
      order_by: { created_at: desc }
      where: { user_id: { _eq: $userId } }
    ) {
      item: attraction {
        id
        name
        features {
          feature {
            id
            name
          }
        }
        price
        description {
          content
        }
        cover {
          link
        }
        area {
          name
          country {
            name
          }
        }
        users(where: { user_id: { _eq: $userId } }) {
          user_id
        }
      }
    }
    total: vontravel_users_attractions_bridge_aggregate(where: { user_id: { _eq: $userId } }) {
      aggregate {
        count
      }
    }
  }
`;

const QUERY = {
  [TAGS['自由旅程']]: GET_TOUR_WISH_LIST,
  [TAGS['優遊地點']]: GET_ATTRACTION_WISH_LIST,
};

const WishList = () => {
  const history = useHistory();
  const [collectedIds, setCollectedIds] = useState([]);
  const [query, setQuery] = useQueryParams({
    tag: StringParam,
    page: NumberParam,
  });
  const { user } = useUser();
  const { tag = TAGS['自由旅程'], page = 1 } = query;
  const onTagChange = useCallback(
    tag => {
      setQuery({
        ...query,
        page: 1,
        tag,
      });
    },
    [query, setQuery]
  );
  const onPageChange = useCallback(
    nextPage => {
      setQuery({ ...query, page: nextPage });
    },
    [query, setQuery]
  );

  const userId = R.propOr('', 'id', user);

  const { data, loading } = useQuery(QUERY[tag], {
    fetchPolicy: 'cache-and-network',
    skip: !userId,
    variables: {
      limit: PAGE_SIZE,
      offset: (page - 1) * PAGE_SIZE,
      userId,
    },
  });
  const list = R.pipe(R.pathOr([], ['list']), R.pluck('item'))(data);
  const total = R.pathOr(0, ['total', 'aggregate', 'count'], data);

  const onCollect = id => () => {
    if (R.includes(id, collectedIds)) {
      setCollectedIds(R.without([id]));
    } else {
      setCollectedIds(R.append(id));
    }
  };

  useEffect(() => {
    const ids = R.pipe(
      // 沒有加入收藏的話 users 會是空陣列
      R.reject(R.pipe(R.prop('users'), R.isEmpty)),
      R.pluck('id')
    )(list);

    if (!R.equals(ids, collectedIds)) {
      setCollectedIds(ids);
    }
  }, [collectedIds, list]);

  return (
    <>
      <Carousel>
        <Carousel.Slide
          title="我的願望清單"
          backgroundImage={`url(${imageSwitch('wishlist_banner.jpg')})`}
          height="304px"
        />
      </Carousel>
      <Section flexDirection="column" mt={5} mb="80px" mx="auto" px={[5, 0]}>
        <Box mt={6}>
          <Radio.Group onChange={onTagChange} value={tag}>
            {R.map(
              tag => (
                <Radio.Tag key={tag} value={tag}>
                  {tag}
                </Radio.Tag>
              ),
              R.values(TAGS)
            )}
          </Radio.Group>
        </Box>
        <Flex
          flexDirection={['column', 'row']}
          alignItems="center"
          flexWrap="wrap"
          mt={5}
          mx={[0, -4]}
          mb={['-24px', '-48px']}
        >
          {loading
            ? R.times(
                () =>
                  tag === TAGS['自由旅程'] ? (
                    <TourCardLoading />
                  ) : (
                    <TourCardBox>
                      <LocationCardLoading />
                    </TourCardBox>
                  ),
                3
              )
            : R.map(
                item => (
                  <TourCardBox key={item.id}>
                    {tag === TAGS['自由旅程'] ? (
                      <TourCard
                        id={item.id}
                        collectible={R.includes(item.id, collectedIds)}
                        onCollect={onCollect(item.id)}
                        as={Link}
                        to={`/自由旅程/${R.pathOr('', ['country', 'name'], item)}/所有旅程/${item.name}`}
                        coverUrl={R.pathOr('', ['cover', 'link'], item)}
                        height="460px"
                        title={item.name}
                        description={R.pipe(R.pathOr('', ['description', 'content']), getHTMLString)(item)}
                        tags={R.pipe(
                          R.propOr([], 'features'),
                          R.pluck('feature'),
                          R.map(x => <Tag key={x.id}>{x.name}</Tag>)
                        )(item)}
                        links={
                          <AddToPlanButton
                            onClick={e => {
                              e.preventDefault();
                              history.push(
                                `/自助設計優遊/新計劃?cardType=tour&cardName=${item.name}&country=${R.pathOr(
                                  '',
                                  ['country', 'name'],
                                  item
                                )}`
                              );
                            }}
                          />
                        }
                        country={R.pathOr('', ['country', 'name'], item)}
                        isCharter={item.isCharter}
                      />
                    ) : (
                      <LocationCard
                        id={item.id}
                        collectible={R.includes(item.id, collectedIds)}
                        onCollect={onCollect(item.id)}
                        as={Link}
                        to={`/優遊地點/${R.pathOr('', ['area', 'country', 'name'], item)}/${item.name}`}
                        // TODO: 加上預設圖
                        coverUrl={R.path(['cover', 'link'], item)}
                        title={item.name}
                        height="410px"
                        tags={R.map(
                          x => (
                            <Tag key={x.feature.name}>{x.feature.name}</Tag>
                          ),
                          item.features
                        )}
                        location={`${R.pathOr('', ['area', 'country', 'name'], item)} ${R.pathOr(
                          '',
                          ['area', 'name'],
                          item
                        )}`}
                        links={
                          <AddToPlanButton
                            onClick={e => {
                              e.preventDefault();
                              history.push(
                                `/自助設計優遊/新計劃?cardType=attraction&cardName=${
                                  item.name
                                }&country=${R.pathOr('', ['area', 'country', 'name'], item)}`
                              );
                            }}
                          />
                        }
                      />
                    )}
                  </TourCardBox>
                ),
                list
              )}
        </Flex>
        {total > PAGE_SIZE && (
          <Box mt={7}>
            <Flex justifyContent="center">
              <Pagination current={page} total={total} pageSize={PAGE_SIZE} onChange={onPageChange} />
            </Flex>
          </Box>
        )}
      </Section>
    </>
  );
};

export default WishList;
