import React, { useState } from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { Box, Button, Flex, Form, Input, Notification } from '@cowsquare/design';
import { themeGet } from '@styled-system/theme-get';
import { Form as FinalForm, Field } from 'react-final-form';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import * as rules from '../../../utils/validate';
import { AUTH_TOKEN } from '../../../constants';

const FormItem = Form.FormItem;

const UPDATE_PASSWORD = gql`
  mutation UpdatePassword($oldPassword: String!, $newPassword: String!) {
    update_password(oldPassword: $oldPassword, newPassword: $newPassword)
  }
`;

const Title = styled(Box).attrs({ as: 'h1', m: 0, mb: 5 })`
  font-size: ${themeGet('fontSizes.h2')};
  line-height: 1.5;
  font-weight: 500;
`;

const wait = ms => new Promise(resolve => setTimeout(resolve, ms));

const logout = () => {
  localStorage.removeItem(AUTH_TOKEN);
  window.location.assign('/');
};

const ResetPasswordForm = () => {
  const [updatePassword] = useMutation(UPDATE_PASSWORD);
  const [oldPasswordErrorMessage, setOldPasswordErrorMessage] = useState(null);

  return (
    <FinalForm
      initialValues={{
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      }}
      validate={({ newPassword, confirmPassword }) => {
        if (newPassword !== confirmPassword) {
          return {
            confirmPassword: '輸入的密碼不一致',
          };
        }
        return undefined;
      }}
      onSubmit={formValues =>
        updatePassword({
          variables: {
            oldPassword: formValues.oldPassword,
            newPassword: formValues.newPassword,
          },
        })
          .then(() => {
            Notification.success({ message: '已成功更新密碼', description: '請重新以新密碼登入' });
            // 直接 logout 會看不到 Notification
            return wait(1500).then(logout);
          })
          .catch(err => {
            if (err.message === 'GraphQL error: Password is incorrect.') {
              setOldPasswordErrorMessage('請輸入正確的目前密碼');
            }
          })
      }
    >
      {({ handleSubmit, submitting }) => (
        <Form onSubmit={handleSubmit}>
          <Title>重設密碼</Title>
          <Field name="oldPassword" validate={rules.required('請輸入目前密碼')}>
            {({ input, meta }) => (
              <FormItem
                label="目前密碼"
                input={input}
                meta={R.over(R.lensProp('error'), R.defaultTo(oldPasswordErrorMessage), meta)}
              >
                <Input
                  {...input}
                  onChange={event => {
                    setOldPasswordErrorMessage(null);
                    input.onChange(event.target.value);
                  }}
                  size="large"
                  placeholder="請輸入目前密碼"
                  type="password"
                />
              </FormItem>
            )}
          </Field>
          <Field name="newPassword" validate={rules.required('請輸入新密碼')}>
            {({ input, meta }) => (
              <FormItem label="新密碼" input={input} meta={meta}>
                <Input {...input} size="large" placeholder="請輸入新密碼" type="password" />
              </FormItem>
            )}
          </Field>
          <Field name="confirmPassword" validate={rules.required('請再次輸入新密碼')}>
            {({ input, meta }) => (
              <FormItem label="確認密碼" input={input} meta={meta}>
                <Input {...input} size="large" placeholder="再次輸入新密碼" type="password" />
              </FormItem>
            )}
          </Field>
          <Flex justifyContent="flex-end">
            <Button w="84px" size="large" variant="primary" disabled={submitting}>
              儲存
            </Button>
          </Flex>
        </Form>
      )}
    </FinalForm>
  );
};

export default ResetPasswordForm;
