import { Box, Dropdown, Flex, Icon } from '@cowsquare/design';
import { themeGet } from '@styled-system/theme-get';
import * as R from 'ramda';
import React, { useCallback, useEffect, useState } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { clickUserOption, userOptions } from './userOptions';

const ArrowIcon = styled(Icon).attrs({
  name: 'ARROW_DOWN',
})`
  ${({ active }) =>
    active &&
    css`
      color: ${themeGet('colors.link')};
    `}
`;

const Block = styled(Box).attrs({
  p: '18px',
  pb: 0,
  mx: 10,
})`
  cursor: pointer;
`;

const UserBlock = styled(Box).attrs({
  py: '18px',
  pl: '15px',
})`
  cursor: pointer;
`;

const Menu = styled.nav`
  display: flex;
  flex-direction: row;
`;

const MenuItem = styled(Box)`
  color: ${themeGet('colors.blacks.4')};
  font-size: 14px;
  text-decoration: none;

  ${({ active }) =>
    active &&
    css`
      color: ${themeGet('colors.link')};
      font-weight: 500;
    `}
`;

const MenuLinkItem = styled(NavLink)`
  display: block;
  color: ${themeGet('colors.blacks.4')};
  font-size: 14px;
  text-decoration: none;

  &.active {
    color: ${themeGet('colors.link')};
    font-weight: 500;
  }
`;

const OptionBlock = styled(MenuLinkItem)`
  padding: ${themeGet('dropdown.option.padding')};
  font-size: ${themeGet('dropdown.option.fontSize')};
  user-select: none;
  cursor: pointer;

  &:hover {
    color: ${themeGet('dropdown.option.hover.color')};
    background-color: ${themeGet('dropdown.option.hover.backgroundColor')};
  }
`;

export const DesktopMenu = ({ menu = [] }) => {
  const history = useHistory();
  const handleHeaderNavLinkClicked = useCallback(
    ({ link }) => {
      history.push(link);
    },
    [history]
  );

  return (
    <Menu>
      {R.map(item =>
        item.children ? (
          <Dropdown
            key={item.name}
            itemToString={R.prop('name')}
            onChange={handleHeaderNavLinkClicked}
            options={item.children}
            render={({ toggleMenu, getInputProps, isOpen }) => (
              <Block onClick={toggleMenu} {...getInputProps()}>
                <MenuItem>
                  <Flex flexDirection="column" alignItems="center" justifyContent="flex-start">
                    <span>{item.name}</span>
                    <ArrowIcon active={isOpen} />
                  </Flex>
                </MenuItem>
              </Block>
            )}
            renderOption={item => (
              <OptionBlock activeClassName="active" key={item.link} to={item.link}>
                {item.name}
              </OptionBlock>
            )}
          />
        ) : (
          <Block key={item.name}>
            <MenuLinkItem activeClassName="active" key={item.link} to={item.link}>
              {item.name}
            </MenuLinkItem>
          </Block>
        )
      )(menu)}
    </Menu>
  );
};

export const DesktopUser = ({ user, onLoginClick, onSignupClick }) => {
  const history = useHistory();
  const [selectedItem, setSelectedItem] = useState(null);
  const location = useLocation();
  const handleHeartClick = useCallback(() => history.push('/願望清單'), [history]);
  const onChange = useCallback(
    option => {
      if (option.link) {
        history.push(option.link);
      }
      setSelectedItem(option);
      clickUserOption(option);
    },
    [history]
  );

  useEffect(() => {
    if (!R.includes(location.pathname, R.map(R.prop('link'), userOptions))) {
      setSelectedItem(null);
    }
  }, [location.pathname]);

  return user ? (
    <Menu>
      <UserBlock onClick={handleHeartClick}>
        <Icon name="heart" />
      </UserBlock>
      <Dropdown
        itemToString={R.identity}
        onChange={onChange}
        selectedItem={selectedItem}
        options={userOptions}
        placement="bottomRight"
        render={({ getInputProps, toggleMenu, isOpen }) => (
          <UserBlock onClick={toggleMenu} {...getInputProps()}>
            <MenuItem active={isOpen}>{user.name}</MenuItem>
          </UserBlock>
        )}
      />
    </Menu>
  ) : (
    <>
      <Menu>
        <UserBlock>
          <MenuItem onClick={onSignupClick}>註冊</MenuItem>
        </UserBlock>
        <UserBlock>
          <MenuItem onClick={onLoginClick}>登入</MenuItem>
        </UserBlock>
      </Menu>
    </>
  );
};
