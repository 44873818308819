import React from 'react';
import { DndProvider as ReactDnDProvider } from 'react-dnd';
import MultiBackend from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch';

const DndProvider = ({ children }) => (
  <ReactDnDProvider backend={MultiBackend} options={HTML5toTouch}>
    {children}
  </ReactDnDProvider>
);

export default DndProvider;
