import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { vontravelTheme } from '@cowsquare/design';
import { normalize } from 'polished';
import App from './views/App';
import { client } from './apollo/client';
import * as serviceWorker from './serviceWorker';
import { themeGet } from '@styled-system/theme-get';
import { CookiesProvider } from "react-cookie";

const GlobalStyle = createGlobalStyle`
  ${normalize()}

  * {
    box-sizing: border-box;
    font-family: ${themeGet('fonts.chinese')}, ${themeGet('fonts.english')};
  }
`;

ReactDOM.render(
  <ApolloProvider client={client}>
    <ThemeProvider theme={vontravelTheme}>
      <CookiesProvider>
        <>
          <App />
          <GlobalStyle />
        </>
      </CookiesProvider>
    </ThemeProvider>
  </ApolloProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
