import { Box, Dropdown, Icon } from '@cowsquare/design';
import { themeGet } from '@styled-system/theme-get';
import * as R from 'ramda';
import React, { useCallback } from 'react';
import { NavLink, useHistory, Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { clickUserOption, userOptions } from './userOptions';
import Downshift from 'downshift';

const ArrowIcon = styled(Icon).attrs({
  name: 'ARROW_DOWN',
})`
  transition: transform 300ms;
  transform: ${props => (props.active ? 'rotate(180deg)' : 'rotate(0deg)')};

  ${({ active }) =>
    active &&
    css`
      color: ${themeGet('dropdown.option.hover.color')};
    `}
`;

const Menu = styled.div`
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
`;

const MobileMenuItem = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${themeGet('colors.blacks.4')};
  font-size: 14px;
  font-weight: 500;
  padding: 18px 24px;
  cursor: pointer;

  &:active {
    color: ${themeGet('dropdown.option.hover.color')};
    background-color: ${themeGet('dropdown.option.hover.backgroundColor')};
  }

  ${({ active }) =>
    active &&
    css`
      color: ${themeGet('dropdown.option.hover.color')};
    `}
`;

const MobileMenuLinkItem = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${themeGet('colors.blacks.4')};
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  padding: 18px 24px;
  cursor: pointer;

  &.active,
  &:active {
    color: ${themeGet('dropdown.option.hover.color')};
    background-color: ${themeGet('dropdown.option.hover.backgroundColor')};
  }

  ${({ active }) =>
    active &&
    css`
      color: ${themeGet('dropdown.option.hover.color')};
      background-color: ${themeGet('dropdown.option.hover.backgroundColor')};
    `}
`;

const SubMenuItem = styled(MobileMenuItem)`
  width: 100%;
  background-color: white;
  padding: 18px 48px;
  text-decoration: none;
  border-bottom: ${themeGet('borders.1')} ${themeGet('colors.blacks.10')};
`;

const stateReducer = (state, changes) => {
  switch (changes.type) {
    // do not close at touch end
    case Downshift.stateChangeTypes.touchEnd:
      return {
        ...changes,
        isOpen: state.isOpen,
        highlightedIndex: state.highlightedIndex,
      };
    default:
      return changes;
  }
};

export const MobileMenu = ({ menu = [], closeMenu = R.identity }) => {
  const history = useHistory();
  const handleHeaderNavLinkClicked = useCallback(
    ({ link }) => {
      history.push(link);
    },
    [history]
  );

  return (
    <Menu>
      {R.map(item =>
        item.children ? (
          <Dropdown
            key={item.name}
            itemToString={R.prop('name')}
            onChange={handleHeaderNavLinkClicked}
            options={item.children}
            stateReducer={stateReducer}
            menuWrapperStyle={{
              position: 'static',
              width: '100%',
              p: 0,
              boxShadow: 'none',
            }}
            disablePortal
            disabledPopper
            render={({ toggleMenu, getInputProps, isOpen }) => (
              <MobileMenuItem onClick={toggleMenu} {...getInputProps()} active={isOpen}>
                <span>{item.name}</span>
                <ArrowIcon active={isOpen} />
              </MobileMenuItem>
            )}
            renderOption={item => (
              <div key={item.link} >
                <SubMenuItem onClick={closeMenu} as={Link} to={item.link}>
                  {item.name}
                </SubMenuItem>
              </div>
            )}
          />
        ) : (
          <MobileMenuLinkItem activeClassName="active" key={item.link} to={item.link} onClick={closeMenu}>
            {item.name}
          </MobileMenuLinkItem>
        )
      )(menu)}
    </Menu>
  );
};

export const MobileUser = ({ user, closeMenu, onLoginClick, onSignupClick }) =>
  user ? (
    <>
      <MobileMenuItem active>{user.name}</MobileMenuItem>
      {R.map(
        option =>
          option.link ? (
            <MobileMenuLinkItem
              activeClassName="active"
              key={option.link}
              to={option.link}
              onClick={closeMenu}
            >
              {option.name}
            </MobileMenuLinkItem>
          ) : (
            <MobileMenuItem
              key={option.value}
              onClick={() => {
                closeMenu();
                clickUserOption(option);
              }}
            >
              {option.name}
            </MobileMenuItem>
          ),
        userOptions
      )}
    </>
  ) : (
    <>
      <MobileMenuItem onClick={onSignupClick}>註冊</MobileMenuItem>
      <MobileMenuItem onClick={onLoginClick}>登入</MobileMenuItem>
    </>
  );
