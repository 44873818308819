import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Box } from '@cowsquare/design';
import { usePlanContext } from '../PlanContext';

const DRAGGABLE_CARD = Symbol('DRAGGABLE_CARD');

const DragDropCardBox = ({ children, cardId, date, index, draggable, ...rest }) => {
  const { canAddCardToDate, moveCard, getPlanByDate } = usePlanContext();
  const ref = useRef(null);
  const [, drag] = useDrag({
    item: { type: DRAGGABLE_CARD, index, date, cardId },
    canDrag: () => draggable,
  });

  const [, drop] = useDrop({
    accept: DRAGGABLE_CARD,
    canDrop: item => {
      const { cardType, days } = getPlanByDate(item.date)[item.index];
      return canAddCardToDate(date, cardType, days);
    },
    hover: (item, monitor) => {
      // Do nothing when the item is hovering on itself
      if (item.cardId === cardId || !monitor.canDrop()) {
        return;
      }

      moveCard({
        fromIndex: item.index,
        fromDate: item.date,
        toIndex: index,
        toDate: date,
      });

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = index;
      item.date = date;
    },
  });
  drag(drop(ref));

  return (
    <Box ref={ref} {...rest}>
      {children}
    </Box>
  );
};

export default DragDropCardBox;
