import React, { useCallback, useEffect } from 'react';
import * as R from 'ramda';
import * as D from 'date-fns/fp';
import { zhTW } from 'date-fns/locale';
import { useMachine } from '@xstate/react';
import { useQueryParams, NumberParam, StringParam, DateParam } from 'use-query-params';
import styled from 'styled-components';
import {
  Box,
  Flex,
  Checkbox,
  Button,
  Modal,
  ModalBody,
  Text,
  ModalFooter,
  Absolute,
  Notification,
} from '@cowsquare/design';
import TourInfoBar from '../components/TourInfoBar';
import PlanTourDrawer from '../components/planTourDrawer/PlanTourDrawer';
import { CARD_TYPE } from '../components/planTourDrawer/constants/CARD_TYPE';
import { usePlanContext } from '../components/PlanContext';
import { getHTMLString } from '../../utils/getHTMLString';
import PlanCard from '../components/planTourDrawer/PlanCard';
import DragDropCardBox from '../components/planTourDrawer/DragDropCardBox';
import DndProvider from '../components/planTourDrawer/DnDProvider';
import { useUser, useMemberForm } from '../../utils/hooks';
import RouteLeavingPrompt from '../components/RouteLeavingPrompt';
import { FORM_STATUS } from '../../constants/form';
import { drawerMachine, FP_DRAWER_EVENTS } from 'views/components/planTourDrawer/machines/drawerMachine';

/**
 * top = Header + TourInfoBar height
 * - mobile: 112px = 56px + 56px
 * - desktop: 124px  = 56px + 68px
 * ---
 * bottom = TourInfoBar's ActionButtonWrap height
 * - mobile: 64px
 * - desktop: 0
 **/
const OutterWrap = styled(Absolute).attrs({
  p: 5,
  top: ['112px', '124px'],
  bottom: [7, 0],
})`
  display: flex;
  width: 100vw;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
`;

const Column = styled(Box)`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 272px;
  flex-shrink: 0;
  flex-grow: 0;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  margin-right: 8px;
  &:last-child {
    margin-right: 0;
  }
  padding: 16px 16px 0 16px;
`;

const DayLabel = styled(Box)`
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
`;

const DateLabel = styled(DayLabel)`
  opacity: 0.3;
  margin-left: 8px;
`;

const FooterWrap = styled.div`
  position: absolute;
  bottom: 0;
  margin-left: -16px;
  width: 100%;
  padding: 8px;
  z-index: 1;
`;

const CardsInnerWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
`;
const CardsOutterWrap = styled.div`
  flex: 1;
  margin-bottom: 56px;
  padding-bottom: 16px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

const queryParamsConfig = {
  country: StringParam,
  startDate: DateParam,
  endDate: DateParam,
  infants: NumberParam,
  children: NumberParam,
  teenagers: NumberParam,
  adults: NumberParam,
  elders: NumberParam,
};

const PlanTour = () => {
  const [query, setQuery] = useQueryParams(queryParamsConfig);
  const [, send, service] = useMachine(drawerMachine);
  const {
    country,
    startDate,
    endDate,
    infants = 0,
    children = 0,
    teenagers = 0,
    adults = 0,
    elders = 0,
  } = query;
  const {
    isEditing,
    setIsEditing,
    setPlans,
    setGuides,
    getAllPlans,
    getPlanByDate,
    setSelectedDate,
    removeCard,
    removeAllCards,
    getGuideByDate,
    toggleGuideByDate,
    setDataFromLocation,
  } = usePlanContext();
  const { user } = useUser();
  const { openLoginForm, formStatus } = useMemberForm();
  useEffect(() => {
    setDataFromLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // 開啟 login form 的時候將 isEditing 設為 false，route 轉換時不跳出 prompt
    if (formStatus !== FORM_STATUS.OFF) {
      setIsEditing(false);
    } else {
      setIsEditing(true);
    }
  }, [formStatus, setIsEditing]);

  const plans = getAllPlans();
  const handleChange = useCallback(
    values => {
      const days = D.eachDayOfInterval({ start: values.startDate, end: values.endDate });
      const formatedDays = R.map(D.format('yyyyMMdd'), days);
      setPlans(R.pick(formatedDays));
      setGuides(R.pick(formatedDays));
      if (values.country !== country) {
        removeAllCards();
      }
      setQuery(values);
    },
    [country, removeAllCards, setQuery, setPlans, setGuides]
  );

  const handleValuationDrawerOpen = useCallback(() => {
    if (!user) {
      Notification.warning({ message: '請先登入或註冊！' });
      openLoginForm();
    } else {
      send(FP_DRAWER_EVENTS.OPEN_VALUATION);
    }
  }, [user, openLoginForm, send]);

  const days = D.eachDayOfInterval({ start: startDate, end: endDate });

  return (
    <DndProvider>
      <TourInfoBar
        plans={plans}
        onChange={handleChange}
        onSend={handleValuationDrawerOpen}
        {...{
          country,
          startDate,
          endDate,
          infants,
          children,
          teenagers,
          adults,
          elders,
        }}
      />
      <OutterWrap>
        {days.map((date, i) => {
          const plan = getPlanByDate(date);
          const hasTourCard = R.compose(
            R.complement(R.isNil),
            R.find(({ cardType, hasGuide }) => cardType === CARD_TYPE.TOUR && hasGuide)
          )(plan);

          return (
            <Column key={D.format('yyyyMMdd', date)}>
              <Flex justifyContent="space-between">
                <Flex>
                  <DayLabel>Day {i + 1}</DayLabel>
                  <DateLabel>{`${D.format('MM/dd', date)} (${D.formatWithOptions(
                    { locale: zhTW },
                    'EEEEE',
                    date
                  )})`}</DateLabel>
                </Flex>
                <Flex>
                  <Checkbox
                    disabled={hasTourCard}
                    checked={getGuideByDate(date)}
                    onChange={() => toggleGuideByDate(date)}
                  >
                    需要導遊
                  </Checkbox>
                </Flex>
              </Flex>
              <CardsOutterWrap>
                <CardsInnerWrap>
                  {plan.map((item, index) => (
                    <DragDropCardBox
                      key={item.cardId}
                      cardId={item.cardId}
                      date={date}
                      index={index}
                      draggable={item.cardType !== CARD_TYPE.TOUR}
                      mt="16px"
                      width="100%"
                    >
                      <PlanCard
                        coverUrl={R.pathOr('', ['cover', 'link'], item)}
                        title={item.name}
                        description={R.pipe(R.pathOr('', ['description', 'content']), getHTMLString)(item)}
                        onClick={() => {
                          send({
                            type:
                              item.cardType === CARD_TYPE.ATTRACTION
                                ? FP_DRAWER_EVENTS.OPEN_ATTRACTION_CONTENT
                                : FP_DRAWER_EVENTS.OPEN_ATTRACTION_CONTENT,
                            data: item.name,
                          });
                        }}
                        onRemove={() => removeCard(item.cardId, item.cardType)}
                      />
                    </DragDropCardBox>
                  ))}
                  {/* A placehloder for dropping cards in the empty column */}
                  {plan.length === 0 && (
                    <DragDropCardBox draggable={false} date={date} index={0} width="100%" flex={1} />
                  )}
                </CardsInnerWrap>
              </CardsOutterWrap>
              <FooterWrap>
                <Flex mb="16px" width="100%" justifyContent="space-around">
                  <Button
                    size="large"
                    variant="primary"
                    onClick={() => {
                      send(FP_DRAWER_EVENTS.OPEN_LIST);
                      setSelectedDate(date);
                    }}
                  >
                    加入行程 / 景點
                  </Button>
                </Flex>
              </FooterWrap>
            </Column>
          );
        })}
      </OutterWrap>
      <PlanTourDrawer
        drawerService={service}
        country={country}
        formValue={{
          country,
          startDate,
          endDate,
          infants,
          children,
          teenagers,
          adults,
          elders,
        }}
      />
      <RouteLeavingPrompt when={isEditing}>
        {({ onConfirm, onCancel, show }) => (
          <Modal show={show} onClose={onCancel} closeButton>
            <ModalBody>
              <Text mb={5} fontWeight={500} fontSize="16px">
                離開自助設計優遊頁面？
              </Text>
              <Text color="blacks.3">離開後將要重新設計行程，您是否決定現在離開？</Text>
            </ModalBody>
            <ModalFooter>
              <Box mr={4}>
                <Button onClick={onConfirm}>現在離開</Button>
              </Box>
              <Button variant="primary" onClick={onCancel}>
                繼續設計行程
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </RouteLeavingPrompt>
    </DndProvider>
  );
};

export default PlanTour;
