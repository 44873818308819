import _taggedTemplateLiteral from "/opt/build/repo/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  font-size: ", ";\n  color: ", ";\n  background-color: ", ";\n  max-width: ", ";\n  border: ", ";\n  border-radius: ", ";\n  display: inline-block;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  height: 24px;\n  line-height: 24px;\n  padding: 0 8px;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
export var StatusLabel = styled.span(_templateObject(), themeGet('statusLabel.fontSize'), themeGet('statusLabel.color'), function (props) {
  return themeGet("statusLabel.types.".concat(props.type))(props);
}, themeGet('statusLabel.maxWidth'), themeGet('statusLabel.border'), themeGet('statusLabel.borderRadius'));
StatusLabel.defaultProps = {
  type: 'info'
};