import { Box, Flex, Image, Text, Heading } from '@cowsquare/design';
import React from 'react';
import styled from 'styled-components';
import ErrorIcon from '../../images/icon-error-web.svg';
import { useParams } from 'react-router-dom';

const CardBox = styled(Box).attrs({
  my: ['100px', '150px'],
})``;

const ErrorPage = () => {
  const { status = '404' } = useParams();
  return (
    <Flex alignItems="center" justifyContent="center">
      <CardBox>
        <Flex flexDirection="column" alignItems="center" justifyContent="center">
          <Image mb={5} src={ErrorIcon} width="160px" height="160px" alt="error_icon" />
          <Heading color="primaryDark" fontWeight={600} mb={4}>
            {status} Error
          </Heading>
          <Text fontSize="16px" color="primaryDark">
            Internal Server Error.
          </Text>
        </Flex>
      </CardBox>
    </Flex>
  );
};

export default ErrorPage;
