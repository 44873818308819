import * as R from 'ramda';

export const getBedAmount = R.pipe(
  R.evolve({ bunk_bed: R.multiply(2) }),
  R.props(['double_bed', 'single_bed', 'bunk_bed']),
  R.values,
  R.sum
);

const average = R.converge(R.divide, [R.sum, R.length]);
const round = num => Math.round((num + Number.EPSILON) * 100) / 100 || 0;
export const getRate = R.pipe(R.propOr([], 'reviews'), R.pluck('rate'), average, round);
export const getStaffRate = R.pipe(R.propOr([], 'reviews'), R.pluck('staff'), average, round);
export const getCleanlinessRate = R.pipe(R.propOr([], 'reviews'), R.pluck('cleanliness'), average, round);
