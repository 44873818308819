import _defineProperty from "/opt/build/repo/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _cardVariantToFixedSt;

import { CARD_VARIANT, HEADER_VARIANT } from '../constants';
/**
 * @param {'vertical' | 'horizontal'} cardVariant
 * @param {boolean} hasCover
 */

export var getHeaderVariant = function getHeaderVariant(cardVariant, hasCover) {
  return hasCover ? cardVariant === CARD_VARIANT.VERTICAL ? HEADER_VARIANT.FLOAT : Array.isArray(cardVariant) ? cardVariant.map(function (v) {
    return v === CARD_VARIANT.VERTICAL ? HEADER_VARIANT.FLOAT : HEADER_VARIANT.STATIC;
  }) : HEADER_VARIANT.STATIC : HEADER_VARIANT.STATIC;
};
var cardVariantToFixedStyleMap = (_cardVariantToFixedSt = {}, _defineProperty(_cardVariantToFixedSt, CARD_VARIANT.VERTICAL, 'height'), _defineProperty(_cardVariantToFixedSt, CARD_VARIANT.HORIZONTAL, 'width'), _cardVariantToFixedSt);
/**
 * @param {'vertical' | 'horizontal'} cardVariant
 * @param {string} fixedCoverSize
 */

export var computeFixedCoverStyle = function computeFixedCoverStyle(cardVariant, fixedCoverSize) {
  return fixedCoverSize ? Array.isArray(cardVariant) ? {
    width: cardVariant.map(function (v) {
      return cardVariantToFixedStyleMap[v] === 'width' ? fixedCoverSize : '100%';
    }),
    height: cardVariant.map(function (v) {
      return cardVariantToFixedStyleMap[v] === 'height' ? fixedCoverSize : 'auto';
    })
  } : _defineProperty({}, cardVariantToFixedStyleMap[cardVariant], fixedCoverSize) : {};
};