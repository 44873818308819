import { Section } from './Section';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { themeGet } from '@styled-system/theme-get';
import { Box, Flex, Icon } from '@cowsquare/design';
import React from 'react';
import { layout } from 'styled-system';
import AddToPlanIcon from '../../images/icon_add_to_plan.svg';
import PickTourIcon from '../../images/icon_pick_tour.svg';
import TourPriceIcon from '../../images/icon_tour_price.svg';

const Title = styled(Box).attrs({ as: 'h1', mt: 0 })`
  text-align: center;
  font-size: ${themeGet('fontSizes.h1')};
  line-height: 1.5;
  font-weight: 500;
`;

const SubTitle = styled(Box).attrs({ as: 'h4', m: 0 })`
  text-align: center;
  font-size: ${themeGet('fontSizes.h4')};
  line-height: 1.5;
  font-weight: normal;
`;

const StyledLink = styled(Link)`
  font-size: 16px;
  line-height: 1.5;
  color: ${themeGet('colors.link')};
  text-decoration: none;
`;

const StyledIcon = styled(Icon).attrs({
  display: ['none', 'inline-block'],
})`
    ${layout}
    color: ${themeGet('colors.primary')};
  `;

const StepWrapper = styled(Flex).attrs({
  width: 1,
  py: ['40px', 0],
  mt: '48px',
  height: ['728px', '296px'],
  alignItems: 'center',
  justifyContent: 'center',
})`
  background-color: ${themeGet('colors.whites.0')};
`;

const StepFlex = styled(Flex).attrs({
  width: 1,
  flexDirection: ['column', 'row'],
  alignItems: 'center',
  justifyContent: ['space-between', 'flex-start'],
})`
  background-color: ${themeGet('colors.whites.0')};
`;

const StepTitle = styled(Box).attrs({ as: 'p', m: 0 })`
  font-size: ${themeGet('fontSizes.h4')};
  font-weight: 500;
`;

const StepIcon = styled(Box).attrs({
  as: 'img',
  mb: 5,
})``;

const StepContent = styled(Flex).attrs({
  width: [1, 1 / 3],
  flexDirection: 'column',
  alignItems: 'center',
})``;

const Step = ({ icon, title }) => (
  <StepContent>
    <StepIcon src={icon} />
    <StepTitle>{title}</StepTitle>
  </StepContent>
);

export const FreePlannerIntroSection = () => (
  <>
    <Section mx="auto" px={[5, 0]}>
      <Box maxWidth="680px" mx="auto">
        <Title>自助設計優遊 即時估算旅費</Title>
        <SubTitle>
          旅遊要自主自在，同時希望自己參與策劃行程，我們的「
          <StyledLink to="/自助設計優遊">自助設計優遊</StyledLink>
          」工具讓您輕鬆親自設計專屬行程，然後餘下的細節由我們為您代勞，隨心意和興趣啟航。
        </SubTitle>
      </Box>
    </Section>
    <StepWrapper>
      <Section height="100%">
        <StepFlex>
          <Step icon={PickTourIcon} title="挑選喜歡的行程或地點" />
          <StyledIcon name="ARROW" />
          <Step icon={AddToPlanIcon} title="加入旅遊計劃" />
          <StyledIcon name="ARROW" />
          <Step icon={TourPriceIcon} title="自動估算旅費" />
        </StepFlex>
      </Section>
    </StepWrapper>
  </>
);

export default FreePlannerIntroSection;
