import React, { useState, useCallback } from 'react';
import { Box, Heading, Flex } from '@cowsquare/design';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { background } from 'styled-system';
import { cover } from 'polished';

const Mask = styled(Flex).attrs({
  justifyContent: 'center',
  alignItems: 'center',
})`
  ${cover()}
  border-radius: 8px;
  background-color: ${themeGet('colors.blacks.9')};
`;

const Content = styled(Box).attrs({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: ['120px', '200px'],
})`
  position: relative;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.05);
  text-align: center;
  text-decoration: none;
  background-color: ${themeGet('colors.primary')};
  background-size: cover;
  ${background}

  ${props =>
    props.showMask &&
    css`
      @media (hover: hover) {
        &:hover {
          ${Mask} {
            background-color: rgba(0, 128, 200, 0.5);
          }
        }
      }
      @media (hover: none) {
        &:active {
          ${Mask} {
            background-color: rgba(0, 128, 200, 0.5);
          }
        }
      }
    `}
`;

export const EasyCard = ({ title, img, showMask, maskText, ...rest }) => {
  const [text, setText] = useState(title);
  const changeTextToTitle = useCallback(() => {
    if (showMask) {
      setText(title);
    }
  }, [showMask, title]);
  const changeTextToMaskText = useCallback(() => {
    if (showMask) {
      setText(maskText);
    }
  }, [maskText, showMask]);

  return (
    <Content
      backgroundImage={`url(${img})`}
      showMask={showMask}
      onMouseEnter={changeTextToMaskText}
      onMouseLeave={changeTextToTitle}
      onTouchStart={changeTextToMaskText}
      onTouchEnd={changeTextToTitle}
      {...rest}
    >
      <Mask>
        <Heading fontSize="24px" color="whites.0">
          {text}
        </Heading>
      </Mask>
    </Content>
  );
};
