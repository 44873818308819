import React from 'react';
import * as R from 'ramda';
import { Carousel } from '@cowsquare/design';

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

export const Banners = ({ banners = EMPTY_ARRAY, itemProps = EMPTY_OBJECT }) => (
  <Carousel>
    {R.isEmpty(banners) ? (
      <Carousel.Slide {...itemProps} />
    ) : (
      R.pipe(
        R.map(banner => (
          <Carousel.Slide
            key={banner.id}
            href={banner.link}
            title={banner.name}
            description={banner.description}
            backgroundImage={`url(${R.pathOr('', ['file', 'link'])(banner)})`}
            {...itemProps}
          />
        ))
      )(banners)
    )}
  </Carousel>
);
