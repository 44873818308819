import _taggedTemplateLiteral from "/opt/build/repo/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  position: absolute;\n  ", "\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  box-sizing: border-box;\n  ", "\n  ", "\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import styled from 'styled-components';
import { flexbox, space, layout, position } from 'styled-system';
export var Box = styled.div(_templateObject(), space, layout, flexbox);
export var Flex = styled(Box).attrs({
  display: 'flex'
})(_templateObject2());
export var Absolute = styled(Box)(_templateObject3(), position);