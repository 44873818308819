import gql from 'graphql-tag';

export const GET_BANNERS = gql`
  query getBanners($name: String!) {
    banners: vontravel_pages(where: { name: { _eq: $name } }) {
      banners(order_by: [{ priority: asc }]) {
        id
        name
        description
        file {
          link
        }
      }
    }
  }
`;

export const GET_FEATURE_BANNER = gql`
  query getFeatureBanner($name: String!) {
    banner: vontravel_features(where: { name: { _eq: $name } }) {
      banner {
        link
      }
    }
  }
`;
