import { Machine, assign } from 'xstate';
import gql from 'graphql-tag';
import { client } from 'apollo/client';

const STATES = {
  LOADING: 'LOADING',
  UNKNOWN: 'UNKNOWN',
  LOADED: 'LOADED',
  ERROR: 'ERROR',
  EMPTY: 'EMPTY',
};

const EVENTS = {
  PAGE_CHANGE: 'PAGE_CHANGE',
  REFETCH: 'REFETCH',
};

const GET_TOUR_WISH_LIST = gql`
  query getTourWishList($userId: uuid!, $country: String!, $feature: String, $limit: Int, $offset: Int) {
    list: vontravel_users_tours_bridge(
      limit: $limit
      offset: $offset
      order_by: { created_at: desc }
      where: {
        user_id: { _eq: $userId }
        tour: { country: { name: { _eq: $country } }, features: { feature: { name: { _eq: $feature } } } }
      }
    ) {
      item: tour {
        id
        name
        price
        days
        isCharter
        cover {
          link
        }
        content {
          content
        }
        description {
          content
        }
      }
    }
    meta: vontravel_users_tours_bridge_aggregate(
      where: {
        user_id: { _eq: $userId }
        tour: { country: { name: { _eq: $country } }, features: { feature: { name: { _eq: $feature } } } }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

const favoriteTourMachine = Machine(
  {
    context: {
      data: [],
      page: 1,
      pageSize: 12,
      country: null,
      feature: null,
      userId: null,
      area: null,
    },
    initial: STATES.LOADING,
    states: {
      [STATES.LOADING]: {
        invoke: {
          src: ({ page, pageSize, country, feature, userId }) =>
            client.query({
              query: GET_TOUR_WISH_LIST,
              variables: {
                limit: pageSize,
                offset: (page - 1) * pageSize,
                userId,
                feature,
                country,
              },
            }),
          onDone: {
            target: STATES.UNKNOWN,
            actions: 'assignData',
          },
          onError: STATES.ERROR,
        },
      },
      [STATES.UNKNOWN]: {
        on: {
          '': [
            {
              target: STATES.EMPTY,
              cond: 'isEmpty',
            },
            {
              target: STATES.LOADED,
            },
          ],
        },
      },
      [STATES.LOADED]: {
        on: {
          [EVENTS.PAGE_CHANGE]: {
            target: STATES.LOADING,
            actions: 'changePage',
          },
        },
      },
      [STATES.EMPTY]: {},
      [STATES.ERROR]: {},
    },
    on: {
      [EVENTS.REFETCH]: {
        target: STATES.LOADING,
        actions: 'refetch',
      },
    },
  },
  {
    actions: {
      refetch: () => {},
      changePage: assign({
        page: (_, event) => event.page,
      }),
      assignData: assign({
        data: (context, event) => event.data.data,
      }),
    },
    guards: {
      isEmpty: context => context.data.length === 0,
    },
  }
);

export { favoriteTourMachine, STATES as FAVORITE_TOUR_STATES, EVENTS as FAVORITE_TOUR_EVENTS };
