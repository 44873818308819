import React, { useMemo } from 'react';
import { Box, Flex } from '@cowsquare/design';
import * as R from 'ramda';
import * as D from 'date-fns/fp';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import queryString from 'query-string';
import CreateTourForm from '../components/CreateTourForm';
import { StringParam, useQueryParams } from 'use-query-params';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { PLANNER_LOCAL_DATA_KEY } from '../../constants/planner';
import { sum } from 'utils/sum';

const formatDate = D.format('yyyy-MM-dd');

const GET_TOUR_RECORD = gql`
  query getTourRecord($country: String!, $name: String!) {
    record: vontravel_tours(where: { name: { _eq: $name }, country: { name: { _eq: $country } } }) {
      id
      name
      cover {
        link
      }
      description {
        content
      }
      days
      price
    }
  }
`;

const GET_ATTRACTIONS_CONTENT = gql`
  query getAttractionsContent($name: String!, $country: String!) {
    record: vontravel_attractions(
      where: { name: { _eq: $name }, area: { country: { name: { _eq: $country } } } }
    ) {
      id
      name
      cover {
        link
      }
      description {
        content
      }
    }
  }
`;

const Title = styled(Box).attrs({ as: 'h1', m: 0, mt: 6, mb: 8 })`
  font-size: ${themeGet('fontSizes.h1')};
  line-height: 1.5;
  font-weight: 500;
`;

const SubTitle = styled(Box).attrs({ as: 'h4', m: 0, mb: 32 })`
  font-size: ${themeGet('fontSizes.h4')};
  line-height: 1.5;
  font-weight: normal;
`;

const ActionBox = styled(Box).attrs({
  width: '100%',
  maxWidth: '904px',
  mb: '64px',
  px: ['16px', '24px'],
  pt: ['16px', '24px'],
  pb: '24px',
})`
  box-shadow: ${themeGet('card.boxShadow')};
  background-color: ${themeGet('card.backgroundColor')};
  border: ${themeGet('card.border')};
  border-radius: ${themeGet('card.borderRadius')};
  border-color: ${themeGet('card.borderColor')};
`;

const CreateTour = () => {
  const history = useHistory();
  const [{ country, cardType = '', cardName }] = useQueryParams({
    country: StringParam,
    cardType: StringParam,
    cardName: StringParam,
  });

  const { data } = useQuery(
    cardType.toLocaleLowerCase() === 'tour' ? GET_TOUR_RECORD : GET_ATTRACTIONS_CONTENT,
    {
      skip: !(country && cardType && cardName),
      variables: {
        country,
        name: cardName,
      },
    }
  );
  const card = R.path(['record', 0], data);
  const priceT = card ? card.price: 0;
  const nameT = card ? card.name: '';
  const initialValues = useMemo(
    () => ({
      startDate: new Date(),
      endDate: new Date(),
      infants: 0,
      children: 0,
      teenagers: 0,
      adults: 0,
      elders: 0,
      country,
      cardType,
      cardName,
    }),
    [country, cardName, cardType]
  );
  const handleSubmit = ({
    country,
    startDate,
    endDate,
    infants = 0,
    children = 0,
    teenagers = 0,
    adults = 0,
    elders = 0,
  }) => {
    if (sum([infants, children, teenagers, adults, elders]) === 0) {
      return {
        infants: '請輸入旅行人數；至少要有一名成年人同行',
        children: true,
        teenagers: true,
        adults: true,
        elders: true,
      };
    }
    if (sum([adults, elders]) === 0) {
      return {
        adults: '至少要有一名成年人同行',
        elders: true,
      };
    }
    const query = queryString.stringify({
      country,
      infants,
      children,
      teenagers,
      adults,
      elders,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      cardType,
      cardName,
    });
    localStorage.removeItem(PLANNER_LOCAL_DATA_KEY);
    history.push(`/自助旅遊計畫?${query}`);
  };
  return (
    <Flex px={16} alignItems="center" flexDirection="column">
      <Title>建立自己的旅行計劃</Title>
      <SubTitle> 隨心所欲的旅程從現在開始</SubTitle>

      <ActionBox>
        <CreateTourForm
          initialValues={initialValues}
          price={priceT}
          nameT={nameT}
          onSubmit={handleSubmit}
          minDuration={card && card.days ? card.days : 0}
        />
      </ActionBox>
    </Flex>
  );
};

export default CreateTour;
