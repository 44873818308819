import * as R from 'ramda';
import { WISH_LIST, MY_ACCOUNT, LOGOUT } from '../../../constants/menu';
import { AUTH_TOKEN } from '../../../constants';

export const userOptions = [
  {
    name: '個人資料',
    link: '/個人資料',
    value: MY_ACCOUNT,
  },
  {
    name: '願望清單',
    link: '/願望清單',
    value: WISH_LIST,
  },
  {
    name: '登出',
    value: LOGOUT,
  },
];

const logout = () => {
  localStorage.removeItem(AUTH_TOKEN);
  window.location.assign('/');
};

export const clickUserOption = option => {
  switch (R.prop('value', option)) {
    case MY_ACCOUNT:
      return;
    case WISH_LIST:
      return;
    case LOGOUT:
      logout();
      return;
    default:
      return;
  }
};
