import React from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { Box, Button } from '@cowsquare/design';
import { themeGet } from '@styled-system/theme-get';
import { layout } from 'styled-system';
import { Section } from './Section';

const ActionBar = styled(Box)`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 64px;
  background-color: ${themeGet('colors.whites.0')};
  box-shadow: 0 -2px 10px 0 ${themeGet('colors.blacks.10')};
  z-index: ${themeGet('zIndex.fixed')};
`;

const PlanLink = styled(Link)`
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  color: ${themeGet('link.color')};
  ${layout}
`;

const AddToFreePlannerBar = ({ name, country, type }) => {
  const history = useHistory();
  return (
    <ActionBar>
      <Section justifyContent={['center', 'space-between']} alignItems="center" margin="0 auto" height="100%">
        <PlanLink display={['none', 'block']} to="/自助設計優遊">
          {`把「${name}」加入「自助設計優遊」吧！`}
        </PlanLink>
        <Button
          onClick={e => {
            e.preventDefault();
            history.push(`/自助設計優遊/新計劃?cardType=${type}&cardName=${name}&country=${country}`);
          }}
          variant="primary"
          size="large"
        >
          加入旅遊計劃
        </Button>
      </Section>
    </ActionBar>
  );
};

export default AddToFreePlannerBar;
