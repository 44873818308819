import React from 'react';
import * as R from 'ramda';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { Flex, Box, Icon } from '@cowsquare/design';
import { FOOTER_SERVICE, FOOTER_MORE, FOOTER_OTHER } from '../../../constants/footer';
import Logo from './Logo';

const Wrapper = styled(Box)`
  background-color: ${themeGet('colors.primary')};
`;

const FooterIcon = styled(Icon).attrs({
  color: 'whites.3',
})`
  &:hover,
  &:active {
    color: ${themeGet('colors.whites.0')};
  }
`;

const FooterInfos = styled(Box).attrs({
  mb: ['48px', 0],
})`
  width: 240px;
  color: ${themeGet('colors.whites.0')};
  font-size: 12px;

  ${FooterIcon} {
    margin-right: ${themeGet('space.4')}px;
    cursor: pointer;
  }

  ${FooterIcon} &:last-child {
    margin-right: 0;
  }
`;

const FooterInfo = styled(Flex).attrs({
  justifyContent: ['center', 'flex-start'],
})``;

const FooterList = styled(Box).attrs({
  as: 'ul',
  mb: ['48px', 0],
})`
  width: 152px;
  color: ${themeGet('colors.whites.5')};
  font-size: 12px;
  list-style: none;
  padding-left: 0;
  margin-top: 0;

  &:last-child {
    margin-bottom: 0;
  }
`;

const FooterLink = styled(Link)`
  color: ${themeGet('colors.whites.5')};
  font-size: 12px;
  text-decoration: none;

  &:hover,
  &:active {
    color: ${themeGet('colors.whites.0')};
  }
`;

const FooterItem = styled(Flex).attrs({
  as: 'li',
  mr: [0, 6],
  height: '24px',
  justifyContent: ['center', 'flex-start'],
  alignItems: ['center', 'flex-start'],
})`
  &:last-child {
    margin-right: 0;
  }
`;

const FooterTitle = styled(FooterItem)`
  margin-bottom: ${themeGet('space.4')}px;
  color: ${themeGet('colors.whites.0')};
  font-size: ${themeGet('fontSizes.h5')};
`;

const Copyright = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  font-size: ${themeGet('fontSizes.text')};
  color: ${themeGet('colors.whites.0')};
  background-color: ${themeGet('colors.blacks.5')};
`;

const Footer = () => {
  return (
    <Wrapper>
      <Box py={7}>
        <Flex flexDirection={['column', 'row']} justifyContent="center" alignItems={['center', 'flex-start']}>
          <FooterInfos>
            <FooterInfo>
              <Box mb={50}>
                <Link to="/">
                  <Logo />
                </Link>
              </Box>
            </FooterInfo>
            <FooterInfo>
              <Box mb={4} style={{display: 'flex'}}>
                <a 
                  target="_blank" 
                  href="https://www.instagram.com/von_travel" 
                  rel="noopener noreferrer" 
                  style={{display: 'flex', flexDirection:'column', alignItems: 'center',  marginRight: '6px'}} 
                >
                  <svg xmlns="http://www.w3.org/2000/svg" 
                    x="0px" y="0px"
                    width="20" height="20"
                    viewBox="0 0 30 30"
                  >
                    <path fill="rgba(255,255,255,0.7)" d="M 9.9980469 3 C 6.1390469 3 3 6.1419531 3 10.001953 L 3 20.001953 C 3 23.860953 6.1419531 27 10.001953 27 L 20.001953 27 C 23.860953 27 27 23.858047 27 19.998047 L 27 9.9980469 C 27 6.1390469 23.858047 3 19.998047 3 L 9.9980469 3 z M 22 7 C 22.552 7 23 7.448 23 8 C 23 8.552 22.552 9 22 9 C 21.448 9 21 8.552 21 8 C 21 7.448 21.448 7 22 7 z M 15 9 C 18.309 9 21 11.691 21 15 C 21 18.309 18.309 21 15 21 C 11.691 21 9 18.309 9 15 C 9 11.691 11.691 9 15 9 z M 15 11 A 4 4 0 0 0 11 15 A 4 4 0 0 0 15 19 A 4 4 0 0 0 19 15 A 4 4 0 0 0 15 11 z"></path>
                  </svg>
                </a>
                <a href="https://www.facebook.com/vontravel" target="_blank" rel="noopener noreferrer">
                  <FooterIcon name="FACEBOOK" />
                </a>
                <a href="mailto:enquiry@vontravels.com">
                  <FooterIcon name="EMAIL" />
                </a>
              </Box>
            </FooterInfo>
            <FooterInfo>旅遊牌照號碼 : 354418</FooterInfo>
          </FooterInfos>
          <FooterList>
            <FooterTitle>我們的服務</FooterTitle>
            {R.map(item => (
              <FooterItem key={item.name}>
                <FooterLink to={item.link}>{item.name}</FooterLink>
              </FooterItem>
            ))(FOOTER_SERVICE)}
          </FooterList>
          <FooterList>
            <FooterTitle>更多</FooterTitle>
            {R.map(item => (
              <FooterItem key={item.name}>
                <FooterLink to={item.link}>{item.name}</FooterLink>
              </FooterItem>
            ))(FOOTER_MORE)}
          </FooterList>
          <FooterList>
            <FooterTitle>其他</FooterTitle>
            {R.map(item => (
              <FooterItem key={item.name}>
                <FooterLink to={item.link}>{item.name}</FooterLink>
              </FooterItem>
            ))(FOOTER_OTHER)}
          </FooterList>
        </Flex>
      </Box>
      <Copyright>Copyright © 2019 - {new Date().getFullYear()} Von travel. All rights reserved.</Copyright>
    </Wrapper>
  );
};

export default Footer;
