import _taggedTemplateLiteral from "/opt/build/repo/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  ", ";\n  ", ";\n  display: inline-block;\n  width: ", "px;\n  height: ", "px;\n  text-align: center;\n  vertical-align: text-bottom;\n  font-family: 'fontello';\n  font-style: normal;\n  font-weight: 400;\n  font-variant: normal;\n  text-transform: none;\n  line-height: 1;\n  @font-face {\n    font-family: 'fontello';\n    font-style: normal;\n    font-weight: 400;\n    src: url(", ") format('woff2'),\n       url(", ") format('woff'),\n       url(", ") format('ttf'),\n       url(", ") format('eot');\n  }\n\n  &::before {\n    content: \"", "\";\n    display: inline-block;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import styled from 'styled-components';
import { color, typography } from 'styled-system';
import FONT_URL_WOFF2 from './fontello.woff2';
import FONT_URL_WOFF from './fontello.woff';
import FONT_URL_TTF from './fontello.ttf';
import FONT_URL_EOT from './fontello.eot';
import { ICON_NAME_MAP } from '../constants/iconNames';
export var Icon = styled.i(_templateObject(), color, typography, function (props) {
  return props.fontSize;
}, function (props) {
  return props.fontSize;
}, FONT_URL_WOFF2, FONT_URL_WOFF, FONT_URL_TTF, FONT_URL_EOT, function (props) {
  return ICON_NAME_MAP[(props.name || '').toUpperCase()];
});
Icon.defaultProps = {
  fontSize: 'icon',
  color: 'icon'
};