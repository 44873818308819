import React from 'react';
import styled from 'styled-components';
import { Box, Button, Flex, Form, Icon, Input } from '@cowsquare/design';
import { themeGet } from '@styled-system/theme-get';
import { Field, Form as FinalForm } from 'react-final-form';
import { Logo } from '../../layouts/Logo';
import * as rules from '../../../utils/validate';

const Wrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  background-color: ${themeGet('colors.whites.0')};
  width: 534px;
  height: 376px;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 ${themeGet('colors.blacks.8')};
`;

const LogoWrapper = styled(Box)`
  top: 16px;
  left: 16px;
  position: absolute;
`;

const FormWrapper = styled(Flex).attrs({
  p: [6, 7],
})`
  background-color: ${themeGet('colors.whites.0')};
`;

const ErrorMessage = styled(Box).attrs({
  p: 4,
})`
  font-size: 13px;
  height: 28px;
  color: ${themeGet('colors.danger')};
`;

const Title = styled(Box)`
  font-size: 24px;
  font-weight: 500;
  color: ${themeGet('colors.blacks.0')};
`;

const SubTitle = styled(Box)`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: ${themeGet('colors.blacks.4')};
`;

export const DesktopForgetPasswordForm = ({ onSubmit = () => {}, errorMessage }) => {
  return (
    <Wrapper>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <Flex>
        <FormWrapper flex="1" flexDirection="column" justifyContent="center">
          <Title mb={5}>重設密碼</Title>
          <SubTitle mb={5} fontSize="24px" color="black">
            請輸入註冊的電子郵件，我們將發送重設密碼的連結給您，謝謝。
          </SubTitle>
          <FinalForm onSubmit={onSubmit}>
            {({ handleSubmit, submitting }) => (
              <>
                <Form>
                  <Field
                    name="email"
                    validate={rules.pipe(
                      rules.required('請輸入您的電子郵件帳號'),
                      rules.isEmail('請輸入正確的電子郵件帳號')
                    )}
                  >
                    {({ input, meta }) => {
                      const fieldErrorMessage = meta.touched && (meta.error || errorMessage);
                      return (
                        <>
                          <Input
                            {...input}
                            placeholder="請輸入您的電子郵件帳號"
                            prefix={<Icon name="email" />}
                            size="large"
                            error={fieldErrorMessage}
                            type="email"
                          />
                          <ErrorMessage>{errorMessage || fieldErrorMessage}</ErrorMessage>
                        </>
                      );
                    }}
                  </Field>
                </Form>

                <Flex justifyContent="flex-end" alignItems="center">
                  <Button
                    onClick={handleSubmit}
                    disabled={submitting}
                    type="submit"
                    variant="primary"
                    size="large"
                  >
                    發送
                  </Button>
                </Flex>
              </>
            )}
          </FinalForm>
        </FormWrapper>
      </Flex>
    </Wrapper>
  );
};

export const MobileForgetPasswordForm = ({ onSubmit, errorMessage }) => {
  return (
    <FormWrapper flexDirection="column" flex="1">
      <Title mb={5}>重設密碼</Title>
      <SubTitle mb={5} fontSize="24px" color="black">
        請輸入註冊的電子郵件，我們將發送重設密碼的連結給您，謝謝。
      </SubTitle>
      <FinalForm
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting }) => (
          <>
            <Form>
              <Field
                name="email"
                validate={rules.pipe(
                  rules.required('請輸入您的電子郵件帳號'),
                  rules.isEmail('請輸入正確的電子郵件帳號')
                )}
              >
                {({ input, meta }) => {
                  const fieldErrorMessage = meta.touched && (meta.error || errorMessage);
                  return (
                    <>
                      <Input
                        {...input}
                        placeholder="請輸入您的電子郵件帳號"
                        prefix={<Icon name="email" />}
                        size="large"
                        error={fieldErrorMessage}
                        type="email"
                      />
                      <ErrorMessage>{errorMessage || fieldErrorMessage}</ErrorMessage>
                    </>
                  );
                }}
              </Field>
            </Form>
            <Flex justifyContent="flex-end" alignItems="center">
              <Button
                onClick={handleSubmit}
                disabled={submitting}
                type="submit"
                variant="primary"
                size="large"
              >
                發送
              </Button>
            </Flex>
          </>
        )}
      />
    </FormWrapper>
  );
};
