import { Box } from '@cowsquare/design';
import { themeGet } from '@styled-system/theme-get';
import React, {useEffect} from 'react';
import gql from 'graphql-tag';
import { useHistory } from 'react-router-dom';
import { useMutation , useQuery} from 'react-apollo';
import styled from 'styled-components';
import { Logo } from '../layouts/Logo';
import { useCookies } from 'react-cookie';


const Title = styled(Box).attrs({ as: 'h3', m: 0, mb: 5 })`
  font-size: ${themeGet('fontSizes.h2')};
  line-height: 1.5;
  font-weight: 500;
  color:#0080c8;
  &.text-center{
    text-align:center;
  }
`;

const MainBox = styled(Box).attrs({
  maxWidth: '904px',
  margin:'30px auto',
})`
  position:relative;
  margin-bottom: 100px;
  @media (max-width:640px){
    padding:10px;
    margin-bottom:20px;
  }
`;


const ColBox = styled(Box).attrs({
  display: 'block',
})`
  text-align:center;
  h3{
    margin-top:10px;
  }
`;
const INSERT_ORDER_ENQUIRY = gql`
mutation insert_vontravel_new_transactions($input: vontravel_new_transactions_insert_input!) {
insert_vontravel_new_transactions(
 objects: [$input]
) {
  returning {
    first_name,
		address
    }
  }
}
`;

const FETCH_ORDER_ENQUIRY = gql`
 query getOrderId($pid: String!) {
    content: vontravel_new_transactions(
      where: {payment_id: {_eq: $pid} }
    ) {
      id
  }
}
`;

const Errors = () => {
  const history = useHistory();
  // eslint-disable-next-line
  const [cookies, removeCookie] = useCookies(['tourFinal']);
  const [insertOrder] = useMutation(INSERT_ORDER_ENQUIRY);
  const pId = cookies.payId;
  const { data, loading } = useQuery(FETCH_ORDER_ENQUIRY, {
    variables: { pid: pId },
  });
  const info = cookies.info;
  const input = info;
  
  const onSave = async () => {
    let lengthId = 1
    input['payment_status'] = 'unsuccessful';
    delete input.number_package;
    delete input.checkout_on;
    console.log('called', data, loading)
    if(data) lengthId = data.content.length
    if(lengthId === 0 && !loading){
      await insertOrder({
          variables: { input },
      });
   }
  }
  useEffect(() => {
    if(!pId || pId === 'undefined' || !info) history.replace(`/`)
    else if(info.checkout_on === "hotel") return
    else{
      onSave();
    }
    // eslint-disable-next-line
  }, [data])

  return (
    <MainBox>
      <ColBox>
        <Logo/>
        <Title className="text-center">Order canceled -- continue to shop around and checkout when you're ready.</Title>
      </ColBox>
    </MainBox>
  );
};

export default Errors;
