import React from 'react';
import { Card, Text, Icon, Flex, Box } from '@cowsquare/design';
import styled from 'styled-components';
import { ellipsis } from 'polished';
import { themeGet } from '@styled-system/theme-get';

const CardInfo = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  padding-top: 16px;
  width: 100%;
  height: auto;
  align-items: center;
`;

const Info = styled.li`
  ${ellipsis()}
  height: 20px;
  padding: 0 16px;
  margin-bottom: 16px;
`;

const InfoText = styled(Text)`
  display: inline;
  font-size: ${themeGet('cardInfo.fontSize')};
  color: ${themeGet('cardInfo.color')};
  margin-left: 4px;
`;

const HomestayCard = ({
  coverUrl,
  title,
  description,
  people,
  bedroom,
  bed,
  bathroom,
  area,
  city,
  rate,
  featured = false,
  ...rest
}) => {
  return (
    <Card
      height={460}
      variant="vertical"
      coverUrl={coverUrl}
      footer={
        <CardInfo>
          <Info key="LOCATION">
            <Icon name="LOCATION_FILL" />
            <InfoText>{`${area} - ${city}`}</InfoText>
          </Info>
          <Info key="STAR_FILL">
            <Icon name="STAR_FILL" color="#ed9519" />
            <InfoText>{rate}</InfoText>
          </Info>
        </CardInfo>
      }
      {...rest}
    >
      <Card.Meta
        title={title}
        description={`${people}人民宿 · ${bedroom}間睡房 · ${bed}張床 · ${bathroom}間浴室 \n${description}`}
        descriptionLineClamp={3}
        style={{ whiteSpace: 'pre-line' }}
      >
        {featured && (
          <Flex mb={5}>
            <Box mr={3}>
              <Icon name="LIKE" color="primary" />
            </Box>
            <Text color="primary">精選推介</Text>
          </Flex>
        )}
      </Card.Meta>
    </Card>
  );
};
export default HomestayCard;
