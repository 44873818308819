import _slicedToArray from "/opt/build/repo/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { useState, useEffect, useCallback } from 'react';
export var useCheckbox = function useCheckbox(_ref) {
  var checked = _ref.checked,
      onChange = _ref.onChange;

  var _useState = useState(checked || false),
      _useState2 = _slicedToArray(_useState, 2),
      _checked = _useState2[0],
      setChecked = _useState2[1];

  useEffect(function () {
    if (checked !== undefined && checked !== _checked) {
      setChecked(checked);
    }
  }, [_checked, checked]);
  var handleChange = useCallback(function (e) {
    if (checked === undefined) {
      setChecked(e.target.checked);
    }

    if (typeof onChange === 'function') {
      onChange({
        target: e.target,
        stopPropagation: function stopPropagation() {
          e.stopPropagation();
        },
        preventDefault: function preventDefault() {
          e.preventDefault();
        },
        nativeEvent: e.nativeEvent
      });
    }
  }, [checked, onChange]);
  return {
    isChecked: _checked,
    handleChange: handleChange
  };
};