import _defineProperty from "/opt/build/repo/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { theme } from './default';
var WHITE = 'white';
var BLACK = 'black';

var base = _objectSpread({}, theme, {
  space: [0, 1, 2, 4, 8, 16, 32, 64],
  breakpoints: ['1032px'],
  colors: _objectSpread({}, theme.colors, {
    primary: '#0080c8',
    primaryDark: '#005f99',
    primaryHover: '#0073b4',
    primaryLight: '#cbe6f5',
    primaryUltralight: '#e5f2f9',
    success: '#6CC978',
    info: '#0080c8',
    link: '#0080c8',
    background: '#f4f6f8'
  }),
  fonts: _objectSpread({}, theme.fonts, {
    chinese: '"Microsoft JhengHei","微軟正黑體"'
  })
});

var calendar = {
  day: {
    zIndex: base.zIndex.select,
    color: base.colors.blacks[0],
    hover: {
      backgroundColor: base.colors.primaryUltralight,
      color: base.colors.blacks[0]
    },
    active: {
      backgroundColor: base.colors.primaryLight,
      color: base.colors.blacks[0]
    },
    selected: {
      backgroundColor: base.colors.primary,
      color: base.colors.whites[0],
      hover: {
        backgroundColor: base.colors.primaryHover
      },
      range: {
        backgroundColor: base.colors.primaryUltralight,
        color: base.colors.blacks[0]
      }
    },
    disabled: {
      backgroundColor: base.colors.transparent,
      color: base.colors.blacks[8]
    }
  },
  wrapper: {
    border: "".concat(base.borders[1], " ").concat(base.colors.border),
    borderRadius: "".concat(base.radii[2], "px"),
    boxShadow: null,
    backgroundColor: base.colors.whites[0]
  },
  separator: {
    border: "".concat(base.borders[1], " ").concat(base.colors.borderSeparator)
  }
};
var button = {
  border: base.borders[1],
  borderRadius: '8px',
  borderTop: null,
  borderBottom: null,
  borderLeft: null,
  borderRight: null,
  boxShadow: null,
  fontSize: base.fontSizes.text,
  iconSize: '20px'
};
var buttons = {
  primary: {
    color: base.colors.whites[0],
    backgroundColor: base.colors.primary,
    borderColor: base.colors.primary,
    '&:hover': {
      color: base.colors.whites[0],
      borderColor: base.colors.primaryHover,
      backgroundColor: base.colors.primaryHover
    },
    '&:active': {
      color: base.colors.whites[0],
      borderColor: base.colors.primaryDark,
      backgroundColor: base.colors.primaryDark
    },
    '&:disabled': {
      color: base.colors.blacks[8],
      borderColor: base.colors.transparent,
      backgroundColor: base.colors.blacks[9]
    }
  },
  link: {
    color: base.colors.link,
    backgroundColor: 'transparent',
    border: 'none',
    '&:hover': {
      color: base.colors.primaryDark
    },
    '&:active': {
      color: base.colors.primaryDark
    },
    '&:disabled': {
      color: base.colors.blacks[8]
    }
  },
  default: {
    color: base.colors.primary,
    backgroundColor: base.colors.whites[0],
    borderColor: base.colors.primary,
    '&:hover': {
      color: base.colors.primary,
      borderColor: base.colors.primary,
      backgroundColor: base.colors.primaryUltralight
    },
    '&:active': {
      color: base.colors.primary,
      borderColor: base.colors.primaryDark,
      backgroundColor: base.colors.primaryLight
    },
    '&:disabled': {
      color: base.colors.blacks[8],
      borderColor: base.colors.blacks[9],
      backgroundColor: base.colors.whites[7]
    }
  },
  size: {
    large: {
      height: 40,
      padding: '0 16px'
    },
    small: {
      height: 26,
      padding: '0 16px'
    },
    default: {
      height: 32,
      padding: '0 16px'
    }
  }
};
var card = {
  backgroundColor: base.colors.whites[0],
  border: 0,
  borderColor: base.colors.blacks[8],
  borderRadius: '8px',
  boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.05)',
  wrapper: {
    vertical: {
      flexDirection: 'column'
    },
    horizontal: {
      flexDirection: 'row'
    }
  },
  cover: {
    vertical: {
      width: '100%',
      height: '208px'
    },
    horizontal: {
      height: 'auto',
      width: '180px'
    }
  },
  content: {
    vertical: {
      minWidth: '300px'
    },
    horizontal: {
      minWidth: '400px'
    }
  },
  header: {
    float: {
      position: 'absolute',
      marginTop: '-56px',
      color: base.colors.whites[0],
      backgroundColor: base.colors.blacks[7]
    },
    static: {
      position: 'static',
      marginTop: '0',
      color: base.colors.blacks[0],
      backgroundColor: base.colors.whites[0]
    }
  },
  title: {
    color: base.colors.blacks[0],
    fontSize: base.fontSizes.h3,
    lineClamp: 2
  },
  description: {
    marginBottom: '8px',
    color: base.colors.blacks[3],
    fontSize: base.fontSizes.h5,
    lineClamp: 2
  },
  footer: {
    borderTop: base.borders[1],
    borderColor: base.colors.blacks[9]
  },
  event: {
    date: {
      static: {
        color: base.colors.primary
      },
      float: {
        color: base.colors.whites[0]
      },
      fontSize: base.fontSizes.h2,
      fontWeight: base.fontWeights.semibold
    }
  },
  article: {
    avatar: {
      size: '26px'
    },
    author: {
      fontSize: base.fontSizes.text,
      fontWeight: base.fontWeights.semibold,
      static: {
        color: base.colors.blacks[0]
      },
      float: {
        color: base.colors.whites[0]
      }
    },
    date: {
      static: {
        color: base.colors.blacks[6]
      },
      float: {
        color: base.colors.whites[0]
      },
      fontSize: base.fontSizes.text
    }
  }
};
var cardInfo = {
  fontSize: base.fontSizes.text,
  color: base.colors.blacks[3]
};
var carousel = {
  dots: {
    default: {
      position: 'absolute'
    },
    gallery: {
      position: 'static'
    },
    left: 0,
    bottom: '16px',
    textAlign: 'center'
  },
  dot: {
    width: '5px',
    height: '5px',
    margin: '0px 5px',
    backgroundColor: BLACK,
    border: 'none',
    borderRadius: '50%',
    active: {
      width: '20px',
      height: '5px',
      borderRadius: '5px',
      backgroundColor: WHITE
    }
  },
  slideTitle: {
    fontSize: '36px',
    fontWeight: 500,
    color: WHITE
  },
  slideDescription: {
    fontSize: '16px',
    color: WHITE
  },
  arrow: {
    default: {
      top: 'calc(50% - 20px)'
    },
    gallery: {
      // arrow and thumbnail half-height
      top: 'calc(50% - 20px - 50px)'
    },
    bottom: 'auto',
    width: '40px',
    height: '40px',
    border: 0,
    borderRadius: '50%',
    backgroundColor: base.colors.blacks[6],
    prev: {
      left: '8px',
      right: 'auto'
    },
    next: {
      left: 'auto',
      right: '8px'
    }
  },
  customPage: {
    width: '100px',
    height: '100px',
    margin: '4px 4px 0 0',
    border: '4px solid',
    borderColor: base.colors.primary
  },
  index: {
    default: {
      bottom: '16px'
    },
    gallery: {
      // customPage height + bottom
      bottom: '120px'
    },
    position: 'absolute',
    top: 'auto',
    right: '16px',
    left: 'auto',
    padding: '4px 8px',
    backgroundColor: base.colors.blacks[6],
    color: base.colors.whites[0],
    borderRadius: '4px',
    fontSize: '14px'
  }
};
var drawer = {
  maskColor: base.colors.blacks[5],
  header: {
    justifyContent: 'flex-start',
    height: '52px',
    px: '16px',
    heading: {
      fontSize: '18px'
    }
  },
  closeButton: {
    size: '18px',
    fontSize: '18px',
    color: base.colors.blacks[0],
    top: '16px',
    right: '16px',
    backgroundColor: base.colors.whites[0],
    borderRadius: 0
  },
  body: {
    padding: 0,
    backgroundColor: base.colors.background
  },
  content: {
    boxShadow: null,
    backgroundColor: base.colors.whites[0],
    border: null,
    borderRadius: 0,
    borderColor: null
  },
  footer: {
    justifyContent: 'center',
    height: '64px',
    px: '16px',
    borderTop: null,
    borderColor: base.colors.blacks[9],
    boxShadow: "0 -2px 10px 0 ".concat(base.colors.blacks[10])
  }
};
var INPUT_DEFAULT_BORDER = "".concat(base.borders[1], " ").concat(base.colors.blacks[7]);
var INPUT_PRIMARY_BORDER = "".concat(base.borders[1], " ").concat(base.colors.primary);
var INPUT_DISABLED_BORDER = "".concat(base.borders[1], " ").concat(base.colors.blacks[9]);
var INPUT_ERROR_BORDER = "".concat(base.borders[1], " ").concat(base.colors.danger);
var input = {
  fontSize: base.fontSizes.text,
  borderRadius: '8px',
  backgroundColor: base.colors.whites[0],
  placeHolderColor: base.colors.blacks[7],
  border: {
    top: INPUT_DEFAULT_BORDER,
    left: INPUT_DEFAULT_BORDER,
    right: INPUT_DEFAULT_BORDER,
    bottom: INPUT_DEFAULT_BORDER
  },
  hover: {
    border: {
      top: INPUT_PRIMARY_BORDER,
      left: INPUT_PRIMARY_BORDER,
      right: INPUT_PRIMARY_BORDER,
      bottom: INPUT_PRIMARY_BORDER
    },
    backgroundColor: base.colors.whites[0]
  },
  focus: {
    border: {
      top: INPUT_PRIMARY_BORDER,
      left: INPUT_PRIMARY_BORDER,
      right: INPUT_PRIMARY_BORDER,
      bottom: INPUT_PRIMARY_BORDER
    },
    backgroundColor: base.colors.whites[0]
  },
  disabled: {
    border: {
      top: INPUT_DISABLED_BORDER,
      left: INPUT_DISABLED_BORDER,
      right: INPUT_DISABLED_BORDER,
      bottom: INPUT_DISABLED_BORDER
    },
    backgroundColor: base.colors.blacks[10],
    placeHolderColor: base.colors.blacks[8],
    color: base.colors.blacks[7]
  },
  error: {
    border: {
      top: INPUT_ERROR_BORDER,
      left: INPUT_ERROR_BORDER,
      right: INPUT_ERROR_BORDER,
      bottom: INPUT_ERROR_BORDER
    },
    backgroundColor: base.colors.errorBackground
  },
  clearButton: {
    hover: {
      color: base.colors.primary
    },
    active: {
      color: base.colors.primaryDark
    }
  },
  sizes: {
    large: '40px',
    default: '32px',
    small: '26px'
  }
};
var link = {
  color: base.colors.link,
  fontSize: base.fontSizes.text,
  hover: {
    color: base.colors.primaryDark
  },
  active: {
    color: base.colors.primaryDark
  }
};
var pagination = {
  border: {
    bottom: 0
  },
  item: {
    minWidth: '24px',
    fontSize: base.fontSizes.h5,
    color: base.colors.blacks[6],
    backgroundColor: base.colors.transparent,
    border: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    },
    borderRadius: 0,
    hover: {
      color: base.colors.primary,
      backgroundColor: base.colors.transparent,
      border: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      },
      borderRadius: 0
    },
    selected: {
      color: WHITE,
      backgroundColor: base.colors.primary,
      border: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      },
      borderRadius: "".concat(base.radii[2], "px")
    }
  },
  arrow: {
    color: base.colors.blacks[7],
    hover: {
      color: base.colors.primary
    },
    disabled: {
      color: base.colors.blacks[9]
    }
  },
  more: {
    color: base.colors.blacks[6]
  }
};
var select = {
  arrow: {
    color: base.colors.blacks[6],
    up: {
      color: base.colors.blacks[3]
    }
  },
  menu: {
    maxHeight: '300px',
    border: 0,
    borderColor: null,
    borderRadius: '8px',
    boxShadow: "0 4px 16px 0 ".concat(base.colors.blacks[9]),
    backgroundColor: base.colors.whites[0],
    zIndex: base.zIndex.select
  },
  option: {
    height: '32px',
    fontSize: base.fontSizes.text,
    color: base.colors.blacks[3],
    backgroundColor: null,
    border: {
      top: null,
      left: null,
      right: null,
      bottom: null
    },
    borderRadius: null,
    boxShadow: null,
    hover: {
      color: base.colors.blacks[3],
      backgroundColor: base.colors.primaryUltralight
    },
    selected: {
      color: WHITE,
      backgroundColor: base.colors.primary
    },
    disabled: {
      color: base.colors.blacks[8],
      backgroundColor: WHITE
    }
  },
  group: {
    title: {
      fontSize: base.fontSizes.text,
      color: base.colors.primaryHover,
      backgroundColor: null,
      border: {
        top: null,
        left: null,
        right: null,
        bottom: null
      },
      borderRadius: null
    }
  }
};
var tag = {
  maxWidth: '120px',
  fontSize: base.fontSizes.text,
  lineHeight: 1.4,
  color: base.colors.blacks[3],
  backgroundColor: base.colors.whites[0],
  border: "".concat(base.borders[1], " ").concat(base.colors.border),
  borderRadius: "".concat(base.radii[3], "px"),
  boxShadow: null
};
var dropdown = {
  menu: {
    backgroundColor: base.colors.whites[0],
    borderRadius: '8px',
    boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.1)'
  },
  option: {
    fontSize: '14px',
    padding: '6px 10px',
    selected: {
      color: base.colors.primary,
      backgroundColor: WHITE
    },
    hover: {
      color: base.colors.primary,
      backgroundColor: base.colors.primaryLight
    }
  }
};
var checkbox = {
  text: {
    fontSize: base.fontSizes.text,
    color: base.colors.blacks[0],
    disabled: {
      color: base.colors.blacks[8]
    }
  },
  indicator: {
    size: '16px',
    border: base.borders[1],
    borderRadius: '2px',
    borderColor: base.colors.blacks[8],
    backgroundColor: base.colors.whites[0],
    checked: {
      borderColor: base.colors.primary,
      backgroundColor: base.colors.primary
    },
    disabled: {
      borderColor: base.colors.blacks[8],
      backgroundColor: base.colors.blacks[9]
    },
    hover: {
      borderColor: base.colors.primary
    },
    icon: {
      borderColor: base.colors.whites[0]
    },
    indeterminate: {
      backgroundColor: base.colors.primary,
      disabled: {
        backgroundColor: base.colors.blacks[8]
      }
    }
  }
};
var radio = {
  text: theme.checkbox.text,
  indicator: _objectSpread({}, theme.checkbox.indicator, {
    borderRadius: "".concat(base.radii[3], "px"),
    disabledChecked: {
      borderColor: base.colors.transparent,
      backgroundColor: base.colors.blacks[8]
    },
    icon: {
      backgroundColor: base.colors.whites[0]
    }
  }),
  tag: {
    active: {
      color: base.colors.whites[0],
      backgroundColor: base.colors.primary,
      borderColor: base.colors.primary,
      '&:hover': {
        color: base.colors.whites[0],
        backgroundColor: base.colors.primary
      }
    }
  }
};
export var vontravelTheme = _objectSpread({}, base, {
  button: button,
  buttons: buttons,
  card: card,
  cardInfo: cardInfo,
  carousel: carousel,
  drawer: drawer,
  dropdown: dropdown,
  input: input,
  link: link,
  pagination: pagination,
  select: select,
  calendar: calendar,
  tag: tag,
  checkbox: checkbox,
  radio: radio,
  name: 'vontravel'
});