import React from 'react';
import { useParams } from 'react-router-dom';
import AttractionContent from './Content';

const AttractionDetail = () => {
  const { country, name } = useParams();

  return <AttractionContent country={country} name={name} />;
};

export default AttractionDetail;
