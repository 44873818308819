import React, { useState, useMemo, useContext, createContext, useCallback, useEffect } from 'react';
import * as D from 'date-fns/fp';
import * as R from 'ramda';
import uuid from 'uuid';
import { PLANNER_LOCAL_DATA_KEY } from '../../constants/planner';
import { CARD_TYPE } from './planTourDrawer/constants/CARD_TYPE';

const PlanContext = createContext();
const FORMAT = 'yyyyMMdd';
const formatDateKey = D.format(FORMAT);
const parseDateKey = D.parse(new Date(), FORMAT);
/**
 * ```typescript
 * type DateString = string;
 * interface Tour {
 *   cardType: 'TOUR',
 *   cardId: string,
 *   [x: string]: any
 * }
 * interface Attraction {
 *   cardType: 'ATTRACTION',
 *   cardId: string,
 *   [x: string]: any
 * }
 * type Card = Tour | Attraction;
 * interface Plans {
 *   [x: DateString]: Card[]
 * }
 * interface Guides {
 *   [x: DateString]: boolean
 * }
 * ```
 */
export const PlanContextProvider = ({ children, endDate, startDate, firstCard }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [plans, setPlans] = useState({});
  const getAllPlans = useCallback(
    () =>
      R.compose(
        R.flatten,
        R.values,
        R.mapObjIndexed((plan, dateKey) => R.map(R.assoc('date', parseDateKey(dateKey)), plan))
      )(plans),
    [plans]
  );
  const [guides, setGuides] = useState({});
  const [isEditing, setIsEditing] = useState(true);
  const getAllGuides = useCallback(() => R.values(guides), [guides]);
  const getPlanByDate = useCallback(date => plans[formatDateKey(date)] || [], [plans]);
  const getGuideByDate = useCallback(date => guides[formatDateKey(date)] || false, [guides]);
  const updatePlanByDate = useCallback(date => {
    const key = formatDateKey(date);
    return f =>
      setPlans(plans => {
        const previous = plans[key] || [];
        return {
          ...plans,
          [key]: f(previous),
        };
      });
  }, []);
  const updateGuidesByDate = useCallback(date => {
    const key = formatDateKey(date);
    return f => setGuides(R.over(R.lensProp(key), f));
  }, []);
  const toggleGuideByDate = useCallback(
    date => {
      const cards = R.propOr([], formatDateKey(date), plans);
      const tour = R.find(R.propEq('cardType', CARD_TYPE.TOUR), cards);

      if (!tour) {
        updateGuidesByDate(date)(R.not);
      } else {
        const originValue = R.propOr(false, formatDateKey(date), guides);
        const result = R.pipe(
          R.pickBy(v => R.find(R.propEq('cardId', tour.cardId), v)),
          R.map(R.always(!originValue))
        )(plans);
        setGuides(guides => ({ ...guides, ...result }));
      }
    },
    [updateGuidesByDate, plans, guides]
  );

  const removeCard = useCallback(
    (cardId, cardType) => {
      const withCardId = R.propEq('cardId', cardId);
      if (cardType === CARD_TYPE.TOUR) {
        const noGuideDates = R.pipe(R.pickBy(R.find(withCardId)), R.map(R.always(false)))(plans);

        setGuides(guides => ({ ...guides, ...noGuideDates }));
      }
      setPlans(R.map(R.reject(withCardId)));
    },
    [plans]
  );
  const removeAllCards = useCallback(() => {
    setPlans({});
    setGuides({});
  }, []);
  const moveCard = useCallback(
    ({ fromDate, fromIndex, toDate, toIndex }) => {
      const item = R.path([formatDateKey(fromDate), fromIndex], plans);
      updatePlanByDate(fromDate)(R.remove(fromIndex, 1));
      updatePlanByDate(toDate)(R.insert(toIndex, item));
    },
    [plans, updatePlanByDate]
  );
  const canAddCardToDate = useCallback(
    (date, cardType, days = 0) => {
      if (R.isNil(date)) return false;

      const end = D.addDays(Math.max(days - 1, 0), date);

      // The end of the tour should be before the end of the endDate
      if (D.isAfter(D.endOfDay(endDate), end)) return false;

      switch (cardType) {
        case CARD_TYPE.TOUR:
          // ensure no date plans contain tours
          return R.compose(
            R.all(R.none(R.propEq('cardType', CARD_TYPE.TOUR))),
            R.map(getPlanByDate)
          )(
            D.eachDayOfInterval({
              start: date,
              end,
            })
          );
        default:
          return true;
      }
    },
    [endDate, getPlanByDate]
  );
  const addCard = useCallback(
    (date, item) => {
      const { cardType, days = 0, hasGuide } = item;
      if (date && canAddCardToDate(date, cardType, days)) {
        const cardId = uuid();
        R.forEach(
          d => {
            updatePlanByDate(d)(R.append({ ...item, cardId }));
            if (cardType === CARD_TYPE.TOUR && hasGuide) {
              updateGuidesByDate(d)(R.always(true));
            } else if (cardType === CARD_TYPE.TOUR && !hasGuide) {
              updateGuidesByDate(d)(R.always(false));
            }
          },
          D.eachDayOfInterval({
            start: date,
            end: D.addDays(Math.max(days - 1, 0), date),
          })
        );
      }
    },
    [canAddCardToDate, updateGuidesByDate, updatePlanByDate]
  );
  const setDataFromLocation = useCallback(() => {
    const dataString = localStorage.getItem(PLANNER_LOCAL_DATA_KEY);
    if (dataString) {
      const data = JSON.parse(dataString);
      setGuides(R.propOr({}, 'guides', data));
      setPlans(R.propOr({}, 'plans', data));
    }
  }, []);

  useEffect(
    () => {
      if (firstCard) {
        addCard(startDate, firstCard);
      }
    },
    [firstCard] // eslint-disable-line
  );
  useEffect(() => {
    if (!R.isEmpty(plans) || !R.isEmpty(guides)) {
      const dataString = JSON.stringify({ plans, guides });
      localStorage.setItem(PLANNER_LOCAL_DATA_KEY, dataString);
    }
  }, [plans, guides]);

  const value = useMemo(
    () => ({
      setPlans,
      setGuides,
      getAllPlans,
      getAllGuides,
      selectedDate,
      setSelectedDate,
      getPlanByDate,
      updatePlanByDate,
      getGuideByDate,
      toggleGuideByDate,
      addCard,
      removeCard,
      removeAllCards,
      moveCard,
      setDataFromLocation,
      canAddCardToDate,
      isEditing,
      setIsEditing,
    }),
    [
      setPlans,
      setGuides,
      getAllPlans,
      getAllGuides,
      selectedDate,
      getPlanByDate,
      addCard,
      removeCard,
      removeAllCards,
      moveCard,
      updatePlanByDate,
      getGuideByDate,
      toggleGuideByDate,
      setDataFromLocation,
      canAddCardToDate,
      isEditing,
      setIsEditing,
    ]
  );

  return <PlanContext.Provider value={value}>{children}</PlanContext.Provider>;
};

export const usePlanContext = () => useContext(PlanContext);
