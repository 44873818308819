import React, { useCallback, useEffect } from 'react';
import * as R from 'ramda';
import { useMachine, useService } from '@xstate/react';
import { Flex, Pagination, Box, Radio } from '@cowsquare/design';
import LocationCard from '../LocationCard';
import { COUNTRY_MAP } from '../../../constants/country';
import AddToPlanButton from '../AddToPlanButton';
import CardList from './CardList';
import TourCardBox from './TourCardBox';
import { CARD_TYPE } from './constants/CARD_TYPE';
import { usePlanContext } from '../PlanContext';
import { useUser } from '../../../utils/hooks';
import TourCard from '../TourCard';
import { getHTMLString } from '../../../utils/getHTMLString';
import { FP_DRAWER_STATES, FP_DRAWER_EVENTS } from './machines/drawerMachine';
import {
  favoriteTourMachine,
  FAVORITE_TOUR_STATES,
  FAVORITE_TOUR_EVENTS,
} from 'machines/favoriteTourMachine';
import {
  favoriteAttractionMachine,
  FAVORITE_ATTRACTION_EVENTS,
  FAVORITE_ATTRACTION_STATES,
} from 'machines/favoriteAttractionMachine';
import { TourCardLoading } from '../Loading';
import { assign } from 'xstate';

const LoadingCards = R.times(
  i => (
    <TourCardBox key={i}>
      <TourCardLoading />
    </TourCardBox>
  ),
  2
);

const TAGS = {
  TOUR: `${FP_DRAWER_STATES.LIST}.${FP_DRAWER_STATES.LIST_FAVORITE_TAB}.${FP_DRAWER_STATES.FAVORITE_TAB_TOUR}`,
  ATTRACTION: `${FP_DRAWER_STATES.LIST}.${FP_DRAWER_STATES.LIST_FAVORITE_TAB}.${FP_DRAWER_STATES.FAVORITE_TAB_ATTRACTION}`,
};

const TAG_OPTION = [
  { value: TAGS.TOUR, label: '旅程' },
  { value: TAGS.ATTRACTION, label: '地點' },
];

const FavoirteTourList = ({ userId, country, feature, onClickCard, closeDrawer }) => {
  const { addCard, selectedDate, canAddCardToDate } = usePlanContext();
  const [current, send] = useMachine(favoriteTourMachine, {
    context: {
      userId,
      country,
      feature,
    },
    actions: {
      refetch: assign({
        userId,
        country,
        feature,
      }),
    },
  });
  useEffect(() => {
    send(FAVORITE_TOUR_EVENTS.REFETCH);
  }, [userId, country, feature, send]);
  const onPageChange = useCallback(
    page => {
      send({ type: FAVORITE_TOUR_EVENTS.PAGE_CHANGE, page });
    },
    [send]
  );

  const { data, page, pageSize } = current.context;
  const total = R.pathOr(0, ['meta', 'aggregate', 'count'], data);
  const countryName = COUNTRY_MAP[country];

  return (
    <>
      <CardList>
        {current.matches(FAVORITE_TOUR_STATES.LOADING) && LoadingCards}
        {current.matches(FAVORITE_TOUR_STATES.LOADED) &&
          R.pipe(
            R.propOr([], 'list'),
            R.map(({ item }) => (
              <TourCardBox key={item.id}>
                <TourCard
                  onClick={() => {
                    onClickCard(item.name, CARD_TYPE.TOUR);
                  }}
                  coverUrl={R.pathOr('', ['cover', 'link'], item)}
                  fixedCoverSize="160px"
                  height="380px"
                  title={item.name}
                  country={countryName}
                  isCharter={item.isCharter}
                  description={R.pipe(R.pathOr('', ['description', 'content']), getHTMLString)(item)}
                  showCollectIcon={false}
                  links={
                    <AddToPlanButton
                      disabled={R.complement(canAddCardToDate)(selectedDate, CARD_TYPE.TOUR, item.days)}
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        addCard(selectedDate, { ...item, cardType: CARD_TYPE.TOUR });
                        closeDrawer();
                      }}
                    />
                  }
                />
              </TourCardBox>
            ))
          )(data)}
        {/* --- TODO: handle EMPTY & ERROR --- */}
        {current.matches(FAVORITE_TOUR_STATES.EMPTY) && null}
        {current.matches(FAVORITE_TOUR_STATES.ERROR) && null}
      </CardList>
      {total > pageSize && (
        <Flex py={6} justifyContent="center">
          <Pagination total={total} current={page} pageSize={pageSize} onChange={onPageChange} />
        </Flex>
      )}
    </>
  );
};

const FavoirteAttractionList = ({ userId, country, feature, area, onClickCard, closeDrawer }) => {
  const { addCard, selectedDate, canAddCardToDate } = usePlanContext();
  const [current, send] = useMachine(favoriteAttractionMachine, {
    context: {
      userId,
      country,
      feature,
      area,
    },
    actions: {
      refetch: assign({
        userId,
        country,
        feature,
        area,
      }),
    },
  });

  useEffect(() => {
    send(FAVORITE_ATTRACTION_EVENTS.REFETCH);
  }, [userId, country, feature, send]);

  const onPageChange = useCallback(() => {
    send(FAVORITE_ATTRACTION_EVENTS.PAGE_CHANGE);
  }, [send]);

  const { data, page, pageSize } = current.context;
  const total = R.pathOr(0, ['meta', 'aggregate', 'count'], data);
  const countryName = COUNTRY_MAP[country];

  return (
    <>
      <CardList>
        {current.matches(FAVORITE_ATTRACTION_STATES.LOADING) && LoadingCards}
        {current.matches(FAVORITE_ATTRACTION_STATES.LOADED) &&
          R.pipe(
            R.propOr([], 'list'),
            R.map(({ item }) => (
              <TourCardBox key={item.id}>
                <LocationCard
                  onClick={() => {
                    onClickCard(item.name, CARD_TYPE.ATTRACTION);
                  }}
                  coverUrl={R.path(['cover', 'link'], item)}
                  fixedCoverSize="160px"
                  title={item.name}
                  height="380px"
                  location={`${countryName} ${R.pathOr('', ['area', 'name'], item)}`}
                  showCollectIcon={false}
                  disabled={R.complement(canAddCardToDate)(selectedDate, CARD_TYPE.ATTRACTION)}
                  links={
                    <AddToPlanButton
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        addCard(selectedDate, { ...item, cardType: CARD_TYPE.ATTRACTION });
                        closeDrawer();
                      }}
                    />
                  }
                />
              </TourCardBox>
            ))
          )(data)}
        {/* --- TODO: handle EMPTY & ERROR --- */}
        {current.matches(FAVORITE_ATTRACTION_STATES.EMPTY) && null}
        {current.matches(FAVORITE_ATTRACTION_STATES.ERROR) && null}
      </CardList>
      {total > pageSize && (
        <Flex py={6} justifyContent="center">
          <Pagination total={total} current={page} pageSize={pageSize} onChange={onPageChange} />
        </Flex>
      )}
    </>
  );
};

const TabFavorite = ({ service, country, area, feature, onOpenContentDrawerPage, closeDrawer }) => {
  const { user } = useUser();
  const userId = R.prop('id', user);
  const [currentDrawer, sendDrawer] = useService(service);

  return (
    <>
      <Flex mt="16px" justifyContent="center">
        <Box>
          <Radio.Group
            onChange={value => {
              if (value === TAGS.ATTRACTION) {
                sendDrawer(FP_DRAWER_EVENTS.CLICK_FAVORITE_ATTRACTION);
              } else if (value === TAGS.TOUR) {
                sendDrawer(FP_DRAWER_EVENTS.CLICK_FAVORITE_TOUR);
              }
            }}
            value={R.last(currentDrawer.toStrings())}
          >
            {R.map(
              ({ value, label }) => (
                <Radio.Tag key={label} value={value}>
                  {label}
                </Radio.Tag>
              ),
              TAG_OPTION
            )}
          </Radio.Group>
        </Box>
      </Flex>
      {currentDrawer.matches(TAGS.TOUR) && (
        <FavoirteTourList
          country={country}
          feature={feature}
          userId={userId}
          onClickCard={onOpenContentDrawerPage}
          closeDrawer={closeDrawer}
        />
      )}
      {currentDrawer.matches(TAGS.ATTRACTION) && (
        <FavoirteAttractionList
          country={country}
          feature={feature}
          area={area}
          userId={userId}
          onClickCard={onOpenContentDrawerPage}
          closeDrawer={closeDrawer}
        />
      )}
    </>
  );
};

export default TabFavorite;
