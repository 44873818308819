import React, { useCallback } from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { Button, Box, Breadcrumb, Form, Flex, Input, Link, Notification } from '@cowsquare/design';
import { Field, Form as FinalForm } from 'react-final-form';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { Banners } from '../components/Banners';
import { Section } from '../components/Section';
import { HTMLContent } from '../components/HTMLContent';
import { getUri } from '../../utils/getUri';
import * as rules from '../../utils/validate';
import { useBannersQuery, useStaticContentQuery } from '../../utils/hooks';

const Wrapper = styled(Box).attrs({ width: 1 })`
  textarea {
    height: 96px;
  }
`;
const Textarea = props => (
  <Wrapper>
    <Input p="8px" as="textarea" {...props} />
  </Wrapper>
);

const INSERT_STUDY_TOUR_APPLICANT = gql`
  mutation INSERT_STUDY_TOUR_APPLICANT($input: vontravel_study_tour_applicants_insert_input!) {
    insert_vontravel_study_tour_applicants(objects: [$input]) {
      returning {
        name
        email
        phone
        content {
          content
        }
      }
    }
  }
`;

const StudyTour = () => {
  const location = useLocation();
  const uri = getUri(location);
  const { banners } = useBannersQuery(uri);
  const { content } = useStaticContentQuery(uri);
  const [insertStudyTourApplicant] = useMutation(INSERT_STUDY_TOUR_APPLICANT);
  const onSubmit = useCallback(
    async values => {
      const content = R.pipe(
        R.propOr(null, 'note'),
        R.unless(R.isNil, value => ({ data: { content: value } }))
      )(values);

      await insertStudyTourApplicant({
        variables: {
          input: R.pipe(R.pick(['name', 'phone', 'email']), R.assoc('content', content))(values),
        },
      });

      Notification.success({
        message: '已送出表單',
        description: '感謝您填寫表單！專員會儘快與您聯絡！',
      });
    },
    [insertStudyTourApplicant]
  );

  return (
    <>
      <Banners banners={banners} itemProps={{ height: '424px' }} />
      <Section flexDirection="column" mx="auto" px={[5, 0]}>
        <Box mt={6}>
          <Breadcrumb>
            <Breadcrumb.Item linkProps={{ as: Link, to: '/' }}>首頁</Breadcrumb.Item>
            <Breadcrumb.Item linkProps={{ as: Link, to: `/更多` }}>更多</Breadcrumb.Item>
            <Breadcrumb.Item>遊學團</Breadcrumb.Item>
          </Breadcrumb>
        </Box>
        <Box mt={6}>
          <HTMLContent html={content} />
        </Box>
        <Flex flexDirection="column" alignItems="center" mt={5} mb={'80px'}>
          <Box width={[1, '504px']}>
            <FinalForm
              onSubmit={onSubmit}
              render={({ handleSubmit, submitting, form }) => (
                <>
                  <Form
                    onSubmit={event => {
                      handleSubmit(event).then(form.reset);
                    }}
                  >
                    <Field name="name" validate={rules.required('此欄位必填')}>
                      {({ input, meta }) => (
                        <Form.FormItem input={input} meta={meta} label="姓名" required>
                          <Input {...input} placeholder="請填寫真實姓名" size="large" />
                        </Form.FormItem>
                      )}
                    </Field>
                    <Field name="phone" validate={rules.required('此欄位必填')}>
                      {({ input, meta }) => (
                        <Form.FormItem input={input} meta={meta} label="電話" required>
                          <Input {...input} placeholder="請填寫您的電話" size="large" />
                        </Form.FormItem>
                      )}
                    </Field>
                    <Field name="email" validate={rules.isEmail('請輸入正確的 Email')}>
                      {({ input, meta }) => (
                        <Form.FormItem input={input} meta={meta} label="Email" required>
                          <Input {...input} type="email" placeholder="請填寫您的Email" size="large" />
                        </Form.FormItem>
                      )}
                    </Field>
                    <Field name="note">
                      {({ input, meta }) => (
                        <Form.FormItem input={input} meta={meta} label="備註">
                          <Textarea {...input} placeholder="備註說明" />
                        </Form.FormItem>
                      )}
                    </Field>
                    <Flex justifyContent="flex-end" alignItems="center">
                      <Button disabled={submitting} type="submit" variant="primary" size="large">
                        送出
                      </Button>
                    </Flex>
                  </Form>
                </>
              )}
            />
          </Box>
        </Flex>
      </Section>
    </>
  );
};

export default StudyTour;
