import React from 'react';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { Flex } from '@cowsquare/design';
import { ContactIcon } from './Icons';

const Icon = styled.div`
  color: ${themeGet('colors.primary')};
`;

const Wrapper = styled(Flex)`
  position: fixed;
  right: 16px;
  bottom: 80px;
  border-radius: 50% 50% 0;
  background-color: ${themeGet('colors.whites.0')};
  box-shadow: 0 10px 20px 0 ${themeGet('colors.blacks.9')};
  cursor: pointer;

  &:hover {
    background-color: ${themeGet('colors.primary')};
    ${Icon} {
      color: ${themeGet('colors.whites.0')};
    }
  }

  &:active,
  &:focus {
    background-color: ${themeGet('colors.primaryDark')};
    ${Icon} {
      color: ${themeGet('colors.whites.0')};
    }
  }
`;

const ContactButton = ({ onClick }) => {
  return (
    <Wrapper width={60} height={60} justifyContent="center" alignItems="center" onClick={onClick}>
      <Icon>
        <ContactIcon />
      </Icon>
    </Wrapper>
  );
};

export default ContactButton;
