export var ICON_NAME_MAP = {
  SHARE: '\\e802',
  LIKE: '\\e800',
  EMAIL: '\\e804',
  ATTACHMENT: '\\e805',
  PHONE: '\\e806',
  TIME: '\\e807',
  LOCATION: '\\e808',
  ARROW: '\\e809',
  USER: '\\e821',
  NEXT: '\\e80a',
  EYE: '\\e80b',
  DOWNLOAD: '\\e80d',
  SUCCESS: '\\e81f',
  ERROR: '\\e820',
  CALENDAR: '\\e811',
  DELETE: '\\e81d',
  ARROW_UP: '\\e80f',
  ARROW_DOWN: '\\e810',
  ARROW_LEFT: '\\e812',
  ARROW_RIGHT: '\\e813',
  ADVANCED_SEARCH: '\\e814',
  SEARCH: '\\e815',
  PASSWORD: '\\e801',
  LINK: '\\e816',
  ORGANIZATION: '\\e818',
  POINT: '\\e817',
  LIST: '\\e819',
  MAP: '\\e81a',
  CLOSE: '\\e81b',
  MENU: '\\e81c',
  ADD_SQUARE: '\\e81e',
  CAR: '\\e803',
  CAR_FILL: '\\e827',
  MONEY: '\\e80c',
  MONEY_CIRCLE_FILL: '\\e826',
  HEART: '\\e822',
  HEART_FILL: '\\e823',
  DATE: '\\e824',
  LOCATION_FILL: '\\e825',
  FACEBOOK: '\\e80e',
  LOGOUT: '\\e828',
  LOGIN: '\\e82b',
  CART: '\\e82a',
  STAR: '\\e82e',
  STAR_FILL: '\\e82b'
};