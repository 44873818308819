import gql from 'graphql-tag';

export const GET_USER_INFO = gql`
  query {
    user: me {
      id
      name
      email
      role
      phone
    }
  }
`;

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!, $organizationId: String) {
    login(email: $email, password: $password, organizationId: $organizationId) {
      token
    }
  }
`;

export const FORGET_PASSWORD = gql`
  mutation ForgetPassword($email: String!, $organizationId: String!) {
    forget_password_by_org(email: $email, organizationId: $organizationId)
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($token: String!, $newPassword: String!) {
    reset_password(password: $newPassword, token: $token)
  }
`;
