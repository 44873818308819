import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as R from 'ramda';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { Box, Flex, Tag, Text } from '@cowsquare/design';
import TourCard from '../components/TourCard';
import { Section } from '../components/Section';
import { EasyCard } from '../components/EasyCard';
import { COUNTRIES, HOMESTAY_COUNTRIES } from '../../constants/country';
import { getHTMLString } from '../../utils/getHTMLString';
import { getFeature } from '../../utils/getFeature';
import { useUser } from '../../utils/hooks';
import AddToPlanButton from '../components/AddToPlanButton';
import { InfoCardSection } from '../components/InfoCardSection';
import { TourCardLoading } from 'views/components/Loading';
import { Banners } from 'views/components/Banners';

const HotSection = styled(Section).attrs({
  mt: 6,
  mb: [-5, '-48px'],
})``;

const InfoCardWrapper = styled(Box)`
  width: 100%;
  background-color: ${themeGet('colors.whites.0')};
`;

const SubTitle = styled(Box).attrs({
  as: 'h2',
})`
  font-size: 36px;
  font-weight: 500;
  line-height: 1.5;
  color: ${themeGet('colors.blacks.0')};
`;

const TravelCardBox = styled(Box).attrs({
  width: [1, 1 / 3],
  px: [5, 4],
  pb: [5, '48px'],
})``;

const EasyCardBox = styled(Box).attrs({
  width: [1, 1 / 3],
  px: [5, 4],
  pb: [5, '48px'],
})``;

const GET_HOME_DATA = gql`
  query getHomeData($userId: uuid, $isLogin: Boolean!) {
    banners: vontravel_banners(where: { page: { name: { _eq: "/首頁/" } } }) {
      id
      name
      description
      file {
        link
      }
    }

    tours: vontravel_tours(limit: 6, order_by: { created_at: desc, name: asc }) {
      id
      cover {
        link
      }
      name
      description {
        content
      }
      features {
        feature {
          id
          name
        }
      }
      country {
        name
      }
      users(where: { user_id: { _eq: $userId } }) @include(if: $isLogin) {
        user_id
      }
      isCharter
    }

    features: vontravel_hot_features(limit: 6, order_by: { created_at: desc }) {
      feature {
        name
        banner {
          id
          link
        }
      }
    }
  }
`;

export default () => {
  const history = useHistory();
  const { user } = useUser();
  const userId = R.propOr(null, 'id', user);
  const { data, loading } = useQuery(GET_HOME_DATA, {
    variables: {
      userId,
      isLogin: Boolean(userId),
    },
  });
  const banners = R.propOr([], 'banners')(data);
  const tours = R.propOr([], 'tours')(data);

  return (
    <>
      <Banners banners={banners} />
      <Flex flexDirection="column" justifyContent="center" alignItems="center" mb="80px">
        <InfoCardWrapper>
          <InfoCardSection />
        </InfoCardWrapper>
        <SubTitle mt="80px" mb={0}>
          熱門自由旅程
        </SubTitle>
        <HotSection>
          {loading
            ? R.times(
                i => (
                  <TravelCardBox key={i}>
                    <TourCardLoading />
                  </TravelCardBox>
                ),
                3
              )
            : R.map(tour => (
                <TravelCardBox key={tour.id}>
                  <TourCard
                    id={tour.id}
                    collectible={!R.pipe(R.propOr([], 'users'), R.isEmpty)(tour)}
                    as={Link}
                    to={`/自由旅程/${tour.country.name}/所有旅程/${tour.name}`}
                    coverUrl={R.pathOr('', ['cover', 'link'], tour)}
                    title={tour.name}
                    description={R.pipe(R.pathOr('', ['description', 'content']), getHTMLString)(tour)}
                    tags={R.pipe(
                      getFeature,
                      R.map(tag => <Tag key={tag.id}>{tag.name}</Tag>)
                    )(tour)}
                    links={
                      <AddToPlanButton
                        onClick={e => {
                          e.preventDefault();
                          history.push(
                            `/自助設計優遊/新計劃?cardType=tour&cardName=${tour.name}&country=${R.pathOr(
                              '',
                              ['country', 'name'],
                              tour
                            )}`
                          );
                        }}
                      />
                    }
                    country={R.pathOr('', ['country', 'name'], tour)}
                    isCharter={tour.isCharter}
                  ></TourCard>
                </TravelCardBox>
              ))(tours)}
        </HotSection>
        <SubTitle mt="80px" mb={0}>
          熱門優遊地點
        </SubTitle>
        <HotSection>
          {R.map(country => (
            <EasyCardBox key={country.name}>
              <EasyCard
                as={Link}
                to={`/優遊地點/${country.name}`}
                img={country.cover}
                title={country.name}
              ></EasyCard>
            </EasyCardBox>
          ))(COUNTRIES)}
        </HotSection>
        <SubTitle mt="80px" mb={0}>
          熱門主題優遊
        </SubTitle>
        <HotSection>
          {R.pipe(
            getFeature,
            R.map(feature => (
              <EasyCardBox key={feature.name}>
                <EasyCard
                  as={Link}
                  to={`/主題優遊/${feature.name}`}
                  img={R.pathOr('', ['banner', 'link'])(feature)}
                  title={feature.name}
                ></EasyCard>
              </EasyCardBox>
            ))
          )(data)}
        </HotSection>
        <SubTitle mt="80px" mb={0}>
          民宿優選
        </SubTitle>
        <Text mt={4} px={[5, 0]} textAlign="center">
          旅遊中透過特色民宿感受不一樣的住宿設計，或貼近自然景觀和生態環境，讓自己融入當地的生活和美景之中
        </Text>
        <HotSection>
          {R.map(country => (
            <EasyCardBox key={country.name}>
              <EasyCard
                as={Link}
                to={country.available ? `/民宿優選/${country.name}` : ''}
                img={country.cover}
                title={country.name}
                showMask={!country.available}
                maskText="即將推出"
              />
            </EasyCardBox>
          ))(HOMESTAY_COUNTRIES)}
        </HotSection>
      </Flex>
    </>
  );
};
