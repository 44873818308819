import React, { useCallback } from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { Box, Flex, Input, Button, Form, Notification } from '@cowsquare/design';
import { themeGet } from '@styled-system/theme-get';
import { Link } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Form as FinalForm } from 'react-final-form';
import { Field } from 'react-final-form-html5-validation';
import { imageSwitch } from '../../components/ResponsiveImage';

const Wrapper = styled(Box).attrs({
  height: [292, 228],
})`
  width: 100%;
  text-align: center;
  background-image: url("${props => props.bannerUrl}");
  background-position: center center;
  background-size: cover;
`;

const Mask = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  background-color: ${themeGet('colors.blacks.5')};
`;

const Title = styled(Box).attrs({
  mb: 6,
})`
  font-size: ${themeGet('fontSizes.h2')};
  font-weight: 500;
  color: ${themeGet('colors.whites.0')};
`;

const Text = styled(Box).attrs({
  mt: 5,
})`
  font-size: ${themeGet('fontSizes.text')};
  color: ${themeGet('colors.whites.0')};
`;

const StyledLink = styled(Link)`
  color: ${themeGet('colors.whites.0')};
  text-decoration: underline;
`;

const INSERT_NEWSLETTER_APPLICANT = gql`
  mutation InsertNewsLetterApplicant($objects: [vontravel_newsletter_applicants_insert_input!]!) {
    insert_vontravel_newsletter_applicants(
      objects: $objects
      on_conflict: { constraint: newsletter_applicants_email_key, update_columns: email }
    ) {
      affected_rows
    }
  }
`;

const GET_NEWSLETTER_BANNERS = gql`
  query getNewsletterBanners {
    newsletter_banners: vontravel_newsletter_banners {
      id
      file {
        link
      }
    }
  }
`;

const Epaper = () => {
  const [insertNewsletterApplicant] = useMutation(INSERT_NEWSLETTER_APPLICANT);

  const { data } = useQuery(GET_NEWSLETTER_BANNERS);
  const newsletterBannerUrl = R.pipe(
    R.propOr([], 'newsletter_banners'),
    R.head,
    R.pathOr(imageSwitch('epaper-background-image.jpeg'), ['file', 'link'])
  )(data);

  const onSubmit = useCallback(
    formValues =>
      insertNewsletterApplicant({
        variables: {
          objects: {
            email: formValues.email,
          },
        },
      }).then(res => {
        if (!res.errors) {
          Notification.success({
            message: '您已成功訂閱電子報',
            description: '感謝您的訂閱！您將以電郵的形式收到我們最新的優惠訊息！',
          });
        }
      }),
    [insertNewsletterApplicant]
  );

  return (
    <Wrapper bannerUrl={newsletterBannerUrl}>
      <Mask>
        <Title>獲得第一手優惠訊息</Title>
        <FinalForm initialValues={{ email: '' }} onSubmit={onSubmit}>
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Flex flexDirection={['column', 'row']} justifyContent="center" alignItems="center">
                <Box width={[288, 328]}>
                  <Field name="email" typeMismatch="請輸入正確的電郵格式" valueMissing="請輸入您的電郵地址">
                    {({ input }) => (
                      <Input
                        {...input}
                        mr={[0, 4]}
                        size="large"
                        placeholder="輸入您的電郵地址"
                        type="email"
                        required
                      />
                    )}
                  </Field>
                </Box>
                <Box width={100} mt={[5, 0]}>
                  <Button variant="primary" size="large">
                    訂閱電子報
                  </Button>
                </Box>
              </Flex>
            </Form>
          )}
        </FinalForm>
        <Text>
          點擊「訂閱電子報」，即表示您已閱讀並同意我們的 <StyledLink to="/更多/隱私政策">隱私政策</StyledLink>
        </Text>
      </Mask>
    </Wrapper>
  );
};

export default Epaper;
