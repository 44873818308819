import { useQuery } from '@apollo/react-hooks';
import { Box, Breadcrumb, Button, Carousel, Flex, Icon, Tag } from '@cowsquare/design';
import { themeGet } from '@styled-system/theme-get';
import gql from 'graphql-tag';
import queryString from 'query-string';
import * as R from 'ramda';
import React, { useCallback, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { borderRight } from 'styled-system';
import { sum } from 'utils/sum';
import AddToFreePlannerBar from 'views/components/AddToFreePlannerBar';
import ContactButton from 'views/components/ContactButton';
import CreateTourFormPick from 'views/components/CreateTourFormPick';
import { TourCardLoading } from 'views/components/Loading';
import { COUNTRY_MAP } from '../../constants/country';
import { getHTMLString } from '../../utils/getHTMLString';
import { useUser } from '../../utils/hooks';
import AddToPlanButton from '../components/AddToPlanButton';
import { HTMLContent } from '../components/HTMLContent';
import { Section } from '../components/Section';
import TourCard from '../components/TourCard';

const StyledIcon = styled(Icon).attrs({
  fontSize: 32,
})`
  color: ${props => props.color || themeGet('colors.primary')(props)};
  margin-right: 8px;
`;

const Description = styled(Box).attrs({
  mt: 6,
})`
  font-size: ${themeGet('fontSizes.h5')};
  line-height: 1.5;
  color: ${themeGet('colors.blacks.3')};
`;

const InfoBox = styled(Box).attrs({
  width: [1, 1 / 3],
  pl: '24px',
  display: 'flex',
  alignItems: 'center',
  borderRight: [null, '1px solid'],
})`
  ${borderRight}
  height: 64px;
  && {
    border-color: ${themeGet('colors.blacks.8')};
  }
`;

const InfoFlex = styled(Flex).attrs({
  width: 1,
  height: ['192px', '112px'],
  mt: ['-56px'],
  flexDirection: ['column', 'row'],
  justifyContent: 'space-between',
  alignItems: 'center',
})`
  z-index: 1;
  background-color: ${themeGet('colors.whites.0')};
  border-left-top-radius: 8px;
  border-right-top-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.05);
  & > :last-child {
    border: none;
  }
`;

const TourCardBox = styled(Box).attrs({
  width: [1, 1 / 3],
  pb: ['24px', '48px'],
  px: [0, 4],
})``;

const ContentBox = styled(Box)`
  background-color: ${themeGet('colors.whites.0')};
`;

const Title = styled(Box).attrs({ as: 'h1', m: 0 })`
  text-align: center;
  font-size: ${themeGet('fontSizes.h1')};
  line-height: 1.5;
  font-weight: 500;
`;

const SubTitle = styled(Box).attrs({ as: 'h2', m: 0, mb: 30 })`
  font-size: ${themeGet('fontSizes.h2')};
  line-height: 1.5;
  font-weight: normal;
  text-align:center
`;
const ActionBox = styled(Box).attrs({
  width: '100%',
  mb: '64px',
  px: ['16px', '24px'],
  pt: ['16px', '24px'],
  pb: '24px',
})`
  box-shadow: ${themeGet('card.boxShadow')};
  background-color: ${themeGet('card.backgroundColor')};
  border: ${themeGet('card.border')};
  border-bottom-left-radius: ${themeGet('card.borderRadius')};
   border-bottom-right-radius: ${themeGet('card.borderRadius')};
  border-color: ${themeGet('card.borderColor')};
`;
const GET_RECOMMEND_TOUR = gql`
  query getRecommendTour(
    $country_id: uuid
    $name: String!
    $features: [String!]
    $userId: uuid
    $isLogin: Boolean!
  ) {
    tours: vontravel_tours(
      limit: 3
      where: {
        _not: { name: { _eq: $name } }
        features: { feature: { name: { _in: $features } } }
        _or: [{ country_id: { _eq: $country_id } }]
      }
    ) {
      id
      name
      price
      days
      cover {
        link
      }
      content {
        content
      }
      description {
        content
      }
      features {
        feature {
          id
          name
        }
      }
      users(where: { user_id: { _eq: $userId } }) @include(if: $isLogin) {
        user_id
      }
      isCharter
    }
  }
`;

const GET_TOUR_RECORD = gql`
  query getTourRecord($country: String!, $name: String!) {
    record: vontravel_tours(where: { name: { _eq: $name }, country: { name: { _eq: $country } } }) {
      tour_no
      date_range
      type_date_range
      open_booking
      name
      price
      days
      cover {
        link
      }
      content {
        content
      }
      description {
        content
      }
      features {
        feature {
          id
          name
        }
      }
      country_id
      file {
        id
        link
      }
    }
  }
`;


const numberWithCommas = x => x.toLocaleString();

const FreeTourContent = ({ country, name }) => {
  const history = useHistory();
  const { user } = useUser();
  const userId = R.propOr(null, 'id', user);
  const countryName = COUNTRY_MAP[country];

  if (!countryName) {
    // TODO: When 404 page is done, change redirect url to 404 page.
    history.push('/');
  }

  const { data: tourContent } = useQuery(GET_TOUR_RECORD, {
    variables: { country: countryName, name },
  });

  const record = R.pathOr({}, ['record', '0'], tourContent);

  const handleContactButton = useCallback(() => {
    window.open(
      `/更多/聯絡我們?${queryString.stringify({
        type: 'tour',
        object: name,
        no: R.propOr(null, 'tour_no')(record),
      })}`,
      '聯絡我們'
    );
  }, [name, record]);
  const features = R.pipe(R.propOr([], 'features'), R.map(R.path(['feature', 'name'])))(record);
  const { data: recommendTours, loading: recommendToursLoading } = useQuery(GET_RECOMMEND_TOUR, {
    variables: {
      name,
      country_id: R.propOr(null, 'country_id')(record),
      features,
      userId,
      isLogin: Boolean(userId),
    },
  });

  const tours = R.propOr([], 'tours', recommendTours);
  const showTours = R.gt(tours.length, 1);
  const card = record;

  const initialValues = useMemo(
    () => ({
      startDate: new Date(),
      endDate: new Date(),
      infants: 0,
      children: 0,
      teenagers: 0,
      adults: 0,
      elders: 0,
      country,
    }),
    [country]
  );
  const handleSubmit = ({
    country,
    startDate,
    endDate,
    infants = 0,
    children = 0,
    teenagers = 0,
    adults = 0,
    elders = 0,
  }) => {
    if (sum([infants, children, teenagers, adults, elders]) === 0) {
      return {
        infants: '請輸入旅行人數；至少要有一名成年人同行',
        children: true,
        teenagers: true,
        adults: true,
        elders: true,
      };
    }
    if (sum([adults, elders]) === 0) {
      return {
        adults: '至少要有一名成年人同行',
        elders: true,
      };
    }

  };
  return (
    <>
      <Carousel>
        <Carousel.Slide
          title={R.propOr('', 'name', record)}
          backgroundImage={`url(${R.pathOr('', ['cover', 'link'], record)})`}
          height={'304px'}
        />
      </Carousel>
      <Section flexDirection="column" m="0 auto" px={[5, 0]}>
        <InfoFlex>
          <InfoBox>
            <StyledIcon name="LOCATION_FILL" />
            {countryName}
          </InfoBox>
          <InfoBox>
            <StyledIcon name="DATE" color="#38c296" />
            {`${R.propOr('', 'days', record)}日遊`}
          </InfoBox>
          <InfoBox>
            <StyledIcon name="MONEY_CIRCLE_FILL" color="#ec951a" />
            {`${R.pipe(R.propOr(0, 'price'), numberWithCommas)(record)}起 (每人)`}
          </InfoBox>
        </InfoFlex>
        {
          record.open_booking ?
         <ActionBox>
          <Title>建立自己的旅行計劃</Title>
          <SubTitle> 隨心所欲的旅程從現在開始</SubTitle>
          <CreateTourFormPick
            initialValues={initialValues}
            price={card.price}
            nameT={card.name}
            date_range={record.date_range}
            type_date_range={record.type_date_range }
            onSubmit={handleSubmit}
            minDuration={card && card.days ? card.days : 0}
          />
        </ActionBox>
        : null }
        <Box mt={6}>
          <Breadcrumb>
            <Breadcrumb.Item linkProps={{ as: Link, to: '/' }}>首頁</Breadcrumb.Item>
            <Breadcrumb.Item linkProps={{ as: Link, to: `/自由旅程/${countryName}` }}>
              自由旅程-{countryName}
            </Breadcrumb.Item>
            <Breadcrumb.Item linkProps={{ as: Link, to: `/自由旅程/${countryName}/所有旅程` }}>
              所有{countryName}自由旅程
            </Breadcrumb.Item>
            <Breadcrumb.Item>{R.propOr('', 'name', record)}</Breadcrumb.Item>
          </Breadcrumb>
        </Box>
        <Box mt={6} mb={-4}>
          <Tag.Group flexWrap="wrap" itemStyle={{ mr: '4px', mb: 4 }}>
            {R.map(
              feature => (
                <Tag key={feature}>{feature}</Tag>
              ),
              features
            )}
          </Tag.Group>
        </Box>
        <Description>
          <HTMLContent html={R.pathOr('', ['description', 'content'], record)} />
        </Description>
      </Section>
      <ContentBox>
        <Section margin="48px auto 0px" py="48px" px={[5, 0]}>
          <HTMLContent html={R.pathOr('', ['content', 'content'], record)} />
        </Section>
        <Section margin="48px auto 0px" py="48px" px={[5, 0]}>
          {record.file && (
            <Button
              variant="primary"
              size="large"
              onClick={() => {
                window.open(R.pathOr('', ['file', 'link'], record));
              }}
            >
              下載旅程文件
            </Button>
          )}
        </Section>
      </ContentBox>
      {showTours && (
        <Section flexDirection="column" margin="80px auto" px={[5, 0]}>
          <Title>你可能還會喜歡</Title>
          <Flex
            flexDirection={['column', 'row']}
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
            mt={6}
            mx={[0, -4]}
            mb={['-24px', '-48px']}
          >
            {recommendToursLoading
              ? R.times(() => <TourCardLoading />, 3)
              : R.map(
                  item => (
                    <TourCardBox key={item.id}>
                      <TourCard
                        id={item.id}
                        collectible={!R.pipe(R.propOr([], 'users'), R.isEmpty)(item)}
                        as={Link}
                        to={`/自由旅程/${countryName}/所有旅程/${item.name}`}
                        coverUrl={R.pathOr('', ['cover', 'link'], item)}
                        height="460px"
                        title={item.name}
                        description={R.pipe(R.pathOr('', ['description', 'content']), getHTMLString)(item)}
                        tags={R.pipe(
                          R.propOr([], 'features'),
                          R.pluck('feature'),
                          R.map(x => <Tag key={x.id}>{x.name}</Tag>)
                        )(item)}
                        links={
                          <AddToPlanButton
                            onClick={e => {
                              e.preventDefault();
                              history.push(
                                `/自助設計優遊/新計劃?cardType=tour&cardName=${item.name}&country=${country}`
                              );
                            }}
                          />
                        }
                        country={countryName}
                        isCharter={item.isCharter}
                      />
                    </TourCardBox>
                  ),
                  tours
                )}
          </Flex>
        </Section>
      )}
      <AddToFreePlannerBar country={country} name={name} type="tour"></AddToFreePlannerBar>
      <ContactButton onClick={handleContactButton} />
    </>
  );
};
export default FreeTourContent;
